import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import { extendedApiSlice } from "./features/admin/adminApiSlice/adminApiSlice";
import { getAdminStatus } from "./features/auth/authThunks/authThunks";
import "./index.css";

const container = document.getElementById("root")!;
const root = createRoot(container);

store.dispatch(getAdminStatus());
store.dispatch(
  extendedApiSlice.endpoints.getAdmins.initiate({ page: "1", limit: "500" })
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
