import { apiSlice } from "../../../app/api/apiSlice";
import { SearchResult } from "../../../types/types";
import { HighSchool } from "../types/types";

export const extendeHischoolApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchHigschoolwithagnacyName: builder.query<
      SearchResult[],
      { term: string; agancyName: string }
    >({
      query: ({ term, agancyName }) =>
        `/highschool/searchwithagancyname?term=${term}&agancyName=${agancyName}`,
      providesTags: (result, error, arg) => [
        { type: "HighSchool", id: "LIST" },
        ...result!.map((item) => ({
          type: "HighSchool" as const,
          id: item.id,
        })),
      ],
    }),
  }),
});

export const {
  useSearchHigschoolwithagnacyNameQuery,
  useLazySearchHigschoolwithagnacyNameQuery,
} = extendeHischoolApiSlice;
