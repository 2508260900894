import { EntityState } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import Loading from "../../../components/Loading/Loading";
import { Category } from "../../category/types/types";
import { selectedProject } from "../../home/appSlice/appslice";
import { SubCategory } from "../../subcategory/types/types";
import {
  useGetAllShopQuery,
  useLazyGetAllShopQuery,
} from "../api/shopApiSlice";
import ShopActionPresentation from "../presentation/shopActionPresentation/ShopActionPresentation";
import { ShopType } from "../types/type";
import ShopAddContainer from "./ShopAddContainer";
import ShopFilterContainer from "./ShopFilterContainer";
import ShopListContainer from "./ShopListContainer";
import { selectAdmin } from "../../auth/authSlice/authSlice";

const ShopContainer = () => {
  const project = useAppSelector(selectedProject);
  const [getAllShop, { isLoading, isError, isSuccess }] =
    useLazyGetAllShopQuery({
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });

  const [shopList, setShopList] = useState<EntityState<ShopType> | null>(null);

  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [image, setImage] = useState<string>(
    "https://api.gradify.app/static/download.ff.png"
  );
  const [title, setTitle] = useState<string | null>(null);
  const [initalStock, setInitialStock] = useState<number | null>(null);
  const [unlockLevel, setUnLockLevel] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [costPerItems, setCostPerItems] = useState<number | null>(null);
  const [gem, setGem] = useState<number | null>(null);
  const [store, setStore] = useState<boolean>(false);
  const [prizeWheel, setPrizeWheel] = useState<boolean>(false);
  const [term, setTerm] = useState<string>("null");
  const [sort, setSort] = useState<string>("-createdAt");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [reload, setReload] = useState<boolean>(false);
  const {
    data: shops,
    isLoading: shopsLoading,
    isSuccess: shopSuccess,
  } = useGetAllShopQuery({
    project: project!._id!,
    category: category?._id,
    subCategory: subCategory?._id!,
    page: page,
    limit: limit,
    sort: sort!,
    term: term!,
  });

  useEffect(() => {
    (async () => {
      if (project) {
        try {
          const list = await getAllShop({
            project: project._id!,
            category: category?._id,
            subCategory: subCategory?._id!,
            page: page,
            limit: limit,
            sort: sort!,
            term: term!,
          }).unwrap();
          console.log("list", list);
          setShopList(list);

          if (term !== "null") {
            setPage(1);
          } else {
            setPage(page);
          }
        } catch (error) {}
      }
    })();
  }, [project, category, subCategory, shops, term, sort, reload, page, limit]);

  const onClear = () => {
    setInitialStock(null);
    setTitle("");
    setUnLockLevel(null);
    setDescription(null);
    setCostPerItems(null);
    setGem(null);
    setStore(false);
    setPrizeWheel(false);
    setImage("https://api.gradify.app/static/download.ff.png");
  };

  const onTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setTerm(e.target.value);
    } else {
      setTerm("null");
    }
  };

  const onPrizeWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrizeWheel(e.target.checked);
  };

  const onStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStore(e.target.checked);
  };

  const onGem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGem(Number(e.target.value));
  };

  const onCostPerItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCostPerItems(Number(e.target.value));
  };

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onUnlockLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnLockLevel(Number(e.target.value));
  };

  const onInitialStock = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInitialStock(Number(e.target.value));
  };

  const onTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value);
    },
    [title]
  );

  const onImage = (url: string) => {
    setImage(url);
  };

  const openHandler = () => {
    setOpenAdd(true);
  };

  const closeHandler = () => {
    setOpenAdd(false);
  };

  const onCategory = (categroyselected: Partial<Category> | null) => {
    console.log("change category",categroyselected);
    setCategory(categroyselected);
  };

  const onSubCategory = (subCategoryselected: Partial<SubCategory> | null) => {
    setSubCategory(subCategoryselected);
  };

  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };

  const onReload = () => {
    setReload(!reload);
  };

  const onPage = (pageNumber: number) => {
    console.log(pageNumber);
    setPage(pageNumber);
  };

  const onLimit = (limitNumber: number) => {
    setLimit(limitNumber);
  };

  let content;
  if (isLoading || shopsLoading) {
    content = <Loading />;
  } else if (isSuccess && shopSuccess) {
    content = (
      <>
        <ShopFilterContainer
          category={category}
          onCategory={onCategory}
          subCategory={subCategory}
          onSubCategory={onSubCategory}
          defaultValue={category?._id ? category as string : category?._id as string}
          project={project!}
        />
        <ShopActionPresentation
          openAddHandler={openHandler}
          onTerm={onTerm!}
          term={term}
        />
        {openAdd ? (
          <ShopAddContainer
            onClear={onClear}
            closeHandler={closeHandler}
            image={image}
            onImage={onImage}
            title={title}
            onTitle={onTitle}
            initialStock={initalStock!}
            onInitialStock={onInitialStock}
            unlockLevel={unlockLevel}
            onUnlockLevel={onUnlockLevel}
            description={description}
            onDescription={onDescription}
            costPerItems={costPerItems}
            onCostPerItems={onCostPerItems}
            gem={gem}
            onGem={onGem}
            store={store}
            onStore={onStore}
            prizeWeel={prizeWheel}
            onPrizeWheel={onPrizeWheel}
            project={project!}
            onClose={closeHandler}
            onReload={onReload}
          />
        ) : null}
        {isSuccess && shopList && (
          <ShopListContainer
            shopList={shopList}
            onSort={onSort}
            sort={sort}
            page={page}
            limit={limit}
            onPage={onPage}
            onLimit={onLimit}
            shopCount={shops ? shops?.ids.length : 0}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ShopContainer;
