import React from "react";
import { Student } from "../../types/types";
import { useGetAllAwardsQuery } from "../../../awards/api/awardsApi";
import { useAppSelector } from "../../../../app/hooks";
import { accountSelected } from "../../slice/studentSlice";
import StudentActionPresentation from "../../presantation/studentActionPresentation/StudentActionPresentation";
import StudentAwardListPresentation from "../../presantation/studentAwardsListPresentation/StudentAwardListPresentation";
import { EntityId } from "@reduxjs/toolkit";
type StudentAwardListContainerProps = {
  student: Student;
  onRemoveAward: (id: EntityId) => void;
  xpDescriptoin: string | null;
  xpAmount: number | null;
  gemAmount: number | null;
  onXpDescripton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onXpAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGemAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenAward: () => void;
  onCloseAward: () => void;
  openAward: boolean;
  onAddAward: () => void;
};
const StudentAwardListContainer: React.FC<StudentAwardListContainerProps> = ({
  student,
  onRemoveAward,
  gemAmount,
  onAddAward,
  onCloseAward,
  onGemAmount,
  onOpenAward,
  onXpAmount,
  onXpDescripton,
  openAward,
  xpAmount,
  xpDescriptoin,
}) => {
  const account = useAppSelector(accountSelected);
  const {
    data: awardList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllAwardsQuery({
    currentSystem: JSON.stringify(account),
    student: student?._id as string,
  });

  return (
    <>
      {isSuccess && (
        <StudentAwardListPresentation
          awardsList={awardList!}
          onRemoveAward={onRemoveAward}
          gemAmount={gemAmount}
          onAddAward={onAddAward}
          onGemAmount={onGemAmount!}
          onXpAmount={onXpAmount!}
          onXpDescripton={onXpDescripton!}
          xpAmount={xpAmount!}
          xpDescriptoin={xpDescriptoin!}
          openAward={openAward}
          onCloseAward={onCloseAward}
          onOpenAward={onOpenAward}
        />
      )}
    </>
  );
};

export default StudentAwardListContainer;
