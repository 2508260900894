import React, { useEffect, useState } from "react";
import RaffelTicketAddPresentation from "../presentation/RaffelTicketAddPresentation/RaffelTicketAddPresentation";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { Project } from "../../project/types/types";
import { useCreateRaffelTicketMutation } from "../api/raffelTicketApiSlice";
import Toast from "../../../components/toast/Toast";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../auth/authSlice/authSlice";
type RaffelTicketAddContainerProp = {
  openAdd: boolean;
  onOpenAdd: () => void;
  onClose: () => void;
  project: Partial<Project> | null;
};
const RaffelTicketAddContainer: React.FC<RaffelTicketAddContainerProp> = ({
  openAdd,
  onOpenAdd,
  onClose,
  project,
}) => {
  const user = useAppSelector(selectAdmin);
  const [createRaffelTicket, { isLoading, isError, isSuccess }] =
    useCreateRaffelTicketMutation();

  const [defaultImage, setDefaultImage] = useState<string>(
    "https://api.gradify.app/static/download.ff.png"
  );
  const [store, setStore] = useState<boolean>(true);
  const [gem, setGem] = useState<number | null>(1);
  const [prizeWheel, setPrizeWheel] = useState<boolean>(false);
  const [title, setTitle] = useState<string | null>(null);
  const [numberOfWinner, setNumberofWinner] = useState<number | null>(1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [drawDate, setDrawDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [drawTime, setDrawTime] = useState<string | null>(null);
  const [category, setCategory] = useState<Partial<Category> | null>(
    user?.adminLevel === "ADMIN"
      ? user!.categoryAccess!.length > 0
        ? user.categoryAccess![0].category!
        : null
      : null
  );
  const [subCategory, setSubCategory] = useState<Partial<SubCategory | null>>(
    user?.adminLevel === "ADMIN"
      ? user!.subCategoryAccess!.length > 0
        ? user.subCategoryAccess![0].subcategory!
        : null
      : null
  );
  const [description, setDescription] = useState<string | null>("");

  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [showToastSuccess, setshowToastSuccess] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [emptyfiled, setEmpthyfileds] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    console.log("run thisssss");
    const tomorrow = new Date();
    const daysAfter = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    daysAfter.setDate(daysAfter.getDate() + 2);

    const time = tomorrow.toTimeString().slice(0, 5);

    setStartDate(tomorrow);
    setEndDate(daysAfter);
    setDrawDate(daysAfter);
    setStartTime(time);
    setEndTime(time);
    setDrawTime(time);
  }, []);

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onCategory = (categorySelected: Partial<Category> | null) => {
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelected: Partial<SubCategory> | null) => {
    setSubCategory(subCategorySelected);
  };

  const onStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
    const nedate = startDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);

      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);
      console.log(localDate);

      setStartDate(localDate);
    }
  };
  const onEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);

    const nedate = endDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);
      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);
      console.log(localDate);
      setEndDate(localDate);
    }
  };

  const onDrawTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrawTime(e.target.value);

    const nedate = drawDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);
      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);
      console.log(localDate);
      setDrawDate(localDate);
    }
  };

  const onStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(new Date(e.target.value));
  };

  const onEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(new Date(e.target.value));
  };

  const onDrawDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrawDate(new Date(e.target.value));
  };

  const onNumberOfWinner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberofWinner(Number(e.target.value));
  };

  const onGem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGem(Number(e.target.value));
  };

  const onTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStore(e.target.checked);
  };

  const onPrizeWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrizeWheel(e.target.checked);
  };

  const onImage = (url: string) => {
    setDefaultImage(url);
  };

  const onSave = async () => {
    setSaveLoading(true);
    try {
      await createRaffelTicket({
        image: defaultImage!,
        store: store!,
        prizeWheel: prizeWheel!,
        title: title!,
        gem: gem!,
        numberWinner: numberOfWinner!,
        startDate: startDate!,
        endDate: endDate!,
        drawDate: drawDate!,
        category: category!,
        subCategory: subCategory!,
        project: project!,
        description: description!,
      }).unwrap();
      setshowToastSuccess(true);

      setTimeout(() => {
        setshowToastSuccess(false);
        setTitle("");
        setGem(1);
        setDefaultImage("https://api.gradify.app/static/download.ff.png");
        setStore(true);
        setPrizeWheel(false);
        setSaveLoading(false);
        setNumberofWinner(1);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const time = tomorrow.toTimeString().slice(0, 5);
        const daysAfter = new Date();

        daysAfter.setDate(daysAfter.getDate() + 2);
        setStartDate(tomorrow);
        setEndDate(daysAfter);
        setDrawDate(daysAfter);
        setStartTime(time);
        setEndTime(time);
        setDrawTime(time);
        setCategory(null);
        setSubCategory(null);
        setDescription("");
        setTitle("");
        onClose();
      }, 3000);
    } catch (error: any) {
      console.log(error);
      if (error.status === 400) {
        setToastMessage(error.data.message[0]);
      } else {
        setToastMessage(error.data.message);
      }
      setShowToastFail(true);
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
      setSaveLoading(false);
    }
  };
  return (
    <>
      <RaffelTicketAddPresentation
        openAdd={openAdd}
        onOpenAdd={onOpenAdd}
        onClose={onClose}
        image={defaultImage}
        onImage={onImage}
        store={store}
        onStore={onStore}
        prizeWheel={prizeWheel}
        onPrizeWheel={onPrizeWheel}
        title={title!}
        onTitle={onTitle}
        gem={gem}
        onGem={onGem}
        numberOfWinner={numberOfWinner}
        onNumberOfWinner={onNumberOfWinner}
        startDate={startDate}
        onStartDate={onStartDate}
        endDate={endDate}
        onEndDate={onEndDate}
        drawDate={drawDate}
        onDrawDate={onDrawDate}
        startTime={startTime}
        onStartTime={onStartTime}
        endTime={endTime}
        onEndTime={onEndTime}
        drawTime={drawTime}
        onDrawTime={onDrawTime}
        category={category}
        onCategory={onCategory}
        subCategory={subCategory}
        onSubCategory={onSubCategory}
        description={description}
        onDescription={onDescription}
        project={project}
        onSave={onSave}
        saveLoading={saveLoading}
      />
      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Raffle Save Successfuly" typeToast="Success" />
        </div>
      )}
    </>
  );
};

export default RaffelTicketAddContainer;
