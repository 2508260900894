import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 430px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  flex: 2;
  margin-right: 6px;
  overflow: scroll;
  padding-bottom: 20px;
  @media (max-width: 1650px) {
    width: 360px;
  }
  @media (max-width: 1500px) {
    width: 90%;
    min-width: 300px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;
export const Title = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-weight: 700;
  line-height: 45px;
  font-family: "Rajdhani";
  text-align: center;
`;
export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StatusText = styled.div`
  margin-top: 21px;
  margin-right: 76px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Rajdhani";
  line-height: inherit;
  text-align: center;
`;
export const ListWrapper = styled.table`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
`;
export const HeadTr = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
`;
export const HeaderInven = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;
export const HeadTh = styled.th`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  font-family: "Rajdhani";
  line-height: inherit;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
`;
export const RowTr = styled.tr`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-left: 36px;
`;
export const RowTd = styled.td`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  font-family: "Rajdhani";
  line-height: inherit;
  text-align: left;
  width: 250px;
  margin-bottom: 12px;
`;
export const RowInput = styled.input`
  border: none;
  &:active {
    border-color: #eee;
  }
  &:focus-visible {
    outline-width: 0;
    border: 1px solid #767676;
    padding: 3px;
    border-radius: 5px;
    max-width: 96%;
    margin: 0 2%;
  }
`;

export const UploadItemBtn = styled.button`
  background-color: #34c38f;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-family: "Rajdhani";
  line-height: inherit;
  text-align: left;
  width: 180px;
  height: 42px;
  border-radius: 4px;
  outline: none;
  border: none;
  position: absolute;
  right: 80px;
  padding-left: 20px;
`;
export const UploadItemIcon = styled.img`
  position: absolute;
  right: 10px;
  width: 24px;
  height: 30px;
`;
