import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { LeaderBoard } from "../types/types";
import { IResponseList } from "../../../types/types";

const leaderBoardAdaptor = createEntityAdapter<LeaderBoard>({
  selectId: (leaderBoard) => leaderBoard._id,
});

interface LeaderBoardInitialState extends EntityState<LeaderBoard> {}

const initialState: LeaderBoardInitialState =
  leaderBoardAdaptor.getInitialState();

export const extendedLeaderBoardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderBoard: builder.query<EntityState<LeaderBoard>, string>({
      query: (currentSystem) =>
        `student/leaderborad?currentsystem=${currentSystem}&sort=-xp`,
      transformResponse: (response: IResponseList<LeaderBoard[]>) => {
        const leaderBoardData = response.data;

        const leaderBoardMetaData = response.metadata;
        return leaderBoardAdaptor.setAll(initialState, leaderBoardData);
      },
      providesTags: (result, error, arg) => [
        {
          type: "LeaderBoard",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "LeaderBoard" as const, id })),
      ],
    }),
  }),
});

export const { useGetLeaderBoardQuery, useLazyGetLeaderBoardQuery } =
  extendedLeaderBoardApi;
