import { EntityId, EntityState } from "@reduxjs/toolkit";
import React from "react";
import { ExperienceCard } from "../../types/types";
import { StudentContainer } from "./experienceListPresentation.styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExperienceListItemPresentation from "./ExperienceListItemPresentation";
import images from "../../../../constants/images/images";
import { useAppSelector } from "../../../../app/hooks";
import { experienceMetadataSelected } from "../../api/experienceSlice";

type ExperienceListPresentationPorps = {
  list: EntityState<ExperienceCard>;
  onEdit: (id: EntityId) => void;
  onReview: (id: EntityId) => void;
  onSort: (fieldName: string) => void;
  sort: string;
  page?: number;
  limit?: number;
  onPage?: (pageNumber: number) => void;
  onLimit?: (limitNumber: number) => void;
};
const ExperienceListPresentation: React.FC<ExperienceListPresentationPorps> = ({
  list,
  onEdit,
  onReview,
  onSort,
  sort,
  page,
  limit,
  onPage,
  onLimit,
}) => {
  const experienceMeta: any = useAppSelector(experienceMetadataSelected);

  return (
    <StudentContainer>
      <div
        style={{
          width: "100%",
          marginTop: -40,
          background: "white",
          paddingTop: "15px",
          boxShadow: "0 10px 20px rgba(18, 38, 63, 0.03)",
        }}
      >
        <Table style={{ overflow: "visible", position: "relative" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("title");
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Title{" "}
                  {sort !== "-title" && sort !== "title" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-title") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "title" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("description");
                }}
              >
                <div
                  style={{
                    maxWidth: 370,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Description
                  {sort !== "-description" && sort !== "description" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-description") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "description" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("startDate");
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Duration{" "}
                  {sort !== "-startDate" && sort !== "startDate" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-startDate") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "startDate" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell style={{ cursor: "pointer" }} onClick={() => {}}>
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Location{" "}
                  {sort !== "-" && sort !== "" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("xpCompletion");
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Xp{" "}
                  {sort !== "-xpCompletion" && sort !== "xpCompletion" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-xpCompletion") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "xpCompletion" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("experienceType.title");
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Type{" "}
                  {sort !== "-experienceType.title" &&
                  sort !== "experienceType.title" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-experienceType.title") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "experienceType.title" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSort("completionType.title");
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Completion{" "}
                  {sort !== "-completionType.title" &&
                  sort !== "completionType.title" ? (
                    <img style={{ marginLeft: 4 }} src={images.unSelectIcon} />
                  ) : !!sort?.startsWith("-completionType.title") ? (
                    <img style={{ marginLeft: 4 }} src={images.BottomIcon} />
                  ) : (
                    sort === "completionType.title" && (
                      <img style={{ marginLeft: 4 }} src={images.topIcon} />
                    )
                  )}
                </div>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list!.ids.map((id) => (
              <ExperienceListItemPresentation
                item={list!.entities[id]!}
                onEdit={onEdit}
                onReview={onReview}
              />
            ))}
          </TableBody>
        </Table>
        {experienceMeta[0]?.toatlCount > 24 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={experienceMeta[0]?.toatlCount}
            rowsPerPage={limit!}
            page={page! - 1}
            onPageChange={(event: unknown, newPage: number) => {
              onPage!(newPage + 1);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              onLimit!(parseInt(event.target.value, 10));
            }}
          />
        )}
      </div>
    </StudentContainer>
  );
};
// this is test
export default ExperienceListPresentation;
