import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authreducer from "../features/auth/authSlice/authSlice";
import adminReducer from "../features/admin/adminSlice/adminSlice";
import organizationReducer from "../features/organization/organizationSlice/organizationSlice";
import projectReducer from "../features/project/projectSlice/projectSlice";
import categoryReducer from "../features/category/categorySlice/categorySlice";
import subCategoryReducer from "../features/subcategory/subCategorySlice/subCategorySlice";
import appReducer from "../features/home/appSlice/appslice";
import studentReducer from "../features/students/slice/studentSlice";
import shopReducer from "../features/shop/api/shopSlice";
import raffleReducer from "../features/raffelTickets/api/raffleTicketSlice";
import experienceReducer from "../features/experience/api/experienceSlice";
import organizatonMetaReducer from "../features/organization/api/organizationSlice";

//initial configuration for redux store
export const store = configureStore({
  //combine reducers
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authreducer,
    admin: adminReducer,
    organization: organizationReducer,
    project: projectReducer,
    category: categoryReducer,
    subCategory: subCategoryReducer,
    app: appReducer,
    student: studentReducer,
    shop: shopReducer,
    raffle: raffleReducer,
    experience: experienceReducer,
    organizationMeta: organizationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware
    ),
});

// get types for wroking with typescript with redux
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
