import React, { useState } from "react";
import HomeOrganizationPresentation from "../../presentation/HomeOrganizationPresentation/HomeOrganizationPresentation";
import { EntityId } from "@reduxjs/toolkit";
import { Organization } from "../../../organization/types/types";
import { HomeOrganizationContainerProps } from "../../types/types";

const HomeOrganizationContainer: React.FC<HomeOrganizationContainerProps> = ({
  organizatoinID,
  onSelectOrganization,
  term,
  onTerm,
}) => {
  return (
    <HomeOrganizationPresentation
      organizatoinID={organizatoinID}
      onSelectOrganization={onSelectOrganization}
      term={term}
      onTerm={onTerm}
    />
  );
};

export default HomeOrganizationContainer;
