import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 288px;
 
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 4px;
  margin-bottom: 20px;
  width: calc(100% - 62px);
  margin: 0 31px 10px 31px;
`;
export const BlackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108;
  width: 100%;
  background-color: black;
  border-radius: 4px;
`;
export const WhiteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 144px;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  flex-direction: row;

  @media (max-width: 1650px) {
    padding-left: 0;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const CountDown = styled.div`
  color: #3e3f5e;
  font-size: 28px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 36px;
  @media (max-width: 1300px) {
    font-size: 21px;
    line-height: 26px;
  }
`;
export const RemainingTime = styled.div`
  color: #b7b7bc;
  font-size: 27px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 34px;
  @media (max-width: 1300px) {
    font-size: 23px;
    line-height: 28px;
  }
`;
export const Winners = styled.div`
  color: #3e3f5e;
  font-size: 26px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 33px;
  @media (max-width: 1300px) {
    font-size: 21px;
    line-height: 25px;
  }
`;
export const WinnersName = styled.div`
  color: #ffba31;
  font-size: 25px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 32px;
  white-space: nowrap;
  @media (max-width: 1300px) {
    font-size: 21px;
    line-height: 26px;
  }
`;
export const Sold = styled.div`
  color: #3e3f5e;
  font-size: 50px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 64px;
  @media (max-width: 1300px) {
    font-size: 30px;
    line-height: 38px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  color: white;
  font-size: 72px;
  font-family: "Rajdhani";
  font-weight: 700;
  line-height: 75px;
  @media (max-width: 1650px) {
    font-size: 50px;
    line-height: 55px;
  }
`;
export const Desc = styled.div`
  color: white;
  font-family: "Rajdhani";
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 21px;
  }
`;
export const Raffle = styled.img`
  position: absolute;
  right: 63px;
`;
export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SectionCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Rajdhani";
  justify-content: flex-start;
  padding-top: 5px;
  @media (max-width: 1650px) {
    justify-content: center;
  }
`;
