import React from "react";
import HomeContainer from "../container/HomeContainer/HomeContainer";
import Header from "../../../components/header/Header";

const HomePage = () => {
  return (
    <>
      <Header title="Home" />
      <HomeContainer />
    </>
  );
};

export default HomePage;
