import React, { FC } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { selectOrganizationById } from "../../api/orgApiSlice";
import OrganizationItemPresentation from "../../presantation/organization.list.presentation/organixation.item.presentation/organization.item.presentation";
import { OrganizationItemContainerProps } from "../../types/types";
const OrganizationItemContainer: FC<OrganizationItemContainerProps> = ({
  organizationId,
  organizationItem,
}) => {
  // const organization = useAppSelector((state: RootState) =>
  //   selectOrganizationById(state, organizationId)
  //);
  return <OrganizationItemPresentation organization={organizationItem!} />;
};

export default OrganizationItemContainer;
