import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { uploadImageContainerProps } from "../../types/types";
import { useUploadFileMutation } from "../../features/uploadFile/uploadFileApiSlice/uploadFileApiSlice";
import { IMAGE_URL_PREFIX } from "../../constants";
import UPloadImagePresentation from "./presentation/UPloadImagePresentation";
import axios from "axios";
const UploadImageContainer: FC<uploadImageContainerProps> = ({
  onChangeImageUrlState,
  defaultImage,
  shop,
  hexShape,
}) => {
  const [imageUrl, setImageUrl] = useState<string>(defaultImage!);
  const [loading,setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (defaultImage) {
      setImageUrl(defaultImage);
    }
  }, [defaultImage]);

  // const [uploadFile, { isLoading }] = useUploadFileMutation();

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const formData = new FormData();

    const file = e.target.files![0];
    formData.append("file", file);

    try {
      const response = await axios.post(
        `https://api.gradify.app/api/upload-image/upload`,
        formData
      );

      setTimeout(() => {
        const urlGenarator = response.data.uri;
        console.log(urlGenarator);

        setImageUrl(urlGenarator);
        onChangeImageUrlState(urlGenarator);
        setLoading(false)
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UPloadImagePresentation
      imageUrl={imageUrl}
      onChagne={onChange}
      shop={shop}
      hexShape={hexShape}
      loading={loading}
    />
  );
};

export default UploadImageContainer;
