import React, { useEffect } from "react";
import AdminMainContainer from "../conatiner/admin.main.container/admin.main.container";
import Header from "../../../components/header/Header";

const AdminPage = () => {
  // const project = useAppSelector(selectedProject);
  // const navigate = useNavigate();

  return (
    <>
      <Header title="Admin" />
      <AdminMainContainer />
    </>
  );
};

export default AdminPage;
