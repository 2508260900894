import { EntityId, EntityState } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { Student } from "../../../students/types/types";
import ExperienceReviewPhotoItemPresentation from "../../presentation/experience.review.presentation/ExperienceReviewPhotoItemPresentation";
import Slider from "../../../../components/Slider/slider";
type ExperienceReviewPhotoSubmitionContainerPorps = {
  studentCompletionPhotoList: EntityState<Student>;
  onCompleteChange: (student: Student, completion: boolean) => void;
};
const ExperienceReviewPhotoSubmistionContainer: React.FC<
  ExperienceReviewPhotoSubmitionContainerPorps
> = ({ studentCompletionPhotoList, onCompleteChange }) => {
  const [showSlider, setShowSlider] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [photoArray, setPhotoArray] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [indexId, setIndexId] = useState<number>(0);
  const [imageId, setImageId] = useState<EntityId>(
    studentCompletionPhotoList.ids[indexId]
  );

  console.log(studentCompletionPhotoList.ids);

  const imageClickHandler = (id: EntityId) => {
    setShowSlider(true);
    const listPhoto = studentCompletionPhotoList.ids.filter(
      (item) => studentCompletionPhotoList.entities[item]?.photos
    );

    let indexId = listPhoto.findIndex((item) => item === id);

    if (listPhoto[indexId]) {
      setImageId(listPhoto[indexId]);
      setIndexId(indexId);
    }
    // setImageIndex(itemIndex);
    // const newSlide = photoArray?.filter((item: { _id: any }, index: any) => {
    //   if (itemId === item?._id) {
    //     return item;
    //   }
    // });
    // setGallery(newSlide);
  };

  const nextBtnHandler = () => {
    const listPhoto = studentCompletionPhotoList.ids.filter(
      (item) => studentCompletionPhotoList.entities[item]?.photos
    );
    console.log(listPhoto);

    if (listPhoto[indexId + 1]) {
      setImageId(listPhoto[indexId + 1]);
      setIndexId(indexId + 1);
    }
  };

  const leftBtnHandler = () => {
    const listPhoto = studentCompletionPhotoList.ids.filter(
      (item) => studentCompletionPhotoList.entities[item]?.photos
    );
    if (listPhoto[indexId - 1]) {
      setImageId(listPhoto[indexId - 1]);
      setIndexId(indexId - 1);
    }
  };

  const backdropHandler = () => {
    setShowSlider(false);
  };

  return (
    <>
      {showSlider && (
        <Slider
          item={studentCompletionPhotoList}
          id={imageId}
          nextBtnHandler={nextBtnHandler}
          leftBtnHandler={leftBtnHandler}
          backdropHandler={backdropHandler}
        />
      )}

      {studentCompletionPhotoList.ids.map((id) => (
        <ExperienceReviewPhotoItemPresentation
          key={id}
          item={studentCompletionPhotoList.entities[id]!}
          imageClicked={imageClickHandler}
          onCompleteChange={onCompleteChange}
        />
      ))}
    </>
  );
};

export default ExperienceReviewPhotoSubmistionContainer;
