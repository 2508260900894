import React from "react";
import {
  PrizeShopButton,
  SearchShop,
  SearchShopContainer,
  ShopButton,
  ShopHeaderControle,
} from "./shop.action.styles";
import { BiSearchAlt } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import { useAppSelector } from "../../../../app/hooks";
type ShopActionPresentationProps = {
  openAddHandler: () => void;
  onTerm?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  term?: string;
};
const ShopActionPresentation: React.FC<ShopActionPresentationProps> = ({
  openAddHandler,
  onTerm,
  term,
}) => {
  const admin = useAppSelector(selectAdmin);

  const accessProject = admin?.projectAccess?.find(
    (project) => project.accessType === "Full"
  );
  const accessCategory = admin?.categoryAccess?.find(
    (category) => category.accessType === "Full"
  );
  const accessSubCategory = admin?.subCategoryAccess?.find(
    (sub) => sub.accessType === "Full"
  );
  const permissionResult = accessProject || accessCategory || accessSubCategory;
  return (
    <ShopHeaderControle style={{ marginTop: -2, marginBottom:4 }}>
      <SearchShopContainer>
        <BiSearchAlt
          style={{ position: "absolute", top: 11, left: 18 }}
          color={"#707070"}
        />
        <SearchShop
          type="text"
          value={term === "null" ? "" : term}
          placeholder="Search Item"
          onChange={onTerm}
          autoFocus={true}
        />
      </SearchShopContainer>
      <ShopButton
        style={{
          paddingRight: 4,
          paddingTop: 6,
          paddingBottom: 6,
          fontSize: 16,
          marginLeft: 50,
        }}
        disabled={admin?.adminLevel === "ADMIN" && !permissionResult?.store}
        // spaceLeft="50px"
        onClick={openAddHandler}
      >
        <MdAddCircle size={32} />
        <span style={{ paddingRight: 18 }}>Item</span>
      </ShopButton>
      <PrizeShopButton spaceLeft="30px">
        <MdAddCircle size={32} />
        <span style={{ paddingRight: 25, whiteSpace: "nowrap" }}>
          Add Prize Wheel
        </span>
      </PrizeShopButton>
    </ShopHeaderControle>
  );
};

export default ShopActionPresentation;
