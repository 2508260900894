import React from "react";
import { useGetAllOrganizationQuery } from "../../api/orgApiSlice";
import SelectOrganizationListPresentation from "../../presantation/select.organization.presentation/select.organization.presentation";
import { SelectOrganizationListContainerProps } from "../../types/types";
import Loading from "../../../../components/Loading/Loading";
const SelectOrganizationListContainer: React.FC<
  SelectOrganizationListContainerProps
> = ({ onSelectOrganization }) => {
  const {
    data: organizationList,
    isLoading,
    isSuccess,
  } = useGetAllOrganizationQuery();

  const addOrganizationCollabrotive = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const getOrganization = organizationList?.entities[e.target.value];
    onSelectOrganization(getOrganization!);
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <SelectOrganizationListPresentation
        organizationList={organizationList}
        onSelectOrganization={addOrganizationCollabrotive}
      />
    );
  }
  return <>{content}</>;
};

export default SelectOrganizationListContainer;
