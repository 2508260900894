import { apiSlice } from "../../../app/api/apiSlice";
import { SearchResult } from "../../../types/types";

export const extendedSchoolDistrictApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchUniversity: builder.query<SearchResult[], string>({
      query: (term: string) => `university?term=${term}`,
      providesTags: (result, error, arg) => [
        { type: "University", id: "LIST" },
        ...result!.map((item) => ({
          type: "University" as const,
          id: item.id,
        })),
      ],
    }),
  }),
});

export const { useLazySearchUniversityQuery } = extendedSchoolDistrictApiSlice;
