import React, { StyleHTMLAttributes } from "react";
import { MdEdit } from "react-icons/md";
import {
  EditButtonContainer,
  EditButtonText,
  EditButtonIcon,
} from "../features/experience/presentation/experience.review.presentation/experience.review.styles";
import { selectAdmin } from "../features/auth/authSlice/authSlice";
import { useAppSelector } from "../app/hooks";

type EditButtonProps = {
  onClick: () => void;
  style: React.CSSProperties;
  children?: React.ReactNode;
};

const EditButton: React.FC<EditButtonProps> = ({
  style,
  onClick,
  children,
}) => {
  const user = useAppSelector(selectAdmin);
  console.log(
    "project store",
    user?.projectAccess?.length! > 0 && user?.projectAccess![0].store
  );
  console.log(
    "category store",
    user?.categoryAccess?.length! > 0 && user?.categoryAccess![0].store
  );
  console.log(
    "subCategory store",
    user?.subCategoryAccess?.length! > 0 && user?.subCategoryAccess![0].store
  );
  return (
    <EditButtonContainer
      style={style}
      onClick={onClick}
      disabled={
        user?.adminLevel === "ADMIN" &&
        !(
          user?.projectAccess![0].store ||
          user?.categoryAccess![0]?.store ||
          user?.subCategoryAccess![0]?.store
        )
      }
    >
      {children ? (
        children
      ) : (
        <>
          <EditButtonText>Edit</EditButtonText>
          <EditButtonIcon>
            <MdEdit color="#34C38F" size={17} />
          </EditButtonIcon>
        </>
      )}
    </EditButtonContainer>
  );
};

export default EditButton;
