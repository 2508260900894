import React from "react";
import { StudentActionPresentationProps } from "../../types/types";
import {
  SearchStudent,
  SearchStudentContainer,
  StudentHeaderControle,
  UploadStudentButton,
} from "./studentAction.styels";
import { BiSearchAlt } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { StudentButton } from "../../../experience/presentation/expereince.list.presentation/experienceListPresentation.styles";
import XpPanelPresentation from "../xpPaneelPresentation/XpPanelPresentation";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
const StudentActionPresentation: React.FC<StudentActionPresentationProps> = ({
  onOpenHandler,
  onSetTerm,
  gemAmount,
  onAddAward,
  onGemAmount,
  onXpAmount,
  onXpDescripton,
  xpAmount,
  xpDescriptoin,
  onCloseAward,
  onOpenAward,
  openAward,
  term,
  descriptionError,
}) => {
  const admin = useAppSelector(selectAdmin);
  const accessProject = admin?.projectAccess?.find(
    (project) => project.accessType === "Full"
  );
  const accessCategory = admin?.categoryAccess?.find(
    (category) => category.accessType === "Full"
  );
  const accessSubCategory = admin?.subCategoryAccess?.find(
    (sub) => sub.accessType === "Full"
  );
  const permissionResult = accessProject || accessCategory || accessSubCategory;
  return (
    <StudentHeaderControle style={{ maxWidth: 1490 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
          width: "65%",
        }}
      >
        <SearchStudentContainer>
          <BiSearchAlt
            spacing={18}
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchStudent
            type="text"
            placeholder="Search Student"
            value={term === "null" ? "" : term}
            onChange={onSetTerm}
          />
        </SearchStudentContainer>
        <>
          <StudentButton
            style={{
              paddingRight: 4,
              paddingTop: 6,
              paddingBottom: 6,
              fontSize: 16,
            }}
            spaceLeft="50px"
            onClick={onOpenHandler}
            disabled={admin?.adminLevel === "ADMIN" && !permissionResult?.users}
          >
            <span style={{ paddingRight: 18 }}>Add Student</span>{" "}
            <MdAddCircle size={32} />
          </StudentButton>
          <UploadStudentButton>
            <span style={{ paddingRight: 25, whiteSpace: "nowrap" }}>
              Upload Students
            </span>
            <FaFileUpload size={28} />
          </UploadStudentButton>
        </>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
          justifyContent: "flex-end",
          width: "32%",
        }}
      >
        <StudentButton
          style={{ borderRadius: 50, marginRight: 10, marginLeft: 10 }}
          onClick={onOpenAward}
          spaceLeft={""}
        >
          <FaPlus style={{ marginRight: 5 }} size={27} />
          <span>Award Points</span>
        </StudentButton>
        {openAward ? (
          <XpPanelPresentation
            gemAmount={gemAmount}
            xpAmount={xpAmount}
            onAddAward={onAddAward}
            onGemAmount={onGemAmount}
            onCloseAward={onCloseAward}
            onXpAmount={onXpAmount}
            onXpDescription={onXpDescripton}
            xpDescription={xpDescriptoin}
            style={{
              top: "230px",
              height: descriptionError ? "270px" : "242px",
            }}
            descriptionError={descriptionError}
          />
        ) : null}
      </div>
    </StudentHeaderControle>

    // <div>
    //   <input type="text" />
    //   <button onClick={onOpenHandler}>Add Student</button>
    // </div>
  );
};

export default StudentActionPresentation;
