import React from "react";
import './loading.css';
const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex:99999
      }}
    >
      <svg
        className='spinner-img'
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 589.34 589.34"
        width={60}
        height={60}

      >
        <defs>
          <style>
            {".cls-1{fill:#003267;}.cls-2{fill:#ffbf00;}.cls-3{fill:#1169c1;}"}
          </style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_28" data-name="Layer 28">
            <path
              className="cls-1"
              d="M589.34,294.67c0,162.74-131.93,294.67-294.67,294.67V515.67c122.05,0,221-98.95,221-221Z"
            />
            <path
              className="cls-1"
              d="M294.67,515.67v73.67C131.93,589.34,0,457.41,0,294.67H73.67C73.67,416.72,172.61,515.67,294.67,515.67Z"
            />
            <path
              className="cls-1"
              d="M294.67,0V73.67c-122.06,0-221,98.94-221,221H0C0,131.93,131.93,0,294.67,0Z"
            />
            <path
              className="cls-1"
              d="M589.34,294.67H515.67c0-122.06-98.95-221-221-221V0C457.41,0,589.34,131.93,589.34,294.67Z"
            />
            <path
              className="cls-2"
              d="M515.67,294.67c0,122.05-98.95,221-221,221V442A147.33,147.33,0,0,0,442,294.67Z"
            />
            <path
              className="cls-3"
              d="M294.67,442v73.67c-122.06,0-221-98.95-221-221h73.66A147.34,147.34,0,0,0,294.67,442Z"
            />
            <path
              className="cls-3"
              d="M294.67,73.67v73.66A147.34,147.34,0,0,0,147.33,294.67H73.67C73.67,172.61,172.61,73.67,294.67,73.67Z"
            />
            <path
              className="cls-3"
              d="M515.67,294.67H442A147.33,147.33,0,0,0,294.67,147.33V73.67C416.72,73.67,515.67,172.61,515.67,294.67Z"
            />
          </g>
          <g id="LOCKED_copy" data-name="LOCKED copy">
            <path
              className="cls-2"
              d="M195.52,442h109a0,0,0,0,1,0,0v73.67a0,0,0,0,1,0,0h-53a56,56,0,0,1-56-56V442A0,0,0,0,1,195.52,442Z"
            />
            <path
              className="cls-2"
              d="M408.3,296.71h150a3.13,3.13,0,0,0,2.4-5.14L483.3,198.84l-77.4,92.73A3.13,3.13,0,0,0,408.3,296.71Z"
            />
          </g>
        </g>
      </svg>
    </div>


  );
};

export default Loading;
