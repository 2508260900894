import styled from "@emotion/styled";

export const AdminInfoWrapper = styled.div`
  padding: 15px;
  width: 100%;
`;

export const AdminInfoHeaderStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AdminImageHeaderWrapper = styled.div`
  width: 15%;
`;


export const AdminInfoEditWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const AdminInfoNameWrapper = styled.div`
  width: 85%;
`;

export const AdminInfoNameStyle = styled.h2`
  width: 100%;
  padding: 15px;
`;

export const AdminInfoAdminLevelStyle = styled.h4`
  width: 100%;
  padding: 0 15px;
`;

export const AdminDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const AdminInfoStyle = styled.div`
  width: 40%;
`;

export const AdminInfoTitle = styled.h4`
  width: 100%;
`;

export const AdminPersonalStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AdminPersonalItemStyle = styled.div`
  padding: 10px 15px;
`;
export const AdminDetailPic = styled.div`
  width: 125px;
  height: 110px;
`;
export const AdminDetailSubTitle = styled.h5`
  color: #556ee6;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  white-space: nowrap;
`;