import React from "react";
import "./fuses.css";
import { images } from "../../constants";

type FusesProps = {
  fusesNumber: number;
};
const Fuses: React.FC<FusesProps> = ({ fusesNumber }) => {
  return (
    <div className="fuses-student">
      <img src={images.fuze} alt="" style={{width:30}} />
      <p className="fuses-number">{fusesNumber}</p>
      <p className="fuses">Focus Fuses</p>
    </div>
  );
};

export default Fuses;
