import React, { useState } from "react";
import ExperienceFilterPresentation from "../../presentation/experience.filter.presentation/ExperienceFilterPresentation";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { useAppSelector } from "../../../../app/hooks";
import { selectedProject } from "../../../home/appSlice/appslice";
import { Project } from "../../../project/types/types";

type ExperienceFitlerContainerProps = {
  project: Partial<Project>;
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  onCategoryHandler: (category: Partial<Category> | null) => void;
  onSubCategoryHandler: (subCategory: Partial<SubCategory> | null) => void;
};

const ExperienceFilterContainer: React.FC<ExperienceFitlerContainerProps> = ({
  project,
  category,
  subCategory,
  onCategoryHandler,
  onSubCategoryHandler,
}) => {
  return (
    <ExperienceFilterPresentation
      projectId={project!._id!}
      categoryId={category?._id!}
      onCategoryHanlder={onCategoryHandler}
      onSubCategoryHandler={onSubCategoryHandler}
    />
  );
};

export default ExperienceFilterContainer;
