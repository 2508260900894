import { EntityId, createSlice } from "@reduxjs/toolkit";
import { MetaData } from "../../../types/types";
import { RootState } from "../../../app/store";

interface IinitialState {
  metadata: MetaData;
  subCategoryId: EntityId | null;
  subCategorySelectedStatus: boolean;
}
const initialState: IinitialState = {
  metadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
  subCategoryId: null,
  subCategorySelectedStatus: false,
};

export const subCategorySlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    getSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload;
    },
    changeSubCategoryStatus: (state, action) => {
      state.subCategorySelectedStatus = action.payload;
    },
  },
});

export const selectSubCategoryIdByClick = (state: RootState) =>
  state.subCategory.subCategoryId;
export const selectSubCategoryStatus = (state: RootState) =>
  state.subCategory.subCategorySelectedStatus;
export const { setMetaData, getSubCategoryId, changeSubCategoryStatus } =
  subCategorySlice.actions;
export default subCategorySlice.reducer;
