import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { MetaData } from "../../../types/types";

interface IinitialState {
  organizationMetadata: MetaData;
}

const initialState: IinitialState = {
  organizationMetadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
};

export const organizationSlice = createSlice({
  name: "organizationmeta",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.organizationMetadata = action.payload;
    },
  },
});

export const organizationMetadataSelected = (state: RootState) => {
  return state.organizationMeta.metadata;
};

export const { setMetaData } = organizationSlice.actions;
export default organizationSlice.reducer;
