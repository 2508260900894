import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { Award } from "../types/type";

const awardAdaptor = createEntityAdapter<Award>({
  selectId: (award) => award._id,
});

interface AwardInitialState extends EntityState<Award> {}

const initialState: AwardInitialState = awardAdaptor.getInitialState();

export const extendApiAdaptor = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAwards: builder.query<
      EntityState<Award>,
      {
        student: EntityId;
        currentSystem: string;
      }
    >({
      query: (data) =>
        `/menualy-xp?student=${data.student}&currentSystem=${data.currentSystem}`,
      transformResponse: (response: Award[]) => {
        return awardAdaptor.setAll(initialState, response);
      },
      //change the provideTags to the simple version
      providesTags:  [
        "Award"
      ],
    }),
    addAward: builder.mutation<EntityState<Award>, Partial<Award>>({
      query: (award) => ({
        url: "/menualy-xp",
        method: "POST",
        body: award,
      }),
      transformResponse: (response: Award) => {
        console.log("Awards", response);

        return awardAdaptor.addOne(initialState, response);
      },
      invalidatesTags: (result, error, arg) => [
        "Award",
        "Student",
        "LeaderBoard",
      ],
    }),
    removeAward: builder.mutation<EntityState<Award>, EntityId>({
      query: (id) => ({
        url: `/menualy-xp/${id}`,
        method: "DELETE",
        body: id,
      }),
      transformResponse: (response: Award) => {
        return awardAdaptor.removeOne(initialState, response._id);
      },
      invalidatesTags: (result, error, arg) => [
        "Award",
        "Student",
        "LeaderBoard",
      ],
    }),
  }),
});

export const {
  useAddAwardMutation,
  useGetAllAwardsQuery,
  useRemoveAwardMutation,
} = extendApiAdaptor;
