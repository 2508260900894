import { FC } from "react";
import { ILogin } from "../../types/auth.types";
import ChangePassword from "./changePassword";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import "./login.css";
import {
  LoginContainer,
  LoginHeader,
  LoginWrapper,
  LogoImage,
} from "./login.style";
import VerificationCode from "./verificationsCode";

const LoginPresentation: FC<ILogin> = ({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  onLogin,
  handleClickShowPassword,
  showPassword,
  isError,
  error,
}) => {
  const ScreenDisplay = () => {
    if (true) {
      return (
        <Login
          email={email}
          password={password}
          onChangeEmail={onChangeEmail}
          onChangePassword={onChangePassword}
          onLogin={onLogin}
          handleClickShowPassword={handleClickShowPassword}
          showPassword={showPassword}
          isError={isError}
          error={error}
        />
      );
    } else if (false) {
      return <ForgotPassword email={email} onChangeEmail={onChangeEmail} />;
    } else if (false) {
      return <VerificationCode email={email} />;
    } else if (false) {
      return <ChangePassword password={password} />;
    }
  };

  return (
    <>
      <LoginContainer className="login_container">
        <LoginWrapper>
          <LoginHeader>
            <LogoImage
              src={require("../../../../assets/image/gradegy-big-icon.png")}
              alt="logo"
            />
          </LoginHeader>
          {ScreenDisplay()}
        </LoginWrapper>
      </LoginContainer>
    </>
  );
};

export default LoginPresentation;
