import React, { useEffect, useState } from "react";
import AdminAccessUniversityPresentation from "../../presentation/admin.access.university.presentation/AdminAccessUniversityPresentation";
import {
  Admin,
  AdminAccessUniversityContainerProps,
  ProjectPermissionType,
} from "../../types/types";
import { useUpdateAdminMutation } from "../../adminApiSlice/adminApiSlice";
import { EntityId } from "@reduxjs/toolkit";
import { Project } from "../../../project/types/types";

const AdminAccessUniversityContainer: React.FC<
  AdminAccessUniversityContainerProps
> = ({ admin }) => {
  const [updateAdmin, { isLoading, isSuccess, isError }] =
    useUpdateAdminMutation();

  const [projectID, setProjectId] = useState<EntityId | null>(null);
  const [secondaryProjects, setSecondaryProject] = useState<Project[]>([]);
  const [projectCanView, setProjectCanView] = useState<ProjectPermissionType[]>(
    []
  );
  const [showCanEdit, SetShowCanEdit] = useState<ProjectPermissionType | null>(
    null
  );

  useEffect(() => {
    if (admin) {
      setSecondaryProject(
        admin.projects
          ? admin.projects!.filter((project) => {
              return project.project_type === "Post Secondary";
            })
          : []
      );
    }
  }, [admin]);

  useEffect(() => {
    if (secondaryProjects.length > 0) {
      const projectPermission = admin!.projectAccess!.filter((project) => {
        const findProject = secondaryProjects?.find(
          (sProject) => sProject._id === project.project
        );

        if (findProject) {
          return project.accessType === "Full";
        } else {
          return false;
        }
      });
      setProjectCanView(
        projectPermission!.map((project) => {
          const findProject = secondaryProjects?.find(
            (sProject) => sProject._id === project.project
          );

          return {
            ...project,
            project: findProject!,
          };
        })
      );
    }
  }, [secondaryProjects]);

  const onProjectHanlder = (project: Partial<Project> | null) => {
    setProjectId(project!._id as EntityId);
  };

  const onAddAccessPermission = async () => {
    let newProjectAccess: ProjectPermissionType[] = [];

    if (projectID) {
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      if (exist) {
        newProjectAccess = admin.projectAccess!.map((pr) => {
          return pr.project === exist.project
            ? (pr = { ...exist, accessType: "Full" })
            : pr;
        });

        setProjectCanView(newProjectAccess);
      } else {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];

        setProjectCanView(newProjectAccess);
      }
    }

    try {
      const newAdmin: Partial<Admin> & { status: boolean } = {
        _id: admin._id,
        projectAccess: newProjectAccess!,
        status: true,
      };

      await updateAdmin({ ...newAdmin, status: true }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const onProjectRemove = async (project: ProjectPermissionType) => {
    const projectPermission = admin!.projectAccess!.filter(
      (pro) => pro.project !== project.project._id
    );

    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: projectPermission!,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setProjectCanView(projectPermission);
    } catch (error) {}
  };

  const onClickProject = async (project: ProjectPermissionType) => {
    SetShowCanEdit(project as ProjectPermissionType);
  };

  const onUserCheck = async () => {
    SetShowCanEdit({ ...showCanEdit!, users: !showCanEdit!.users! });
  };

  const onExpereinceCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, experience: !showCanEdit?.experience });
  };

  const onStoreCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, store: !showCanEdit?.store });
  };

  const onSurveyCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, survey: !showCanEdit?.survey });
  };
  const onSaveHandler = async () => {
    let newProjectAccess = [...admin.projectAccess!];
    if ((showCanEdit as ProjectPermissionType).project) {
      newProjectAccess = newProjectAccess.map((proj) => {
        if (
          proj.project === (showCanEdit as ProjectPermissionType).project._id
        ) {
          proj = showCanEdit as ProjectPermissionType;
          return proj;
        } else {
          return proj;
        }
      });
    }
    try {
      await updateAdmin({
        _id: admin._id,
        projectAccess: newProjectAccess,
        status: true,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <AdminAccessUniversityPresentation
      admin={admin}
      onExpereinceCheck={onExpereinceCheck}
      onProjectHanlder={onProjectHanlder}
      onSaveHandler={onSaveHandler}
      onStoreCheck={onStoreCheck}
      onSurveyCheck={onSurveyCheck}
      onUserCheck={onUserCheck}
      projectID={projectID}
      showCanEdit={showCanEdit}
      onAddAccessPermission={onAddAccessPermission}
      onClickProject={onClickProject}
      onProjectRemove={onProjectRemove}
      projectCanView={projectCanView}
    />
  );
};

export default AdminAccessUniversityContainer;
