import React from "react";
import { InventoryType } from "../types/type";

import { RowTr, RowTd } from "./Inventory.style";
type InventoryListItemProps = {
  inventory: InventoryType;
};
const InvetoryListItem: React.FC<InventoryListItemProps> = ({ inventory }) => {
  return (
    <RowTr>
      <RowTd>{(inventory._id as string).substring(0,8)}</RowTd>
      <RowTd>
        {inventory.serialNumber ? inventory.serialNumber : "---"}
      </RowTd>
      <RowTd>
        {inventory.digitalCode ? inventory.digitalCode : "---"}
      </RowTd>
      <RowTd>
        {inventory.dataPurchase
          ? inventory.dataPurchase.toLocaleDateString("en-US")
          : "---"}
      </RowTd>
      <RowTd>{inventory.pickup ? "Yes" : "No"}</RowTd>
    </RowTr>
  );
};

export default InvetoryListItem;
