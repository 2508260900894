import Header from "../../../components/header/Header";
import OrganizationMainContainer from "../container/OrganizationMainContainer";
import { OrganizationPageContainerStyled } from "./OraganizationPage.styles";
const OrganizationPage = () => {
  return (
    <OrganizationPageContainerStyled>
      <Header title="Organization" />
      <OrganizationMainContainer />
    </OrganizationPageContainerStyled>
  );
};

export default OrganizationPage;
