import React from "react";
import {
  InventorySection,
  InventoryTitle,
  InventoryItem,
  InventoryText,
  InventoryWrapper,
  InventoryAdd,
  InventoryTextArea,
  EditInventoryBtn,
} from "./shopmidcol.styles";
type ShopEditInventoryPresentationProps = {
  initialStock: number;
  currentStock: number;
  addStock: number;
  onAddStock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddStockHandler: () => void;
  onEditInventory: () => void;
};
const ShopEditInventoryPresenation: React.FC<
  ShopEditInventoryPresentationProps
> = ({
  initialStock,
  currentStock,
  addStock,
  onAddStock,
  onAddStockHandler,
  onEditInventory,
}) => {
  return (
    <InventorySection>
      <InventoryTitle>Inventory</InventoryTitle>
      <InventoryItem>
        <InventoryText style={{ opacity: "50%" }}>Initial Stock</InventoryText>
        <InventoryText style={{ opacity: "50%" }}>{initialStock}</InventoryText>
      </InventoryItem>
      <InventoryItem>
        <InventoryText>Current Stock</InventoryText>
        <InventoryText>{currentStock}</InventoryText>
      </InventoryItem>
      <InventoryItem>
        <InventoryText>Add Stock</InventoryText>
        <InventoryWrapper>
          <InventoryAdd onClick={onAddStockHandler}>+</InventoryAdd>
          <InventoryTextArea value={addStock} onChange={onAddStock} />
        </InventoryWrapper>
      </InventoryItem>
      <EditInventoryBtn onClick={onEditInventory}>
        Edit Full Inventory
      </EditInventoryBtn>
    </InventorySection>
  );
};

export default ShopEditInventoryPresenation;
