import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import { Button } from "@mui/material";

export const DialogStyle = styled(Dialog)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaper-root {
    max-width: 1000px !important;
        border-radius: 20px;
  }
`;

export const OrganizationAddWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const OrganizationAddRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

interface IOrganizationAddCol {
  width: string;
}
export const OrganizationAddCol = styled.div<IOrganizationAddCol>`
  width: ${({ width }) => width};
  padding: 20px;
  box-sizing: border-box;
`;




export const CreateOrganizationFormCol = styled.div<{ alignment: string, Direction: string }>`
  display:flex;
  justify-content:${({ alignment }) => alignment};
  text-align:left;
  flex-direction:${({ Direction }) => Direction};
  padding:0 10px 0 0;
`;

export const CreateOrganizationContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display:flex;
  justify-content:center;
  align-items:center;
  
`;

export const CreateOrganizationForm = styled.div`
    padding-bottom: 10px;
   
    padding-top:20px;
    width: fit-content;
    height: fit-content;
    background:#fff;
    border-radius:10px;
   box-shadow: 1px 2px 14px -1px rgba(0,0,0,0.82);
   min-width: 803px;



`;

export const CreateOrganizationFormRow = styled.div`
  width:100%;
  display:flex;
  padding-top:1px;
  

`;

export const OrganizationInput = styled.input<{ SpacingLR: string, Width: string, Height: string, FontFamily: string, Size: string, Weight: string, SpacingTB: string }>`
    width:${({ Width }) => Width ? Width : "190px"};
    height:${({ Height }) => Height ? Height : "32px"};
    text-align:center;
    font-family:${({ FontFamily }) => FontFamily};
    font-size:${({ Size }) => Size};
    font-weight:${({ Weight }) => Weight};
    padding:${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
    border-radius:5px;
    border:1px solid #CED4DA;
    color:#070708;
    max-width:${({ Width }) => Width ? Width : "235px"};

`;


export const LabelOrganization = styled.label`
  font-family:'Poppins';
  font-size:12px;
  font-weight:500;
  color:#707070;


`;
export const SwitchLabelOrganization = styled.label`
  font-family:'Poppins';
  font-weight:400;
  font-size:13px;
  color:#74788D;
  padding-right:7px;

`;

export const OrganizationImage = styled.img`
    width:103px;
    height:103px;
    border-radius: 50px;
    object-fit: contain;


`;
export const OrganizationEditButtton = styled.button`
  
    transition-duration:300ms; 
    display: flex;
    height: 32px;
    border-radius: 4px;
    width: 60px;
    background-color: #34C38F;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    border: none;
    position:absolute;
    right: -31px;
    bottom: 7px;
    &:hover{
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      transition-duration:300ms;
      background:#37d39a;
    }

`;

export const OrganizationProjectEditButtton = styled.button`
  

    display: flex;
    height: 40px;
    border-radius: 4px;
    width: 80px;
    background-color: #34C38F;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    border: none;
    transition-duration:300ms;
    &:hover{
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      transition-duration:300ms;
      background:#37d39a;
    }

`;

export const OrganizationEditButttonText = styled.p`
font-family: 'Poppins';
font-weight: 400;
font-size: 10px;
color:#fff;
margin: 0;
padding-right: 5px;

`;

export const OrganizationEditButttonIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;

`;


export const OrganizationButon = styled(Button)`

    color:#fff;
    background-color:#0078FF;
    border:none;
    border-radius:50px;
    padding: 10px 46px;
    font-family:'Poppins';
    font-size:15px;
    font-weight:500;
    &:hover {
      background-color: #3996ff;
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      border:none;
    };
    &:active {
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      background-color: #3996ff;
      border:none;
  
    };
    &:focus {
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
      border:none;
    },


`;
export const OrganizationDetailsContainer = styled.div`
    padding:27px;
    padding-top:35px;

`;

export const OrganizationPic = styled.div`
    width: 125px;
    height: 110px;
`;

export const OrganizationInfo = styled.div`
    display: flex;
    align-items: center;

`;

export const OrgainzationsListContainer = styled.div`
  width: 100%;
  margin-top:10px;
  max-width:835px;
  border:1px solid #CED4DA;
  border-radius:22px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:10px;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const OrgainzationsListProject = styled.div`
  border:1px solid #CED4DA;
  border-radius:12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin:5px;
  position:relative;
  max-width: 46%;
  color: #74788D;
    font-size: 13px;
    flex-direction: column;
    border-radius: 5px;
    min-width: 110px;
    min-height: 25px;
    padding: 0 25px 0 7px;
    span{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
`;
export const OrgainzationsListCloseProject = styled.div`
position: absolute;
top: 1px;
right: 7px;
color: #313539;
font-family: system-ui;
font-size: 14px;
font-weight: 600;
cursor: pointer;
`;

export const ProjectCatListContainer = styled.div`
  width: 95%;
  margin-top:10px;
  max-width:386px;
  border:1px solid #CED4DA;
  border-radius:14px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:17px;
  justify-content: space-around;
  margin-right: 20px;
  max-height:450px;
  overflow: auto;
`;

export const ToggleBtn = styled.div`
position: absolute;
right: 0px;
top: 0px;
width: 40px;
border: none;
outline: none;
cursor: pointer;
background: white;
transition: all 0.4s ease 0s;
`;