import OrganizationItemContainer from "../../container/OrganizationListContainer/OrganizationItemContainer";
import { Link } from "react-router-dom";
import { OrganizationListPresentationProps } from "../../types/types";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { organizationMetadataSelected } from "../../api/organizationSlice";
import { images } from "../../../../constants";
const OrganizationListPresentation: React.FC<
  OrganizationListPresentationProps
> = ({
  organizationIds,
  organizationDataTable,
  page,
  onPage,
  onLimit,
  limit,
  term,
  onTerm,
  sort,
  onSort,
}) => {
  const orgmeta = useAppSelector(organizationMetadataSelected);

  return (
    <div
      className="organization-table-container"
      style={{
        width: "90%",
        background: "#fff",
        marginLeft: 50,
        minHeight: "calc(100vh - 238px)",
      }}
    >
      <div style={{ boxShadow: "0 10px 20px rgba(18, 38, 63, 0.03)" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("title");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Name
                   {sort !== "-title" && sort !== "title" ? (
                        <img src={images.unSelectIcon} />
                      ) : !!sort?.startsWith("-title") ? (
                        <img src={images.BottomIcon} />
                      ) : (
                        sort === "title" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("ufcs_member");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    UFCSMember
               

                    {sort !== "-ufcs_member" && sort !== "ufcs_member" ? (
                        <img src={images.unSelectIcon} />
                      ) : !!sort?.startsWith("-ufcs_member") ? (
                        <img src={images.BottomIcon} />
                      ) : (
                        sort === "ufcs_member" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("paid");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Paid
          
                    {sort !== "-paid" && sort !== "paid" ? (
                        <img src={images.unSelectIcon} />
                      ) : !!sort?.startsWith("-paid") ? (
                        <img src={images.BottomIcon} />
                      ) : (
                        sort === "paid" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("organization_type");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Organization Type
           

                   {sort !== "-organization_type" && sort !== "organization_type" ? (
                        <img src={images.unSelectIcon} />
                      ) : !!sort?.startsWith("-organization_type") ? (
                        <img src={images.BottomIcon} />
                      ) : (
                        sort === "organization_type" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("projects_number");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    #Projects
                    {sort !== "-projects_number" &&
                    sort !== "projects_number" ? (
                      <img src={images.unSelectIcon} />
                    ) : sort === "-projects_number" ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "projects_number" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("reward_system");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Reward System
        
                  {sort !== "-reward_system" && sort !== "reward_system" ? (
                        <img src={images.unSelectIcon} />
                      ) : !!sort?.startsWith("-reward_system") ? (
                        <img src={images.BottomIcon} />
                      ) : (
                        sort === "reward_system" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("survey_system");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Survey System
                    {sort !== "-survey_system" && sort !== "survey_system" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith("-survey_system") ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "survey_system" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationDataTable?.ids?.map((id) => (
                <OrganizationItemContainer
                  organizationId={id}
                  organizationItem={organizationDataTable?.entities[id]!}
                  key={id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {orgmeta?.toatlCount > 24 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={orgmeta?.toatlCount}
            rowsPerPage={limit!}
            page={page! - 1}
            onPageChange={(event: unknown, newPage: number) => {
              onPage!(newPage + 1);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              onLimit!(parseInt(event.target.value, 10));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationListPresentation;
