import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const SideBarContainer = styled.div`
  width: 100%;
  height: 100%;
  padding:18px;
  display: flex;
  flex-direction: column;
  
`;

export const SidebarLink = styled(Link)`
  color: white;
  display: flex;
  width: 100%;
  font-family: Poppins;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition-duration: 0.3s;
  padding-bottom: 16px;
  svg{
    margin-right: 13px;
  }
  p{
    font-family: Poppins;
    font-size: 16px;
  }
`;

export const SidebarLogo = styled.img`
  width: 80%;
`;


export const SidebarResponsiveLogo = styled.img`
  width: 80%;
`;


export const SidebarBoxHome = styled.div`
   padding-top:65px;
`;

export const SidebarBox = styled.div`
`;

export const SidebarBoxTitle = styled.h4`
  margin-top: 0.7rem;
  color:white;
  font-family: "PoppinsBold";
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
`;
export const LayoutSidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  flex-grow: 1;
  text-align: center;
`;

export const LayoutSidebarCollaps = styled.div`
position: absolute;
top: 13px;
right: 7px;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
`;

export const LayoutSidebarUpperText = styled.p`
  width: 100%;
  font-family: 'Poppins';
  font-size: 15px;
  color: rgb(166, 176, 207);
  margin-bottom: 0;
  text-align: center;
  text-align: left;
  @media (max-width: 1300px) {
    font-size:13px;
  }
`;
export const LayoutSidebarDownerText = styled.p`
  width: 100%;
  font-family: 'Poppins';
  font-size: 13px;
  color:rgb(166, 176, 207)};
  margin-bottom: 0;
  text-align: center;
`;

export const LayoutSidebarDownerTextSpan = styled(Link)`
  width: 100%;
  font-family:  'Poppins';
  font-size: 15px;
  color: rgb(166, 176, 207);
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
`;

export const SpanSidebar = styled.span`
  font-size: 19px;
  font-weight: bold;
  @media (max-width: 1300px) {
    font-size:15px;
  }
`;