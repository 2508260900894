import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import { LayoutContainer, LayoutMain, LayoutSidebar } from "./layout.styles";
import { useState } from "react";

const Layout = () => {

  const [sidebarOpen, setSideBarOpen] = useState(true);
  
  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const contentClass = sidebarOpen ? "content open" : "content close";

  const sidebarClass = sidebarOpen ? "sidebar open" : "sidebar close";

  return (
    <LayoutContainer>
      <LayoutSidebar className={sidebarClass}>
        <Sidebar  toggleSidebar={handleViewSidebar} />
      </LayoutSidebar>
      <LayoutMain className={contentClass}>
        <Outlet />
      </LayoutMain>
    </LayoutContainer>
  );
};

export default Layout;
