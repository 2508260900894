import { EntityId, EntityState } from "@reduxjs/toolkit";
import { MetaData } from "../../../types/types";
import { Category } from "../../category/types/types";
import { Organization } from "../../organization/types/types";
import { Project } from "../../project/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { AuthAdmin } from "../../auth/types/auth.types";
import { z, ZodType } from "zod";
import {
  Control,
  FormState,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
export type ProjectPermissionType = {
  project: Partial<Project>;
  accessType: "Full" | "Partial";
  users?: boolean;
  experience?: boolean;
  survey?: boolean;
  store?: boolean;
};
export type CategoryPermissionType = {
  category: Partial<Category>;
  accessType: "Full" | "Partial";
  users: boolean;
  experience: boolean;
  survey: boolean;
  store: boolean;
};

export type SubCategoryPermissionType = {
  subcategory: Partial<SubCategory>;
  accessType: "Full" | "Partial";
  users: boolean;
  experience: boolean;
  survey: boolean;
  store: boolean;
};

export type Admin = {
  _id: string;
  image: string;
  firstName: string;
  lastName: string;
  fullName: string;
  adminLevel: string;
  phone: string;
  email: string;
  jobTitle: string;
  password: string;
  active: boolean;
  organization?: Partial<Organization> | null;
  projectAccess?: ProjectPermissionType[];
  categoryAccess?: CategoryPermissionType[];
  subCategoryAccess?: SubCategoryPermissionType[];
  projects?: Project[];
  categories?: Category[];
  subcategories: SubCategory[];
};

export type AdminFilterPresentationProps = {
  organizationID: EntityId | null;
  projectID: EntityId | null;
  categoryID: EntityId | null;
  onSelectOrganizationHandler: (
    organization: Partial<Organization> | null
  ) => void;

  onSelectProjectHandler: (project: Partial<Project> | null) => void;

  onSelectCategoryHandler: (category: Partial<Category> | null) => void;

  onSelectSubCategoryHandler: (
    subcategory: Partial<SubCategory> | null
  ) => void;
};

export type AdminActionContainerProps = {
  openAdminAddState: boolean;
  openAdminAddHandler: () => void;
  term?: string;
  setTerm?: React.Dispatch<React.SetStateAction<string>>;
};

export interface AdminActionPresentationProps
  extends AdminActionContainerProps {}

export type AdminAddContainerProps = {
  openAddAdminState: boolean;
  openAddAdminHandler: () => void;
  closeHandler: () => void;
  authAdmin: Admin | AuthAdmin;
  status?: "Add" | "Edit";
};

export interface AdminAddPresentationProps extends AdminAddContainerProps {
  image: string;
  firstName: string;
  lastName: string;
  fullName: string;
  adminLevel: string;
  phone: string;
  email: string;
  password: string;
  jobTitle: string;
  organization?: Partial<Organization> | null;
  project?: Partial<Project> | null;
  category?: Partial<Category> | null;
  categoryLink: boolean;
  onCategoryLinkHandler: () => void;

  onImageHandler: (url: string) => void;

  onFirstNameHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLastNameHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAdminLevelHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onPhoneHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onJobTitleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;

  setOrganizationHandler?: (organization: Partial<Organization> | null) => void;
  onProjectHanlder?: (project: Partial<Project> | null) => void;

  onCategoryHandler?: (category: Partial<Category> | null) => void;
  saveLoading: boolean;
  onSaveHandler: () => void;
  onCancel?: () => void;
  control: Control<z.infer<typeof AdminSchema>, any>;
  onSubmit: UseFormHandleSubmit<z.infer<typeof AdminSchema>, undefined>;
  formState: FormState<z.infer<typeof AdminSchema>>;
  reset: UseFormReset<z.infer<typeof AdminSchema>>;
  handleSubmit: (params: z.infer<typeof AdminSchema>) => Promise<void>;
  register: UseFormRegister<z.infer<typeof AdminSchema>>;
  projectAccess?: ProjectPermissionType[] | undefined;
}

export type AdminListContainerProps = {
  adminList: EntityState<Admin>;
  page?: number;
  limit?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  sort?: string;
  onSort?: (fieldName: string) => void;
};

export type AdminListPresentationProps = {
  adminList: EntityState<Admin>;
  page?: number;
  limit?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  sort?: string;
  onSort?: (fieldName: string) => void;
};

export type AdminItemPresentationProps = {
  item: Admin;
};

export type AdminDetailsContainerProps = {
  adminId: EntityId;
};

export type AdminInfoContainerProps = {
  admin: Admin;
  openEdit: () => void;
  open: boolean;
};

export interface AdminInfoPresentationProps extends AdminInfoContainerProps {}

export type AdminAccessSchoolDistrictContainerProps = {
  admin: Admin;
};

export interface AdminAccessSchoolDistrictPresentationProps
  extends AdminAccessSchoolDistrictContainerProps {
  onProjectHanlder: (project: Partial<Project> | null) => void;
  projectId: EntityId | null;
  onCategoryHandler: (category: Partial<Category> | null) => void;

  projectCanView: ProjectPermissionType[];
  categoryCanView: CategoryPermissionType[];
  onAddAccessPermission: () => void;
  onProjectRemove?: (project: ProjectPermissionType) => void;
  onCategoryRemove?: (category: CategoryPermissionType) => void;
  onClickProject?: (project: ProjectPermissionType) => void;
  onClickCategory?: (category: CategoryPermissionType) => void;
  showCanEdit: ProjectPermissionType | CategoryPermissionType | null;
  onUserCheck?: () => void;
  onExperienceCheck?: () => void;
  onStoreCheck?: () => void;
  onSurveyCheck?: () => void;
  onSaveHandler?: () => void;
}

export type AdminAccessSpecialContainerProps = {
  admin: Admin;
};

export interface AdminAccessSpecialPresentationProps
  extends AdminAccessSpecialContainerProps {
  onProjectHanlder: (project: Partial<Project> | null) => void;
  projectID: EntityId | null;
  onCategoryHandler: (category: Partial<Category> | null) => void;
  categoryID: EntityId | null;
  onSubCategoryHandler: (subCategory: Partial<SubCategory> | null) => void;
  subCategory: EntityId | null;
  projectCanView?: ProjectPermissionType[];
  categoryCanview?: CategoryPermissionType[];
  subCategoryCanView?: SubCategoryPermissionType[];
  onAddAccessPermission?: () => void;
  onProjectRemove?: (project: ProjectPermissionType) => void;
  onCategoryRemove?: (category: CategoryPermissionType) => void;
  onSubCategoryRemove?: (subcategory: SubCategoryPermissionType) => void;
  onClickProject?: (project: ProjectPermissionType) => void;
  onClickCategory?: (category: CategoryPermissionType) => void;
  onClickSubCategory?: (subcategory: SubCategoryPermissionType) => void;
  showCanEdit:
    | ProjectPermissionType
    | CategoryPermissionType
    | SubCategoryPermissionType
    | null;
  onUserCheck: () => void;

  onExpereinceCheck: () => void;

  onStoreCheck: () => void;

  onSurveyCheck: () => void;
  onSaveHandler: () => void;
}

export type AdminAccessUniversityContainerProps = {
  admin: Admin;
};

export interface AdminAccessUniversityPresentationProps
  extends AdminAccessUniversityContainerProps {
  onProjectHanlder: (project: Partial<Project> | null) => void;
  projectID: EntityId | null;
  projectCanView?: ProjectPermissionType[];
  onAddAccessPermission?: () => void;
  onProjectRemove?: (project: ProjectPermissionType) => void;
  onClickProject?: (project: ProjectPermissionType) => void;
  showCanEdit:
    | ProjectPermissionType
    | CategoryPermissionType
    | SubCategoryPermissionType
    | null;
  onUserCheck: () => void;

  onExpereinceCheck: () => void;

  onStoreCheck: () => void;

  onSurveyCheck: () => void;
  onSaveHandler: () => void;
}

export const AdminSchema = z.object({
  firstName: z
    .string()

    .min(1, { message: "*Please insert firstname" }),
  lastName: z.string().min(1, { message: "*Please insert lastname" }),
  adminLevel: z.string(),
  phone: z.string().min(1, { message: "*Phone is requierd" }),
  email: z.string().email({ message: "*email is required" }),
  password: z
    .string()
    .min(3, { message: "*Password should be 3 character at least" }),
  jobTitle: z.string().min(1, { message: "*job title is requierd" }),
  organization: z.string().optional(),
  projectAccess: z.string().optional(),
  categoryAccess: z.string().optional(),
});
