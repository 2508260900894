import { Paper } from "@mui/material";
import { FC, useState } from "react";

import { useGetAllProjectByOrganizationQuery } from "../api/projectApiSlice";
import { ProjectContainerProps } from "../types/types";
import ProjectActionContainer from "./project.action.container/project.action.container";
import ProjectAddContainer from "./project.add.container/project.add.container";
import ProjectListContainer from "./project.list.container/project.list.container";
import Loading from "../../../components/Loading/Loading";
const ProjectContainer: FC<ProjectContainerProps> = ({ organization }) => {
  const [term, setTerm] = useState<string>("");
  const {
    data: projects,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetAllProjectByOrganizationQuery({
    organizationId: organization._id,
    term: term,
  });

  const [openProjectBox, setOpenProjectBox] = useState<boolean>(false);

  const onTerm = (text: string) => {
    console.log(text);
    setTerm(text);
  };
  const handleClose = () => {
    setOpenProjectBox(false);
  };

  const handleOpen = () => {
    setOpenProjectBox(true);
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <>
        <Paper
          elevation={3}
          style={{
            marginTop: 20,
            borderRadius: 12,
            width: "calc(100% - 62px)",
            margin: "15px 31px 10px 31px",
          }}
        >
          <div
            className="st-info st-info-game-status"
            style={{
              minHeight: 467,
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <ProjectActionContainer
              handleOpen={handleOpen}
              term={term}
              onTerm={onTerm}
            />
            <ProjectListContainer projects={projects!} />
            <ProjectAddContainer
              openProjectDialog={openProjectBox}
              handleClose={handleClose}
              handleOpen={handleOpen}
              defaultOrganization={organization}
              actionStatus="Add"
              refetch={refetch}
            />
          </div>
        </Paper>
      </>
    );
  } else if (isError) {
    content = <h2>Something went Wrong</h2>;
  }
  return <div>{content}</div>;
};

export default ProjectContainer;
