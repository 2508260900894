import React from "react";
import ProjectDetailsActionContainer from "../../container/project.details.action.container/project.details.action.container";
import { useParams } from "react-router-dom";
import ProjectDetailsContainer from "../../container/project.details.container/project.details.container";
import Header from "../../../../components/header/Header";
import CategoryContainer from "../../../category/container/category.container/category.container";
import SubCategoryContainer from "../../../subcategory/container/subcategory.container/subcategory.container";
import CSSettingContainer from "../../../cssetting/cs.setting.container/cs.setting.container";
import { EntityId } from "@reduxjs/toolkit";
import { Paper } from "@mui/material";
import { OrganizationDetailsContainer } from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import { useGetProjectByIdQuery } from "../../api/projectApiSlice";
const ProjectDetailsPage = () => {
  const { projectId } = useParams();
  const {
    data: projectDetails,
    isLoading: projectDetailsLoading,
    isError: projectDetailsError,
    isSuccess: projectDetailsSuccess,
  } = useGetProjectByIdQuery(projectId as EntityId);
  return (
    <div>
      <Header title="Project" />
      <ProjectDetailsContainer projectId={projectId!} />
      <OrganizationDetailsContainer className="">
        <Paper
          elevation={3}
          className="st-info"
          style={{
            flexDirection: "row",
            padding: 20,
            justifyContent: "space-between",
            marginBottom: 80,
            overflow: "inherit",
          }}
        >
          <CategoryContainer />
          <SubCategoryContainer />
          {projectDetails && (
            <CSSettingContainer
              projectId={projectId as EntityId}
              rewardSystem={
                projectDetails.entities[projectDetails.ids[0]]!.reward_system
              }
            />
          )}
        </Paper>
      </OrganizationDetailsContainer>
    </div>
  );
};

export default ProjectDetailsPage;
