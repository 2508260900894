import React, { FC } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";
import { ProjectActionPresentationProps } from "../../types/types";
import {
  SearchOrganizationsContainer,
  SearchOrganizations,
  OrganizationsButton,
} from "./project.action.presentation.style";
const ProjectActionPresentation: FC<ProjectActionPresentationProps> = ({
  handleOpen,
  term,
  onTerm,
}) => {
  return (
    <div className="st-info-team-container">
      <div style={{ display: "flex" }}>
        <h3 className="st-info-title">Projects</h3>
        <SearchOrganizationsContainer>
          <BiSearchAlt
            style={{ position: "absolute", top: 15, left: 48 }}
            color={"#707070"}
          />
          <SearchOrganizations
            type="text"
            placeholder="Search Project"
            value={term}
            onChange={(e) => onTerm!(e.target.value)}
            autoFocus
          />
        </SearchOrganizationsContainer>
        <OrganizationsButton
          style={{
            marginLeft: 85,
            height: 42,
            width: 240,
            position: "relative",
            justifyContent: "start",
          }}
          onClick={handleOpen}
        >
          <span style={{ paddingLeft: 12, fontSize: 17 }}> Add Project</span>
          <MdAddCircle
            size={34}
            style={{ position: "absolute", right: 3, top: 6 }}
          />
        </OrganizationsButton>
      </div>
    </div>
  );
};

export default ProjectActionPresentation;
