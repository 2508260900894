import {useEffect} from 'react'
import Header from "../../../components/header/Header";
import ShopContainer from "../container/ShopContainer";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";

const ShopPage = () => {
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project) {
      navigate("/");
    }
  }, [project]);
  return (
    <>
      {project && (
        <>
          <Header title="Shop" />
          <ShopContainer />
        </>
      )}
    </>
  );
};

export default ShopPage;
