import styled from "@emotion/styled";
import SelectField from "../../../../components/design/selectFiled/selectField";

export const HomeOrganizationPresentationSelec = styled(SelectField)`
        padding: 3px 20px;
        width: 200px;
        font-size: 12px;
        border: 1px solid rgb(206, 212, 218);
        font-family: Poppins;
        border-radius: 5px;
        color: rgb(73, 80, 87);
        appearance: none;
        margin-right: 10px;
        position: relative;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAABHNCSVQICAgIfAhkiAAAADVJREFUCFtj9AgITWBkYJrPgAT+M/xfuGPDKqA4ECArgEmAxMGSMAVArgNIB0wMLolsLIwNAD2NEgVESBCSAAAAAElFTkSuQmCC) right 6px bottom 13px no-repeat white;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        background-position: 96% 60%;
`;

