import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const LayoutSidebar = styled.div`
  width: 13%;
  height: 100vh;
  background-color: black;
  position:fixed;
`;

export const LayoutMain = styled.div`
  width: 100%;
  min-height: 10vh;
  padding-left: 13%;
`;
