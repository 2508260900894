import React, { SyntheticEvent, useState } from "react";
import {
  SearchResult,
  SearchSchoolDistrictContainerProps,
} from "../../../../types/types";
import { useLazySearchCategoryQuery } from "../../api/categoryApiSlice";
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../types/types";
import SearchField from "../../../../components/SearchField/SearchField";

const SearchCategoryContainer: React.FC<
  SearchSchoolDistrictContainerProps<Category>
> = ({ onChangeState, filterId }) => {
  const [options, setOptions] = useState<SearchResult[]>([]);

  const [searchCategory, { isLoading, isError, isSuccess }] =
    useLazySearchCategoryQuery();

  const onFetch = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    try {
      const newOptions = await searchCategory({
        projectId: filterId!,
        term: value,
      }).unwrap();

      setOptions(newOptions);
    } catch (error) {}
  };

  const onChagneHandler = (
    event: SyntheticEvent<Element, Event>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value?.id) {
      onChangeState({ _id: value.id, title: value.label });
    }
  };

  return (
    <SearchField
      options={options}
      loading={isLoading}
      handleInputChange={onFetch}
      onChangeHandle={onChagneHandler}
    />
  );
};

export default SearchCategoryContainer;
