import React, { useEffect } from "react";
import {
  HeadTh,
  HeadTr,
  Header,
  ListWrapper,
  SearchBox,
  StatusText,
  StatusWrapper,
  Title,
  Wrapper,
} from "./shop.right.col.style";
import { EntityId } from "@reduxjs/toolkit";
import { useGetAllBagStudentQuery } from "../../../../bag/api/bagApi";
import ShopEditReviewStudentPresentations from "./ShopEditReviewStudentPresentations";
import { Bag } from "../../../../bag/types/types";
import { TableBody } from "@mui/material";

type ShopEditReviewColPresentationProps = {
  id: EntityId;
  OnPickupBagClick: (picked: boolean, bag: Partial<Bag>) => void;
};
const ShopEditReviewColPresentation: React.FC<
  ShopEditReviewColPresentationProps
> = ({ id, OnPickupBagClick }) => {
  const {
    data: studentsBags,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllBagStudentQuery(id);

  useEffect(() => {
    if (studentsBags) {
      console.log("bssss", studentsBags);
    }
  }, [studentsBags]);
  return (
    <Wrapper>
      <Header>
        <SearchBox
          //   value={searchStudentValue}
          //   onChange={onSearchInputChanged}
          placeholder="Search Student"
        />
        <Title>Students</Title>
        <StatusWrapper>
          <StatusText>Status</StatusText>
          {/* <SelectComponent
            options={[
              { id: 0, name: "All" },
              { id: 1, name: "Not picked up" },
              { id: 2, name: "Picked up" },
            ]}
            selectedValue={selectedStatusId}
            name="Status"
            selected={false}
            onChange={onStatusChanged}
            style={{ minWidth: 130 }}
          /> */}
        </StatusWrapper>
      </Header>
      <ListWrapper>
        <HeadTr>
          <HeadTh>Student</HeadTh>
          <HeadTh>Date Purchased</HeadTh>
          <HeadTh>Picked up?</HeadTh>
          <HeadTh>Date Picked up</HeadTh>
          <HeadTh>Stock ID</HeadTh>
        </HeadTr>
        <TableBody style={{ width: "100%" }}>
          {isSuccess &&
            studentsBags.ids.map((id) => (
              <>
                <ShopEditReviewStudentPresentations
                  itemBag={studentsBags.entities[id]!}
                  OnPickupBagClick={OnPickupBagClick}
                />
              </>
            ))}
        </TableBody>
      </ListWrapper>
    </Wrapper>
  );
};

export default ShopEditReviewColPresentation;
