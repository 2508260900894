import React, { useEffect, useState } from "react";
import AdminAccessSchoolDistrictPresentation from "../../presentation/admin.access.schoolDisrict.presentation/AdminAccessSchoolDistrictPresentation";
import {
  Admin,
  AdminAccessSchoolDistrictContainerProps,
  CategoryPermissionType,
  ProjectPermissionType,
  SubCategoryPermissionType,
} from "../../types/types";
import { Project } from "../../../project/types/types";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../../category/types/types";
import { useUpdateAdminMutation } from "../../adminApiSlice/adminApiSlice";

const AdminAccessSchoolDistrictContainer: React.FC<
  AdminAccessSchoolDistrictContainerProps
> = ({ admin }) => {
  const [updateAdmin, { isLoading, isSuccess, isError }] =
    useUpdateAdminMutation();
  const [projectID, setProjectId] = useState<EntityId | null>(null);
  const [categoryID, setCategoryID] = useState<EntityId | null>(null);
  const [secondaryProjects, setSecondaryProject] = useState<Project[]>([]);
  const [secondaryCategory, setSecondaryCategory] = useState<Category[]>([]);

  const [projectCanView, setProjectCanView] = useState<ProjectPermissionType[]>(
    []
  );
  const [categoryCanView, setCategoryCanView] = useState<
    CategoryPermissionType[]
  >([]);

  const [showCanEdit, SetShowCanEdit] = useState<
    ProjectPermissionType | CategoryPermissionType | null
  >(null);

  useEffect(() => {
    console.log("ADDDDDDDDDDDEEEEEEE", admin);
    if (admin) {
      setSecondaryProject(
        admin?.projects
          ? admin?.projects?.filter((project) => {
              return project.project_type === "Secondary";
            })
          : []
      );
      setSecondaryCategory(
        admin?.categories
          ? admin.categories.filter((category) => category.highschool)
          : []
      );
    }
  }, [admin]);

  useEffect(() => {
    if (secondaryProjects.length > 0) {
      const projectPermission = admin!.projectAccess!.filter((project) => {
        const findProject = secondaryProjects?.find(
          (sProject) => sProject?._id === project.project
        );
        if (findProject) {
          return project.accessType === "Full";
        } else {
          return false;
        }
      });
      setProjectCanView(
        projectPermission!.map((project) => {
          const findProject = secondaryProjects?.find(
            (sProject) => sProject?._id === project.project
          );

          return {
            ...project,
            project: findProject!,
          };
        })
      );
    }
  }, [secondaryProjects]);

  useEffect(() => {
    if (secondaryCategory.length > 0) {
      const categoryPermiseion = admin!.categoryAccess!.filter((category) => {
        const findCategory = secondaryCategory.find(
          (sCategory) => sCategory._id === category.category
        );

        if (findCategory) {
          return category.accessType === "Full";
        } else {
          return false;
        }
      });
      setCategoryCanView(
        categoryPermiseion.map((category) => {
          const findCategory = secondaryCategory.find(
            (sCategory) => sCategory._id === category.category
          );

          return {
            ...category,
            category: {
              _id: findCategory?._id,
              title: findCategory?.title,
            },
          };
        })
      );
    }
  }, [secondaryCategory]);

  console.log("can View", categoryCanView);

  const [subCategoryCanView, setSubCategoryCanView] = useState<
    SubCategoryPermissionType[]
  >([]);

  const onProjectHanlder = (project: Partial<Project> | null) => {
    console.log('PROJECT Handler id', project!._id)
    setProjectId(project!._id as EntityId);
  };

  const onCategoryHandler = (category: Partial<Category> | null) => {
    setCategoryID(category?._id!);
  };

  const onAddAccessPermission = async () => {
    let newProjectAccess: ProjectPermissionType[] = [];
    let newCategoryAccess: CategoryPermissionType[] = [];
    if (projectID && !categoryID) {
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      if (exist) {
        newProjectAccess = admin.projectAccess!.map((pr) => {
          return pr.project === exist.project
            ? (pr = { ...exist, accessType: "Full" })
            : pr;
        });

        newCategoryAccess = admin.categoryAccess!.filter((category) => {
          const find = admin!.categories?.find(
            (cat) => cat._id === category.category
          );
          if (find) {
            return find.project !== projectID;
          } else {
            return false;
          }
        });
        setProjectCanView(newProjectAccess);
        setCategoryCanView(newCategoryAccess);
      } else {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];

        setProjectCanView(newProjectAccess);
      }
    } else if (projectID && categoryID) {
      console.log(categoryID);
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      console.log("160", exist);

      if (exist && exist!.accessType === "Full") {
        newProjectAccess = [...admin.projectAccess!];
        setProjectCanView(newProjectAccess);
      } else if (exist && exist.accessType === "Partial") {
        const existCategory = admin.categoryAccess!.find(
          (category) => category.category === categoryID
        );
        console.log("166", existCategory);
        if (!existCategory) {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [
            ...admin.categoryAccess!,
            {
              category: { _id: categoryID.toString() },
              accessType: "Full",
              users: false,
              experience: false,
              survey: false,
              store: false,
            },
          ];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
        } else {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [...admin.categoryAccess!];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
        }
      } else if (!exist) {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Partial",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];
        newCategoryAccess = [
          ...admin.categoryAccess!,
          {
            category: { _id: categoryID.toString() },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];

        setProjectCanView(newProjectAccess);
        setCategoryCanView(newCategoryAccess);
      }
    }

    try {
      const newAdmin: Partial<Admin> & { status: boolean } = {
        _id: admin._id,
        projectAccess: newProjectAccess!,
        categoryAccess: newCategoryAccess!,
        status: true,
      };

      console.log("newAdmin", newAdmin);

      await updateAdmin({ ...newAdmin, status: true }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const onProjectRemove = async (project: ProjectPermissionType) => {
    const projectPermission = admin!.projectAccess!.filter(
      (pro) => pro.project !== project.project._id
    );

    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: projectPermission!,
      categoryAccess: admin!.categoryAccess!,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setProjectCanView(projectPermission);
    } catch (error) {}
  };

  const onCategoryRemove = async (category: CategoryPermissionType) => {
    const categoryPermission = admin!.categoryAccess!.filter(
      (cat) => cat.category !== category.category._id
    );
    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: admin.projectAccess!,
      categoryAccess: categoryPermission!,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setCategoryCanView(categoryPermission!);
    } catch (error) {}
  };

  const onClickProject = async (project: ProjectPermissionType) => {
    SetShowCanEdit(project as ProjectPermissionType);
  };

  const onClickCategory = async (category: CategoryPermissionType) => {
    SetShowCanEdit(category as CategoryPermissionType);
  };

  const onUserCheck = async () => {
    SetShowCanEdit({ ...showCanEdit!, users: !showCanEdit!.users! });
  };

  const onExpereinceCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, experience: !showCanEdit?.experience });
  };

  const onStoreCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, store: !showCanEdit?.store });
  };

  const onSurveyCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, survey: !showCanEdit?.survey });
  };

  const onSaveHandler = async () => {
    let newProjectAccess = [...admin.projectAccess!];
    let newCategoryAccess = [...admin.categoryAccess!];
    if ((showCanEdit as ProjectPermissionType).project) {
      newProjectAccess = newProjectAccess.map((proj) => {
        if (
          proj.project === (showCanEdit as ProjectPermissionType).project._id
        ) {
          proj = showCanEdit as ProjectPermissionType;
          return proj;
        } else {
          return proj;
        }
      });
    } else if ((showCanEdit as CategoryPermissionType).category) {
      newCategoryAccess = newCategoryAccess.map((cate) => {
        if (
          cate.category === (showCanEdit as CategoryPermissionType).category._id
        ) {
          cate = showCanEdit as CategoryPermissionType;
          return cate;
        } else {
          return cate;
        }
      });
    }
    try {
      console.log(newProjectAccess);
      console.log(newCategoryAccess);
      await updateAdmin({
        _id: admin._id,
        projectAccess: newProjectAccess,
        categoryAccess: newCategoryAccess,
        status: true,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <AdminAccessSchoolDistrictPresentation
      admin={admin}
      onProjectHanlder={onProjectHanlder}
      projectId={projectID}
      onCategoryHandler={onCategoryHandler}
      projectCanView={projectCanView}
      categoryCanView={categoryCanView}
      onAddAccessPermission={onAddAccessPermission}
      onProjectRemove={onProjectRemove!}
      onCategoryRemove={onCategoryRemove!}
      onClickCategory={onClickCategory}
      onClickProject={onClickProject}
      showCanEdit={showCanEdit}
      onUserCheck={onUserCheck}
      onExperienceCheck={onExpereinceCheck}
      onStoreCheck={onStoreCheck}
      onSurveyCheck={onSurveyCheck}
      onSaveHandler={onSaveHandler}
    />
  );
};

export default AdminAccessSchoolDistrictContainer;
