import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { HomeRecent } from "../../types/types";
import {
  HomeProjectAllTitle,
  ProjectAllWrapper,
} from "../HomeProjectAllPresentation/home.project.all.presenation.style";
import HomeProjectItemPresentation from "../HomeProjectItemPresentation/HomeProjectItemPresentation";
import { Project } from "../../../project/types/types";
import { Organization } from "../../../organization/types/types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

type RecentPresentationProps = {
  recents: EntityState<HomeRecent>;
  onSelectProject: (project: Partial<Project> | null) => void;
  onSelectOrganization: (organization: Partial<Organization | null>) => void;
};
const RecentPresentation: React.FC<RecentPresentationProps> = ({
  recents,
  onSelectProject,
  onSelectOrganization,
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1400, min: 900 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <HomeProjectAllTitle style={{ padding: "30px " }}>
        Recent
      </HomeProjectAllTitle>
      <ProjectAllWrapper>
        {recents!.ids!.length > 3 ? (
          <Carousel
            showDots
            deviceType={"desktop"}
            ssr
            slidesToSlide={1}
            containerClass="carousel-with-custom-dots"
            responsive={responsive}
            partialVisible
            infinite
          >
            {recents?.ids.map((id) => (
              <HomeProjectItemPresentation
                key={id}
                projectItem={recents.entities[id]!.project!}
                onSelectProject={onSelectProject}
                orgaizationaItem={recents.entities[id]!.organization}
                onSelectOrganization={onSelectOrganization}
              />
            ))}
          </Carousel>
        ) : (
          <>
            {recents?.ids.map((id) => (
              <HomeProjectItemPresentation
                key={id}
                projectItem={recents.entities[id]!.project!}
                onSelectProject={onSelectProject}
                orgaizationaItem={recents.entities[id]!.organization}
                onSelectOrganization={onSelectOrganization}
              />
            ))}
          </>
        )}
      </ProjectAllWrapper>
    </div>
  );
};

export default RecentPresentation;
