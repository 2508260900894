import React, { useEffect, useState } from "react";
import { Category } from "../../../category/types/types";
import { Organization } from "../../../organization/types/types";
import { Project } from "../../../project/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import AdminFilterPresentation from "../../presentation/admin.filter.presentation/admin.filter.presentation";

import { EntityId, EntityState } from "@reduxjs/toolkit";
import {
  useLazyGetAdminByCategoryQuery,
  useLazyGetAdminByProjectQuery,
  useLazyGetAdminBySubCategoryQuery,
  useLazyGetAdminsByOrganizationQuery,
  useLazyGetAdminsQuery,
} from "../../adminApiSlice/adminApiSlice";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { Admin } from "../../types/types";
type AdminFilterContainerProps = {
  adminList: EntityState<Admin> | null;
  setAdminList: React.Dispatch<React.SetStateAction<EntityState<Admin> | null>>;
};
const AdminFilterContainer: React.FC<AdminFilterContainerProps> = ({
  adminList,
  setAdminList,
}) => {
  const [organizationID, setOrganizationID] = useState<EntityId | null>(null);
  const [projectID, setProjectID] = useState<EntityId | null>(null);
  const [categoryID, setCategoryID] = useState<EntityId | null>(null);
  const [subCategoryId, setSubCategoryID] = useState<EntityId | null>(null);
  const [getAdminsByOrganization] = useLazyGetAdminsByOrganizationQuery();
  const [getAdminByProject] = useLazyGetAdminByProjectQuery();
  const [getAdmin] = useLazyGetAdminsQuery();
  const [getAdminByCategory] = useLazyGetAdminByCategoryQuery();
  const [getAdminBySubCategory] = useLazyGetAdminBySubCategoryQuery();

  useEffect(() => {}, [organizationID, projectID, subCategoryId, categoryID]);

  const onSelectOrganizationHandler = async (
    organization: Partial<Organization> | null
  ) => {
    if (organization) {
      console.log("organization id ........, ", organization);
      setOrganizationID(organization!._id!);
      try {
        const admins = await getAdminsByOrganization({
          organizationId: organization!._id!,
        }).unwrap();
        console.log("admins organization list", admins);
        setAdminList(admins);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrganizationID(null);
      const admins = await getAdmin({});
    }
  };

  const onSelectProjectHandler = async (project: Partial<Project> | null) => {
    try {
      if (project) {
        setProjectID(project!._id!);
        const admins = await getAdminByProject({
          projectId: project!._id!,
        }).unwrap();
        setAdminList(admins);
      } else {
        setProjectID(null);
        const admins = await getAdminsByOrganization({
          organizationId: organizationID!,
        }).unwrap();

        setAdminList(admins);
      }
    } catch (error) {}
  };

  const onSelectCategoryHandler = async (
    category: Partial<Category> | null
  ) => {
    try {
      if (category) {
        setCategoryID(category!._id!);
        const admins = await getAdminByCategory({
          categoryId: category!._id!,
        }).unwrap();
        setAdminList(admins);
      } else {
        setCategoryID(null);
        const admins = await getAdminByProject({
          projectId: projectID!,
        }).unwrap();
        setAdminList(admins);
      }
    } catch (error) {}
  };

  const onSelectSubCategoryHandler = async (
    subCategory: Partial<SubCategory> | null
  ) => {
    if (subCategory) {
      setSubCategoryID(subCategory!._id!);
      await getAdminBySubCategory({
        subCategoryId: subCategory!._id!,
      });
    } else {
      setSubCategoryID(null);
      await await getAdminByCategory({
        categoryId: categoryID!,
      });
    }
  };
  return (
    <AdminFilterPresentation
      organizationID={organizationID}
      projectID={projectID}
      categoryID={categoryID}
      onSelectOrganizationHandler={onSelectOrganizationHandler}
      onSelectProjectHandler={onSelectProjectHandler}
      onSelectCategoryHandler={onSelectCategoryHandler}
      onSelectSubCategoryHandler={onSelectSubCategoryHandler}
    />
  );
};

export default AdminFilterContainer;
