import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { ExperienceCard } from "../types/types";
import { IResponseList } from "../../../types/types";
import { setMetaData } from "./experienceSlice";
import { store } from "../../../app/store";

const experienceCardAdaptor = createEntityAdapter<ExperienceCard>({
  selectId: (experienceCard) => experienceCard._id,
});

interface ExperienceCardInitialState extends EntityState<ExperienceCard> {}

const initialState: ExperienceCardInitialState =
  experienceCardAdaptor.getInitialState();

export const extendedExperienceCardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllExperiences: builder.query<
      EntityState<ExperienceCard>,
      {
        project: EntityId;
        category?: EntityId;
        subCategory?: EntityId;
        expPublish?: boolean;
        expSave?: boolean;
        page: number;
        limit: number;
        sort: string;
        term: string;
      }
    >({
      query: (expData) =>
        `/experience-card/byadmin?project=${expData.project}&category=${expData.category}&subcategory=${expData.subCategory}&page=${expData.page}&limit=${expData.limit}&sort=${expData.sort}&term=${expData.term}&expPublish=${expData.expPublish}&expSave=${expData.expSave}`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;
        const experienceMetaData = response.metadata;
        store.dispatch(setMetaData(experienceMetaData));
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LISt" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),

    createExperienceCard: builder.mutation<
      EntityState<ExperienceCard>,
      Partial<ExperienceCard>
    >({
      query: (experienceCard) => ({
        url: `experience-card`,
        method: "POST",
        body: experienceCard,
      }),
      transformResponse: (response: ExperienceCard) => {
        return experienceCardAdaptor.setOne(initialState, response);
      },
      invalidatesTags: [{ type: "ExperienceCard", id: "LIST" }],
    }),

    getAExperienceById: builder.query<EntityState<ExperienceCard>, EntityId>({
      query: (id) => `/experience-card/${id}`,
      transformResponse: (response: ExperienceCard) => {
        console.log("response........", response);
        return experienceCardAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: arg },
      ],
    }),

    updateExperience: builder.mutation<
      EntityState<ExperienceCard>,
      Partial<ExperienceCard>
    >({
      query: (exp) => ({
        url: `/experience-card?id=${exp._id}`,
        method: "PUT",
        body: exp,
      }),
      transformResponse: (response: ExperienceCard) => {
        console.log("respnse", response);
        return experienceCardAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: ["ExperienceCard"],
    }),

    deleteExprience: builder.mutation<EntityState<ExperienceCard>, EntityId>({
      query: (id) => ({
        url: `/experience-card/${id}`,
        method: "DELETE",
        body: id,
      }),
      transformResponse: (response: ExperienceCard) => {
        return experienceCardAdaptor.removeOne(initialState, response._id);
      },
      invalidatesTags: ["ExperienceCard"],
    }),

    getExperienceHotspotSublist: builder.query<
      EntityState<ExperienceCard>,
      {
        hotspotgroupid: string;
        project: EntityId;
        category?: EntityId;
        subCategory?: EntityId;
        expPublish?: boolean;
        expSave?: boolean;
        page?: number;
        limit?: number;
        sort?: string;
        term?: string;
      }
    >({
      query: (expData) =>
        `/experience-card/hotspotsublist?hotspotgroupid=${expData.hotspotgroupid}&project=${expData.project}&category=${expData.category}&subcategory=${expData.subCategory}&page=${expData.page}&limit=${expData.limit}&sort=${expData.sort}&term=${expData.term}&expPublish=${expData.expPublish}&expSave=${expData.expSave}`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const experienceData = response.data;
        const experienceMetaData = response.metadata;
        return experienceCardAdaptor.setAll(initialState, experienceData);
      },
      providesTags: (result, error, arg) => [
        { type: "ExperienceCard", id: "LISt" },
        ...result!.ids.map((id) => ({ type: "ExperienceCard" as const, id })),
      ],
    }),
  }),
});

export const {
  useCreateExperienceCardMutation,
  useLazyGetAllExperiencesQuery,
  useGetAllExperiencesQuery,
  useLazyGetAExperienceByIdQuery,
  useGetAExperienceByIdQuery,
  useUpdateExperienceMutation,
  useDeleteExprienceMutation,
  useLazyGetExperienceHotspotSublistQuery,
  useGetExperienceHotspotSublistQuery,
} = extendedExperienceCardApiSlice;
