import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectOrganizationIds,
  useGetAllOrganizationForDataTableQuery,
  useLazyGetAllOrganizationForDataTableQuery,
} from "../../api/orgApiSlice";
import OrganizationListPresentation from "../../presantation/organization.list.presentation/organization.list.presentation";
import { EntityState } from "@reduxjs/toolkit";
import { Organization } from "../../types/types";

type OrganizationListContainerProp = {
  page?: number;
  limit?: number;
  onPage: (pageNumber: number) => void;
  onLimit: (limit: number) => void;
  term?: string;
  onTerm?: (text: string) => void;
  sort?: string;
  onSort?: (fieldName: string) => void;
};
const OrganizationListContainer: React.FC<OrganizationListContainerProp> = ({
  onLimit,
  onPage,
  page,
  limit,
  term,
  onTerm,
  sort,
  onSort,
}) => {
  const organizationIds = useAppSelector(selectOrganizationIds);
  const [orgListData, setOrgListData] =
    useState<EntityState<Organization> | null>(null);
  const {
    data: organizationDataTable,
    isLoading: organizationDataTableLoading,
    isError: organizationDataTableIsError,
    isSuccess: organizationDataTableSuccess,
    error,
  } = useGetAllOrganizationForDataTableQuery({
    page: page ? String(page) : "1",
    limit: limit ? String(limit) : "25",
    term: term,
    sort: sort,
  });

  const [orgDataList, { isLoading, isSuccess, isError }] =
    useLazyGetAllOrganizationForDataTableQuery();

  useEffect(() => {
    (async () => {
      try {
        const orgList = await orgDataList({
          page: page ? String(page) : "1",
          limit: limit ? String(limit) : "25",
          term: term,
          sort: sort,
        }).unwrap();

        setOrgListData(orgList);
      } catch (error) {}
    })();
  }, [orgListData, term, page, limit, sort, organizationDataTable]);

  if (organizationDataTableIsError) {
    return <>{error}</>;
  }
  return (
    <>
      {organizationDataTableSuccess && (
        <OrganizationListPresentation
          organizationDataTable={orgListData!}
          organizationIds={organizationIds}
          page={page}
          onPage={onPage}
          onLimit={onLimit}
          limit={limit}
          term={term}
          onTerm={onTerm}
          sort={sort}
          onSort={onSort}
        />
      )}
    </>
  );
};

export default OrganizationListContainer;
