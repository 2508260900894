import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectAllTeam } from "../../features/team/teamApiSlice/teamApiSlice";
import { Team } from "../../features/team/types/types";
import SelectTeamPresentation from "./SelectTeamPresentation";
export type SelectTeamContainerProps = {
  onTeamSelect: (e: Partial<Team> | null) => void;
  team?: Partial<Team>;
};
const SelectTeamContainer: React.FC<SelectTeamContainerProps> = ({
  onTeamSelect,
  team,
}) => {
  const allTeam = useAppSelector(selectAllTeam);

  const onSelectTeam = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const findTeam = allTeam.find((team) => team._id === e.target.value);
    if (findTeam) {
      onTeamSelect(findTeam);
    } else {
      onTeamSelect(null);
    }
  };

  return (
    <SelectTeamPresentation
      onSelectTeam={onSelectTeam}
      teams={allTeam}
      team={team}
    />
  );
};

export default SelectTeamContainer;
