import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { ILogin } from "../../types/auth.types";
import "./login.css";
import {
  LoginContent,
  LoginContentTitle,
  LoginButtonContainer,
  LoginButton,
  ForgotPasswordButton,
} from "./login.style";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const Login: FC<ILogin> = ({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  onLogin,
  handleClickShowPassword,
  showPassword,
  error,
  isError,
}) => {
  return (
    <LoginContent>
      <LoginContentTitle>Account Login</LoginContentTitle>
      <TextField
        sx={{
          "& fieldset": {
            borderRadius: "12px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
          },
        }}
        style={{ width: "100%" }}
        id="outlined-basic"
        InputLabelProps={{
          shrink: true,
        }}
        autoComplete="off"
        label="Username"
        variant="outlined"
        name="email"
        value={email}
        onChange={onChangeEmail}
      />
      <FormControl style={{ width: "100%", marginTop: 10 }} variant="outlined">
        <InputLabel shrink htmlFor="outlined-adornment-password">
          Password
        </InputLabel>
        <OutlinedInput
          sx={{
            "& fieldset": {
              borderRadius: "12px",
              border: "1px solid rgba(0, 0, 0, 0.23)",
            },
            "& input": {
              padding: "16.5px 14px",
            },
          }}
          notched={true}
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={password}
          name="password"
          onChange={onChangePassword}
          style={{ borderRadius: 12 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={() => {}}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>

      {isError && error && (
        <div style={{ marginTop: 10, color: "red" }}>
          {"status" in error
            ? error.status === 401
              ? "Email or password is worng"
              : "Network Error"
            : error.message}
        </div>
      )}
      <ForgotPasswordButton onClick={() => {}}>
        Forgot Password?
      </ForgotPasswordButton>
      <LoginButtonContainer>
        <LoginButton onClick={onLogin}>
          {false ? "Loading..." : "Login"}
        </LoginButton>
      </LoginButtonContainer>
    </LoginContent>
  );
};

export default Login;
