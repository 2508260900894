import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthAdmin, AuthErrorResponse } from "../types/auth.types";

// get admin status, it is exist or not, that shows us it is logged in or not
export const getAdminStatus = createAsyncThunk<
  AuthAdmin | AuthErrorResponse,
  void,
  { rejectValue: AuthErrorResponse }
>("auth/getAdminStatus", async (_, { rejectWithValue }) => {
  try {
    const admin = await axios.get<AuthAdmin>(
     `https://api.gradify.app/api/authadmin/status`,
    // `http://localhost:6800/api/authadmin/status`,
      //this is test

      {
        withCredentials: true,
      }
    );

    return admin.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

//logout
export const loguot = createAsyncThunk<
  { logout: boolean },
  void,
  { rejectValue: AuthErrorResponse }
>("auth/logut", async (_, { rejectWithValue }) => {
  try {
    const logout = await axios.post(
     `https://api.gradify.app/api/authadmin/logout`,
    // `http://localhost:6800/api/authadmin/logout`,
      _,
      {
        withCredentials: true,
      }
    );
    return logout.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
