import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import ExperienceMainDetailsContainer from "../container/experience.details.container/experience.main.details.container";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAExperienceByIdQuery,
  useLazyGetAExperienceByIdQuery,
} from "../api/experienceCardApiSlice";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import Loading from "../../../components/Loading/Loading";

const ExperienceDetailsPage = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  const projectApp = useAppSelector(selectedProject);

  useEffect(() => {
    if (!projectApp) {
      navigate("/");
    }
  }, [projectApp]);

  const {
    data: experienceDetails,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAExperienceByIdQuery(status!);
  console.log("experienceDetails", experienceDetails);

  let content;
  if (isLoading || isLoading) {
    content = (
      <>
        <Header title="Experience Datails" />
        <Loading />
      </>
    );
  } else if (isSuccess) {
  
    content = (
      <>
        <Header title="Experience Datails" />
        {isSuccess && (
          <ExperienceMainDetailsContainer
          
            experienceDtails={experienceDetails?.entities[status!]}
            isUpdated={true}
          />
        )}
      </>
    );
  } else if (isError) {
    content = <ExperienceMainDetailsContainer isUpdated={false} />;
  }

  return <>{content}</>;
};

export default ExperienceDetailsPage;
