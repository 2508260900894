import { apiSlice } from "../../../app/api/apiSlice";
import { ProfileChart } from "../types/type";

export const exendedProfileChartApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileChart: builder.query<
      ProfileChart,
      {
        currentSystem: string;
        studentId: string;
      }
    >({
      query: (data) =>
        `/experience-card/chartcalc?currentsystemstring=${data.currentSystem}&studentId=${data.studentId}`,
      providesTags: ["ProfileChart"],
    }),
  }),
});

export const { useGetProfileChartQuery, useLazyGetProfileChartQuery } =
  exendedProfileChartApi;
