import React from "react";
import {
  InfoItem,
  InfoSection,
  InfoText,
  InfoTextArea,
  InfoTitle,
} from "./shopmidcol.styles";
import { Switch } from "@mui/material";
import CustomSwitch from "../../../../../components/switch/CustomSwitch";
type ShopInfoPresentationProps = {
  costPerItem: number;
  onCostPerItem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unLockLevel: number;
  onUnlockLevel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimitState: boolean;
  onPurchaseLimitState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimit: number;
  onPurchaseLimit: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const ShopInfoPresentation: React.FC<ShopInfoPresentationProps> = ({
  costPerItem,
  gem,
  onGem,
  unLockLevel,
  onUnlockLevel,
  purchaseLimit,
  onPurchaseLimit,
  onPurchaseLimitState,
  purchaseLimitState,
  onCostPerItem,
}) => {
  return (
    <InfoSection>
      <InfoTitle>Info</InfoTitle>
      <InfoItem>
        <InfoText>Cost Per Item ($)</InfoText>
        <InfoTextArea value={costPerItem} onChange={onCostPerItem} />
      </InfoItem>
      <InfoItem>
        <InfoText>Suggested Gems</InfoText>
        <InfoTextArea
          placeholder="gems"
          style={{ border: "none", outline: "none", fontSize: "10px" }}
        />
      </InfoItem>
      <InfoItem>
        <InfoText>Price In Gems</InfoText>
        <InfoTextArea value={gem} onChange={onGem} />
      </InfoItem>
      <InfoItem>
        <InfoText>Unlock Level</InfoText>
        <InfoTextArea value={unLockLevel} onChange={onUnlockLevel} />
      </InfoItem>
      <InfoItem>
        <InfoText
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Purchase Limit
          <CustomSwitch
              style={{ marginLeft: 10 }}
              isToggle={purchaseLimitState}
              onChangeToggle={onPurchaseLimitState}
            />
        </InfoText>
        {purchaseLimitState && (
          <InfoTextArea value={purchaseLimit} onChange={onPurchaseLimit} />
        )}
      </InfoItem>
    </InfoSection>
  );
};

export default ShopInfoPresentation;
