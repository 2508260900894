import React, { ChangeEvent } from "react";
import { FilterCategoryPresentationProps } from "../../../category/types/types";
import { FilterSubCategoryPresentationProps } from "../../types/types";
import {
  StudentFillterLabel,
  StudentFillterSelec,
} from "../../../students/presantation/student.filter.presentation/StudentFilterPresentation.style";

const FilterSubCategoryPresentation: React.FC<
  FilterSubCategoryPresentationProps
> = ({ subCategories, onSelectSubCategory, defaultValue }) => {
  console.log("defautlvalue!!!!!!!", defaultValue);
  return (
    <div>
      {/* {subCategories.ids.length === 1 ? (
        <p>
          {" "}
          <p>{subCategories.entities[subCategories.ids[0]]?.title}</p>
        </p>
      ) : ( */}

      <StudentFillterSelec
        onChange={onSelectSubCategory}
        defaultValue={
          defaultValue
            ? defaultValue?._id
              ? String(defaultValue._id!)
              : String(defaultValue!)
            : "All"
        }
      >
        <option value="All">All</option>
        {subCategories.ids.map((id) => (
          <option
            key={subCategories.entities[id]?._id}
            value={subCategories.entities[id]?._id}
          >
            {subCategories.entities[id]?.title}
          </option>
        ))}
      </StudentFillterSelec>
      {/* )} */}
    </div>
  );
};

export default FilterSubCategoryPresentation;
