import React, { useState } from "react";
import {
  DescriptionTextArea,
  ExpCardSubTitle,
  ExpCardTitle,
  ExperienceCardDetailsInfo,
  ExperienceDetailsActionCol,
  ExperienceInfoCol1,
  ExperienceInfoRow,
  ExperienceCardDetailsAction,
  ShadowBox,
  ExperienceAdvanceDay,
  ExperienceAdvanceRandom,
  ExperienceAdvanceIntervalsBox,
} from "./experience.details.styles";
import "../../../experiencesForStudent/presentation/experience.for.student.css";
import { ExperienceType } from "../../../experiencetype/types/types";
import { CompletionType } from "../../../completion-type/types/types";
import { Button } from "@mui/material";
import SelectCompletionTypeContainer from "../../../completion-type/selectCompletionTypeContainer/SelectCompletionTypeContainer";
import Switch from "@mui/material/Switch";
import SelectField from "../../../../components/design/selectFiled/selectField";
import { images } from "../../../../constants";
import { BsCaretRightFill, BsTrash } from "react-icons/bs";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import TabName from "../../../../components/TabName/TabName";
import FireSvg from "../../../../components/logos/fire/FireSvg";
import StarSvg from "../../../../components/logos/star/StarSvg";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import { ExperienceCard } from "../../types/types";
import { StudentButton } from "../expereince.list.presentation/experienceListPresentation.styles";
import { FaRegChartBar } from "react-icons/fa";
import { EntityId } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
const label = { inputProps: { "aria-label": "Switch demo" } };

type ExperienceDetailsInfoPresentationProps = {
  expType: Partial<ExperienceType>;
  title: string | null;
  onTitleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  subTitle: string | null;
  onSubTitleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string | null;
  onDescriptionHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  image: string;
  onImageHandler: () => void;
  completionType: Partial<CompletionType>;
  onCompletionTypeHandler: (comType: Partial<CompletionType>) => void;
  xpCompletion: number;
  onXpCompletionHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  xpView: number;
  onXpViewHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number;
  onGemHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectCompletionType: (completionType: Partial<CompletionType>) => void;
  autoCompletion: boolean;
  onAutoCompletion: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addLink: boolean;
  onAddLinkHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textLink: string | null;
  onTextLinkHanlder: (e: React.ChangeEvent<HTMLInputElement>) => void;
  texturl: string | null;
  onTextUrlHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonLink: string | null;
  onButtonLink: (e: React.ChangeEvent<HTMLInputElement>) => void;
  driverOneItems: { value: string; label: string }[];
  driverTwoItems: { value: string; label: string }[];
  driverOneSelected: string | null;
  onDriverOneSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  driverTwoSelected: string | null;
  onDriverTwoSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onPublish: () => void;
  onSave: () => void;
  isUpdated: boolean;
  publish: boolean;
  onPublishUpdate: () => void;
  onSaveUpdate: () => void;
  onRemoveCard: () => void;
  onUpdate: () => void;
  onNotificationHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNotification: boolean;
  saveLoading: boolean;
  notification: boolean;
  createDayNotification: boolean;
  norificationDaysBefore: boolean;
  notificationDaysBeforeNumber: number;
  notificationDaysOf: boolean;
  onChangeNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCreateDaysNotification: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChangeDaysBefore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDaysBeforeNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDaysOf: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startDateNotification: boolean;
  onStartDateNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  midNotification: boolean;
  onMidNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  passDueNotification: boolean;
  onPassDueNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reSpawn: boolean;
  countHotSpot: number;
  total_reSpawns: number | null;
  intervals: string;
  weeklyDays: string | null;
  onReSpawn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountHotSpot: (count: number) => void;
  onTotalReSpawns: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInterval: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onWeeklyDays: (day: string) => void;
  multiDays: string[] | null;
  onMultiDays: (day: string) => void;
  dailyTime: string | null;
  onDailyTime: (time: string) => void;
  dayofMonth: string | null;
  onDayOfMonthly: (e: React.ChangeEvent<HTMLInputElement>) => void;
  availableDays: number;
  onAvailableDays: (n: number) => void;
  respwanNotification?: boolean;
  onRespwanNotification: () => void;
  missionRevive: boolean;
  onMissionRevive: () => void;
  onDonotPassDue?: () => void;
  doNotPassDue?: boolean;
  id?: string | null | number;
};

const ExperienceDetailsInfoPresentation: React.FC<
  ExperienceDetailsInfoPresentationProps
> = ({
  expType,
  title,
  onTitleHandler,
  subTitle,
  onSubTitleHandler,
  description,
  onDescriptionHandler,
  image,
  onImageHandler,
  completionType,
  onCompletionTypeHandler,
  xpCompletion,
  onXpCompletionHandler,
  xpView,
  onXpViewHandler,
  onGemHandler,
  gem,
  onSelectCompletionType,
  autoCompletion,
  onAutoCompletion,
  addLink,
  onAddLinkHandler,
  textLink,
  onTextLinkHanlder,
  texturl,
  onTextUrlHandler,
  buttonLink,
  onButtonLink,
  driverOneItems,
  driverTwoItems,
  driverOneSelected,
  onDriverOneSelected,
  driverTwoSelected,
  onDriverTwoSelected,
  onPublish,
  onSave,
  isUpdated,
  publish,
  onPublishUpdate,
  onSaveUpdate,
  onRemoveCard,
  onUpdate,
  onNotificationHandler,
  onNotification,
  saveLoading,
  createDayNotification,
  norificationDaysBefore,
  notification,
  notificationDaysBeforeNumber,
  notificationDaysOf,
  onChangeCreateDaysNotification,
  onChangeDaysBefore,
  onChangeDaysBeforeNumber,
  onChangeDaysOf,
  onChangeNotification,
  midNotification,
  onMidNotification,
  onPassDueNotification,
  onStartDateNotification,
  passDueNotification,
  startDateNotification,
  countHotSpot,
  intervals,
  onCountHotSpot,
  onInterval,
  onReSpawn,
  onTotalReSpawns,
  onWeeklyDays,
  reSpawn,
  total_reSpawns,
  weeklyDays,
  multiDays,
  onMultiDays,
  dailyTime,
  onDailyTime,
  dayofMonth,
  onDayOfMonthly,
  availableDays,
  onAvailableDays,
  respwanNotification,
  onRespwanNotification,
  missionRevive,
  onMissionRevive,
  onDonotPassDue,
  doNotPassDue,
  id
}) => {
  const [toggleBox, setToggleBox] = useState({
    box1: true,
    box2: false,
    box3: false,
  });
  const navigation = useNavigate();
  
  const onReview = (id: EntityId) => {
    navigation(`/experience/expReview/${id}`);
  };

  const [weeklySelectedDays, setWeeklySelectedDays] = useState<string>(
    weeklyDays ? weeklyDays : "Monday"
  );

  const [pickWeekRandomDay, setPickWeekRandomDay] = useState<string[]>([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);

  const [passDue, setPassDue] = useState<boolean>(doNotPassDue!);

  return (
    <>
      <ExperienceInfoRow
        style={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "flex-start",
          width:"calc(100% - 49px)",
          margin:"0 11px 10px 39px"
        }}
        className="row w-100 m-0"
      >
        <ExperienceDetailsActionCol
          style={{
            justifyContent: "flex-end",
            width: "26%",
            padding: 0,
            marginTop: 7,
          }}
          Direction={""}
        >
          <ExperienceInfoCol1 backColor={expType.color!}>
            <ExperienceCardDetailsInfo>
              {id !== "" && id != undefined && (
                           <StudentButton
                           style={{ border:"3px solid #fff", position:"absolute",top:15,left:15, display: "flex", alignItems: "center", marginLeft: 3, paddingRight: 5, }}
                           onClick={() => {
                             onReview(id!);
                           }}
                           spaceLeft={""}
                         >
                           <span
                             className="experience-item-button"
                             style={{
                               maxWidth: 130,
                               textAlign: "center",
                               fontSize: 12,
                               justifyContent: "center",
                               fontFamily: 'Poppins',
                               fontWeight:600,
                               paddingRight:5
                         }}
                           >
                             Review
                           </span>
                           <FaRegChartBar />
                         </StudentButton> 
              )}

              <div className="experience-title">
                <ExpCardTitle
                  type="text"
                  value={title!}
                  onChange={onTitleHandler}
                  placeholder="Title"
                />
              </div>
              <div className="experience-title-subtitle">
                <ExpCardSubTitle
                  type="text"
                  value={subTitle!}
                  onChange={onSubTitleHandler}
                  placeholder="SubTitle"
                />
              </div>
              <div className="experience-title-description">
                <DescriptionTextArea
                  placeholder="Description of the experience item to inform the students what they need to do for this task. "
                  value={description!}
                  onChange={onDescriptionHandler}
                />
              </div>
              <div className="experience-title-link">
                {addLink && (
                  <div
                    className="experience-title-link-content"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={images.TitleLinkImage} alt="title link" />
                    <p>{textLink}</p>{" "}
                  </div>
                )}
              </div>
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={image!}
                  height={300}
                  className="pick-pic"
                  onClick={onImageHandler}
                  alt="this is xp completeion"
                />
              </div>
            </ExperienceCardDetailsInfo>
            <ExperienceCardDetailsAction>
              <div className="experience-details-action">
                <div className="xp">
                  <span>{xpCompletion}</span>
                  <span>XP</span>
                </div>
                <div className="icon">
                  <img className="exp-icon" src={expType.icon} alt="icon" />
                </div>
              </div>
              <div className="competion-message">
                <div className="completion-action-container">
                  <p>{completionType.message}</p>
                  <button className="completion-button">
                    {completionType.buttonTitle}
                  </button>
                </div>
              </div>
            </ExperienceCardDetailsAction>
          </ExperienceInfoCol1>
        </ExperienceDetailsActionCol>

        <ExperienceDetailsActionCol
          style={{
            width: "25%",
          }}
          className={`${toggleBox.box1 ? "" : "boxActive"}`}
          Direction={""}
        >
          <div className="exp-setting motivational">
            <button
              onClick={() => {
                setToggleBox({ ...toggleBox, box1: !toggleBox.box1 });
              }}
            >
              <TabName
                name="Motivational Design"
                left={-75}
                condition={toggleBox.box1}
              />
              <BsCaretRightFill color="black" size={20} />
            </button>
            <div className="motivational-title">Motivational Design</div>
            <ShadowBox disable={""}>
              <div className="xp-comp">
                <span>XP for Completion</span>
                <input
                  type="text"
                  placeholder="100-300"
                  id="xpCompletion"
                  name="xpCompletion"
                  value={xpCompletion}
                  onChange={onXpCompletionHandler}
                />
              </div>

              <div className="xp-comp">
                <span>XP for viewing</span>
                <input
                  type="text"
                  placeholder="0-5"
                  name="xpView"
                  id="xpView"
                  value={xpView}
                  onChange={onXpViewHandler}
                />
              </div>
              <div className="xp-comp">
                <span>Gems</span>
                <input
                  type="text"
                  placeholder="-"
                  name="coins"
                  id="coins"
                  value={gem}
                  onChange={onGemHandler}
                />
              </div>
            </ShadowBox>

            <ShadowBox disable="">
              <div className="submition">
                <p>
                  Submission <span>(completion types)</span>{" "}
                </p>
                <div>
                  <SelectCompletionTypeContainer
                    onSelectCompletionType={onSelectCompletionType}
                    completionType={completionType}
                  />
                </div>
              </div>
              {completionType.title === "Link" && (
                <input
                  type="text"
                  placeholder="Add URL"
                  name="experienceUrl"
                  id="experienceUrl"
                  className="dark-input"
                  value={buttonLink!}
                  onChange={onButtonLink}
                />
              )}
              <div className="auto-complition">
                <span className="auto-title">Auto Complition</span>
                <Switch
                  {...label}
                  checked={autoCompletion}
                  onChange={onAutoCompletion}
                  color="success"
                />
              </div>
            </ShadowBox>

            <ShadowBox disable={""}>
              <div className="text-link" style={{ paddingRight: 50 }}>
                <span className="text-link-title">Add Link in text</span>
                <Switch
                  {...label}
                  checked={addLink}
                  onChange={onAddLinkHandler}
                  color="success"
                />
              </div>
              {addLink && (
                <>
                  <input
                    type="text"
                    placeholder="Title for link"
                    name="titleForLink"
                    id="titleForLink"
                    className="dark-input"
                    value={textLink!}
                    onChange={onTextLinkHanlder}
                  />

                  <input
                    type="text"
                    placeholder="Add URL"
                    name="addTitleUrl"
                    id="addTitleUrl"
                    className="dark-input"
                    value={texturl!}
                    onChange={onTextUrlHandler}
                  />
                </>
              )}
            </ShadowBox>
            <ShadowBox disable={""}>
              <div className="notification-Container">
                <div className="notification-title-container">
                  <span className="notification-title">Notification</span>

                  <CustomSwitch
                    style={{ marginTop: 5, marginLeft: 30 }}
                    isToggle={notification}
                    onChangeToggle={onChangeNotification}
                  />
                </div>

                {notification &&
                  (expType.title === "Gradegy" ||
                    expType.title === "Mission") && (
                    <div className="notification-content-container">
                      <div className="create-date">
                        <span className="create-date">Start Date</span>
                        <CustomSwitch
                          isToggle={startDateNotification}
                          onChangeToggle={onStartDateNotification}
                          style={{ marginLeft: 20 }}
                        />
                      </div>
                      <div className="days-before mid">
                        <span className="days-before ">Mid</span>
                        <CustomSwitch
                          isToggle={midNotification}
                          onChangeToggle={onMidNotification}
                          style={{ marginLeft: 20 }}
                        />
                      </div>
                      <div className="day-of">
                        <span className="day-of">PassDue</span>
                        <CustomSwitch
                          isToggle={passDueNotification}
                          onChangeToggle={onPassDueNotification}
                          style={{ marginLeft: 20 }}
                        />
                      </div>
                    </div>
                  )}

                {notification && expType.title === "HotSpot" && (
                  <div className="notification-content-container">
                    <div className="create-date">
                      <span className="create-date">Start Date</span>
                      <CustomSwitch
                        isToggle={startDateNotification}
                        onChangeToggle={onStartDateNotification}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div className="days-before">
                      <span className="days-before">Respwan</span>
                      <CustomSwitch
                        isToggle={respwanNotification}
                        onChangeToggle={onRespwanNotification}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div className="day-of">
                      <span className="day-of">PassDue</span>
                      <CustomSwitch
                        isToggle={passDueNotification}
                        onChangeToggle={onPassDueNotification}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                  </div>
                )}

                {notification && expType.title === "Events" && (
                  <div className="notification-content-container">
                    <div className="create-date">
                      <span className="create-date">Create Date</span>
                      <CustomSwitch
                        isToggle={createDayNotification}
                        onChangeToggle={onChangeCreateDaysNotification}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div className="days-before">
                      <span className="days-before">Day (s) Before</span>
                      <CustomSwitch
                        isToggle={norificationDaysBefore}
                        onChangeToggle={onChangeDaysBefore}
                        style={{ marginLeft: 20 }}
                      />
                      <input
                        type="text"
                        placeholder="0-10"
                        name="daysBefore"
                        id="daysBefore"
                        style={{
                          width: 37,
                          textAlign: "center",
                          marginLeft: 5,
                        }}
                        value={notificationDaysBeforeNumber}
                        onChange={onChangeDaysBeforeNumber}
                      />
                    </div>
                    <div className="day-of">
                      <span className="day-of">Day Of</span>
                      <CustomSwitch
                        isToggle={notificationDaysOf}
                        onChangeToggle={onChangeDaysOf}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </ShadowBox>
          </div>
        </ExperienceDetailsActionCol>
        <ExperienceDetailsActionCol
          style={{
            width: "21%",
          }}
          className={`${toggleBox.box2 ? "" : "boxActive"}`}
          Direction={""}
        >
          <div className="exp-setting motivational">
            <button
              onClick={() => {
                setToggleBox({ ...toggleBox, box2: !toggleBox.box2 });
              }}
            >
              <TabName name="Tags" left={-10} condition={toggleBox.box2} />
              <BsCaretRightFill color="black" size={20} />
            </button>
            <div className="motivational-title">Tags</div>
            <div className="driver">
              <span>Driver</span>
              <SelectField
                onChange={onDriverOneSelected}
                defaultValue={driverOneSelected!}
              >
                {driverOneItems.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </SelectField>
              <SelectField
                onChange={onDriverTwoSelected}
                defaultValue={driverTwoSelected!}
              >
                {driverTwoItems.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </SelectField>
            </div>
            <div className="level">
              <span>Level of Progression</span>
              <SelectField onChange={() => {}} defaultValue={""}>
                <option key={""} value={""}>
                  {""}
                </option>
              </SelectField>
            </div>
            <div className="persona">
              <span>Persona</span>
              <SelectField onChange={() => {}} defaultValue={""}>
                <option key={""} value={""}>
                  {""}
                </option>
              </SelectField>
            </div>
            <div className="milestone mt-5">
              <span className="milestone-title">Milestone</span>
              <CustomSwitch
                style={{ marginTop: 5, marginLeft: 30 }}
                isToggle={undefined}
                onChangeToggle={undefined} //  isToggle={milstone}
                //  onChangeToggle={() =>
                //    setMilstone(!milstone)
                //   }
              />
            </div>

            <div className="weight">
              <span>Weight</span>
              <div className="stars">
                <button onClick={() => {}}>
                  <StarSvg
                    stroke="#7b7777"
                    fill="#FFB100"
                    width={20}
                    height={26}
                  />
                </button>
                <button onClick={() => {}}>
                  <StarSvg
                    stroke="#7b7777"
                    fill="#FFB100"
                    width={20}
                    height={26}
                  />
                </button>
                <button onClick={() => {}}>
                  <StarSvg
                    stroke="#7b7777"
                    fill="#FFB100"
                    width={20}
                    height={26}
                  />
                </button>
              </div>
            </div>
            <div className="difficulty">
              <span className="difficulty-title">Difficulty</span>
              <CustomSwitch
                style={{ marginTop: 5, marginLeft: 30 }}
                isToggle={undefined}
                onChangeToggle={undefined}
              />
            </div>
            {true ? (
              <div className="fires">
                <span className="fires-title">fire</span>
                <div className="fire">
                  <button onClick={() => {}}>
                    <FireSvg
                      stroke="#7b7777"
                      fill="#FFB100"
                      width={20}
                      height={26}
                    />
                  </button>
                  <button onClick={() => {}}>
                    <FireSvg
                      stroke="#7b7777"
                      fill="#FFB100"
                      width={20}
                      height={26}
                    />
                  </button>

                  <button onClick={() => {}}>
                    <FireSvg
                      stroke="#7b7777"
                      fill="#FFB100"
                      width={20}
                      height={26}
                    />
                  </button>
                  <button onClick={() => {}}>
                    <FireSvg
                      stroke="#7b7777"
                      fill="#FFB100"
                      width={20}
                      height={26}
                    />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </ExperienceDetailsActionCol>
        <ExperienceDetailsActionCol
          style={{
            width: "27%",
          }}
          className={`${toggleBox.box3 ? "" : "boxActive"}`}
          Direction={""}
        >
          <div className="exp-setting motivational advance-tailoroad">
            <button
              onClick={() => {
                setToggleBox({ ...toggleBox, box3: !toggleBox.box3 });
              }}
            >
              <TabName name="Advance" left={-29} condition={toggleBox.box3} />
              <BsCaretRightFill color="black" size={20} />
            </button>
            <div className="motivational-title">Advaned</div>
            {expType.title === "Gradegy" && (
              <div>
                <span className="text-link-title">Past due? </span>
                <CustomSwitch
                  style={{ marginLeft: 20 }}
                  isToggle={passDue}
                  onChangeToggle={() => {
                    onDonotPassDue!();
                    setPassDue(!passDue);
                  }}
                />
                {passDue && (
                  <div>
                    <span
                      style={{
                        fontSize: 13,
                        paddingRight: 10,
                      }}
                    >
                      Days Available:
                    </span>
                    <input
                      type="text"
                      placeholder="&infin;"
                      style={{ width: 35, height: 27, borderColor: "#CED4DA" }}
                      value={availableDays}
                      onChange={(e) => {
                        onAvailableDays(Number(e.target.value));
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {expType.title === "HotSpot" && (
              <ShadowBox disable={""}>
                <div className="text-link" style={{ paddingRight: 50 }}>
                  <span className="text-link-title">ReSpawn</span>
                  <CustomSwitch
                    style={{ marginLeft: 20 }}
                    isToggle={reSpawn}
                    onChangeToggle={onReSpawn}
                  />
                </div>
                {reSpawn && 

                <>
                

                <div
                  className="xp-comp"
                  style={{
                    justifyContent: "flex-start",
                    paddingBottom: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 13,
                      paddingRight: 10,
                    }}
                  >
                    Total ReSpawns
                  </span>
                  <input
                    type="text"
                    placeholder="&infin;"
                    style={{ width: 35, height: 27, borderColor: "#CED4DA" }}
                    value={total_reSpawns!}
                    onChange={onTotalReSpawns}
                  />
                </div>
                <div>
                  <span
                    style={{ fontSize: 12, color: "#495057", paddingRight: 7 }}
                  >
                    Intervals
                  </span>
                  <SelectField onChange={onInterval} defaultValue={intervals}>
                    <option value={"Weekly"}>Weekly</option>
                    <option value={"Multi-Day"}>Multi-Day</option>
                    <option value={"Daily"}>{"Daily"}</option>
                    <option value={"Monthly"}>{"Monthly"}</option>
                  </SelectField>
                </div>

                {/* weekly */}

                {intervals === "Weekly" && (
                  <ExperienceAdvanceIntervalsBox>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          weeklySelectedDays === "Monday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Monday" ? "white" : "black",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Monday");
                        onWeeklyDays("Monday");
                      }}
                    >
                      M
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          weeklySelectedDays === "Tuesday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Tuesday" ? "white" : "black",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Tuesday");
                        onWeeklyDays("Tuesday");
                      }}
                    >
                      T
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          weeklySelectedDays === "Wednesday"
                            ? "green"
                            : "white",
                        color:
                          weeklySelectedDays === "Wednesday"
                            ? "white"
                            : "black",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Wednesday");
                        onWeeklyDays("Wednesday");
                      }}
                    >
                      W
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        background:
                          weeklySelectedDays === "Thursday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Thursday" ? "white" : "black",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Thursday");
                        onWeeklyDays("Thursday");
                      }}
                    >
                      T
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        background:
                          weeklySelectedDays === "Friday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Friday" ? "white" : "black",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Friday");
                        onWeeklyDays("Friday");
                      }}
                    >
                      F
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          weeklySelectedDays === "Saturday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Saturday" ? "white" : "black",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Saturday");
                        onWeeklyDays("Saturday");
                      }}
                    >
                      S
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          weeklySelectedDays === "Sunday" ? "green" : "white",
                        color:
                          weeklySelectedDays === "Sunday" ? "white" : "black",
                      }}
                      onClick={() => {
                        setWeeklySelectedDays("Sunday");
                        onWeeklyDays("Sunday");
                      }}
                    >
                      S
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceRandom
                      onClick={() => {
                        const day =
                          pickWeekRandomDay[
                            Math.floor(Math.random() * pickWeekRandomDay.length)
                          ];
                        setWeeklySelectedDays(day);
                        onWeeklyDays(day);
                      }}
                    >
                      Random
                    </ExperienceAdvanceRandom>
                  </ExperienceAdvanceIntervalsBox>
                )}

                {/* Multi day */}

                {intervals === "Multi-Day" && (
                  <ExperienceAdvanceIntervalsBox>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                        multiDays?.find((item) => item === "Monday") ? "green" : "white",
                      color:
                      multiDays?.find((item) => item === "Monday") ? "white" : "black",
                 
                      }}
                      onClick={() => {
                        onMultiDays("Monday");
                      }}
                    >
                      M
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          multiDays?.find((item) => item === "Tuesday") ? "green" : "white",
                        color:
                        multiDays?.find((item) => item === "Tuesday") ? "white" : "black",
                      }}
                      onClick={() => {
                        onMultiDays("Tuesday");
                      }}
                    >
                      T
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          multiDays?.find((item) => item === "Wednesday") ? "green" : "white",
                        color:
                         multiDays?.find((item) => item === "Wednesday") ? "white" : "black",
                      }}
                      onClick={() => {
                        onMultiDays("Wednesday");
                      }}
                    >
                      W
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          multiDays?.find((item) => item === "Thursday") ? "green" : "white",
                        color:
                         multiDays?.find((item) => item === "Thursday") ? "white" : "black",
                      }}
                      onClick={() => {
                        onMultiDays("Thursday");
                      }}
                    >
                      T
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          multiDays?.find((item) => item === "Friday") ? "green" : "white",
                        color:
                         multiDays?.find((item) => item === "Friday") ? "white" : "black",
                      }}
                      onClick={() => {
                        onMultiDays("Friday");
                      }}
                    >
                      F
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                          background:
                            multiDays?.find((item) => item === "Saturday") ? "green" : "white",
                          color:
                          multiDays?.find((item) => item === "Saturday") ? "white" : "black",
                      }}
                      onClick={() => {
                        onMultiDays("Saturday");
                      }}
                    >
                      S
                    </ExperienceAdvanceDay>
                    <ExperienceAdvanceDay
                      style={{
                        cursor: "pointer",
                        background:
                          multiDays?.find((item) => item === "Sunday") ? "green" : "white",
                        color:
                        multiDays?.find((item) => item === "Sunday") ? "white" : "black",

                      }}
                      onClick={() => {
                        onMultiDays("Sunday");
                      }}
                    >
                      S
                    </ExperienceAdvanceDay>
                  </ExperienceAdvanceIntervalsBox>
                )}

                {/* Daily */}

                {intervals === "Daily" && (
                  <ExperienceAdvanceIntervalsBox>
                    <MdOutlineAccessTimeFilled
                      style={{ marginRight: 5 }}
                      size={27}
                    />{" "}
                    <input
                      type="time"
                      name=""
                      id=""
                      style={{
                        width: 93,
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={dailyTime!}
                      onChange={(e) => {
                        console.log(e.target.value);
                        onDailyTime(e.target.value);
                      }}
                    />
                  </ExperienceAdvanceIntervalsBox>
                )}

                {/* Monthly */}

                {intervals === "Monthly" && (
                  <ExperienceAdvanceIntervalsBox>
                    <div className="xp-comp">
                      <span
                        style={{
                          fontSize: 12,
                          color: "#495057",
                          paddingRight: 10,
                        }}
                      >
                        Day
                      </span>
                      <input
                        type="text"
                        placeholder="-"
                        name=""
                        style={{ width: 47, height: 34 }}
                        id=""
                        value={dayofMonth!}
                        onChange={onDayOfMonthly}
                      />
                      <span
                        style={{
                          fontSize: 12,
                          color: "#495057",
                          paddingLeft: 10,
                        }}
                      >
                        (pick a day 1 - 31 with in the month)
                      </span>
                    </div>
                  </ExperienceAdvanceIntervalsBox>
                )}
               
               </>
              }
              </ShadowBox>
            )}
            {expType.title === "Mission" && (
              <div className="text-link" style={{ paddingRight: 50 }}>
                <span className="text-link-title">Revive</span>
                <CustomSwitch
                  style={{ marginLeft: 20 }}
                  isToggle={missionRevive}
                  onChangeToggle={onMissionRevive}
                />
              </div>
            )}
          </div>
        </ExperienceDetailsActionCol>
      </ExperienceInfoRow>
      {!isUpdated && (
        <ExperienceInfoRow>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="control-button">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginLeft: "10px",
                }}
                onClick={onPublish}
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                    alt="chatne"
                  />
                ) : (
                  "Publish"
                )}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                    alt="change"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </ExperienceInfoRow>
      )}
      {isUpdated && (
        <div
          style={{
            width: "100%",
            padding: "5px 100px",

            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="control-button">
            {publish && (
              <Button name="unPublish"
              variant="contained" onClick={onSaveUpdate} disabled={saveLoading}>
                {saveLoading ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                    alt="change"
                  />
                ) : (
                  "UnPublish"
                )}
              </Button>
            )}

            {!publish && (
              <Button
                name="publish"
                onClick={onPublishUpdate}
                disabled={saveLoading!}
                variant="contained"
              >
                {saveLoading ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    alt="this is test"
                  />
                ) : (
                  "Publish"
                )}
              </Button>
            )}

            <Button className="control-button-trash" onClick={onRemoveCard}>
              <BsTrash color="white" size={28} />
            </Button>
            <Button
              className="control-button-update"
              name="update"
              onClick={onUpdate}
              variant="contained"
              disabled={saveLoading}
            >
              {saveLoading ? (
                <img
                  src={images.saveLoadingSpinner}
                  style={{
                    width: "25px",
                    height: "25px",
                  }}
                  alt="change"
                />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExperienceDetailsInfoPresentation;
