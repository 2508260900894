import {
  createSelector,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../../app/api/apiSlice";
import { RootState } from "../../../app/store";
import { Organization } from "../types/types";
import { store } from "../../../app/store";
import { setMetaData } from "../organizationSlice/organizationSlice";
import { IResponseList } from "../../../types/types";
import { RaffelTicket } from "../../raffelTickets/types/types";

const organizationAdaptor = createEntityAdapter<Organization>({
  selectId: (organization) => organization._id,
});

interface OrganizationInitialState extends EntityState<Organization> {}

const initialState: OrganizationInitialState =
  organizationAdaptor.getInitialState();

export const extendedOrganizationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrganization: builder.query<EntityState<Organization>, void>({
      query: () => `/organization?sort=-createdAt`,
      keepUnusedDataFor: 20,
      transformResponse: (resposne: IResponseList<Organization[]>) => {
        const organizationData = resposne.data;
        const organizationMetaData = resposne.metadata;
        console.log("meta data", organizationMetaData);
        store.dispatch(setMetaData(organizationMetaData));

        return organizationAdaptor.setAll(initialState, organizationData);
      },
      providesTags: (
        result: EntityState<Organization> | undefined,
        error: FetchBaseQueryError | undefined,
        arg: void
      ) => {
        return [
          { type: "Organization", id: "LIST" },
          ...result!.ids.map((id) => ({ type: "Organization" as const, id })),
        ];
      },
    }),

    getAllOrganizationForDataTable: builder.query<
      EntityState<Organization>,
      {
        page?: string;
        limit?: string;
        term?: string;
        sort?: string;
      }
    >({
      query: (data) =>
        `/organization?limit=${data.limit}&page=${data.page}&sort=${
          data.sort
        }&term=${data.term ? data.term : "null"}`,

      transformResponse: (resposne: IResponseList<Organization[]>) => {
        const organizationData = resposne.data;
        const organizationMetaData = resposne.metadata;
        console.log("Organization meta", organizationMetaData);

        store.dispatch(setMetaData(organizationMetaData));
        return organizationAdaptor.setAll(initialState, organizationData);
      },
      providesTags: (
        result: EntityState<Organization> | undefined,
        error: FetchBaseQueryError | undefined,
        arg: {
          page?: string;
          limit?: string;
        }
      ) => {
        console.log("result", result);
        return [
          { type: "Organization", id: "LIST" },
          ...result!.ids.map((id) => ({ type: "Organization" as const, id })),
        ];
      },
    }),

    getAnOrganization: builder.query<EntityState<Organization>, EntityId>({
      query: (id: EntityId) => `/organization/${id}`,
      transformResponse: (response: Organization) => {
        const loadedData = response;
        console.log("LD", loadedData);
        return organizationAdaptor.setOne(initialState, loadedData);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "Organization" as const, id })),
      ],
    }),

    addNewOrganization: builder.mutation<
      EntityState<Organization>,
      Partial<Organization>
    >({
      query: (organization) => ({
        url: "/organization",
        method: "POST",
        body: organization,
      }),
      transformResponse: (response: Organization) => {
        return organizationAdaptor.addOne(initialState, response);
      },
      invalidatesTags: [{ type: "Organization", id: "LIST" }],
    }),

    updateOrganization: builder.mutation<
      EntityState<Organization>,
      Partial<Organization>
    >({
      query: (organization) => ({
        url: `organization/${organization._id}`,
        method: "PUT",
        body: organization,
      }),
      invalidatesTags: (result, error, args) => [
        { type: "Organization", id: args._id },
      ],
    }),
  }),
});

export const {
  useGetAllOrganizationQuery,
  useLazyGetAllOrganizationQuery,
  useAddNewOrganizationMutation,
  useGetAnOrganizationQuery,
  useUpdateOrganizationMutation,
  useGetAllOrganizationForDataTableQuery,
  useLazyGetAllOrganizationForDataTableQuery,
} = extendedOrganizationApi;

export const selectOrganizationResult =
  extendedOrganizationApi.endpoints.getAllOrganization.select();

export const selectedOrganizationData = createSelector(
  selectOrganizationResult,
  (organizationResult) => organizationResult.data
);

export const {
  selectAll: selectAllOrganizations,
  selectById: selectOrganizationById,
  selectIds: selectOrganizationIds,
} = organizationAdaptor.getSelectors(
  (state: RootState) => selectedOrganizationData(state) ?? initialState
);
