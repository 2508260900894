import { DialogContent, DialogActions, Button, Input } from "@mui/material";
import { title } from "process";
import React from "react";

import SelectField from "../../../../components/design/selectFiled/selectField";

import { DialogStyle } from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import SearchSchoolDistrictContainer from "../../../schoolDistrict/searchSchoolDistrict/search.schoolDestict.container";
import SearchUniversityContainer from "../../../unviersity/container/university.container";
import { StudentAddPresentationProps } from "../../types/types";
import SelectTeamContainer from "../../../../components/selectTeam/SelectTeamContainer";
import StudentSystemComponents from "../../studentSystemComponent/StudentSystemComponents";
import StudentSystemAdd from "../../studentSystemComponent/studentSystemAdd";
import SearchHighSchool from "../../../highschool/container/SearchHighSchool";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  CreateStudentContainer,
  CreateStudentFormCol,
  CreateStudentFormRow,
  SaveButton,
  StudentInput,
  SwitchLabelStudent,
} from "./student.add.presentation";
import { userInfo } from "os";
import {
  AddTagCloseIcon,
  AddTagColumn,
  AddTagPopup,
  AddTagRow,
  AddTagTitle,
  LabelStudent,
  StudentTagContainer,
} from "../student.details.info.presentation/student.details.info.style";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { StudentButton } from "../../../experience/presentation/expereince.list.presentation/experienceListPresentation.styles";
import { images } from "../../../../constants";

const StudentAddPresentation: React.FC<StudentAddPresentationProps> = ({
  open,
  onClose,
  email,
  firstName,
  lastName,
  onEmailHanlder,
  onFirstNameHandler,
  onLastNameHandler,
  onPasswordHandler,
  onPhoneHandler,
  password,
  phone,
  status,
  onTeamSelectHandler,
  system,
  openSystem,
  onCloseSystem,
  onOpenSystem,
  selectedHomeOrganization,
  selectedHomeProject,
  onAddSystem,
  onSelectedCategory,
  onSelectedSubCategory,
  selectedCategory,
  onRemove,
  onSchoolDistrictID,
  schoolDistrictID,
  onHighSchoolHandler,
  highSchool,
  studentSchoolDistrictId,
  studentSchoolEmail,
  onStudentSchoolDistrictId,
  onStudentSchoolEmail,
  university,
  onUniversityEmail,
  onStudetnUniversityID,
  onUniversityHandler,
  studentUniversityID,
  universityEmail,
  firstGen,
  onFirstGenHanlder,
  call,
  onCallHandler,
  sendEmail,
  onSendEmailHandler,
  text,
  onTextHandler,
  working,
  onWorkingHandler,
  age,
  onAgeHanlder,
  gender,
  onGenderHandler,
  workingDescription,
  onWorkginDescription,
  onEducationalPhaseHandler,
  onSaveStudent,
  isloading,
  progress,
  educationalPhases,
  team,
  student,
  onSelectedProject,
  selectedProject,
  external_link_one,
  external_link_two,
  onExternalLinkOneHandler,
  onExternalLinkTwoHandler,
  onCancelHandler,
}) => {
  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ width: "999px", padding: "10px" }}>
        <CreateStudentFormRow style={{ padding: 10, justifyContent: "center" }}>
          <CreateStudentFormCol alignment="flex-start" Direction={""}>
            <StudentInput
              Width="329px"
              Height="57px"
              FontFamily="Rajdhani"
              Size="45px"
              Weight="700"
              name="firstName"
              placeholder="FirstName"
              value={firstName}
              onChange={onFirstNameHandler}
              SpacingLR={""}
              SpacingTB={""}
              autoComplete="new-password"
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol
            alignment="flex-start"
            style={{ paddingLeft: 15 }}
            Direction={""}
          >
            <StudentInput
              Width="329px"
              Height="57px"
              FontFamily="Rajdhani"
              Size="45px"
              Weight="700"
              name="lastName"
              placeholder="LastName"
              autoComplete="new-password"
              value={lastName}
              onChange={onLastNameHandler}
              SpacingLR={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>

        <CreateStudentFormRow style={{ marginTop: 30, padding: 10 }}>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="passworrd">password</LabelStudent>
            <StudentInput
              id="password"
              type="password"
              name="pssword"
              value={password}
              autoComplete="new-password"
              onChange={onPasswordHandler}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="email">Email</LabelStudent>
            <StudentInput
              id="email"
              type="email"
              value={email}
              name={email}
              autoComplete="new-password"
              onChange={onEmailHanlder}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="phone">phone</LabelStudent>
            <StudentInput
              type="text"
              name="phone"
              id="phone"
              autoComplete="new-password"
              value={phone}
              onChange={onPhoneHandler}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow style={{ marginTop: 20, padding: 10 }}>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent>Team</LabelStudent>
            <SelectTeamContainer
              onTeamSelect={onTeamSelectHandler}
              team={team}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent>Educational Phases</LabelStudent>
            <SelectField
              defaultValue={educationalPhases!}
              onChange={onEducationalPhaseHandler}
            >
              | | "HS-10" | "HS-11" | "HS-12" | "Transition" | "OY" |
              "University-1" | "University-2" | "University-3" | "University-4"
              | "Recapture";
              <option value="HS-9">HS-9</option>
              <option value="HS-10">HS-10</option>
              <option value="HS-11">HS-11</option>
              <option value="Transition">Transition</option>
              <option value="University-1">University-1</option>
              <option value="University-2">University-2</option>
              <option value="University-3">University-3</option>
              <option value="University-4">University-4</option>
              <option value="Recapture">Recapture</option>
            </SelectField>
          </CreateStudentFormCol>
        </CreateStudentFormRow>

        <CreateStudentFormCol
          alignment={""}
          Direction={"row"}
          style={{ marginTop: 30, width: "93%", marginLeft: "3.5%" }}
        >
          <StudentButton
            style={{
              borderRadius: 50,
              marginTop: 5,
              marginRight: 10,
              marginLeft: 0,
              height: 50,
            }}
            onClick={onOpenSystem}
            spaceLeft={""}
          >
            <FaPlus style={{ marginRight: 12 }} size={27} />{" "}
            <span>Add Project</span>{" "}
          </StudentButton>
          <StudentSystemAdd
            open={openSystem}
            onClose={onCloseSystem}
            selectedOrganization={selectedHomeOrganization}
            selectedProject={selectedProject!}
            onSelectedCategory={onSelectedCategory!}
            onSelectedSubCategory={onSelectedSubCategory}
            onAddSystem={onAddSystem}
            selectedCategory={selectedCategory}
            system={system}
            onRemove={onRemove}
            student={student!}
            onSelectProject={onSelectedProject!}
          />

          <StudentSystemComponents
            system={system}
            onRemove={onRemove}
            student={student}
          />
        </CreateStudentFormCol>

        <CreateStudentFormRow
          style={{
            marginTop: 20,
            padding: 10,
            justifyContent: "start",
          }}
        >
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent>School District</LabelStudent>
            <SearchSchoolDistrictContainer
              onChangeState={onSchoolDistrictID}
              schoolDistrictID={schoolDistrictID}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent>HighSchools </LabelStudent>
            <SearchHighSchool
              onChangeState={onHighSchoolHandler}
              agancyName={schoolDistrictID?.agancy_name!}
              schoolDistrictID={highSchool}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="schoolDistrictid">
              School District ID
            </LabelStudent>
            <StudentInput
              type="text"
              id="schoolDistrictId"
              name="schoolDistrict.schoolDistrictId"
              autoComplete="new-password"
              value={studentSchoolDistrictId!}
              onChange={onStudentSchoolDistrictId}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="schoolDistrictEmail">
              School District email
            </LabelStudent>
            <StudentInput
              type="text"
              id="schoolDistrictEmail"
              autoComplete="new-password"
              name="schoolDistrict.schoolDistrictEmail"
              value={studentSchoolEmail!}
              onChange={onStudentSchoolEmail}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>

        <CreateStudentFormRow
          style={{
            marginTop: 30,
            paddingTop: 10,
          }}
        >
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="university">University</LabelStudent>
            <SearchUniversityContainer onChangeState={onUniversityHandler} />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="universityId">University ID</LabelStudent>
            <StudentInput
              type="text"
              id="universityId"
              name="universityId"
              autoComplete="new-password"
              value={studentUniversityID!}
              onChange={onStudetnUniversityID}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="universityEmail">
              University email
            </LabelStudent>
            <StudentInput
              type="Text"
              id="universityEmail"
              autoComplete="new-password"
              name="universityEmail"
              value={universityEmail!}
              onChange={onUniversityEmail}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="firstGen">First Gen?</LabelStudent>
            <SelectField onChange={onFirstGenHanlder}>
              <option value="Yes">Yes</option>
              <option value="Yes">No</option>
            </SelectField>
          </CreateStudentFormCol>
        </CreateStudentFormRow>

        <CreateStudentFormRow
          style={{
            marginTop: 30,
            padding: 10,
            justifyContent: "start",
          }}
        >
          <CreateStudentFormCol Direction="column" alignment={""}>
            <CreateStudentFormCol
              Direction="column"
              style={{ paddingBottom: 10 }}
              alignment={""}
            >
              <LabelStudent>Preferred Contact</LabelStudent>
            </CreateStudentFormCol>
            <CreateStudentFormCol
              Direction="row"
              alignment={""}
              style={{ marginTop: 0 }}
            >
              <CreateStudentFormCol Direction="row" alignment={""}>
                <SwitchLabelStudent>Text</SwitchLabelStudent>
                <input
                  type="checkbox"
                  id="text"
                  checked={text}
                  onChange={onTextHandler}
                />
              </CreateStudentFormCol>
              <CreateStudentFormCol Direction="row" alignment={""}>
                <SwitchLabelStudent>call</SwitchLabelStudent>
                <input
                  type="checkbox"
                  id="call"
                  checked={call}
                  onChange={onCallHandler}
                />
              </CreateStudentFormCol>
              <CreateStudentFormCol Direction="row" alignment={""}>
                <SwitchLabelStudent>Email</SwitchLabelStudent>
                <input
                  type="checkbox"
                  checked={sendEmail}
                  onChange={onSendEmailHandler}
                />
              </CreateStudentFormCol>
            </CreateStudentFormCol>
          </CreateStudentFormCol>
          <CreateStudentFormCol
            Direction="column"
            style={{ padding: 0 }}
            alignment={""}
          >
            <LabelStudent>Working?</LabelStudent>
            <SelectField onChange={onWorkingHandler}>
              <option value="Yes">Yes</option>
              <option value="Yes">No</option>
            </SelectField>
          </CreateStudentFormCol>

          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="age">Date of Birth</LabelStudent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{
                    padding: "4px 15px 5px 1px !important",
                    border: "1px solid #ced4da",
                    height: "33px",
                    fontSize: 14,
                    zIndex: 9999,
                    fontFamily: "poppins",
                    borderRadius: "5px",
                    color: "#495057",
                    appearance: "none",
                    marginRight: 10,
                    backgroundPosition: "bottom 13px right 6px",
                    fontWeight: 300,
                    width: 173,
                    textOverflow: "ellipsis",
                  }}
                  value={age}
                  onChange={(newValue) => {
                    onAgeHanlder(newValue);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </CreateStudentFormCol>
        </CreateStudentFormRow>

        <CreateStudentFormRow
          style={{
            marginTop: 30,
            padding: 10,
            justifyContent: "start",
          }}
        >
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent>Gender</LabelStudent>
            <SelectField onChange={onGenderHandler} style={{ marginTop: 0 }}>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </SelectField>
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="workingWhere">Working Where</LabelStudent>
            <StudentInput
              type="text"
              id="workingWhere"
              autoComplete="new-password"
              name="workingWhere"
              value={workingDescription!}
              onChange={onWorkginDescription}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="extraLinkOne">
              External Link One
            </LabelStudent>
            <StudentInput
              type="email"
              id="extraLinkOne"
              autoComplete="new-password"
              name="extraLinkOne"
              value={external_link_one}
              onChange={onExternalLinkOneHandler}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
          <CreateStudentFormCol Direction="column" alignment={""}>
            <LabelStudent htmlFor="extraLinkTwo">
              External Link Two
            </LabelStudent>
            <StudentInput
              type="email"
              id="extraLinkTwo"
              autoComplete="new-password"
              name="extraLinkTwo"
              value={external_link_two}
              onChange={onExternalLinkTwoHandler}
              SpacingLR={""}
              Width={""}
              Height={""}
              FontFamily={""}
              Size={""}
              Weight={""}
              SpacingTB={""}
            />
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{
            marginTop: 20,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <CreateStudentFormCol Direction="column" alignment={""}>
            <StudentButton
              style={{
                borderRadius: 50,
                marginTop: 5,
                marginRight: 10,
                marginLeft: 0,
              }}
              onClick={() => {}}
              spaceLeft={""}
            >
              <FaPlus style={{ marginRight: 12 }} size={27} />{" "}
              <span>Add Tag</span>{" "}
            </StudentButton>
            {false ? (
              <>
                <AddTagRow>
                  <AddTagPopup>
                    <AddTagColumn alignment={"center"}>
                      <AddTagTitle style={{ marginTop: 18 }}>
                        Add Tag
                      </AddTagTitle>
                      <AddTagCloseIcon onClick={() => {}}>
                        <AiOutlineCloseCircle color={"#707070"} />
                      </AddTagCloseIcon>
                    </AddTagColumn>
                    <AddTagColumn Direction="column" alignment={"center"}>
                      <LabelStudent htmlFor="specialProject">
                        Special Project
                      </LabelStudent>
                      <SelectField
                        name="specialProject"
                        value={""}
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onChange={() => {}}
                      >
                        <option value="Yes">Yes</option>
                        <option value="Yes">No</option>
                      </SelectField>
                    </AddTagColumn>
                    <AddTagColumn
                      alignment={"center"}
                      style={{
                        justifyContent: "space-around",
                        marginTop: "13%",
                      }}
                    >
                      <SaveButton>SAVEsss</SaveButton>
                      <SaveButton>Cancel</SaveButton>
                    </AddTagColumn>
                  </AddTagPopup>
                </AddTagRow>
              </>
            ) : null}
          </CreateStudentFormCol>
          <CreateStudentFormCol
            Direction="row"
            style={{ width: "70%" }}
            alignment={""}
          >
            <StudentTagContainer>
              {/* <StudentTag>
                CareerSprings
                <StudentCloseTag onClick={() => {}}>
                  <AiOutlineCloseCircle spaceLeft="18px" color={"#707070"} />
                </StudentCloseTag>
              </StudentTag> */}
            </StudentTagContainer>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
        <CreateStudentFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 50,
            padding: 0,
            justifyContent: "center",
          }}
        >
          <CreateStudentFormCol
            style={{ justifyContent: "flex-end", paddingRight: 30 }}
            alignment={""}
            Direction={""}
          >
            <SaveButton
              onClick={onSaveStudent}
              variant="contained"
              disabled={progress}
            >
               {progress ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    alt="this is test"
                  />
                ) : (
                  "Save"
                )}
            </SaveButton>
          </CreateStudentFormCol>
          <CreateStudentFormCol
            style={{ paddingLeft: 30 }}
            alignment={""}
            Direction={""}
          >
            <SaveButton
              variant="contained"
              disabled={progress}
              onClick={() => {
               // onCancelHandler();
                onClose();
              }}
            >
              Cancel
            </SaveButton>
          </CreateStudentFormCol>
        </CreateStudentFormRow>
      </DialogContent>
    </DialogStyle>
  );
};

export default StudentAddPresentation;
