import { EntityId } from "@reduxjs/toolkit";
import React from "react";
import { SelectFieldStyle } from "../../../../components/design/selectFiled/selectFied.styles";
import { SelectOrganizationListPresentationProps } from "../../types/types";

const SelectOrganizationListPresentation: React.FC<
  SelectOrganizationListPresentationProps
> = ({ organizationList, onSelectOrganization }) => {
  return (
    <SelectFieldStyle name="" id="" onChange={onSelectOrganization} isDisabled={false}>
      {organizationList.ids.map((id: EntityId) => {
        return (
          <option key={id} value={id}>
            {organizationList.entities[id]?.title}
          </option>
        );
      })}
    </SelectFieldStyle>
  );
};

export default SelectOrganizationListPresentation;
