import React, { useState } from "react";
import { useAddCategoryToProjectMutation } from "../../api/categoryApiSlice";
import CategoryAddPresentation from "../../presentation/category.add.presentation/category.add.presentation";
import { CategoryAddContainerProps } from "../../types/types";
const CategoryAddContainer: React.FC<CategoryAddContainerProps> = ({
  project,
}) => {
  const [addCategoryToProject, { isLoading, isError, isSuccess }] =
    useAddCategoryToProjectMutation();

  const [title, setTitle] = useState<string>("");

  const changeTitleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onSaveCategory = async () => {
    try {
      await addCategoryToProject({
        title,
        project,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CategoryAddPresentation
      title={title}
      onChangeTitle={changeTitleHandler}
      onSave={onSaveCategory}
    />
  );
};

export default CategoryAddContainer;
