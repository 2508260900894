import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const AddAdminWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddAdminHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminImageWrapper = styled.div`
  width: 15%;
`;

export const AdminTitleWrapper = styled.div`
  width: 85%;
`;

export const AdminTitleFirstNameAndLastNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminFirstNameWrapper = styled.div`
  width: 50%;
  padding: 0 15px;
`;

export const AdminLastNameWrapper = styled.div`
  width: 50%;
  padding: 0 15px;
`;
export const AdminLevelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AdminLevelSelectWrapper = styled.div`
  width: 30%;
  padding: 15px;
`;

export const AdminLevelLabel = styled.h2`
  font-size: 16px;
  padding-bottom: 5px;
`;

export const AdminOrganizationInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AdminOrganizationInfoOrganizationSelectWrapper = styled.div`
  width: 25%;
  padding: 15px;
  border-right: 1px solid #000;
`;

export const AdminOrganizationInfoDetailsWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
`;

export const AdminSchoolWrapper = styled.div`
  width: 30%;
  padding: 15px;
  border-right: 1px solid #000;
`;

export const AdminHighSchoolWrapper = styled.div`
  width: 30%;
  padding: 15px;
`;

export const AdminLinkWrapper = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 15px;
`;

export const AdminCategoryWrapper = styled.div`
  width: 40%;
`;

export const OrganizationInfoLabel = styled.h4`
  font-size: 16px;
`;

export const ProjectLabel = styled.h4`
  font-size: 16px;
`;

export const HighSchoolLabel = styled.h4`
  font-size: 16px;
`;

export const AboutAddAdminWraper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AboutSectionWrapper = styled.div`
  width: 25%;
  padding: 15px;
  border-right: 1px solid #000;
`;


export const AdminHeader = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 35px;
  position: relative;
`;

export const AdminHeaderTitle = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  position: absolute;
  top: -45px;
  left: 55px;
  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AdminContainer = styled.div`
  width: calc(100% - 50px);
  margin: 10px 25px;
  background: #FFFFFF;
  min-height: calc(100vh - 238px);
  margin-top:10;
`;

export const AdminHeaderControle = styled.div`
  width: 100%;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1770px) {
  
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;

export const SearchAdmin = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #CED4DA;
  padding-left: 35px;
  text-indent: 10px;
  font-family: 'Poppins';
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;

export const SearchAdminContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-top: 18px;
`;

export const AdminButton = styled.button<{ spaceLeft: string }>`
  background: #34C38F;
  color: #fff;
  border: none;
  padding: 6px 10px 5px 37px;
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: 'Poppins';
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 300;
  transition-duration:300ms;
  margin-top: 10px;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
export const AddAdminButton = styled(Button)`
      color:#fff;
      background-color:#0078FF;
      border:none;
      border-radius:50px;
      padding: 10px 46px;
      font-family:'Poppins';
      font-size:15px;
      font-weight:500;
      &:hover {
        background-color: #3996ff;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border:none;
      };
      &:active {
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        background-color: #3996ff;
        border:none;

      };
      &:focus {
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border:none;
      };

`;

export const UploadAdminButton = styled.button<{ spaceLeft: string }>`
  background: #34C38F;
  color:#fff;
  border: none;


  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 200px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  transition-duration:300ms;
  @media (max-width: 1770px) {
    font-size: 13px;
    width: 220px;
  }
  margin-top: 10px;
  white-space: nowrap;
  &:hover{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
    transition-duration:300ms;
  }
`;

export const AdminContentContainer = styled.div`
  padding-top: 10px;
  max-width: 1618px;
  padding-right: 7.3%;
  @media (max-width: 1770px) {
    
  }
`;
export const CreateAdminFormCol = styled.div<{ alignment: string, Direction: string }>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 7px;
`;

export const CreateAdminFormRow = styled.div`
  width: fit-content;
  display: flex;
  padding-top: 1px;
  @media (max-width: 1390px) {
    flex-wrap: wrap;
  }
`;
export const LabelAdmin = styled.label`
  font-family: 'Poppins';
  font-size: 15px;
  color:#707070;
`;

export const CreateAdminContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateAdminForm = styled.div`
  padding-bottom: 10px;

  padding-top: 20px;
  width: fit-content;
  height: fit-content;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 14px -1px rgba(0, 0, 0, 0.82);
  min-width: 890px;

`;

export const AdminInput = styled.input<{ Width: string, Height: string, Size: string, Weight: string, SpacingTB: string, SpacingLR: string }>`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;
  font-family: 'Rajdhani';
  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid #CED4DA;
  color: #070708;
  margin-left: 10px;
`;

export const SwitchLabelAdmin = styled.label`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 13px;
  color: #74788d;
  padding-right: 7px;
`;

export const AdminImage = styled.img`
  border-radius: 50%;
  width: 144px;
  height: 144px;
`;

export const AdminHexImage = styled.img`
  position: absolute;
  top: -12px;
  left: -4px;
`;

export const AdminEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  transition-duration:300ms;
  position: absolute;
  right: -50px;
  bottom: -12px;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const AdminProjectEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const AdminEditButttonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const AdminTitle = styled.h3`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  color: #003267;
  margin: 0;
  padding-right: 5px;
`;

export const AdminEditButttonIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const AdminDetailsContainer = styled.div`
  padding: 27px;
  padding-top: 35px;
`;

export const AdminPic = styled.div`
  width: 125px;
  height: 110px;
`;

export const AdminInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminListContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 835px;
  border: 1px solid #ced4da;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const AdminListProject = styled.div`
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 46%;
  white-space: nowrap;
  margin: 5px;
  position: relative;
  color: #74788d;
  font-size: 13px;
  flex-direction: column;
  border-radius: 5px;
  min-width: 110px;
  min-height: 25px;
  padding: 0 25px 0 7px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const AdminListCloseProject = styled.div`
  position: absolute;
  top: 1px;
  right: 7px;
  color: #313539;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const AdminLinkInput = styled.div`
  position: absolute;
  right: 0;
  top: 22px;
`;
export const AdminLink = styled.div`
  position: absolute;
  right: -7px;
  top: 22px;
`;
