import { EntityId, EntityState } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import {
  useGetAdminByIdQuery,
  useGetAdminsQuery,
  useLazyGetAdminsQuery,
} from "../../adminApiSlice/adminApiSlice";
import { Admin } from "../../types/types";
import AdminActionContainer from "../admin.action.container/AdminActionContainer";
import AdminAddContainer from "../admin.add.container/AdminAddContainer";
import AdminFilterContainer from "../admin.filter.container/admin.filter.container";
import AdminListContainer from "../admin.list.container/AdminListContainer";

const AdminMainContainer = () => {
  const admin = useAppSelector(selectAdmin);

  const [sort, setSort] = useState<string>("-createdAt");
  const [openAdminAddState, setOpenAdminAddState] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [adminList, setAdminList] = useState<EntityState<Admin> | null>(null);
  const [term, setTerm] = useState<string>("");

  const [adminListData, setAdminListData] = useState<EntityState<Admin> | null>(
    null
  );

  const {
    data: admins,
    isLoading,
    isError,
    isSuccess,
  } = useGetAdminsQuery({
    page: String(page),
    limit: String(limit),
    sort,
    term: term!,
  });

  const [
    adminDataList,
    {
      isLoading: adminListisLoading,
      isSuccess: adminListSuccess,
      isError: adminListError,
    },
  ] = useLazyGetAdminsQuery();

  useEffect(() => {
    (async () => {
      try {
        const orgList = await adminDataList({
          page: String(page),
          limit: String(limit),
          sort,
          term: term!,
        }).unwrap();

        setAdminList(orgList);
      } catch (error) {}
    })();
  }, [term, sort]);

  useEffect(() => {
    setAdminList(admins!);
  }, [admins]);

  const openHandler = () => {
    setOpenAdminAddState(true);
  };

  const closeHandler = () => {
    setOpenAdminAddState(false);
  };

  const onSort = (fieldName: string) => {
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      setSort(`-${fieldName}`);
    }
  };

  return (
    <>
      <AdminFilterContainer
        adminList={adminList!}
        setAdminList={setAdminList}
      />
      <AdminActionContainer
        openAdminAddState={openAdminAddState}
        openAdminAddHandler={openHandler}
        term={term}
        setTerm={setTerm}
      />
      <AdminAddContainer
        openAddAdminState={openAdminAddState}
        openAddAdminHandler={openHandler}
        closeHandler={closeHandler}
        authAdmin={admin!}
        status="Add"
      />
      {adminList && (
        <AdminListContainer
          adminList={adminList!}
          page={page}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          sort={sort}
          onSort={onSort}
        />
      )}
    </>
  );
};

export default AdminMainContainer;
