import React from "react";
import {
  AdminActionCol,
  AdminActionWrapper,
  AdminButton,
} from "./admin.action.presentation.styles";
import SearchInputBox from "../../../../components/design/searchInputBox/SearchInputBox";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AdminActionPresentationProps } from "../../types/types";
import { MdAddCircle } from "react-icons/md";
const AdminActionPresentation: React.FC<AdminActionPresentationProps> = ({
  openAdminAddHandler,
  openAdminAddState,
  term,
  setTerm,
}) => {
  return (
    <AdminActionWrapper>
      <AdminActionCol style={{ width: "24%" }}>
        <SearchInputBox
          name="organization"
          type="Search Organization"
          placeholder="Search Admin"
          value={term}
          onChange={(e) => setTerm!(e.target.value)}
        />
      </AdminActionCol>
      <AdminActionCol>
        <AdminButton
          style={{
            paddingRight: 4,
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 16,
          }}
          onClick={openAdminAddHandler}
          spaceLeft={""}
        >
          {" "}
          <span style={{ paddingRight: 18 }}>Add Admin</span>{" "}
          <MdAddCircle size={32} />
        </AdminButton>
      </AdminActionCol>
      <AdminActionCol></AdminActionCol>
    </AdminActionWrapper>
  );
};

export default AdminActionPresentation;
