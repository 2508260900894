import styled from "@emotion/styled";

export const StudentHeaderControle = styled.div`
  width: 95%;
  display: flex;
  height: 50px;
  margin-left: 50px;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1300px) {
    margin-left: 20px;
  }
`;

export const SearchStudentContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-left: 0px;
`;

export const SearchStudent = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ced4da;
  padding-left: 35px;
  text-indent: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  &:focus {
    outline: none;
  }
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
`;
export const UploadStudentButton = styled.button`
  background: #34c38f;
  color: white;
  border: none;
  transition-duration:300ms;
  margin-left: 15px;

  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: 259px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    width: 220px;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
