import React from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  selectAll,
  useLazyGetAllCategoryByProjectQuery,
  useRemoveCategoryMutation,
} from "../../api/categoryApiSlice";
import CategoryListPresentation from "../../presentation/categroy.list.presentation/category.list.presentation";
import { Category, CategoryListContainerProps } from "../../types/types";

import {
  changeSelectCategoryId,
  changeSelectCategoryStatus,
} from "../../categorySlice/categorySlice";
import { EntityId } from "@reduxjs/toolkit";
import { changeSubCategoryStatus } from "../../../subcategory/subCategorySlice/subCategorySlice";
import { useLazyGetAllSubCategoryByCategoryIdQuery } from "../../../subcategory/api/subCategoryApiSlice";
const CategoryListContainer: React.FC<CategoryListContainerProps> = ({
  projectId,
}) => {
  const [getAllSubCategoryByCategoryId, { isLoading, isError }] =
    useLazyGetAllSubCategoryByCategoryIdQuery();
  const [
    removeCategory,
    {
      isLoading: removeLoading,
      isError: removeError,
      isSuccess: removeSuccess,
    },
  ] = useRemoveCategoryMutation();
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: RootState) =>
    selectAll(state, projectId)(state)
  );

  const selectCategoryHandler = async (item: Partial<Category>) => {
    try {
      await getAllSubCategoryByCategoryId(item._id!);
      dispatch(changeSelectCategoryId(item._id));
      dispatch(changeSelectCategoryStatus(true));
      dispatch(changeSubCategoryStatus(false));
    } catch (error) {}
  };

  const selectCategoryRemoveHandler = async (item: Partial<Category>) => {
    console.log(item);
    await removeCategory(item._id as EntityId);
  };

  return (
    <CategoryListPresentation
      categories={categories!}
      selectCategoryHandler={selectCategoryHandler}
      selectCategoryRemoveHandler={selectCategoryRemoveHandler}
    />
  );
};

export default CategoryListContainer;
