import React from "react";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import ShopFilterPresentation from "../presentation/shopfilterPresentation/ShopFilterPresentation";
import { Project } from "../../project/types/types";
type ShopFilterContainerProps = {
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  onCategory: (category: Partial<Category> | null) => void;
  onSubCategory: (subCategory: Partial<SubCategory> | null) => void;
  project: Partial<Project>;
  defaultValue?:string;
};
const ShopFilterContainer: React.FC<ShopFilterContainerProps> = ({
  category,
  subCategory,
  onCategory,
  onSubCategory,
  project,
  defaultValue
}) => {
  return (
    <ShopFilterPresentation
      category={category}
      subCategory={subCategory}
      onCategory={onCategory}
      onSubCategory={onSubCategory}
      project={project}
     
    />
  );
};

export default ShopFilterContainer;
