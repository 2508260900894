import { EntityId, EntityState } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { ExperienceCard } from "../../types/types";
import { useGetStudentExperiencePhotoQuery } from "../../../students/api/studentApi";
import ExperienceReviewPresentation from "../../presentation/experience.review.presentation/ExperienceReviewPresentation";
import { useAppSelector } from "../../../../app/hooks";
import {
  accountSelected,
  analyticsStudentExperience,
} from "../../../students/slice/studentSlice";
import { useUpdateExperienceStudentMutation } from "../../../experiencesForStudent/api/experienceForStudentApi";
import { Student } from "../../../students/types/types";
import {
  selectedOrganization,
  selectedProject,
} from "../../../home/appSlice/appslice";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
type ExperienceReviewContainerProps = {
  experience: ExperienceCard;
};
const ExperienceReviewContainer: React.FC<ExperienceReviewContainerProps> = ({
  experience,
}) => {
  const analytics = useAppSelector(analyticsStudentExperience);
  const organization = useAppSelector(selectedOrganization);
  const project = useAppSelector(selectedProject);
  const [reload, setReload] = useState<boolean>(false);
  const [switchLoading, setSwitchLoading] = useState<boolean>(false);
  const [term, setTerm] = useState<string>("");
  const [students, setStudents] = useState<EntityState<Student> | null>(null);
  const [sort, setSort] = useState<string>("fullName");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  console.log("*** experience", experience);
  const {
    data: studentCompletionPhoto,
    isLoading,
    isError,
    isSuccess,
  } = useGetStudentExperiencePhotoQuery(
    {
      experience: experience,
      term: term === "" ? "null" : term,
      sort: sort,
      page: page,
      limit: limit,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    updateExperience,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateExperienceStudentMutation();
  useEffect(() => {
    console.log("useeffect runn");
    setStudents(studentCompletionPhoto!);
    if (term !== "") {
      setPage(1);
    } else {
      setPage(page);
    }
    //first clue
  }, [studentCompletionPhoto]);

  const navigation = useNavigate();

  const onTerm = (t: string) => {
    console.log(t);
    setTerm(t);
  };

  const onEditHanlder = (id: EntityId) => {
    navigation(`/experience/expdetails/${id}`);
  };
  const onPage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onLimit = (limitNumber: number) => {
    setLimit(limitNumber);
  };
  const onCompleteChange = async (student: Student, completion: boolean) => {
    setSwitchLoading(true);
    try {
      await updateExperience({
        experience: experience,
        student: student,
        complete: completion,
        currentSystem: {
          category: null,
          organization: organization!._id as any,
          project: project!._id as any,

          categories: [],
          xps: 0,
          gems: 0,
          subcategory: null,
        },
      }).unwrap();
      setReload(!reload);
      setSwitchLoading(false);
    } catch (error) {}
  };
  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <>
        {isSuccess && students && (
          <>
            <ExperienceReviewPresentation
              experience={experience}
              studentCompletionPhotoList={students!}
              analytics={analytics!}
              onCompleteChange={onCompleteChange}
              switchLoading={switchLoading}
              onEdit={onEditHanlder}
              term={term}
              onTerm={onTerm!}
              sort={sort}
              onSort={onSort}
              page={page}
              onpage={onPage}
              limit={limit}
              onLimit={onLimit}
            />
          </>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ExperienceReviewContainer;
