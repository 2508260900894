import React, { useEffect, useState } from "react";
import SettingPresentation from "../settingPresentation/SettingPresentation";
import {
  useCreateLearnerSettingMutation,
  useGetLearnerSettingQuery,
} from "../api/settingApi";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";

const SettingContainer = () => {
  const project = useAppSelector(selectedProject);
  const {
    data: learnerSetting,
    isLoading,
    isSuccess,
    isError,
  } = useGetLearnerSettingQuery(project!);
  const [
    createLearnerSetting,
    {
      isLoading: createLoading,
      isError: createError,
      isSuccess: createSuccess,
    },
  ] = useCreateLearnerSettingMutation();

  const [learnerTiming, setLearnerTiming] = useState<string | null>(null);
  const [learnerStartDate, setLearnerStartDate] = useState<Date | null>(null);
  const [learnerEndDate, setLearnerEndDate] = useState<Date | null>(null);
  const [progressDaysAgo, setProgressDaysAgo] = useState<number>(15);
  const [progressScore, setProgressScore] = useState<string>("Progress Score");
  const [momentumLabel, setMomenLabel] = useState<string>("Momentum Score");
  const [momentumDaysAgo, setMomentumDaysAgo] = useState<number>(15);
  const [academicType, setAcademicType] = useState<string | null>(
    "Default Gradegy"
  );
  const [chartViewType, setChartViewType] = useState<string | null>("Standard");
  const [termStartDate, setTermStartDate] = useState<Date | null>(null);
  const [termEndDate, setTermEndDate] = useState<Date | null>(null);
  const [selectedterms, setSelectedterms] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess && learnerSetting.entities[learnerSetting.ids[0]]!.typeTime) {
      setLearnerTiming(
        learnerSetting.entities[learnerSetting.ids[0]]!.typeTime
      );
      setProgressScore(
        learnerSetting.entities[learnerSetting.ids[0]]!.progressLabel
      );
      setLearnerStartDate(
        new Date(learnerSetting.entities[learnerSetting.ids[0]]!.startDate)
      );
      setLearnerEndDate(
        new Date(learnerSetting.entities[learnerSetting.ids[0]]!.endDate)
      );

      setProgressDaysAgo(learnerSetting.entities[learnerSetting.ids[0]]!.days);

      setMomenLabel(
        learnerSetting.entities[learnerSetting.ids[0]]!.momentumProgress
      );

      setMomentumDaysAgo(
        learnerSetting.entities[learnerSetting.ids[0]]!.momentumDays
      );

      setTermStartDate(
        learnerSetting.entities[learnerSetting.ids[0]]!.termStartDate!
      );
      console.log(learnerSetting.entities[learnerSetting.ids[0]]!.termEndDate);
      setTermEndDate(
        learnerSetting.entities[learnerSetting.ids[0]]!.termEndDate!
      );

      setSelectedterms(
        learnerSetting.entities[learnerSetting.ids[0]]!.selectedTerms!
      );
    }
  }, [isSuccess]);

  const onSelectedTerms = (terms: string[]) => {
    setSelectedterms(terms);
  };

  const onChartViewType = (t: string) => {
    setChartViewType(t);
  };

  const onAcademicScoreType = (t: string) => {
    setAcademicType(t);
  };

  const onMomentumDaysAgo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMomentumDaysAgo(Number(e.target.value));
  };

  const onMomentumScoreLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMomenLabel(e.target.value);
  };

  const onProgressScoreHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgressScore(e.target.value);
  };

  const onChangeProgressDaysAgo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgressDaysAgo(Number(e.target.value));
  };

  const changeLearnerTimingHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log(e.target.value);
    setLearnerTiming(e.target.value);
  };

  const changeStartLearnerDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLearnerStartDate(new Date(e.target.value));
  };

  const changeEndLearnerDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLearnerEndDate(new Date(e.target.value));
  };

  const changeTermStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermStartDate(new Date(e.target.value));
  };

  const changeTermEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermEndDate(new Date(e.target.value));
  };

  const onSave = async () => {
    try {
      await createLearnerSetting({
        typeTime: learnerTiming!,
        startDate: learnerStartDate!,
        endDate: learnerEndDate!,
        days: progressDaysAgo!,
        momentumDays: momentumDaysAgo!,
        momentumProgress: momentumLabel!,
        progressLabel: progressScore!,
        project: project!._id!,
        typeOfAcademic: academicType!,
        typeOfChart: chartViewType!,
        termStartDate: termStartDate!,
        termEndDate: termEndDate!,
        selectedTerms: selectedterms,
      }).unwrap();
    } catch (error) {}
  };
  return (
    <SettingPresentation
      firstTab="Driver"
      secondTab="Charts:Learner"
      learnerTiming={learnerTiming!}
      changeLearnerTimingHandler={changeLearnerTimingHandler}
      changeStartLearnerDate={changeStartLearnerDate}
      learnerStartDate={learnerStartDate!}
      changeEndLearnerDate={changeEndLearnerDate}
      learnerEndDate={learnerEndDate!}
      changeLearnerProgressDaysAgo={onChangeProgressDaysAgo}
      progressDaysAgo={progressDaysAgo}
      onProgressScore={onProgressScoreHandler}
      progressScore={progressScore}
      momentumLabel={momentumLabel}
      onMomentumLabel={onMomentumScoreLabel}
      momentumDaysAgo={momentumDaysAgo}
      onMomentumDaysAgo={onMomentumDaysAgo}
      onSave={onSave}
      academicScoreType={academicType}
      onAcademicScoreType={onAcademicScoreType}
      chartViewType={chartViewType}
      onChartViewType={onChartViewType}
      termEndDate={termEndDate!}
      termStartDate={termStartDate!}
      onTermStartDate={changeTermStartDate}
      onTermEndDate={changeTermEndDate}
      onSelectedTerms={onSelectedTerms}
      selectedTerm={selectedterms}
    />
  );
};

export default SettingContainer;
