import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { LearnerSetting } from "../types/types";
import { Project } from "../../project/types/types";

const learnerSettingAdaptor = createEntityAdapter<LearnerSetting>({
  selectId: (learnerSetting) => learnerSetting._id,
});

interface LearnerSettingInititalState extends EntityState<LearnerSetting> {}

const intialState: LearnerSettingInititalState =
  learnerSettingAdaptor.getInitialState();

export const learnerSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLearnerSetting: builder.query<
      EntityState<LearnerSetting>,
      Partial<Project>
    >({
      query: (project) => `/learner-setting/${project._id}`,
      transformResponse: (response: LearnerSetting) => {
        return learnerSettingAdaptor.setOne(intialState, response);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "LearnerSetting" as const, id })),
      ],
    }),

    createLearnerSetting: builder.mutation<
      EntityState<LearnerSetting>,
      Partial<LearnerSetting>
    >({
      query: (learnerSetting) => ({
        url: `/learner-setting`,
        method: "POST",
        body: learnerSetting,
      }),
      transformResponse: (response: LearnerSetting) => {
        return learnerSettingAdaptor.addOne(intialState, response);
      },
      invalidatesTags: ["LearnerSetting"],
    }),
  }),
});

export const {
  useGetLearnerSettingQuery,
  useLazyGetLearnerSettingQuery,
  useCreateLearnerSettingMutation,
} = learnerSettingApiSlice;
