import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { HomeRecent } from "../types/types";
import { Organization } from "../../organization/types/types";

const homeRecentAdaptor = createEntityAdapter<HomeRecent>({
  selectId: (recent) => recent._id,
});

interface HomeRecentInitialState extends EntityState<HomeRecent> {}

const initialState: HomeRecentInitialState =
  homeRecentAdaptor.getInitialState();

export const extendedHomeRecentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRecent: builder.query<EntityState<HomeRecent>, void>({
      query: () => `/recent-home`,
      transformResponse: (response: HomeRecent[]) => {
        return homeRecentAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Recent",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Recent" as const, id })),
      ],
    }),
    addRecent: builder.mutation<EntityState<HomeRecent>, Partial<HomeRecent>>({
      query: (recent) => ({
        url: "/recent-home",
        method: "PUT",
        body: recent,
      }),
      transformResponse: (response: HomeRecent) => {
        return homeRecentAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "Recent",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "Recent" as const,
          id: arg._id,
        })),
      ],
    }),
  }),
});

export const { useGetAllRecentQuery, useAddRecentMutation } =
  extendedHomeRecentApi;
