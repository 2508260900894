import {
  EntityId,
  EntityState,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { Student } from "../types/types";
import { IResponseList } from "../../../types/types";
import { RootState, store } from "../../../app/store";
import { setMetaData } from "../../admin/adminSlice/adminSlice";
import { Project } from "../../project/types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { ExperienceCard } from "../../experience/types/types";
import { setAnalytics } from "../slice/studentSlice";

const studentAdaptor = createEntityAdapter<Student>({
  selectId: (student) => student._id,
});

interface StudentInitialState extends EntityState<Student> {}

const initialState: StudentInitialState = studentAdaptor.getInitialState();

export const extendedStudentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudents: builder.query<
      EntityState<Student>,
      {
        projectId: EntityId;
        term?: string;
        category?: EntityId;
        subCategory?: EntityId;
        page?: string;
        limit?: string;
        sort?: string;
        status?: string;
      }
    >({
      query: (data) =>
        `/student/getStudent/${data.projectId}?term=${data.term}&page=${data.page}&limit=${data.limit}&sort=${data.sort}&category=${data.category}&subCategory=${data.subCategory}&status=${data.status}`,
      transformResponse: (response: IResponseList<Student[]>) => {
        const studentData = response.data;

        const studentMetaData = response.metadata;
        console.log(
          "---------------------------------- student metada",
          studentMetaData
        );
        store.dispatch(setMetaData(studentMetaData));
        return studentAdaptor.setAll(initialState, studentData);
      },

      providesTags: (result, error, arg) => [
        { type: "Student", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "Student" as const, id })),
      ],

      // async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data: newData } = await queryFulfilled;

      //     const patchResult = dispatch(
      //       extendedStudentApiSlice.util.updateQueryData(
      //         "getAllStudents",
      //         { projectId },
      //         (draft) => {
      //           Object.assign(draft, newData);
      //         }
      //       )
      //     );
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
    }),

    getAllStudentByCategory: builder.query<
      EntityState<Student>,
      { projectId: EntityId; category: EntityId; term?: string }
    >({
      query: (system) =>
        `/student/getStudent/${system.projectId}?category=${system.category}&term=${system.term}`,
      transformResponse: (response: IResponseList<Student[]>) => {
        const studentData = response.data;
        const studentMetaData = response.metadata;
        store.dispatch(setMetaData(studentMetaData));
        return studentAdaptor.setAll(initialState, studentData);
      },
      async onQueryStarted(
        { projectId, category },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: newData } = await queryFulfilled;

          const patchResult = dispatch(
            extendedStudentApiSlice.util.updateQueryData(
              "getAllStudents",
              {
                projectId: projectId!,
                term: "null",
              },
              (draft) => {
                Object.assign(draft, newData);
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),

    getAllStudentSubCategory: builder.query<
      EntityState<Student>,
      {
        projectId: EntityId;
        category: EntityId;
        subCategory: EntityId;
        term?: string;
      }
    >({
      query: (system) =>
        `/student/getStudent/${system.projectId}?category=${
          system.category
        }&subCategory=${system.subCategory}&term=${
          system.term ? system.term : "null"
        }`,
      transformResponse: (response: IResponseList<Student[]>) => {
        const studentData = response.data;

        const studentMetaData = response.metadata;
        store.dispatch(setMetaData(studentMetaData));
        return studentAdaptor.setAll(initialState, studentData);
      },
      async onQueryStarted(
        { projectId, category },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: newData } = await queryFulfilled;

          const patchResult = dispatch(
            extendedStudentApiSlice.util.updateQueryData(
              "getAllStudents",
              {
                projectId: projectId,
                term: "null",
              },
              (draft) => {
                Object.assign(draft, newData);
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    createStudent: builder.mutation<EntityState<Student>, Partial<Student>>({
      query: (student) => ({
        url: `/student`,
        method: "POST",
        body: student,
      }),
      invalidatesTags: [{ type: "Student", id: "LIST" }],
    }),
    getStudentExperiencePhoto: builder.query<
      EntityState<Student>,
      {
        experience: Partial<ExperienceCard>;
        term?: string;
        sort?: string;
        page?: number;
        limit?: number;
      }
    >({
      query: (exp) =>
        `/student/experience?project=${exp.experience.project}&category=${exp.experience.category?._id}&subCategory=${exp.experience.subCategory?._id}&experienceId=${exp.experience._id}&term=${exp.term}&sort=${exp.sort}&page=${exp.page}&limit=${exp.limit}`,
      transformResponse: (response: IResponseList<Student[]>) => {
        console.log("res", response);
        const studentData = response.data;
        const studentMetaData = response.metadata;
        const studentAnalytics = response.analytic;
        store.dispatch(setMetaData(studentMetaData));
        store.dispatch(setAnalytics(studentAnalytics));
        return studentAdaptor.setAll(initialState, response.data);
      },
      providesTags: (result, error, arg) => [
        {
          type: "StudentCompletionPhoto",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "StudentCompletionPhoto" as const,
          id,
        })),
      ],
    }),
    getAStudentById: builder.query<EntityState<Student>, EntityId>({
      query: (id) => `/student/${id}`,
      transformResponse: (response: Student) => {
        return studentAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Student",
          id: arg,
        },
      ],
    }),

    updateStudentbyId: builder.mutation<EntityState<Student>, Partial<Student>>(
      {
        query: (student) => ({
          url: "/student",
          method: "PUT",
          body: student,
        }),
        transformResponse: (response: Student) => {
          return studentAdaptor.updateOne(initialState, {
            id: response._id,
            changes: response,
          });
        },
        invalidatesTags: (result, error, arg) => [
          {
            type: "Student",
            id: arg._id,
          },
        ],
      }
    ),
  }),
});

export const {
  useCreateStudentMutation,
  useGetAllStudentsQuery,
  useLazyGetAllStudentsQuery,
  useLazyGetAllStudentByCategoryQuery,
  useLazyGetAllStudentSubCategoryQuery,
  useGetStudentExperiencePhotoQuery,
  useGetAStudentByIdQuery,
  useUpdateStudentbyIdMutation,
} = extendedStudentApiSlice;

export const selectStudentResult = (
  state: RootState,
  projectId: EntityId,
  category?: EntityId,
  subCategory?: EntityId
) =>
  extendedStudentApiSlice.endpoints.getAllStudents.select({
    projectId: projectId!,
    term: "null",
  })(state);

export const selectStudentData = createSelector(
  selectStudentResult,
  (studentResult) =>
    studentAdaptor.getSelectors(() => studentResult?.data ?? initialState)
);

export const selectAllStudent = createSelector(
  selectStudentData,
  (result) => result.selectAll
);

export const selectStudentsByIds = createSelector(
  selectStudentData,
  (result) => result.selectIds
);

export const selectStudentById = createSelector(
  selectStudentData,
  (result) => result.selectById
);

export const selectStudentEntites = createSelector(
  selectStudentData,
  (result) => result.selectEntities
);
