import styled from "@emotion/styled";

export const AdminFilterWarrper = styled.div`
  width: 90%;
  padding: 15px;
  padding-top: 20px;
  margin-left:30px;
`;

export const AdminFilterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AdminFilterCol = styled.div`
  width: 15%;
  padding: 0 15px;
`;

export const FilterTitle = styled.p`
  font-size: 14px;
  padding-bottom: 8px;
`;
