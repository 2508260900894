import React, { useEffect, useState } from "react";
import AdminAddPresentation from "../../presentation/admin.add.presentation/AdminAddPresentation";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Admin,
  AdminAddContainerProps,
  AdminSchema,
  CategoryPermissionType,
  ProjectPermissionType,
} from "../../types/types";
import { Organization } from "../../../organization/types/types";
import { Project } from "../../../project/types/types";
import { Category } from "../../../category/types/types";
import {
  useCreateAdminMutation,
  useUpdateAdminMutation,
} from "../../adminApiSlice/adminApiSlice";
import Toast from "../../../../components/toast/Toast";
import { z } from "zod";
import { AuthAdmin } from "../../../auth/types/auth.types";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";

const AdminAddContainer: React.FC<AdminAddContainerProps> = ({
  openAddAdminState,
  openAddAdminHandler,
  closeHandler,
  authAdmin,
  status,
}) => {
  const [createAdmin, { isLoading, isError, isSuccess }] =
    useCreateAdminMutation();

  const [
    updateAdmin,
    {
      isLoading: updateLoading,
      isError: upateisError,
      isSuccess: updateIssuccess,
    },
  ] = useUpdateAdminMutation();

  const [image, setImage] = useState<string>(
    "https://api.gradify.app/static/download.ff.png"
  );
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [fullName, setFullName] = useState<string>(
    `${firstName.toLowerCase()}  ${lastName.toLowerCase()}`
  );

  const [adminLevel, setAdminLevel] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [organzation, setOrganization] = useState<Partial<Organization> | null>(
    null
  );

  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [project, setProjct] = useState<Partial<Project> | null>(null);
  const [category, setCategory] = useState<Partial<Category> | null>(null);

  const [categoryLink, setCategoryLink] = useState<boolean>(true);

  const [projectPermissionType, setProjectPermissionType] = useState<
    ProjectPermissionType[]
  >([]);
  const [categoryPermissionType, setCategoryPermissionType] = useState<
    CategoryPermissionType[]
  >([]);

  useEffect(() => {
    if (status === "Edit") {
      setImage(authAdmin.image);
      setFirstName(authAdmin.firstName);
      setLastName(authAdmin.lastName);
      setFullName(authAdmin.fullName);
      setAdminLevel(authAdmin.adminLevel);
      setPhone(authAdmin.phone);
      setEmail(authAdmin.email);
      setPassword(authAdmin.password);
      setJobTitle(authAdmin.jobTitle);
      setOrganization((authAdmin as Admin).organization!);

      setProjectPermissionType(authAdmin.projectAccess!);
      setCategoryPermissionType(authAdmin.categoryAccess!);
    }
  }, []);

  const {
    control,
    handleSubmit: onSubmit,
    formState,
    register,
    reset,
  } = useForm<z.infer<typeof AdminSchema>>({
    resolver: zodResolver(AdminSchema),
    defaultValues: {
      firstName: status === "Edit" ? authAdmin.firstName : "",
      lastName: status === "Edit" ? authAdmin.lastName : "",
      adminLevel: status === "Edit" ? authAdmin.adminLevel : "ULTRA",
      phone: status === "Edit" ? authAdmin.phone : "",
      email: status === "Edit" ? authAdmin.email : "",
      password: status === "Edit" ? authAdmin.password : "",
      jobTitle: status === "Edit" ? authAdmin.jobTitle : "",
      organization:
        status === "Edit"
          ? ((authAdmin as Admin).organization?._id as string)
          : "All",

      projectAccess:
        status === "Edit"
          ? (authAdmin.projectAccess![0]?.project as string)
          : "All",

      categoryAccess:
        status === "Edit"
          ? (authAdmin.categoryAccess![0]?.category as string)
          : "",
    },
  });

  const onCategoryLinkHandler = () => {
    setCategoryLink(!categoryLink);
  };

  const onProjectHanlder = (project: Partial<Project> | null) => {
    setProjct(project);
    const projectPermissionTypeItem: ProjectPermissionType = {
      project: project!,
      accessType: categoryLink ? "Partial" : "Full",
      experience: false,
      users: false,
      store: false,
      survey: false,
    };

    setProjectPermissionType([
      ...projectPermissionType,
      projectPermissionTypeItem,
    ]);
  };

  const onCategoryHandler = (category: Partial<Category> | null) => {
    setCategory(category);
    const categoryPermssionTypeItem: CategoryPermissionType = {
      category: category!,
      accessType: categoryLink ? "Full" : "Partial",
      experience: false,
      users: false,
      store: false,
      survey: false,
    };
    setCategoryPermissionType([
      ...categoryPermissionType,
      categoryPermssionTypeItem,
    ]);
  };

  const onImageHandler = (url: string) => {
    setImage(url);
  };

  const onFirstNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const onLastNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const onAdminLevelHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAdminLevel(e.target.value);
  };
  const onPhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };
  const onEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onJobTitleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobTitle(e.target.value);
  };

  const setOrganizationHandler = (
    organization: Partial<Organization> | null
  ) => {
    setOrganization(organization);
  };
  const handleSubmit = async (params: z.infer<typeof AdminSchema>) => {
    setSaveLoading(true);
    try {
      if (status === "Add") {
        try {
          if (params.adminLevel === "SUPER" && params.organization === "All") {
            alert("Please select organization for super admin");
          } else {
            await createAdmin({
              image,
              firstName: params.firstName,
              lastName: params.lastName,
              adminLevel: params.adminLevel,
              email: params.email,
              password: params.password,
              phone: params.phone,
              jobTitle: params.jobTitle,
              organization: organzation,
              projectAccess: projectPermissionType,
              categoryAccess: categoryPermissionType,
            }).unwrap();
          }
        } catch (error) {
          console.log(error);
        }
        setImage("");
        reset();
        setSaveLoading(false);
      } else if (status === "Edit") {
        await updateAdmin({
          _id: authAdmin._id,
          image,
          firstName: params.firstName,
          lastName: params.lastName,
          fullName: params.firstName + " " + params.lastName,
          adminLevel: params.adminLevel,
          email: params.email.toLowerCase().trim(),
          password: params.password,
          phone: params.phone,
          jobTitle: params.jobTitle,
          organization: organzation,
          projectAccess: projectPermissionType,
          categoryAccess: categoryPermissionType,
        }).unwrap();
        reset();
        setImage("");
        setSaveLoading(false);
      }

      closeHandler();
      setSaveLoading(false);
    } catch (error: unknown) {
      console.log("eeeeeeeeroooooooor", error);
    }
  };
  const onSaveHandler = async () => {
    setSaveLoading(true);
    if (status === "Add") {
      try {
        if (!organzation || !password || !phone || !jobTitle) {
          throw new Error();
        } else {
          await createAdmin({
            image,
            firstName,
            lastName,
            adminLevel,
            email: email.toLowerCase().trim(),
            password,
            phone,
            jobTitle,
            organization: organzation,
            projectAccess: projectPermissionType,
            categoryAccess: categoryPermissionType,
          }).unwrap();
          setImage("");
          setFirstName("");
          setLastName("");
          setFullName("");
          setAdminLevel("UlTRA");
          setPhone("");
          setEmail("");
          setPassword("");
          setJobTitle("");
          closeHandler();
        }
      } catch (error: any) {
        setShowToastFail(true);
        setToastMessage(
          "Email is already exist or somethings not fill correct. please check the all fields and try again"
        );
        setTimeout(() => {
          setShowToastFail(false);
        }, 5000);
        setSaveLoading(false);
      }
    } else if (status === "Edit") {
      try {
        if (!password || !phone || !jobTitle) {
          throw new Error();
        } else {
          await updateAdmin({
            _id: authAdmin._id,
            image,
            firstName,
            lastName,
            fullName: firstName + " " + lastName,
            adminLevel,
            email: email.toLowerCase().trim(),
            password,
            phone,
            jobTitle,
            organization: organzation,
            projectAccess: projectPermissionType,
            categoryAccess: categoryPermissionType,
          }).unwrap();
          setImage("");
          setFirstName("");
          setLastName("");
          setFullName("");
          setAdminLevel("UlTRA");
          setPhone("");
          setEmail("");
          setPassword("");
          setJobTitle("");
          closeHandler();
          setSaveLoading(false);
        }
      } catch (error: any) {
        setShowToastFail(true);
        setToastMessage(
          "Email is already exist or somethings not fill correct. please check the all fields and try again"
        );
        setTimeout(() => {
          setShowToastFail(false);
        }, 5000);
      }
      setSaveLoading(false);
    }
  };

  const onCancel = () => {
    reset();
    setImage("");
    closeHandler();
  };
  return (
    <>
      <AdminAddPresentation
        openAddAdminState={openAddAdminState}
        openAddAdminHandler={openAddAdminHandler}
        closeHandler={closeHandler}
        authAdmin={authAdmin}
        image={image}
        adminLevel={adminLevel}
        email={email}
        firstName={firstName}
        fullName={fullName}
        jobTitle={jobTitle}
        lastName={lastName}
        onAdminLevelHandler={onAdminLevelHandler}
        onEmailHandler={onEmailHandler}
        onFirstNameHandler={onFirstNameHandler}
        onImageHandler={onImageHandler}
        onJobTitleHandler={onJobTitleHandler}
        onLastNameHandler={onLastNameHandler}
        onPasswordHandler={onPasswordHandler}
        onPhoneHandler={onPhoneHandler}
        password={password}
        phone={phone}
        setOrganizationHandler={setOrganizationHandler}
        organization={organzation}
        status={status}
        project={project}
        category={category}
        onProjectHanlder={onProjectHanlder}
        onCategoryHandler={onCategoryHandler}
        categoryLink={categoryLink}
        onCategoryLinkHandler={onCategoryLinkHandler}
        onSaveHandler={onSaveHandler}
        onCancel={onCancel}
        control={control}
        formState={formState}
        reset={reset}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        register={register}
        saveLoading={saveLoading}
        projectAccess={projectPermissionType}
      />
      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Experience Save Successfuly" typeToast="Success" />
        </div>
      )}
    </>
  );
};

export default AdminAddContainer;
