import React from "react";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import {
  ExperienceFilterWrapper,
  LeftExperienceFilter,
  RightExperienceFilter,
} from "../../../experience/presentation/experience.filter.presentation/experience.filter.sytles";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { Project } from "../../../project/types/types";
import { EntityId } from "@reduxjs/toolkit";
import {
  CreateStudentFormRow,
  CreateStudentFormCol,
  LabelStudent,
  CreateStudentAbsoluteRow,
} from "../../../students/presantation/student.details.info.presentation/student.details.info.style";
type ShopFilterPresentationProps = {
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  onCategory: (category: Partial<Category> | null) => void;
  onSubCategory: (subCategory: Partial<SubCategory> | null) => void;
  project: Partial<Project>;
  defaultValue?: string;
};
const ShopFilterPresentation: React.FC<ShopFilterPresentationProps> = ({
  category,
  subCategory,
  onCategory,
  onSubCategory,
  project,
  defaultValue,
}) => {
  return (
    <CreateStudentFormRow style={{ marginTop: 0 }}>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Category</LabelStudent>
        <FilterCategoryContainer
          projectID={project!._id! as EntityId}
           selectedDefault={category?._id as string}
          onSelectCategory={onCategory!}
          page="shop"
        />
      </CreateStudentFormCol>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Sub Category</LabelStudent>
        <FilterSubCategoryContainer
          categoryID={category?._id!}
          defaultValue={subCategory!}
          onSelectSubCategory={onSubCategory!}
        />
      </CreateStudentFormCol>
    </CreateStudentFormRow>
  );
};

export default ShopFilterPresentation;
