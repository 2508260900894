import React from "react";
import { Student, StudentSystemType } from "../types/types";
import {
  StudentSystemItemStyle,
  StudnetSystemWrapper,
} from "./student.system.styles";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  StudentProject,
  StudentCloseProject,
} from "../presantation/student.details.info.presentation/student.details.info.style";

type StudentSystemComponentProps = {
  system: StudentSystemType[];
  onRemove: (item: StudentSystemType) => void;
  student?: Student;
};
const StudentSystemComponents: React.FC<StudentSystemComponentProps> = ({
  system,
  onRemove,
  student,
}) => {
  return (
    <StudnetSystemWrapper>
      {system.map((sysItem) => {
        // console.log(sysItem);
        const organization = student?.systemOrganization?.find(
          (it) => it._id === sysItem.organization
        );

        const project = student?.systemProject?.find(
          (it) => it._id === sysItem.project
        );

        const category = student?.systemCategories?.find((it) => {
          return it._id === sysItem.category;
        });

        const subcategory = student?.systemSubcategories?.find(
          (it) => it._id === sysItem.subcategory
        );

        console.log("System item", sysItem);
        console.log("organization", organization?.title!);
        console.log("category ", category);

        return (
          <StudentSystemItemStyle key={sysItem._id}>
            <StudentProject className="item_student_project">
              <span>
                Organization:{" "}
                {organization!
                  ? organization.title
                  : sysItem.organization.title}{" "}
              </span>
              <span>
                Project: {project! ? project?.title! : sysItem.project.title}{" "}
              </span>

              <span>
                Category:{" "}
                {category !== undefined
                  ? category?.title
                    ? category?.title
                    : "-"
                  : sysItem.category!
                  ? sysItem.category.title
                  : "-"}{" "}
              </span>
              <span>
                Subcategory:{" "}
                {subcategory !== undefined
                  ? subcategory?.title
                    ? subcategory?.title
                    : "-"
                  : sysItem.subcategory!
                  ? sysItem.subcategory.title
                  : "-"}
              </span>
              <StudentCloseProject
                onClick={() => {
                  onRemove!(sysItem);
                }}
              >
                <AiOutlineCloseCircle color={"#707070"} />
              </StudentCloseProject>
            </StudentProject>
          </StudentSystemItemStyle>
        );
      })}
    </StudnetSystemWrapper>
  );
};

export default StudentSystemComponents;
