import { ChangeEvent, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import { useLoginMutation } from "../../authApiSlice/authApiSlice";
import { getAdminStatus } from "../../authThunks/authThunks";
import LoginPresentation from "../../presentations/login/login.presentation";
import Loading from "../../../../components/Loading/Loading";
const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleClickShowPassword = () => setshowPassword(!showPassword);

  const onLogin = async () => {
    try {
      console.log(email);
      const l = await login({
        email: email.toLowerCase().trim(),
        password,
      }).unwrap();
      console.log(l);
      dispatch(getAdminStatus());
      setEmail("");
      setPassword("");
    } catch (error) {}
  };

  let content;
  if (isSuccess) {
    content = <Navigate to="/" replace />;
  } else if (isError) {
    content = (
      <LoginPresentation
        email={email}
        password={password}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        onLogin={onLogin}
        handleClickShowPassword={handleClickShowPassword}
        showPassword={showPassword}
        isError={isError}
        error={error}
      />
    );
  } else if (isLoading) {
    content = <Loading />;
  } else {
    content = (
      <LoginPresentation
        email={email}
        password={password}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        onLogin={onLogin}
        handleClickShowPassword={handleClickShowPassword}
        showPassword={showPassword}
      />
    );
  }

  return <>{content}</>;
};

export default LoginContainer;
