import React from "react";
import {
  ExperienceActionCreate,
  ExperienceActionSearch,
  ExperienceActionSearchInput,
  ExperienceActionWrapper,
} from "./experience.acton.style";
import { Button } from "@mui/material";
import { SearchStudent, SearchStudentContainer, StudentHeaderControle } from "../../../students/presantation/studentActionPresentation/studentAction.styels";
import { MdAddCircle } from "react-icons/md";
import { StudentButton } from "../expereince.list.presentation/experienceListPresentation.styles";
import FilterExperience from "../ExperienceTagFilter";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import { useAppSelector } from "../../../../app/hooks";
import { BiSearchAlt } from "react-icons/bi";

type ExperienceActionPresentationProps = {
  onCreateExperience: () => void;
  onTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  listStatus: string;
  onPublish: () => void;
  onSave: () => void;
  term: string;
};
const ExperienceActionPresentation: React.FC<
  ExperienceActionPresentationProps
> = ({ onCreateExperience, onTerm, listStatus, onPublish, onSave , term }) => {
  const admin = useAppSelector(selectAdmin);

  const accessProject = admin?.projectAccess?.find(
    (project) => project.accessType === "Full"
  );
  const accessCategory = admin?.categoryAccess?.find(
    (category) => category.accessType === "Full"
  );
  const accessSubCategory = admin?.subCategoryAccess?.find(
    (sub) => sub.accessType === "Full"
  );
  const permissionResult = accessProject || accessCategory || accessSubCategory;
  return (
    <>
      <StudentHeaderControle style={{ maxWidth: 1380 , marginTop:-3, marginBottom:30}}>
     
          <SearchStudentContainer>
          <BiSearchAlt
            spacing={18}
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchStudent
            type="text"

            value={term === "null" ? "" : term}
            placeholder="Search Experience"
            onChange={onTerm}
          />
        </SearchStudentContainer>

        <StudentButton
          spaceLeft="35px"
          onClick={onCreateExperience}
          style={{ borderRadius: 50, fontSize: 16 }}
          disabled={
            admin?.adminLevel === "ADMIN" && !permissionResult?.experience
          }
        >
          <MdAddCircle size={30} />
          <span style={{ paddingLeft: 8, fontSize: 19 }}>Create Card</span>
        </StudentButton>

        {/*  the filter by save and publish goes here */}
        <FilterExperience
          listStatus={listStatus}
          onPublish={onPublish}
          onSave={onSave}
        />
      </StudentHeaderControle>
    </>
  );
};

export default ExperienceActionPresentation;
