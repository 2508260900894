import { Button, IconButton, Paper, Switch } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { FaLock } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { Column, InnerCol, Row, SubTitle, SwitchLabel, Box, Label } from "../../../../assets/style/General.style";
import ShowCanView from "../../../../components/showCanView/ShowCanView";
import SearchCategoryContainer from "../../../category/container/search.category.container/SearchCategoryContainer";
import FilterByProjectContainer from "../../../project/container/filter.by.project.container/FilterByProjectContainer";
import {
  AdminAccessSchoolDistrictPresentationProps,
  CategoryPermissionType,
  ProjectPermissionType,
} from "../../types/types";
import { CircleIcon } from "../admin.access.special.presentation/admin.access.special.presentation.styles";
import { HighSchoolLabel } from "../admin.add.presentation/admin.add.presentation.styles";
import {
  SchoolDistrictCol1,
  SchoolDistrictCol2,
  SchoolDistrictCol3,
  SchoolDistrictLabel,
  SchoolDistrictSectionCol,
  SchoolDistrictSectionRow,
  SchoolDistrictWrapper,
  ShowCanEditItemCol,
  ShowCanEditItems,
  ShowCanEditItemsRow,
  ShowCanEditTitle,
  ShowCanEditWrapper,
} from "./admin.access.schooldistrict.styles";
const label = { inputProps: { "aria-label": "Switch demo" } };
const AdminAccessSchoolDistrictPresentation: React.FC<
  AdminAccessSchoolDistrictPresentationProps
> = ({
  admin,
  onProjectHanlder,
  projectId,
  onCategoryHandler,
  projectCanView,
  categoryCanView,
  onAddAccessPermission,
  onProjectRemove,
  onCategoryRemove,
  onClickProject,
  onClickCategory,
  showCanEdit,
  onUserCheck,
  onExperienceCheck,
  onStoreCheck,
  onSurveyCheck,
  onSaveHandler,
}) => {
    return (



      <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
        <Row Direction="column" Width="40%" tabletWidth={'100%'} minWidth={""} alignment={""}>
          <Column width={""} minWidth={""} alignment={""} Direction={""}>
            <SubTitle>School district</SubTitle>
          </Column>
          <Column style={{ flexWrap: "wrap" }} width={""} minWidth={""} alignment={""} Direction={""}>
            <InnerCol Direction="column" align={""} alignment={""}>
              <Label>School District</Label>

              <FilterByProjectContainer
                organizationID={admin?.organization?._id as EntityId}
                onSelectProject={onProjectHanlder!}
                filterdBy="Secondary"
              />
            </InnerCol>
            <InnerCol Direction="column" align={""} alignment={""}>
              <Label>High School</Label>
              <SearchCategoryContainer
                onChangeState={onCategoryHandler!}
                filterId={projectId!}
              />
            </InnerCol>
            <InnerCol style={{ padding: "20px 10px 0 0" }} align="center" alignment={""} Direction={""}>

              <CircleIcon onClick={onAddAccessPermission}>
                {false ? (
                  <FaLock color="#fff" size={14} />
                ) : (
                  <IoMdAdd color="#fff" size={14} />
                )}
              </CircleIcon>
            </InnerCol>
          </Column>
        </Row>

        <Row Width="30%" tabletWidth={'50%'} minWidth={""} alignment={""} Direction={""}>
          <Column width={""} minWidth={""} alignment={""} Direction={""}>
            <ShowCanView
              projectAccess={projectCanView}
              categoryAccess={categoryCanView}
              onCategoryRemove={onCategoryRemove}
              onProjectRemove={onProjectRemove}
              onClickProject={onClickProject}
              onClickCategory={onClickCategory}
            />
          </Column>
        </Row>

        <Row Width="20%" tabletWidth={'40%'} minWidth={'240px'} alignment={""} Direction={""}>

          {showCanEdit && (
            <>
              <Column width="fit-content" style={{ justifyContent: "end" }} minWidth={""} alignment={""} Direction={""}>
                <ShowCanEditTitle>
                  {(showCanEdit as ProjectPermissionType).project
                    ? (showCanEdit as ProjectPermissionType).project.title
                    : (showCanEdit as CategoryPermissionType).category.title}
                </ShowCanEditTitle>
              </Column>
              <Column Direction="column" style={{ minWidth: "210px" }} width={""} minWidth={""} alignment={""}>
                <InnerCol alignment="space-between" align={""} Direction={""}>
                  <SwitchLabel>Users</SwitchLabel>
                  <Switch
                    {...label}
                    checked={showCanEdit.users}
                    onChange={onUserCheck}
                  />
                </InnerCol>
                <InnerCol alignment="space-between" align={""} Direction={""}>
                  <SwitchLabel>Experience cards</SwitchLabel>
                  <Switch
                    {...label}
                    checked={showCanEdit.experience}
                    onChange={onExperienceCheck}
                  />
                </InnerCol>
                <InnerCol alignment="space-between" align={""} Direction={""}>
                  <SwitchLabel>surveys</SwitchLabel>
                  <Switch
                    {...label}
                    checked={showCanEdit.survey}
                    onChange={onSurveyCheck}
                  />
                </InnerCol>
                <InnerCol alignment="space-between" align={""} Direction={""}>
                  <SwitchLabel>store</SwitchLabel>
                  <Switch
                    {...label}
                    checked={showCanEdit.store}
                    onChange={onStoreCheck}
                  />
                </InnerCol>
                <InnerCol align={""} alignment={""} Direction={""}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onSaveHandler}
                  >
                    Save
                  </Button>
                </InnerCol>
              </Column>{" "}
            </>
          )}
        </Row>
      </Box>

    );
  };

export default AdminAccessSchoolDistrictPresentation;
