import { EntityId } from "@reduxjs/toolkit";
import React from "react";
import FilterCategoryContainer from "../../category/container/filter.category.container/FilterCategoryContainer";
import {
  ExperienceFilterWrapper,
  LeftExperienceFilter,
  RightExperienceFilter,
} from "../../experience/presentation/experience.filter.presentation/experience.filter.sytles";
import FilterSubCategoryContainer from "../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { Project } from "../../project/types/types";
import {
  CreateStudentFormRow,
  CreateStudentFormCol,
  LabelStudent,
} from "../../students/presantation/student.details.info.presentation/student.details.info.style";
type RaffelTicketFilterPresentationProp = {
  category: Partial<Category> | null;
  onCategory: (category: Partial<Category> | null) => void;
  subCategory: Partial<SubCategory> | null;
  onSubCategory: (subCategorySelected: Partial<SubCategory | null>) => void;
  project: Partial<Project>;
};
const RaffelTicketFilterPresentation: React.FC<
  RaffelTicketFilterPresentationProp
> = ({ category, onCategory, subCategory, onSubCategory, project }) => {
  return (
    <CreateStudentFormRow style={{ marginTop: 0 }}>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Category</LabelStudent>
        <FilterCategoryContainer
          projectID={project!._id! as EntityId}
          onSelectCategory={onCategory!}
          page="raffle"
        />
      </CreateStudentFormCol>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Sub Category</LabelStudent>
        <FilterSubCategoryContainer
          categoryID={category?._id!}
          onSelectSubCategory={onSubCategory!}
        />
      </CreateStudentFormCol>
    </CreateStudentFormRow>
  );
};

export default RaffelTicketFilterPresentation;
