import React from "react";
import FilterByProjectPresentation from "../../presentation/filter.by.project.presentation/FilterByProjectPresentation";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { useGetAllProjectByOrganizationQuery } from "../../api/projectApiSlice";
import { FilterProjectContainerProps, Project } from "../../types/types";
import { EntityState } from "@reduxjs/toolkit";
const FilterByProjectContainer: React.FC<FilterProjectContainerProps> = ({
  organizationID,
  onSelectProject,
  filterdBy,
  selectedDefault,
}) => {
  const {
    data: projects,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllProjectByOrganizationQuery({ organizationId: organizationID! });

  const selectProjectValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "All") {
      const project = projects?.entities[e.target.value];
      onSelectProject(project!);
    } else {
      onSelectProject(null);
    }
  };

  return (
    <>
      {isSuccess && (
        <FilterByProjectPresentation
          projects={projects}
          onSelectProject={selectProjectValue}
          projectTypeFilter={filterdBy}
          selectedDefault={selectedDefault}
        />
      )}
    </>
  );
};

export default FilterByProjectContainer;
