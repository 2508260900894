import React from "react";
import { Student } from "../../../students/types/types";
import { IconButton, Switch } from "@mui/material";
import { FaTrashAlt } from "react-icons/fa";
import PolygonChart from "../../../../components/PolygonChart/PolygonChart";
import { useRemovePhotoMutation } from "../../../experiencePhoto/api/experiencePhotoApi";
import { EntityId } from "@reduxjs/toolkit";
type ExperienceReviewPhotItemPresentationProps = {
  item: Student;
  imageClicked: (id: EntityId) => void;
  onCompleteChange: (student: Student, completion: boolean) => void;
};
const ExperienceReviewPhotoItemPresentation: React.FC<
  ExperienceReviewPhotItemPresentationProps
> = ({ item, imageClicked, onCompleteChange }) => {
  const [removePhoto, { isLoading, isError, isSuccess }] =
    useRemovePhotoMutation();
  return (
    <>
      {item.photos && (
        <div className="photo-item-container">
          <img
            src={item.photos.photo}
            alt="photo submisson"
            onClick={() => {
              imageClicked(item._id);
            }}
          />
          <div className="photo-item-control">
            <Switch
              sx={{ position: "relative", top: 12, left: 8 }}
              checked={item.completion?.complete}
              onChange={() => {
                onCompleteChange(item, !item.completion?.complete);
              }}
            />
            <IconButton
              onClick={async () => {
                try {
                  console.log("run");
                  await removePhoto(item.photos?._id!).unwrap();
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <FaTrashAlt color="#fff" />
            </IconButton>
          </div>
          <div className="photo-item-profile">
            <PolygonChart
              studentPic={item.image}
              style={{
                transform: "scale(0.2)",
                position: "absolute",
                top: -80,
                left: -100,
              }}
            />
            <p>{item.fullName}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ExperienceReviewPhotoItemPresentation;
