import ShowListInTagFormat from "../../../../components/showListInTagFormat/ShowListInTagFormat";
import { CategoryListPresentationProps } from "../../types/types";
const CategoryListPresentation: React.FC<CategoryListPresentationProps> = ({
  categories,
  selectCategoryHandler,
  selectCategoryRemoveHandler,
}) => {
  return (
    <ShowListInTagFormat
      list={categories}
      selectItemHanlder={selectCategoryHandler}
      onRemove={selectCategoryRemoveHandler}
    />
  );
};

export default CategoryListPresentation;
