import React from "react";
import { useNavigate } from "react-router-dom";
import ExperienceActionPresentation from "../../presentation/experience.action.presentation/ExperienceActionPresentation";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
type ExperienceActionContainerProps = {
  onTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  listStatus: string;
  onPublish: () => void;
  onSave: () => void;
  term: string;
};
const ExperienceActionContainer: React.FC<ExperienceActionContainerProps> = ({
  onTerm,
  listStatus,
  onSave,
  onPublish,
  term
}) => {
  const navigation = useNavigate();
  const onCreateExperience = () => {
    navigation("/experience/expdetails");
  };

  return (
    <ExperienceActionPresentation
      onCreateExperience={onCreateExperience}
      onTerm={onTerm}
      listStatus={listStatus}
      onSave={onSave}
      onPublish={onPublish}
      term={term}
    />
  );
};

export default ExperienceActionContainer;
