import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { StudentGem } from "../types/types";
import { apiSlice } from "../../../app/api/apiSlice";

export const gemAdaptor = createEntityAdapter<StudentGem>({
  selectId: (gem) => gem.student,
});

interface GemInitialState extends EntityState<StudentGem> {}

export const gemInitialState: GemInitialState = gemAdaptor.getInitialState();

export const extendedGemApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStudenGem: builder.query<
      EntityState<StudentGem>,
      {
        projectId: string;
        student: string;
      }
    >({
      query: (data) =>
        `/gem/getgemstudentforadmin?project=${data.projectId}&student=${data.student}`,
      transformResponse: (response: StudentGem) => {
        return gemAdaptor.setOne(gemInitialState, response);
      },
      providesTags: ["GemStudent"],
    }),
  }),
});

export const { useGetStudenGemQuery, useLazyGetStudenGemQuery } =
  extendedGemApi;
