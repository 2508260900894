import React from "react";
import SelectField from "../../../../components/design/selectFiled/selectField";
import { FilterProjectPresentationProps } from "../../types/types";
const FilterByProjectPresentation: React.FC<FilterProjectPresentationProps> = ({
  projects,
  onSelectProject,
  projectTypeFilter,
  selectedDefault,
}) => {
  console.log("PROJECT", projects);
  console.log("projectTypeFilter", projectTypeFilter);
  return (
    <SelectField onChange={onSelectProject} defaultValue={selectedDefault}>
      <option value="All">All</option>
      {projects.ids.map((id) =>
        projectTypeFilter ? (
          projects.entities[id]?.project_type === projectTypeFilter && (
            <option
              key={projects.entities[id]?._id}
              value={projects.entities[id]?._id}
            >
              {projects.entities[id]?.title}
            </option>
          )
        ) : (
          <option
            key={projects.entities[id]?._id}
            value={projects.entities[id]?._id}
          >
            {projects.entities[id]?.title}
          </option>
        )
      )}
    </SelectField>
  );
};

export default FilterByProjectPresentation;
