import { EntityId, EntityState } from "@reduxjs/toolkit";
import React from "react";
import { ExperienceType } from "../types/types";
import SelectField from "../../../components/design/selectFiled/selectField";

type SelectExpereinceTypePresentationProps = {
  exptypes: EntityState<ExperienceType>;
  onSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defaultExperienceType?: Partial<ExperienceType> | null;
};
const SelectExperienceTypePresentation: React.FC<
  SelectExpereinceTypePresentationProps
> = ({ exptypes, onSelected, defaultExperienceType }) => {
  return (
    <SelectField
      onChange={onSelected}
      defaultValue={defaultExperienceType?._id as string}
    >
      {exptypes.ids.map((id) => (
        <option key={id} value={id}>
          {exptypes.entities[id]?.title}
        </option>
      ))}
    </SelectField>
  );
};

export default SelectExperienceTypePresentation;
