import React, { useState } from "react";
import { ExperienceCard } from "../../types/types";
import { TableRow, TableCell } from "@mui/material";
import { FaRegChartBar } from "react-icons/fa";
import { StudentButton } from "./experienceListPresentation.styles";
import { MdModeEdit } from "react-icons/md";
import { useNavigation } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";

import ExperienceHotSpotSubList from "../../container/experienceHotspotSubList/ExperienceHotSpotSubList";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import { useAppSelector } from "../../../../app/hooks";
type ExperienceListItemPresentationProps = {
  item: ExperienceCard;
  onEdit: (id: EntityId) => void;
  onReview: (id: EntityId) => void;
};
const ExperienceListItemPresentation: React.FC<
  ExperienceListItemPresentationProps
> = ({ item, onEdit, onReview }) => {
  const admin = useAppSelector(selectAdmin);

  const accessProject = admin?.projectAccess?.find(
    (project) => project.accessType === "Full"
  );
  const accessCategory = admin?.categoryAccess?.find(
    (category) => category.accessType === "Full"
  );
  const accessSubCategory = admin?.subCategoryAccess?.find(
    (sub) => sub.accessType === "Full"
  );
  const permissionResult = accessProject || accessCategory || accessSubCategory;
  const [showsub, setShowSub] = useState<boolean>(false);

  return (
    <>
      <TableRow>
        <TableCell style={{
                    maxWidth: 10,
                
                    fontFamily: 'Poppins',
              }}>
          {item.experienceType.title === "HotSpot" ? (
            <button
              onClick={() => {
                setShowSub(!showsub);
              }}
              style={{background: "none",
                border: "none",
                fontSize: "x-large",
                paddingLeft:2,
                color: "#2f8f6c"}}
            >
              +
            </button>
          ) : null}
        </TableCell>
        <TableCell>
          <img
            style={{
              width: 50,
              height: 50,
              borderRadius: 50,
            }}
            src={item.image}
            alt=""
          />{" "}
        </TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{item.title}</TableCell>
        <TableCell style={{
                    maxWidth: 370,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{item.description}</TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>
          <div style={{    
            border: "1px solid #CED4DA",
            borderRadius: 5,
            width: 100,
            fontSize: 14,
            marginLeft: 7,
            marginBottom: 2
             }}>{new Date(item.startDate).toLocaleDateString()}</div>
          <div style={{    
            border: "1px solid #CED4DA",
            borderRadius: 5,
            width: 100,
            fontSize: 14,
            marginLeft: 7,
   
             }}>{new Date(item.endDate).toLocaleDateString()}</div>
        </TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}> - </TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{item.xpCompletion}</TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{item.experienceType.title}</TableCell>
        <TableCell style={{
                    maxWidth: 130,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{item.completionType.title}</TableCell>
        <TableCell style={{ overflow: "visible" }}>
          <div style={{ display: "flex" }}>
            <StudentButton
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 5,
                marginLeft: 2,
                paddingRight: 5,
              }}
              onClick={() => {
                onEdit(item._id);
              }}
              spaceLeft={""}
              disabled={
                admin?.adminLevel === "ADMIN" && !permissionResult?.experience
              }
            >
              <span
                className="experience-item-button"
                style={{
                  maxWidth: 130,
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "center",
                  fontFamily: 'Poppins',
                  fontWeight:600,
                  paddingRight:5
            }}
              >
                Edit
              </span>
              <MdModeEdit color="#34c38f" size="20" style={{backgroundColor:"#fff" , borderRadius:50, padding:3}} />
            </StudentButton>
            <StudentButton
              style={{ display: "flex", alignItems: "center", marginLeft: 3, paddingRight: 5, }}
              onClick={() => {
                onReview(item._id);
              }}
              spaceLeft={""}
            >
              <span
                className="experience-item-button"
                style={{
                  maxWidth: 130,
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "center",
                  fontFamily: 'Poppins',
                  fontWeight:600,
                  paddingRight:5
            }}
              >
                Review
              </span>
              <FaRegChartBar />
            </StudentButton>
          </div>
        </TableCell>
      </TableRow>
      {showsub ? (
        <ExperienceHotSpotSubList
          hotspotgroupid={item.hotSpot_group_id!}
          category={item.category}
          subCategory={item.subCategory}
          expPublish={item.expPublish}
          expSave={item.expSave}
          onEdit={onEdit}
          onReview={onReview}
          term="null"
        />
      ) : null}
    </>
  );
};

export default ExperienceListItemPresentation;
