import styled from "@emotion/styled";
import {  Button } from "@mui/material";

export const LoginContent = styled.div`
        padding:22px 45px 40px;
        background-color: #fff;
        border-radius: 12px;
        width: 350px;
`;

export const LoginContentTitle = styled.h3`
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 22px;
        padding-bottom: 20px;
        color: #302F45;
        text-align: center;
`;

export const LoginButtonContainer = styled.div`
        margin-top: 25px;
        width:100%;
`;

export const LoginButton = styled(Button)`
        display: block;
        width:100%;
        padding:9px 44px;
        background: #34269E;
        border:none;
        border-radius: 20px;
        font-size: 15px;
        font-family: "PoppinsBold";
        color:white;
        text-transform: capitalize;
        &:hover{
                box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
                transition-duration:300ms;
                background:#1905b3;
              }
              @media (max-width: 1770px) {
                font-size: 13px;
                margin-left: 20px;
              }
`;
export const ForgotPasswordText = styled.p`
        font-size: 12px;
        font-family: "Rajdhani";
        color: #3E3F5E;
        line-height: 15px;
        text-align: center;
        padding-top: 20px;
        margin-bottom: 0;
`;

export const VerificationResendCode = styled.button`
        font-size: 12px;
        font-family: "Rajdhani";
        color: #34269E;
        line-height: 15px;
        text-align: center;
        width: 100%;
        border: none;
        background: none;
`;

export const LoginContainer= styled.div`
        width:100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #39424D;
`;

export const LoginWrapper= styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
`;

export const LoginHeader= styled.div`
        display: flex;
        align-items: center;
`;

export const LogoImage= styled.img`
        max-width: 700px;
        position: relative;
`;

export const ForgotPasswordButton= styled.button`
        font-size: 12px;
        font-family: "Rajdhani";
        color: #34269E;
        line-height: 15px;
        text-align: right;
        font-weight: bold;
        width: 100%;
        border: none;
        background: none;
        padding-top: 15px;
        padding-bottom: 10px;
`;

