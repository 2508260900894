import { EntityId, EntityState } from "@reduxjs/toolkit";
import React from "react";
import { Analytic } from "../../../../types/types";
import { Student } from "../../../students/types/types";
import ExperienceReviewPhotoSubmistionContainer from "../../container/experience.review.container/ExperienceReviewPhotoSubmistionContainer";
import { ExperienceCard } from "../../types/types";
import ExpereinceReviewCardHeaderPresentation from "./ExpereinceReviewCardHeaderPresentation";
import ExperienceReviewAnalyisPresentation from "./ExperienceReviewAnalyisPresentation";
import ExperienceStudentListPresentaion from "./ExperienceStudentListPresentaion";
import {
  AnalysticsWrapper,
  ExpereinceReviewInnerWrapperStyle,
  ExperienceReviewWrapper,
} from "./experience.review.styles";

type ExperienceReviewPresentationProps = {
  experience: ExperienceCard;
  studentCompletionPhotoList: EntityState<Student>;
  analytics: Analytic;
  onCompleteChange: (student: Student, completion: boolean) => void;
  switchLoading: boolean;
  onEdit: (id: EntityId) => void;
  term?: string;
  onTerm?: (t: string) => void;
  sort?: string;
  onSort?: (fieldName: string) => void;
  limit?: number;
  onLimit?: (l: number) => void;
  page?: number;
  onpage?: (p: number) => void;
};

const ExperienceReviewPresentation: React.FC<
  ExperienceReviewPresentationProps
> = ({
  experience,
  studentCompletionPhotoList,
  analytics,
  onCompleteChange,
  onEdit,
  switchLoading,
  term,
  onTerm,
  sort,
  onSort,
  page,
  limit,
  onpage,
  onLimit,
}) => {
  return (
    <ExperienceReviewWrapper>
      <ExpereinceReviewInnerWrapperStyle>
        {experience.completionType.title === "Photo" ? (
          <>
            <div
              style={{
                width: "40%",
                height: "100%",
                minWidth: "480px",
              }}
            >
              <ExpereinceReviewCardHeaderPresentation
                onEdit={onEdit}
                experience={experience}
              />
              <ExperienceStudentListPresentaion
                studentList={studentCompletionPhotoList}
                experience={experience}
                onCompleteChange={onCompleteChange}
                switchLoading={switchLoading}
                term={term}
                onTerm={onTerm}
                sort={sort}
                onSort={onSort!}
                page={page}
                limit={limit}
                onLimit={onLimit}
                onpage={onpage}
              />
            </div>
            <AnalysticsWrapper>
              <div className="analystics">
                <ExperienceReviewAnalyisPresentation analytics={analytics} />
                <div className="photo-submission-container">
                  <div className="photo_container">
                    <ExperienceReviewPhotoSubmistionContainer
                      studentCompletionPhotoList={studentCompletionPhotoList}
                      onCompleteChange={onCompleteChange}
                    />
                  </div>
                </div>
              </div>
            </AnalysticsWrapper>
          </>
        ) : (
          <>
            <div
              style={{
                width: "40%",
                height: "100%",
                minWidth: "480px",
              }}
            >
              <ExpereinceReviewCardHeaderPresentation
                onEdit={onEdit}
                experience={experience}
              />
              <ExperienceStudentListPresentaion
                studentList={studentCompletionPhotoList}
                experience={experience}
                onCompleteChange={onCompleteChange}
                switchLoading={switchLoading}
                term={term}
                onTerm={onTerm}
                sort={sort}
                onSort={onSort!}
                page={page}
                limit={limit}
                onLimit={onLimit}
                onpage={onpage}
              />
            </div>
            <AnalysticsWrapper>
              <div className="analystics">
                <ExperienceReviewAnalyisPresentation analytics={analytics} />
                {/* <PhotoSubmission experience={experienceItem} users={users} /> */}
              </div>
            </AnalysticsWrapper>
          </>
        )}
      </ExpereinceReviewInnerWrapperStyle>
    </ExperienceReviewWrapper>
  );
};

export default ExperienceReviewPresentation;
