import { Paper } from "@mui/material";
import React from "react";
import { StudentButton } from "../../../experience/presentation/expereince.list.presentation/experienceListPresentation.styles";
import { FaPlus } from "react-icons/fa";
import ProgressChart from "../../../../components/ProgreesChart/ProgressChart";
import PolygonChart from "../../../../components/PolygonChart/PolygonChart";
import ProfileChartsContainer from "../../../profileCharts/container/ProfileChartsContainer";
import {
  Student,
  StudentProjectReport,
  StudentSystemType,
} from "../../types/types";
import StudentXp from "../../../../components/StudentXp/StudentXp";
import GemNumber from "../../../../components/GemsNumber/GemNumber";
import Crystals from "../../../../components/Crystals/Crystals";
import Fuses from "../../../../components/Fuses/Fuses";
import LeaderBoardContainer from "../../../leaderBoard/container/LeaderBoardContainer";
import XpPanelPresentation from "../xpPaneelPresentation/XpPanelPresentation";
type StudentDetailsGamePresentationProps = {
  xpPanel: boolean;
  onOpenXpPanel: () => void;
  onCloseXpPanel: () => void;
  student: Student;
  account: StudentSystemType;
  projectReport: StudentProjectReport;
  xpDescriptoin: string | null;
  xpAmount: number | null;
  gemAmount: number | null;

  onXpDescripton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onXpAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGemAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenAward: () => void;
  onCloseAward: () => void;
  openAward: boolean;
  onAddAward: () => void;
  xpPerProject: number;
  gemNumber: number;
  fusesNumber: number;
  crystalNumber: number;
};
const StudentDetailsGamePresentation: React.FC<
  StudentDetailsGamePresentationProps
> = ({
  xpPanel,
  onOpenXpPanel,
  onCloseXpPanel,
  student,
  account,
  projectReport,
  gemAmount,
  onAddAward,
  onCloseAward,
  onGemAmount,
  onOpenAward,
  onXpAmount,
  onXpDescripton,
  openAward,
  xpAmount,
  xpDescriptoin,
  xpPerProject,
  gemNumber,
  fusesNumber,
  crystalNumber,
}) => {
  console.log("Fuses number", fusesNumber);
  console.log("crystal number", crystalNumber);
  return (
    <Paper
      elevation={3}
      style={{
        marginTop: 10,
        borderRadius: 12,
        marginLeft: 35,
        marginRight: 35,
      }}
    >
      <div
        className="st-info st-info-game-status"
        style={{ minHeight: 467, margin: 0 }}
      >
        <div className="st-info-team-container">
          <div style={{ display: "flex" }}>
            <h3 className="st-info-title">Game Status</h3>

            <StudentButton
              style={{
                borderRadius: 50,
                marginRight: 10,
                marginLeft: 10,
              }}
              onClick={onOpenAward}
              spaceLeft={""}
            >
              <FaPlus style={{ marginRight: 5 }} size={27} />{" "}
              <span>Award Points</span>{" "}
            </StudentButton>
            <div style={{ position: "relative" }}>
              {openAward ? (
                <XpPanelPresentation
                  gemAmount={gemAmount!}
                  onAddAward={onAddAward}
                  onGemAmount={onGemAmount!}
                  onXpAmount={onXpAmount!}
                  onXpDescription={onXpDescripton!}
                  onCloseAward={onCloseAward}
                  xpAmount={xpAmount!}
                  xpDescription={xpDescriptoin!}
                  style={{ top: "50px", left: "-200px" }}
                />
              ) : null}
            </div>
          </div>
          <div
            className="st-info-circlechart-container"
            style={{ display: "flex", width: 460 }}
          >
            {/* {showProgress ? ( */}
            <>
              <ProfileChartsContainer student={student} />
            </>
            {/* ) : (
            <Spinner style={{ width: 50, height: 50, top: 20 }} />
          )} */}
          </div>
        </div>
        <div className="st-info-team-content">
          <h4 className="st-info-team-content-title">Team</h4>
          <PolygonChart
            style={{ transform: "scale(0.5)", top: -50, left: -10 }}
            color={student?.team.color}
            XP={projectReport?.xps + 1}
            studentPic={student.image}
          />
        </div>
        <div className="st-info-team-data">
          <StudentXp xp={xpPerProject} />
          <GemNumber gems={gemNumber!} />
          <Crystals crystalNumber={crystalNumber} />
          <Fuses fusesNumber={fusesNumber} />
        </div>
        <div className="st-info-leader-board">
          <div className="st-info-leader-board-title">Leader Board</div>
          <LeaderBoardContainer
            account={account}
            projectReport={projectReport}
          />
        </div>
      </div>
    </Paper>
  );
};

export default StudentDetailsGamePresentation;
