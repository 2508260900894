import React, { FC } from "react";
import { ProjectItemPresentationProps } from "../../types/types";
import { Link } from "react-router-dom";
import { TableRow, TableCell, Checkbox } from "@mui/material";
const ProjectItemPresentation: FC<ProjectItemPresentationProps> = ({
  project,
}) => {
  return (
      <TableRow>
      <TableCell style={{ borderBottom: 0 }}>
        <Checkbox
          color="default"
          size="small"
          checked={true}
          onChange={() => {}}
          value={""}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        <img
          src={project.image}
          alt="asternuts"
          width="37"
          height="37"
          style={{ borderRadius: 50 }}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0, maxWidth: 205 }}>
        <Link
          style={{ color: "#495057", fontSize: 15, textDecoration: "none" }}
          to={`/project/${project._id}`}
        >
          {project.title}
        </Link>
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {project.project_type}{" "}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {project.condition}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {project.status}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>{project.categoriesNumber}</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>{project.subcategoriesNumber}</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>{project.studentsNumber}</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {project.reward_system ? "Yes" : "No"}
      </TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        {project.survey_system ? "Yes" : "No"}
      </TableCell>
      </TableRow>
  );
};

export default ProjectItemPresentation;
