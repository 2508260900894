import React from "react";
import SelectField from "../design/selectFiled/selectField";
import { Team } from "../../features/team/types/types";
type SelectTeamPresentationProps = {
  teams: Team[];
  onSelectTeam: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  team?: Partial<Team>;
};
const SelectTeamPresentation: React.FC<SelectTeamPresentationProps> = ({
  teams,
  onSelectTeam,
  team,
}) => {
  return (
    <SelectField defaultValue={team?._id as string} onChange={onSelectTeam}>
      {teams.map((team) => (
        <option key={team._id} value={team._id}>
          {team.title}
        </option>
      ))}
    </SelectField>
  );
};

export default SelectTeamPresentation;
