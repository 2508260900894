import React from "react";
import {
  AvailableSection,
  AvailableSectionItem,
  AvailableSectionTitle,
  AvailableText,
  DigitalCodeAddBtn,
  DigitalCodeSection,
  DigitalCodeTitle,
  InfoItem,
  InfoSection,
  InfoText,
  InfoTextArea,
  InfoTitle,
  Wrapper,
} from "./raffel.midldle.style";
import { Switch } from "@mui/material";
import { images } from "../../../../constants";
import CustomSwitch from "../../../../components/switch/CustomSwitch";
type RaffelTickeetMiddleColPresentationProp = {
  gem: number | null;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  numberOfWinner: number | null;
  onNumberOfWinner: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimitState: boolean;
  onPurchaseLimitState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimit: number | null;
  onPurchaseLimit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWheel: boolean;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const RaffelTicketMiddleColPresentation: React.FC<
  RaffelTickeetMiddleColPresentationProp
> = ({
  gem,
  onGem,
  numberOfWinner,
  onNumberOfWinner,
  purchaseLimitState,
  onPurchaseLimitState,
  purchaseLimit,
  onPurchaseLimit,
  store,
  onStore,
  prizeWheel,
  onPrizeWheel,
}) => {
  return (
    <Wrapper>
      <InfoSection style={{ height: 180 }}>
        <InfoTitle>Info</InfoTitle>
        <InfoItem>
          <InfoText>Price In Gems</InfoText>
          <InfoTextArea
            value={gem!}
            onChange={onGem}
            placeholder="50 - 500"
            style={{ width: "80px" }}
          />
        </InfoItem>
        <InfoItem>
          <InfoText>Number Of Winners</InfoText>
          <InfoTextArea
            value={numberOfWinner!}
            onChange={onNumberOfWinner}
            placeholder="2"
            style={{ width: "80px", marginRight: "-60px" }}
          />
          <img src={images.UnlockIcon} alt="unlock"></img>
        </InfoItem>
        <InfoItem>
          <InfoText
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Purchase Limit
                      <CustomSwitch
              style={{ marginLeft: 10 }}
              isToggle={purchaseLimitState}
              onChangeToggle={onPurchaseLimitState}
            />
          </InfoText>
          {purchaseLimitState && (
            <InfoTextArea
              style={{ width: "80px", marginRight: "11px" }}
              value={purchaseLimit!}
              onChange={onPurchaseLimit}
            />
          )}
        </InfoItem>
      </InfoSection>
      <AvailableSection>
        <AvailableSectionTitle>Available In</AvailableSectionTitle>
        <AvailableSectionItem>
          <AvailableText>Store</AvailableText>
          <CustomSwitch
              style={""}
              isToggle={store!}
              onChangeToggle={onStore}
            />
          
        </AvailableSectionItem>
        <AvailableSectionItem>
          <AvailableText>Prize Wheel</AvailableText>

          <CustomSwitch
              style={""}
              isToggle={prizeWheel}
              onChangeToggle={onPrizeWheel}
            />
        </AvailableSectionItem>
      </AvailableSection>
      <DigitalCodeSection>
        <DigitalCodeTitle>Inventory</DigitalCodeTitle>
        <DigitalCodeAddBtn
        //  onClick={goToInventoryHandler}
        >
          Edit Full Inventory
        </DigitalCodeAddBtn>
      </DigitalCodeSection>
    </Wrapper>
  );
};

export default RaffelTicketMiddleColPresentation;
