import React, { useEffect } from "react";
import Header from "../../../components/header/Header";
import ShopEditReviewContainer from "../container/ShopEditReviewContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";

const ShopDetailsPage = () => {
  const { id } = useParams();
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project) {
      navigate("/");
    }
  }, [project]);

  return (
    <>
      {project && (
        <>
          <Header title="Shop Item" />
          <ShopEditReviewContainer id={id!} />
        </>
      )}
    </>
  );
};

export default ShopDetailsPage;
