import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { RaffelTicket } from "../types/types";
import { IResponseList } from "../../../types/types";
import { store } from "../../../app/store";
import { setMetaData } from "./raffleTicketSlice";

const RaffelTicketAdaptor = createEntityAdapter<RaffelTicket>({
  selectId: (raffelTicket) => raffelTicket._id,
});

interface RaffelTicketInitialState extends EntityState<RaffelTicket> {}

const initialState: RaffelTicketInitialState =
  RaffelTicketAdaptor.getInitialState();

export const extendedRaffelTicketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRaffelTicket: builder.query<
      EntityState<RaffelTicket>,
      {
        project: string;
        category?: string;
        subCategory?: string;
        page?: string;
        limit?: string;
        sort?: string;
        term?: string;
      }
    >({
      query: (params) =>
        `/raffel-ticket?project=${params.project}&category=${
          params.category ? params.category : "null"
        }&subCategory=${
          params.subCategory ? params.subCategory : "null"
        }&term=${params.term}&sort=${params.sort}&page=${params.page}&limit=${
          params.limit
        }`,
      transformResponse: (response: IResponseList<RaffelTicket[]>) => {
        const raffelTicketData = response.data;
        const raffelTicketMetaData = response.metadata;
        store.dispatch(setMetaData(raffelTicketMetaData));
        return RaffelTicketAdaptor.setAll(initialState, raffelTicketData);
      },
      providesTags: (result, error, arg) => [
        {
          type: "RaffelTicket",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "RaffelTicket" as const, id })),
      ],
    }),

    createRaffelTicket: builder.mutation<
      EntityState<RaffelTicket>,
      Partial<RaffelTicket>
    >({
      query: (raffelTicket) => ({
        url: `/raffel-ticket`,
        method: "POST",
        body: raffelTicket,
      }),
      transformResponse: (response: RaffelTicket) => {
        return RaffelTicketAdaptor.setOne(initialState, response);
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "RaffelTicket",
          id: "LIST",
        },
        {
          type: "RaffelTicket",
          id: arg._id,
        },
      ],
    }),

    getARaffelTicket: builder.query<EntityState<RaffelTicket>, EntityId>({
      query: (id) => `/raffel-ticket/${id}`,
      transformResponse: (response: RaffelTicket) => {
        console.log("res", response);
        return RaffelTicketAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "RaffelTicket",
          id: arg,
        },
      ],
    }),

    updateRaffelTicket: builder.mutation<
      EntityState<RaffelTicket>,
      Partial<RaffelTicket>
    >({
      query: (raffelTicket) => ({
        url: `/raffel-ticket/${raffelTicket._id}`,
        method: "PUT",
        body: raffelTicket,
      }),
      transformResponse: (response: RaffelTicket) => {
        return RaffelTicketAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: "RaffelTicket", id: "LIST" },
        ...result!.ids.map((id) => ({
          type: "RaffelTicket" as const,
          id: arg._id,
        })),
      ],
    }),

    removeRaffel: builder.mutation<EntityState<RaffelTicket>, EntityId>({
      query: (id) => ({
        url: `/raffel-ticket/${id}`,
        method: "Delete",
        body: id,
      }),
      transformResponse: (response: RaffelTicket) => {
        return RaffelTicketAdaptor.removeOne(initialState, response._id);
      },
      invalidatesTags: ["RaffelTicket"],
    }),
  }),
});

export const {
  useCreateRaffelTicketMutation,
  useGetAllRaffelTicketQuery,
  useLazyGetAllRaffelTicketQuery,
  useGetARaffelTicketQuery,
  useUpdateRaffelTicketMutation,
  useRemoveRaffelMutation,
} = extendedRaffelTicketApi;
