import React from "react";
import { Student, StudentSystemType } from "../../types/types";
import {
  CreateStudentFormCol,
  CreateStudentFormRow,
  LabelStudent,
} from "../student.details.info.presentation/student.details.info.style";
import SelectField from "../../../../components/design/selectFiled/selectField";
import StudnetSystemItemPresentation from "./StudnetSystemItemPresentation";
import { EntityId } from "@reduxjs/toolkit";

type StudentSystemListPresentationProp = {
  systems: StudentSystemType[];
  student: Student;
  OnSelectAccount: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  accountActive: Partial<StudentSystemType>;
};
const StudentSystemListPresentation: React.FC<
  StudentSystemListPresentationProp
> = ({ systems, OnSelectAccount, student, accountActive }) => {
 
  return (
    <CreateStudentFormRow
      style={{
        paddingRight: 30,
        marginTop: 0,
        marginBottom: 35,
        flexWrap: "wrap",
      }}
    >
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Accounts</LabelStudent>
        <SelectField
          onChange={(e) => {
            OnSelectAccount(e);
          }}
          defaultValue={accountActive?._id! as string}
          style={{ width: 350, marginTop: 5 }}
        >
          {systems.map((item) => (
            <StudnetSystemItemPresentation
              key={item._id}
              item={item!}
              student={student}
            />
          ))}
        </SelectField>
      </CreateStudentFormCol>
    </CreateStudentFormRow>
  );
};

export default StudentSystemListPresentation;
