import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { MetaData } from "../../../types/types";

interface IinitialState {
  experienceMetadata: MetaData;
}

const initialState: IinitialState = {
  experienceMetadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
};

export const experinceSlice = createSlice({
  name: "experince",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.experienceMetadata = action.payload;
    },
  },
});

export const experienceMetadataSelected = (state: RootState) => {
  return state.experience.experienceMetadata;
};

export const { setMetaData } = experinceSlice.actions;
export default experinceSlice.reducer;
