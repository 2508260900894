import React from "react";
import { Student, StudentSystemType } from "../../types/types";
type StudentSystemItemPresentationProp = {
  item: Partial<StudentSystemType>;
  student: Student;
};
const StudnetSystemItemPresentation: React.FC<
  StudentSystemItemPresentationProp
> = ({ item, student }) => {
  const organization = student?.systemOrganization?.find(
    (it) => it._id === item.organization
  );

  const project = student?.systemProject?.find((it) => it._id === item.project);

  const category = student?.systemCategories?.find((it) => {
    return it._id === item.category;
  });

  const subcategory = student?.systemSubcategories?.find(
    (it) => it._id === item.subcategory
  );

  return (
    <option value={item?._id}>
      {organization!.title} - {project!.title} -{" "}
      {item.categories!.length > 0 ? (
        item.categories?.map((it) => <>{it.title}</>)
      ) : (
        <>{category?.title}, </>
      )}{" "}
      -{subcategory?.title}
    </option>
  );
};

export default StudnetSystemItemPresentation;
