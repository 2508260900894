import React from "react";
import { BiSearchAlt } from "react-icons/bi";
import { MdAddCircle } from "react-icons/md";
import {
  RaffleHeaderControle,
  SearchRaffleContainer,
  SearchRaffle,
  RaffleButton,
} from "../raffel.styles";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";

type RaffelTicketActionPresentationProps = {
  onOpenAdd: () => void;
  onTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  term: string;
};
const RaffelTicketAcitonPresentation: React.FC<
  RaffelTicketActionPresentationProps
> = ({ onOpenAdd, onTerm, term }) => {
  const user = useAppSelector(selectAdmin);
  // console.log("permission", user?.subCategoryAccess![0].store);
  return (
    <RaffleHeaderControle style={{ marginTop: -2, marginBottom:4 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 2,
          width: "65%",
        }}
      >
        <SearchRaffleContainer>
          <BiSearchAlt
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchRaffle
            type="text"
            value={term === "null" ? "" : term}
            placeholder="Search Item"
            onChange={onTerm}
          />
        </SearchRaffleContainer>
        {/* {haveAccess && ( */}
        <RaffleButton
          style={{
            paddingRight: 4,
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 16,
          }}
          spaceLeft="50px"
          onClick={onOpenAdd}
          disabled={
            user?.adminLevel === "ADMIN" && !user!.subCategoryAccess![0]?.store
          }
        >
          <MdAddCircle size={32} />
          <span style={{ paddingRight: 18 }}>New Raffle</span>
        </RaffleButton>
        {/* )} */}
      </div>
    </RaffleHeaderControle>
  );
};

export default RaffelTicketAcitonPresentation;
