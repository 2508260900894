import React from "react";
import { useGetAllOrganizationQuery } from "../../api/orgApiSlice";
import FilterByOrganizationPresentation from "../../presantation/filter.by.organizaiton.presentation/FilterByOrganizationPresentation";
import { FilterOrganizationListContainerProps } from "../../types/types";
const FilterByOrganizationContainer: React.FC<
  FilterOrganizationListContainerProps
> = ({ onSelectOrganization, selectedDefault }) => {
  const {
    data: organizationList,
    isLoading,
    isSuccess,
  } = useGetAllOrganizationQuery();

  const onSelectOrganizationValue = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (e.target.value !== "All") {
      const getOrganization = organizationList?.entities[e.target.value];
      onSelectOrganization(getOrganization!);
    } else {
      onSelectOrganization(null);
    }
  };

  return (
    <>
      {organizationList && (
        <FilterByOrganizationPresentation
          onSelectOrganization={onSelectOrganizationValue}
          organizationList={organizationList!}
          selectedDefault={selectedDefault}
        />
      )}
    </>
  );
};

export default FilterByOrganizationContainer;
