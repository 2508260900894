import { EntityId } from "@reduxjs/toolkit";

import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import ProjectListPresentation from "../../presentation/project.list.presentation/project.list.presentation";

import { ProjectListContainerProps } from "../../types/types";
const ProjectListContainer: FC<ProjectListContainerProps> = ({ projects }) => {
  return <ProjectListPresentation projects={projects} />;
};

export default ProjectListContainer;
