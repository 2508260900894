import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Button } from "@mui/material";
export const CreateRaffleFormCol = styled.div<{
  alignment?: string;
  Direction?: string;
}>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px 0 0;
`;

export const SuggestRaffleText = styled.h3`
  color: #e83200;
  padding-top: 10px;
  padding-left: 14px;
  font-size: 13px;
`;

export const CreateRaffleContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateRaffleForm = styled.div`
  padding-bottom: 10px;
  margin-left: 200px;
  padding-top: 20px;
  width: fit-content;
  height: fit-content;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 14px -1px rgba(0, 0, 0, 0.82);
  min-width: 750px;

`;

export const CreateRaffleFormRow = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1px;
`;

export const RaffleInput = styled.input<{
  Width?: string;
  Height?: string;
  Size?: string;
  Weight?: number;
  SpacingTB?: string;
  SpacingLR?: string;
}>`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;

  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: #070708;
  max-width: ${({ Width }) => (Width ? Width : "235px")};
`;

export const LabelRaffle = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: #707070;
`;
export const SwitchLabelRaffle = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: #74788d;
  padding-right: 7px;
`;

export const RaffleImage = styled.img`
  width: 200px;
  height: 140px;
  object-fit: cover;
`;
export const RaffleEditButtton = styled.button`
  display: flex;
  height: 32px;
  border-radius: 4px;
  width: 60px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  position: absolute;
  right: 80px;
  bottom: -14px;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const RaffleProjectEditButtton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const RaffleEditButttonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const RaffleEditButttonIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const RaffleButon = styled(Button)`
      color:#fff;
      background-color:#0078FF;
      border:none;
      border-radius:50px;
      padding: 10px 46px;
      font-family:'Poppins';
      font-size:15px;
      font-weight:500;
      &:hover {
        background-color: #3996ff;
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border:none;
      };
      &:active {
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        background-color: #3996ff;
        border:none;

      };
      &:focus {
        box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
        border:none;
      };
`;
export const RaffleDetailsContainer = styled.div`
  padding: 27px;
  padding-top: 35px;
`;

export const RafflePic = styled.div`
  width: 125px;
  height: 110px;
`;

export const RaffleInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const RaffleListContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  max-width: 835px;
  border: 1px solid #ced4da;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 17px;
  justify-content: flex-start;
  margin-right: 20px;
`;
export const RaffleListProject = styled.div`
  border: 1px solid #ced4da;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px;
  position: relative;
  max-width: 46%;
  color: #74788d;
  font-size: 13px;
  flex-direction: column;
  border-radius: 5px;
  min-width: 110px;
  min-height: 25px;
  padding: 0 25px 0 7px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const RaffleListCloseProject = styled.div`
  position: absolute;
  top: 1px;
  right: 7px;
  color: #313539;
  font-family: system-ui;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div<{
  spinnerSize?: number;
}>`
  width: 144px;
  height: 144px;
  ::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 2px solid #d3d3d3;
    background: transparent;
    width: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    height: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    border-radius: 50%;
    align-self: center;
  }
`;
export const ImagContainer = styled.div`
  width: 200px;
  height: 140px;
`;
export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;
