import React, { useEffect, useState } from "react";
import {
  Student,
  StudentListItemPresentationProps,
  StudentProjectReport,
} from "../../types/types";
import { Checkbox, IconButton, TableCell, TableRow } from "@mui/material";

import { Link } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { useAppSelector } from "../../../../app/hooks";
import { accountSelected } from "../../slice/studentSlice";
import { selectedProject } from "../../../home/appSlice/appslice";
import XpPanelPresentation from "../xpPaneelPresentation/XpPanelPresentation";
import { useAddAwardMutation } from "../../../awards/api/awardsApi";
const StudentItemPresentation: React.FC<StudentListItemPresentationProps> = ({
  student,
  onEditClick,
  onSelectStudents,
}) => {
  const project = useAppSelector(selectedProject);
  const [
    addAward,
    { isLoading: awardLoading, isError: awardError, isSuccess: awardSuccess },
  ] = useAddAwardMutation();
  const [openAward, setOpenAward] = useState<boolean>(false);
  const [xpAmount, setXpAmount] = useState<number | null>(null);
  const [gemAmount, setGemAmount] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [selectStudents, setSelectStudents] = useState<Student[]>([student]);
  const [xpsTable, setXpsTable] = useState<number>(0);

  const [projectReport, setProjectReport] =
    useState<StudentProjectReport | null>({
      xps: 0,
      gems: 0,
      notCompletedTasks: 0,
      passDueTasks: 0,
      project: project!,
    });

  const [studentChecked, setStudentChecked] = useState<boolean>(false);
  useEffect(() => {
    const projectReport = student.projectReport!.find(
      (item) => item.project === project?._id
    );

    if (projectReport) {
      console.log(projectReport);
      setProjectReport(projectReport);
    } else {
      setProjectReport({
        xps: 0,
        gems: 0,
        notCompletedTasks: 0,
        passDueTasks: 0,
        project: project!,
      });
    }
  }, [project, student]);

  const onAddAwardHandler = async () => {
    try {
      await addAward({
        description: description!,
        xp: xpAmount! ? xpAmount : 0,
        gem: gemAmount ? gemAmount! : 0,
        project: project!._id! as any,
        students: selectStudents,
      }).unwrap();
      setXpAmount(null);
      setGemAmount(null);
      setDescription(null);

      onCloseAward();
    } catch (error) {}
  };

  const onCloseAward = () => {
    setOpenAward(false);
  };

  const onXpDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onXpAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpAmount(Number(e.target.value));
  };

  const onGemAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGemAmount(Number(e.target.value));
  };

  return (
    <TableRow>
      <TableCell style={{ borderBottom: 0 }}>
        <Checkbox
          checked={studentChecked}
          onChange={(e) => {
            onSelectStudents(e, student);
            setStudentChecked(e.target.checked);
          }}
        />
      </TableCell>
      <TableCell style={{ borderBottom: 0, position: "relative" }}>
        <img
          src={student.image}
          alt="asternuts"
          width="50"
          height="50"
          style={{ borderRadius: 50 }}
        />
        {/* {student?.scores?.change && (
          <div className="exclamation-icon">
            <img src={ExclamationIcon} style={{}} />
          </div>
        )} */}
      </TableCell>
      <TableCell style={{ borderBottom: "0", textAlign: "left" }}>
        <Link
          style={{ color: "#495057", fontSize: "15px", textDecoration: "none" }}
          to={`/student/${student._id}`}
        >
          {student.fullName}
        </Link>
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {student.confirm ? "Active" : "InActive"}
      </TableCell>
      <TableCell style={{ color: "#F70606", borderBottom: 0 }}>
        {/* {isNaN(parseInt(student?.scores?.studentScore))
          ? 0
          : parseInt(student?.scores?.studentScore)} */}
        0
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {projectReport?.notCompletedTasks}
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {projectReport?.passDueTasks}
      </TableCell>
      <TableCell style={{ color: "##797E83", borderBottom: 0 }}>
        {Math.round(Number(student!.xp! / 3500))}
      </TableCell>
      <TableCell style={{ color: "##797E83", borderBottom: 0 }}>
        {student!.xp}
      </TableCell>
      <TableCell
        style={{ borderBottom: 0, position: "relative" }}
        className="addXp"
      >
        <IconButton
          onClick={() => {
            setOpenAward(!openAward);
          }}
        >
          <AiFillPlusCircle color="#34c38f" size={30} />
        </IconButton>
        {openAward && (
          <XpPanelPresentation
            gemAmount={gemAmount}
            xpAmount={xpAmount}
            onAddAward={onAddAwardHandler}
            onGemAmount={onGemAmount}
            onXpAmount={onXpAmount}
            onXpDescription={onXpDescription}
            xpDescription={description}
            onCloseAward={onCloseAward}
            style={{
              top: "50px",
              right: "0",
              width: "300px",
              display: "block",

              height: false ? "270px" : "242px",
            }}
            descriptionError={false}
          />
        )}
      </TableCell>
    </TableRow>

    // <TableRow>
    //   <TableCell>{student.image}</TableCell>
    //   <TableCell>{student.fullName}</TableCell>
    //   <TableCell>{student.active ? "Active" : "InActive"}</TableCell>
    //   <TableCell>0</TableCell>
    //   <TableCell>0</TableCell>
    //   <TableCell>0</TableCell>
    //   <TableCell>0</TableCell>
    //   <TableCell>0</TableCell>
    // </TableRow>
  );
};

export default StudentItemPresentation;
