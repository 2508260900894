import React, { useState } from "react";
import ShopAddPresentation from "../presentation/shopAddPresentation/ShopAddPresentation";
import { Category } from "../../category/types/types";
import { Project } from "../../project/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { useCreateShopMutation } from "../api/shopApiSlice";
import Toast from "../../../components/toast/Toast";
type ShopAddContainerProp = {
  closeHandler: () => void;
  image?: string;
  onImage?: (url: string) => void;
  title: string | null;
  onTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialStock: number | null;
  onInitialStock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unlockLevel: number | null;
  onUnlockLevel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string | null;
  onDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  costPerItems: number | null;
  onCostPerItems: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number | null;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWeel: boolean;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  project: Partial<Project>;
  onReload: () => void;
  onClear: () => void;
};
const ShopAddContainer: React.FC<ShopAddContainerProp> = ({
  closeHandler,
  image,
  onImage,
  title,
  onTitle,
  initialStock,
  onInitialStock,
  unlockLevel,
  onUnlockLevel,
  description,
  onDescription,
  costPerItems,
  onCostPerItems,
  gem,
  onGem,
  store,
  onStore,
  prizeWeel,
  onPrizeWheel,
  onClose,
  project,
  onReload,
  onClear,
}) => {
  const [createShop, { isLoading, isError, isSuccess }] =
    useCreateShopMutation();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );
  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [showToastSuccess, setshowToastSuccess] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const onCategory = (categorySelected: Partial<Category> | null) => {
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelected: Partial<SubCategory> | null) => {
    setSubCategory(subCategorySelected);
  };

  const onSave = async () => {
    setSaveLoading(true);
    try {
      await createShop({
        title: title!,
        image: image!,
        category: category!,
        cost_per_item: costPerItems!,
        description: description!,
        gem: gem!,
        inital_stock: initialStock!,
        current_stock: initialStock!,
        prize_wheel: prizeWeel,
        project: project!,
        store: store,
        subCategory: subCategory!,
        unlock_level: unlockLevel!,
      }).unwrap();
      setshowToastSuccess(true);
      setTimeout(() => {
        setshowToastSuccess(false);
        setSaveLoading(false);
        onReload();
        onClear();
        onClose();
      }, 3000);
    } catch (error: any) {
      if (error.status === 400) {
        setToastMessage(error.data.message[0]);
      } else {
        setToastMessage(error.data.message);
      }
      setShowToastFail(true);
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
      setSaveLoading(false);
    }
  };
  return (
    <>
      <ShopAddPresentation
        closeHandler={closeHandler}
        image={image}
        onImage={onImage}
        title={title}
        onTitle={onTitle}
        initialStock={initialStock}
        onInitialStock={onInitialStock}
        unlockLevel={unlockLevel}
        onUnlockLevel={onUnlockLevel}
        description={description}
        onDescription={onDescription}
        costPerItems={costPerItems}
        onCostPerItems={onCostPerItems}
        gem={gem}
        saveLoading={saveLoading}
        onGem={onGem}
        store={store}
        onStore={onStore}
        prizeWeel={prizeWeel}
        onPrizeWheel={onPrizeWheel}
        category={category}
        onCategory={onCategory}
        subCategory={subCategory}
        onSubCategory={onSubCategory}
        project={project}
        onSave={onSave}
      />
      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Shop Save Successfuly" typeToast="Success" />
        </div>
      )}
    </>
  );
};

export default ShopAddContainer;
