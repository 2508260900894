import React, { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import RaffelTicketFilterPresentation from "../presentation/RaffelTicketFilterPresentation";
import RaffelTicketAcitonPresentation from "../presentation/RaffelTicketAcitonPresentation/RaffelTicketAcitonPresentation";
import RaffelTicketAddContainer from "./RaffelTicketAddContainer";
import RaffelTicketListContainer from "./RaffelTicketListContainer";

const RaffelTicketContainer = () => {
  const project = useAppSelector(selectedProject);

  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const [term, setTerm] = useState<string>("null");
  const [sort, setSort] = useState<string>("-createdAt");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);

  const onPage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onLimit = (limit: number) => {
    setLimit(limit);
  };

  const onOpenAdd = () => {
    setOpenAdd(true);
  };

  const onCloseAdd = () => {
    setOpenAdd(false);
  };

  const onCategory = (categorySelected: Partial<Category> | null) => {
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelected: Partial<SubCategory> | null) => {
    setSubCategory(subCategorySelected);
  };

  const onTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setTerm(e.target.value);
    } else {
      setTerm("null");
    }
  };
  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };
  return (
    <>
      <RaffelTicketFilterPresentation
        category={category}
        onCategory={onCategory}
        subCategory={subCategory}
        onSubCategory={onSubCategory}
        project={project!}
      />
      <RaffelTicketAcitonPresentation
        onOpenAdd={onOpenAdd}
        term={term}
        onTerm={onTerm}
      />
      <RaffelTicketListContainer
        project={project!}
        category={category}
        subCategory={subCategory}
        term={term}
        sort={sort}
        onSort={onSort}
        page={page}
        limit={limit}
        onPage={onPage}
        onLimit={onLimit}
      />
      <RaffelTicketAddContainer
        openAdd={openAdd}
        onOpenAdd={onOpenAdd}
        onClose={onCloseAdd}
        project={project!}
      />
    </>
  );
};

export default RaffelTicketContainer;
