import React from "react";
import { AdminInfoPresentationProps } from "../../types/types";
import {
  AdminDetailPic,
  AdminDetailSubTitle,
  AdminInfoWrapper,
} from "./admin.info.presentation.styles";
import { Button, Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Column,
  ConstantContent,
  ConstantLabel,
  ConstantValue,
  EditButton,
  EditButtonIcon,
  EditButtonText,
  Header,
  InnerCol,
  Row,
  SubTitle,
  Title,
} from "../../../../assets/style/General.style";
import { MdEdit } from "react-icons/md";
import { AdminInfo } from "../admin.add.presentation/admin.add.presentation.styles";
import PolygonChart from "../../../../components/PolygonChart/PolygonChart";
const AdminInfoPresentation: React.FC<AdminInfoPresentationProps> = ({
  admin,
  openEdit,
  open,
}) => {
  return (
    <AdminInfoWrapper>
      <Box elevation={3} style={{ flexDirection: "column" }}>
        <Row
          Width={""}
          minWidth={""}
          alignment={""}
          Direction={""}
          tabletWidth={""}
        >
          <AdminInfo>
            <AdminDetailPic>
              <PolygonChart
                XP={3499}
                studentPic={admin!.image || ""}
                color="#000"
                style={{ transform: "scale(0.35)", top: -98, left: -17 }}
              />
            </AdminDetailPic>
            <div style={{ paddingLeft: 40, width: "100%" }}>
              <Header>{admin!.fullName}</Header>
              <Column width={""} minWidth={""} alignment={""} Direction={""}>
                <AdminDetailSubTitle>{admin!.adminLevel}</AdminDetailSubTitle>
                <AdminDetailSubTitle style={{ paddingLeft: "10%" }}>
                  {admin!.jobTitle}
                </AdminDetailSubTitle>
              </Column>
            </div>
          </AdminInfo>
        </Row>
        <Row
          style={{ height: "36px" }}
          Width={""}
          minWidth={""}
          alignment={""}
          Direction={""}
          tabletWidth={""}
        >
          {true && (
            <EditButton
              style={{ marginLeft: "33px" }}
              onClick={() => {
                openEdit();
              }}
            >
              <EditButtonText>Edit</EditButtonText>
              <EditButtonIcon>
                <MdEdit color="#34C38F" size={12} />
              </EditButtonIcon>
            </EditButton>
          )}
        </Row>
        <Row
          alignment="space-between"
          Width={""}
          minWidth={""}
          Direction={""}
          tabletWidth={""}
        >
          <Column
            width="50%"
            minWidth="550px"
            Direction="column"
            alignment={""}
          >
            <InnerCol
              style={{ paddingLeft: 15, marginTop: 15 }}
              align={""}
              alignment={""}
              Direction={""}
            >
              <SubTitle>Info</SubTitle>
            </InnerCol>
            <InnerCol align={""} alignment={""} Direction={""}>
              <ConstantContent>
                <ConstantLabel>Admin ID</ConstantLabel>
                <ConstantValue SpacingLR={""}>{admin!._id}</ConstantValue>
              </ConstantContent>
              <ConstantContent>
                <ConstantLabel style={{ marginBottom: "5px" }}>
                  Phone
                </ConstantLabel>
                <ConstantValue SpacingLR={""}>
                  {admin!.phone ? admin!.phone : "--"}
                </ConstantValue>
              </ConstantContent>
              <ConstantContent>
                <ConstantLabel style={{ marginBottom: "5px" }}>
                  Email
                </ConstantLabel>

                <ConstantValue SpacingLR={""}>{admin!.email}</ConstantValue>
              </ConstantContent>
              <ConstantContent>
                <ConstantLabel style={{ marginBottom: "5px" }}>
                  Job Title
                </ConstantLabel>
                <ConstantValue SpacingLR={""}>{admin!.jobTitle}</ConstantValue>
              </ConstantContent>
            </InnerCol>
          </Column>
          {admin.adminLevel !== "ULTRA" ? (
            <Column width="50%" Direction="column" minWidth={""} alignment={""}>
              <InnerCol
                style={{ marginTop: 15 }}
                align={""}
                alignment={""}
                Direction={""}
              >
                <SubTitle>Organization</SubTitle>
              </InnerCol>
              <InnerCol align={""} alignment={""} Direction={""}>
                <ConstantContent>
                  <ConstantLabel>Organization</ConstantLabel>
                  <ConstantValue SpacingLR={""}>
                    {admin!.organization?.shf}
                  </ConstantValue>
                </ConstantContent>
                {admin.adminLevel === "ADMIN" ? (
                  <ConstantContent>
                    <ConstantLabel>Project</ConstantLabel>
                    <ConstantValue SpacingLR={""}>
                      {admin!.projects?.length! > 0
                        ? admin!.projects![0]!.title
                        : null}
                    </ConstantValue>
                  </ConstantContent>
                ) : null}
              </InnerCol>
            </Column>
          ) : null}
        </Row>
      </Box>

      {admin!.adminLevel === "ULTRA" && (
        <Box style={{ flexDirection: "column", marginTop: 17 }}>
          <Row
            Width={""}
            minWidth={""}
            alignment={""}
            Direction={""}
            tabletWidth={""}
          >
            <Title HideTablet={""}>Access</Title>
            <Column
              width="45%"
              alignment="flex-start"
              style={{ paddingLeft: 20, marginTop: 15 }}
              minWidth={""}
              Direction={""}
            >
              <SubTitle>
                {" "}
                This Admin is Ultra Admin and access to all organizations
              </SubTitle>
            </Column>
          </Row>
        </Box>
      )}
      {admin!.adminLevel === "SUPER" && (
        <Box style={{ flexDirection: "column", marginTop: 17 }}>
          <Row
            Width={""}
            minWidth={""}
            alignment={""}
            Direction={""}
            tabletWidth={""}
          >
            <Title HideTablet={""}>Access</Title>
            <Column
              width="45%"
              alignment="flex-start"
              style={{ paddingLeft: 20, marginTop: 15 }}
              minWidth={""}
              Direction={""}
            >
              <SubTitle>
                {" "}
                This Admin is Super Admin and access to{" "}
                <span style={{ color: "#34c38f" }}>
                  {admin!.organization?.title}
                </span>
              </SubTitle>
            </Column>
          </Row>
        </Box>
      )}
    </AdminInfoWrapper>
  );
};

export default AdminInfoPresentation;
