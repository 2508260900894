import React, { useEffect, useState } from "react";
import StudentDetailsChartPresentation from "../../presantation/student.details.chart.presentation/StudentDetailsChartPresentation";
import { Student, StudentProjectReport } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import { accountSelected } from "../../slice/studentSlice";

type StudentDetailsChartContainerProps = {
  student: Student;
  projectReport: StudentProjectReport;
};
const StudentDetailsChartContainer: React.FC<
  StudentDetailsChartContainerProps
> = ({ student, projectReport }) => {
  const account = useAppSelector(accountSelected);

  return (
    <StudentDetailsChartPresentation
      student={student}
      account={account!}
      projectReport={projectReport}
    />
  );
};

export default StudentDetailsChartContainer;
