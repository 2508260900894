import { Paper } from "@mui/material";
import React from "react";
import { MdEdit } from "react-icons/md";
import { CreateStudentFormCol } from "../studentAddPresentation/student.add.presentation";
import {
  ConstantLabel,
  ConstantValue,
  StudentProject,
  StudentProjectContainer,
} from "./student.details.info.style";
import { Student } from "../../types/types";
import "./student.details.info.css";
type StudentDetailsInfoPresentationProp = {
  student: Student;
  onOpen: () => void;
};
const StudentDetailsInfoPresentation: React.FC<
  StudentDetailsInfoPresentationProp
> = ({ student, onOpen }) => {
  return (
    <Paper
      elevation={3}
      className="st-info st-info-container"
      style={{ height: "max-content" }}
    >
      <div style={{ display: "flex" }}>
        <h3 className="st-info-title">Info</h3>
        <button className="awarded-edit-button" onClick={onOpen}>
          <p className="awarded-edit-button-text">Edit</p>
          <div className="awarded-edit-button-icon">
            <MdEdit color="#34C38F" size={12} />
          </div>
        </button>
      </div>

      <div className="info-col-container" style={{ display: "flex" }}>
        <div className="info-col">
          <div>
            <ConstantLabel>Phone</ConstantLabel>
            <ConstantValue>{student.phone}</ConstantValue>
          </div>
          <div>
            <ConstantLabel>email</ConstantLabel>
            <ConstantValue>{student.email}</ConstantValue>
          </div>
          <div>
            <ConstantLabel>Preferred Contact</ConstantLabel>
            <ConstantValue>
              {student.preferredContact.call && "Call"}
              {student.preferredContact.email && "Email"}
              {student.preferredContact.text && "Text"}
            </ConstantValue>
          </div>
        </div>
        <div className="info-col">
          <div
            style={{
              width: "100%",
              alignItems: "left",
              textAlign: "left",
            }}
          >
            <ConstantLabel style={{ marginBottom: 0 }}>Class Of</ConstantLabel>
            <ConstantValue style={{ fontSize: 17 }}>2021</ConstantValue>
          </div>
          <div>
            <ConstantLabel>School District</ConstantLabel>
            <ConstantValue>
              {student.studentSchoolDistrict.schoolDistrictID?.agancy_name}
            </ConstantValue>
          </div>
          <div>
            <ConstantLabel>District ID</ConstantLabel>
            <ConstantValue>
              {student.studentSchoolDistrict?.studentSchoolDistrictId}
            </ConstantValue>
          </div>
          <div>
            <ConstantLabel>District email</ConstantLabel>
            <ConstantValue>
              {student.studentSchoolDistrict?.studentSchoolDistrictEmail}
            </ConstantValue>
          </div>
          <div>
            <ConstantLabel>High School</ConstantLabel>
            <ConstantValue>
              {student.studentSchoolDistrict.highschool?.school_name}
            </ConstantValue>
          </div>
        </div>
        <div className="info-col">
          <div>
            <ConstantLabel>University</ConstantLabel>
            <ConstantValue>...</ConstantValue>
          </div>
          <div>
            <ConstantLabel>University ID</ConstantLabel>
            <p>{student.studentUniversity?.studentUniversityID}</p>
          </div>

          <div>
            <ConstantLabel>University email</ConstantLabel>
            <ConstantValue>
              {student.studentUniversity?.studentUniversityEmail}
            </ConstantValue>
          </div>
        </div>

        <div className="info-col">
          <CreateStudentFormCol
            // Direction="column"
            style={{
              width: "100%",
              position: "relative",
              top: -15,
            }}
            alignment={""}
            Direction={""}
          >
            <h3 className="info-student-project-title">Projects</h3>
            <StudentProjectContainer style={{ border: "none" }}>
              {student.system.map((item) => {
                const organization = student?.systemOrganization?.find(
                  (it) => it._id === item.organization
                );

                const project = student?.systemProject?.find(
                  (it) => it._id === item.project
                );

                const category = student?.systemCategories?.find((it) => {
                  return it._id === item.category;
                });

                const subcategory = student?.systemSubcategories?.find(
                  (it) => it._id === item.subcategory
                );
                return (
                  <StudentProject
                    key={item._id}
                    className="item_student_project"
                  >
                    <span>{organization?.title} </span>
                    <span>{project?.title} </span>
                    <span>{category?.title} </span>
                    <span>{subcategory?.title} </span>
                  </StudentProject>
                );
              })}
            </StudentProjectContainer>
          </CreateStudentFormCol>
        </div>

        <div className="info-col">
          <div>
            <ConstantLabel>External Link One</ConstantLabel>
            <ConstantValue>{student?.external_link_one}</ConstantValue>
          </div>

          <div>
            <ConstantLabel>External Link Two</ConstantLabel>
            <ConstantValue>{student?.external_link_two}</ConstantValue>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default StudentDetailsInfoPresentation;
