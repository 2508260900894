import React from "react";
import AdminInfoPresentation from "../../presentation/admin.info.presentation/AdminInfoPresentation";
import { AdminInfoContainerProps } from "../../types/types";

const AdminInfoContainer: React.FC<AdminInfoContainerProps> = ({
  admin,
  open,
  openEdit,
}) => {
  console.log(admin);
  return (
    <>
      {admin && (
        <AdminInfoPresentation admin={admin} open={open} openEdit={openEdit} />
      )}
    </>
  );
};

export default AdminInfoContainer;
