import React, { useEffect, useState } from "react";
import { ExperienceType } from "../../../experiencetype/types/types";
import ExperienceDetailsHeaderContainer from "./ExperienceDetailsHeaderContainer";
import ExperienceDetailsActionContainer from "./ExperienceDetailsActionContainer";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectedOrganization,
  selectedProject,
} from "../../../home/appSlice/appslice";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import ExperienceDetailsInfoContainer from "./ExperienceDetailsInfoContainer";
import ExperienceImageDialogPresentation from "../../presentation/experience.details.presentation/ExperienceImageDialogPresentation";
import { CompletionType } from "../../../completion-type/types/types";
import {
  useCreateExperienceCardMutation,
  useDeleteExprienceMutation,
  useLazyGetAExperienceByIdQuery,
  useUpdateExperienceMutation,
} from "../../api/experienceCardApiSlice";
import { ExperienceCard } from "../../types/types";
import Toast from "../../../../components/toast/Toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { isArray } from "util";
type ExperienceMainDetailsContainerProps = {
  experienceDtails?: ExperienceCard;
  isUpdated: boolean;
};
const d1Tags = [
  {
    value: "Driver 1",
    label: "Driver 1",
  },
  {
    value: "SelfEfficacyTAG",
    label: "SelfEfficacyTAG",
  },
  {
    value: "FutureDevTAG",
    label: "FutureDevTAG",
  },
  {
    value: "ConnectTAG",
    label: "ConnectTAG",
  },
  {
    value: "EmoTAG",
    label: "EmoTAG",
  },
  {
    value: "SocialTAG",
    label: "SocialTAG",
  },
  {
    value: "FinancialTAG",
    label: "FinancialTAG",
  },
  {
    value: "AcademicTAG",
    label: "AcademicTAG",
  },
  {
    value: "SituationalTAG",
    label: "SituationalTAG",
  },
];
const d2Tags = [
  {
    value: "Driver 2",
    label: "Driver 2",
  },
  {
    value: "SelfEfficacyTAG",
    label: "SelfEfficacyTAG",
  },
  {
    value: "FutureDevTAG",
    label: "FutureDevTAG",
  },
  {
    value: "ConnectTAG",
    label: "ConnectTAG",
  },
  {
    value: "EmoTAG",
    label: "EmoTAG",
  },
  {
    value: "SocialTAG",
    label: "SocialTAG",
  },
  {
    value: "FinancialTAG",
    label: "FinancialTAG",
  },
  {
    value: "AcademicTAG",
    label: "AcademicTAG",
  },
  {
    value: "SituationalTAG",
    label: "SituationalTAG",
  },
];
const ExperienceMainDetailsContainer: React.FC<
  ExperienceMainDetailsContainerProps
> = ({ experienceDtails, isUpdated }) => {
  const navigate = useNavigate();
  const project = useAppSelector(selectedProject);
  const organization = useAppSelector(selectedOrganization);

  // const starTimeCovertTime = new Date(
  //   new Date(raffelTicket.entities[id]!.startDate).getTime() - time
  // );

  const [createExperienceCard, { isLoading, isError, isSuccess }] =
    useCreateExperienceCardMutation();

  const [
    updateExperience,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateExperienceMutation();

  const [
    deleteExprience,
    {
      isLoading: removeExpLoading,
      isError: removeError,
      isSuccess: removeSuccess,
    },
  ] = useDeleteExprienceMutation();

  const [experienceType, setExperienceType] =
    useState<Partial<ExperienceType> | null>(
      experienceDtails
        ? {
            ...experienceDtails.experienceType,
            expImages: [
              {
                url: experienceDtails.image,
              },
            ],
          }
        : {
            _id: "645b542c97486c69e11f277b",
            title: "Gradegy",
            color: "#13C4B8",
            icon: "https://api.gradify.app/static/icons/gradegy.png",
            expImages: [
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_collabaration-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_completed-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Group-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Happy-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Setup-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Studying-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Webinar-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_aroundworld-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_checklist-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_customer-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Grades-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Knowledge-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Library-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/online_calendar_rafiki_experience_Item.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Placeholder-rafiki_Experience_Item.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Reading_list-rafiki Experience_Item.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Rising-rafiki_Experience Item.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Telecommuting-rafiki Experience_Item.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Time_management-rafiki_Experience_Items.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_childern-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_classroom-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_ethnic-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Team-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_forms-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Mathematics-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_Medical-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/gradify_News-min.png",
              },
              {
                url: "https://apigrad.azurewebsites.net/static/upload/Segment-rafiki_Experience_Item.png",
              },
            ],
          }
    );

  const [completionType, setCompletionType] = useState<Partial<CompletionType>>(
    experienceDtails
      ? experienceDtails.completionType
      : {
          _id: "645b5bd51d655919829a4166",
          title: "Student",
          message: "Click To Complete!",
          buttonTitle: "Completed?",
        }
  );

  const [startDate, setStartDate] = useState<Date | null>(
    experienceDtails ? new Date(experienceDtails.startDate) : null
  );
  const [startTime, setStartTime] = useState<string | null>(
    experienceDtails
      ? String(new Date(experienceDtails.startDate))
          .split("T")[1]
          .substring(0, 5)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    experienceDtails ? new Date(experienceDtails.endDate) : null
  );
  const [endTime, setEndTime] = useState<string | null>(
    experienceDtails
      ? String(experienceDtails.endDate).split("T")[1].substring(0, 5)
      : null
  );
  const [category, setCategory] = useState<Partial<Category> | null>(
    experienceDtails ? experienceDtails.category : null
  );
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    experienceDtails ? experienceDtails.subCategory : null
  );
  const [title, setTitle] = useState<string | null>(
    experienceDtails ? experienceDtails.title : null
  );
  const [subTitle, setSubTitle] = useState<string | null>(
    experienceDtails ? experienceDtails.subTitle : null
  );
  const [description, setDescription] = useState<string | null>(
    experienceDtails ? experienceDtails.description : null
  );
  const [image, setImage] = useState<string>(
    experienceDtails
      ? experienceDtails!.image!
      : experienceType!.expImages![0].url
  );
  const [xpCompletion, setXpCompletion] = useState<number>(
    experienceDtails ? experienceDtails.xpCompletion : 0
  );
  const [openImage, setOpenImage] = useState<boolean>(false);
  const [xpView, setXpView] = useState<number>(
    experienceDtails ? experienceDtails.xpView : 0
  );
  const [gem, setGem] = useState<number>(
    experienceDtails ? experienceDtails.gem : 0
  );

  const [autoCompletion, setAutoCompletion] = useState<boolean>(
    experienceDtails ? experienceDtails.autoComplete : true
  );
  const [addLink, setAddLink] = useState<boolean>(
    experienceDtails ? !!experienceDtails.link_url : false
  );
  const [onNotification, setOnNotification] = useState<boolean>(false);
  const [textLink, setTextLink] = useState<string | null>(
    experienceDtails ? experienceDtails.text_url : null
  );
  const [textUrl, setTextURL] = useState<string | null>(
    experienceDtails ? experienceDtails.link_url : null
  );
  const [buttonLinkUrl, setButtonLinkUrl] = useState<string | null>(
    experienceDtails ? experienceDtails.button_link_url : null
  );
  const [driverOneItems, setDriverOneItmes] =
    useState<{ value: string; label: string }[]>(d1Tags);

  const [driverTwoItems, setDriverTwoItmes] =
    useState<{ value: string; label: string }[]>(d2Tags);

  const [driverOneSelected, setDriverOneSelected] = useState<string | null>(
    experienceDtails ? experienceDtails.tags.driver_one : null
  );
  const [driverTwoSelected, setDriverTwoSelected] = useState<string | null>(
    experienceDtails ? experienceDtails.tags.driver_two : null
  );
  const [publish, setPublish] = useState<boolean>(
    experienceDtails ? experienceDtails.expPublish : false
  );

  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.notification! : false
  );
  const [createDayNotification, setCreateDateNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.notification! : true
  );
  const [daysBeforeNotification, setDaysBeforeNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.daysBefore! : true
  );

  const [daysBeforeNotificationNumber, setDaysBeforeNotificationNumber] =
    useState<number>(
      experienceDtails ? experienceDtails.daysBeforeNumberDay! : 1
    );

  const [daysOfNotification, setDaysOfNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.daysOf! : true
  );

  const [startNotification, setStartNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.startDateNotification! : true
  );

  const [midNotification, setonMidNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.midNotification! : true
  );

  const [passDueNotification, setPassDueNotification] = useState<boolean>(
    experienceDtails ? experienceDtails.passDueNotification! : true
  );

  const [hotSpot_group_id, set_hotSpot_group_id] = useState<string>(
    experienceDtails ? experienceDtails.hotSpot_group_id! : uuidv4()
  );

  const [reSpawn, setReSpawn] = useState<boolean>(
    experienceDtails ? experienceDtails.reSpawn! : false
  );

  const [countHotSpot, setCountHotSpot] = useState<number>(0);

  const [total_reSpawns, setTotalReSpawns] = useState<number | null>(
    experienceDtails ? experienceDtails.total_reSpawns! : null
  );

  const [intervals, setIntervals] = useState<string>(
    experienceDtails ? experienceDtails.intervals! : "Weekly"
  );

  const [weeklyDays, setWeeklyDays] = useState<string | null>(
    experienceDtails ? experienceDtails.weeklyDays! : null
  );

  const [multiDays, setMultiDays] = useState<string[] | null>(
    experienceDtails ? experienceDtails.multiDays! : []
  );

  const [dailyTime, setDailyTime] = useState<string | null>(
    experienceDtails ? experienceDtails.dailyTime! : null
  );

  const [dayofMonth, setDayOfMonth] = useState<string | null>(
    experienceDtails ? experienceDtails.dailyTime! : "1"
  );

  const [availableDays, setAvailableDays] = useState<number>(
    experienceDtails
      ? experienceDtails.availableDays!
        ? experienceDtails.availableDays
        : 7
      : 7
  );

  const [respwanNotification, setRespwanNotification] = useState<boolean>(true);

  const [missionRevive, setMissionRevie] = useState<boolean>(false);
  const [donotPassDue, setDoNotPassDue] = useState<boolean>(
    experienceDtails ? experienceDtails.donotPassDue : false
  );

  useEffect(() => {
    if (experienceDtails) {
      const tz = new Date().getTimezoneOffset();
      const time = tz * 60 * 1000;

      const startTimeConvert = new Date(
        new Date(experienceDtails?.startDate!).getTime() - time
      );

      const endTimeConvert = new Date(
        new Date(experienceDtails?.endDate!).getTime() - time
      );
      setStartTime(
        new Date(startTimeConvert).toISOString().split("T")[1].substring(0, 5)
      );
      setEndTime(
        new Date(endTimeConvert).toISOString().split("T")[1].substring(0, 5)
      );

      setStartDate(new Date(startTimeConvert.getTime() + time));
      setEndDate(new Date(endTimeConvert.getTime() + time));
    }
  }, [experienceDtails]);

  useEffect(() => {
    
    setImage(experienceType!.expImages![0].url);
  }, [experienceType]);

  //just fo test
  useEffect(() => {
    console.log(multiDays);
  }, [multiDays]);

  const onDonotPassDue = () => {
    console.log(!donotPassDue);
    setDoNotPassDue(!donotPassDue);
  };

  const onMissionRevive = () => {
    setMissionRevie(!missionRevive);
  };

  const onAvailableDays = (n: number) => {
    setAvailableDays(n);
  };

  const onReSpawn = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked);
    setReSpawn(e.target.checked);
  };

  const onCountHotSpot = (count: number) => {
    setCountHotSpot(count);
  };

  const onTotalReSpawns = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setTotalReSpawns(null);
    } else {
      setTotalReSpawns(Number(e.target.value));
    }
  };

  const onInterval = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setIntervals(e.target.value);
  };

  const onWeeklyDays = (day: string) => {
    setWeeklyDays(day);
  };

  const onMultiDays = (day: string) => {
    const findDay = multiDays?.find((item) => item === day);
    if (findDay) {
      const respnose = multiDays?.filter((item) => item !== day);
      setMultiDays(respnose!);
    } else {
      setMultiDays([...multiDays!, day]);
    }
  };

  const onDaylitime = (time: string) => {
    let tz = new Date().getTimezoneOffset();
    const timee = tz * 60 * 1000;
    console.log(
      String(time + timee)
        .split("-")[0]
        .substring(0, 5)
    );
    setDailyTime(
      String(time + timee)
        .split("-")[0]
        .substring(0, 5)
    );
  };

  const onDayOfMonthly = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value > "31") {
      setDayOfMonth("31");
    } else if (e.target.value < "1") {
      setDayOfMonth("1");
    } else {
      setDayOfMonth(e.target.value);
    }
  };

  const onStarDateNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setStartNotification(e.target.checked);
  };

  const onMidNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    setonMidNotification(e.target.checked);
  };

  const onPassDueNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassDueNotification(e.target.checked);
  };

  const onDriverOneSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Driver 1") {
      setDriverOneSelected(null);
      setDriverTwoItmes(d2Tags);
      setDriverOneItmes(d1Tags);
    } else {
      setDriverOneSelected(e.target.value);
      setDriverTwoItmes(d2Tags.filter((item) => item.value !== e.target.value));
    }
  };

  const onDriverTwoSelectd = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Driver 2") {
      setDriverTwoSelected(null);
      setDriverOneItmes(d1Tags);
      setDriverTwoItmes(d2Tags);
    } else {
      setDriverTwoSelected(e.target.value);
      setDriverOneItmes(d1Tags.filter((item) => item.value !== e.target.value));
    }
  };

  const onButtonLinkUrlHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setButtonLinkUrl(e.target.value);
  };

  const onTextUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextURL(e.target.value);
  };

  const onTextLinkHanlder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextLink(e.target.value);
  };
  const onAddLinkHanlder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddLink(e.target.checked);
  };

  const onNotificationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnNotification(e.target.checked);
  };

  const onAutoCompletionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoCompletion(e.target.checked);
  };
  const onGemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGem(Number(e.target.value));
  };
  const onXpViewHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpView(Number(e.target.value));
  };

  const onHandlerXpCompletion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpCompletion(Number(e.target.value));
  };

  const onCompletionTypeHandler = (comType: Partial<CompletionType>) => {
    setCompletionType(comType);
  };

  const onImageHandler = () => {
    setOpenImage(true);
  };

  const onImageCardHandler = (url: string) => {
    setImage(url);
  };

  const onCloseImage = () => {
    setOpenImage(false);
  };

  const onDescriptionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onSubTitleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubTitle(e.target.value);
  };

  const onTitleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onExperienceSelect = (
    experienceType: Partial<ExperienceType> | null
  ) => {
    console.log('exptypeforimage',experienceType);
    setExperienceType(experienceType);
  };

  const onStartDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nedate = new Date(e.target.value);

    const hours = Number(
      startTime
        ? startTime?.split(":")[0]
        : new Date().toLocaleTimeString().split(":")[0]
    );
    const min = Number(
      startTime
        ? startTime?.split(":")[0]
        : new Date().toLocaleTimeString().split(":")[1]
    );

    nedate.setUTCHours(hours, min);

    //console.log("****", new Date(nedate.getTime() - time));
    let tz = nedate.getTimezoneOffset();
    const time = tz * 60 * 1000;
    console.log("tz", tz);
    const localDate = new Date(nedate.getTime() + time);
    console.log("runnnn startDate 1", localDate);

    setStartDate(localDate);
  };

  const onStartTimeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
    const nedate = startDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);

      console.log("NEDATE    ", nedate);
      console.log("HOURS   ", hours);
      console.log("MIN    ", min);

      nedate.setHours(hours, min);

      console.log("NEDATE   ", nedate);

      console.log("NEW NEDATE    ", nedate);

      //console.log("****", new Date(nedate.getTime() - time));
      // let tz = nedate.getTimezoneOffset();
      // const time = tz * 60 * 1000;
      // const localDate = new Date(nedate.getTime() + time);
      // console.log("runnnn start time !", localDate);

      setStartDate(nedate);
    }
  };

  const onStarttiemHandlerM = (time: string) => {
    setStartTime(time);

    const nedate = startDate;
    if (nedate) {
      const hours = Number(time.split(":")[0]);
      const min = Number(time.split(":")[1]);
      nedate.setHours(hours, min);
      // console.log(nedate);

      setStartDate(nedate);
    }
  };

  const onEndDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const endDateGen = new Date(e.target.value);
    const nedate = new Date(e.target.value);

    const hours = Number(
      endTime
        ? endTime?.split(":")[0]
        : new Date().toLocaleTimeString().split(":")[0]
    );
    const min = Number(
      endTime
        ? endTime?.split(":")[0]
        : new Date().toLocaleTimeString().split(":")[1]
    );

    nedate.setUTCHours(hours, min);

    //console.log("****", new Date(nedate.getTime() - time));
    let tz = nedate.getTimezoneOffset();

    const time = tz * 60 * 1000;
    console.log(time);
    const localDate = new Date(nedate.getTime() + time);
    console.log("runnnn endate", localDate);

    setEndDate(localDate);

    // setEndDate(endDateGen);
  };

  const onEndTimeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
    console.log(e.target.value);
    const nedate = endDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setHours(hours, min);

      // let tz = nedate.getTimezoneOffset();
      // const time = tz * 60 * 1000;
      // if (tz < 0) {
      //   const localDate = new Date(nedate.getTime() + time);
      //   console.log("run Enddate time", localDate);
      //   setEndDate(localDate);
      // } else if (tz > 0) {
      //   const localDate = new Date(nedate.getTime() - time);
      //   console.log("run Enddate time", localDate);
      //   setEndDate(localDate);
      // }
      setEndDate(nedate);
    }
  };

  const onEndtimeHandlerM = (time: string) => {
    setEndTime(time);
    console.log(time);
    const nedate = endDate;
    if (nedate) {
      const hours = Number(time.split(":")[0]);
      const min = Number(time.split(":")[1]);
      nedate.setUTCHours(hours, min);

      setEndDate(nedate);
    }
  };

  const onCategoryHandler = (category: Partial<Category> | null) => {
    setCategory(category);
  };

  const onSubCategoryHandler = (subCategory: Partial<SubCategory> | null) => {
    console.log("SUBCATEGORY", subCategory);
    setSubCategory(subCategory);
  };

  const onPublishUpdate = async () => {
    setSaveLoading(true);
    try {
      await updateExperience({
        _id: experienceDtails!._id!,

        title: title!,
        subTitle: subTitle!,
        description: description!,
        image: image!,
        autoComplete: autoCompletion,
        button_link_url: buttonLinkUrl,
        category: category,
        completionType: completionType!,
        educationalPhases: "HS-9",
        endDate: endDate!,
        endExpireDate: endDate!,
        experienceType: experienceType!,
        expPublish: true,
        expSave: false,
        gem: gem,
        link_url: textUrl,
        organization: organization!,
        project: project!,
        startDate: startDate!,
        subCategory: subCategory,
        tags: {
          driver_one: driverOneSelected,
          driver_two: driverTwoSelected,
        },
        text_url: textLink,
        xpCompletion: xpCompletion!,
        xpView: xpView!,
        notification: notification,
        createDateNotification: createDayNotification,
        daysBefore: daysBeforeNotification,
        daysBeforeNumberDay: daysBeforeNotificationNumber,
        daysOf: daysOfNotification,
        hotSpot_group_id: hotSpot_group_id,
        reSpawn: reSpawn,
        countHotSpot: countHotSpot,
        total_reSpawns: total_reSpawns,
        intervals: intervals,
        weeklyDays: weeklyDays!,
        multiDays: multiDays!,
        dailyTime: dailyTime!,
        dayofMonth: dayofMonth!,
        startDateNotification: startNotification,
        passDueNotification: passDueNotification,
        availableDays: availableDays!,
        respwanNotification: respwanNotification,
        missionRevive: missionRevive,
        donotPassDue: donotPassDue,
        mainHotspot: experienceDtails?.mainHotspot,
      }).unwrap();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
        navigate("/experience");
      }, 3000);
    } catch (error: any) {
      setShowToastFail(true);
      setToastMessage(
        Array.isArray(error.data.message)
          ? error.data.message[0]
          : error.data.message
      );
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
    }
  };

  const onSaveUpdate = async () => {
    try {
      await updateExperience({
        _id: experienceDtails!._id!,
        title: title!,
        subTitle: subTitle!,
        description: description!,
        image: image!,
        autoComplete: autoCompletion,
        button_link_url: buttonLinkUrl,
        category: category,
        completionType: completionType!,
        educationalPhases: "HS-9",
        endDate: endDate!,
        endExpireDate: endDate!,
        experienceType: experienceType!,
        expPublish: false,
        expSave: true,
        gem: gem,
        link_url: textUrl,
        organization: organization!,
        project: project!,
        startDate: startDate!,
        subCategory: subCategory,
        tags: {
          driver_one: driverOneSelected,
          driver_two: driverTwoSelected,
        },
        text_url: textLink,
        xpCompletion: xpCompletion!,
        xpView: xpView!,
        notification: notification,
        createDateNotification: createDayNotification,
        daysBefore: daysBeforeNotification,
        daysBeforeNumberDay: daysBeforeNotificationNumber,
        daysOf: daysOfNotification,
        hotSpot_group_id: hotSpot_group_id,
        reSpawn: reSpawn,
        countHotSpot: countHotSpot,
        total_reSpawns: total_reSpawns,
        intervals: intervals,
        weeklyDays: weeklyDays!,
        multiDays: multiDays!,
        dailyTime: dailyTime!,
        dayofMonth: dayofMonth!,
        startDateNotification: startNotification,
        passDueNotification: passDueNotification,
        availableDays: availableDays!,
        respwanNotification: respwanNotification,
        missionRevive: missionRevive,
        donotPassDue: donotPassDue,
        mainHotspot: experienceDtails?.mainHotspot,
      }).unwrap();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
        setSaveLoading(true);
        navigate("/experience");
      }, 3000);
    } catch (error: any) {
      setShowToastFail(true);
      setToastMessage(
        Array.isArray(error.data.message)
          ? error.data.message[0]
          : error.data.message
      );
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
      setSaveLoading(false);
    }
  };

  const onRemoveCard = async () => {
    try {
      console.log("id..., ", experienceDtails?._id);
      // await deleteExprience(experienceDtails?._id!).unwrap();
      await updateExperience({
        _id: experienceDtails!._id,
        mainHotspot: experienceDtails!.mainHotspot,
        hotSpot_group_id: experienceDtails!.hotSpot_group_id,
        active: false,
      }).unwrap();
      navigate("/experience");
    } catch (error) {}
  };

  const onSave = async () => {
    try {
      await createExperienceCard({
        title: title!,
        subTitle: subTitle!,
        description: description!,
        image: image!,
        autoComplete: autoCompletion,
        button_link_url: buttonLinkUrl,
        category: category,
        completionType: completionType!,
        educationalPhases: "HS-9",
        endDate: endDate!,
        endExpireDate: endDate!,
        experienceType: experienceType!,
        expPublish: false,
        expSave: true,
        gem: gem,
        link_url: textUrl,
        organization: organization!,
        project: project!,
        startDate: startDate!,
        subCategory: subCategory,
        tags: {
          driver_one: driverOneSelected,
          driver_two: driverTwoSelected,
        },
        text_url: textLink,
        xpCompletion: xpCompletion!,
        xpView: xpView!,
        notification: notification,
        createDateNotification: createDayNotification,
        daysBefore: daysBeforeNotification,
        daysBeforeNumberDay: daysBeforeNotificationNumber,
        daysOf: daysOfNotification,
        hotSpot_group_id: hotSpot_group_id,
        reSpawn: reSpawn,
        countHotSpot: countHotSpot,
        total_reSpawns: total_reSpawns,
        intervals: intervals,
        weeklyDays: weeklyDays!,
        multiDays: multiDays!,
        dailyTime: dailyTime!,
        dayofMonth: dayofMonth!,
        startDateNotification: startNotification,
        passDueNotification: passDueNotification,
        availableDays: availableDays!,
        respwanNotification: respwanNotification,
        missionRevive: missionRevive,
        donotPassDue: donotPassDue,
      }).unwrap();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
        navigate("/experience");
      }, 3000);
    } catch (error: any) {
      setShowToastFail(true);
      setToastMessage(
        Array.isArray(error.data.message)
          ? error.data.message[0]
          : error.data.message
      );
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
    }
  };

  const onPublish = async () => {
    setSaveLoading(true);
    try {
      await createExperienceCard({
        title: title!,
        subTitle: subTitle!,
        description: description!,
        image: image!,
        autoComplete: autoCompletion,
        button_link_url: buttonLinkUrl,
        category: category,
        completionType: completionType!,
        educationalPhases: "HS-9",
        endDate: endDate!,
        endExpireDate: endDate!,
        experienceType: experienceType!,
        expPublish: true,
        expSave: false,
        gem: gem,
        link_url: textUrl,
        organization: organization!,
        project: project!,
        startDate: startDate!,
        subCategory: subCategory,
        tags: {
          driver_one: driverOneSelected,
          driver_two: driverTwoSelected,
        },
        text_url: textLink,
        xpCompletion: xpCompletion!,
        xpView: xpView!,
        notification: notification,
        createDateNotification: createDayNotification,
        daysBefore: daysBeforeNotification,
        daysBeforeNumberDay: daysBeforeNotificationNumber,
        daysOf: daysOfNotification,
        startDateNotification: startNotification,
        passDueNotification: passDueNotification,
        hotSpot_group_id: hotSpot_group_id,
        reSpawn: reSpawn,
        countHotSpot: countHotSpot,
        total_reSpawns: total_reSpawns,
        intervals: intervals,
        weeklyDays: weeklyDays!,
        multiDays: multiDays!,
        dailyTime: dailyTime!,
        dayofMonth: dayofMonth!,
        availableDays: availableDays!,
        respwanNotification: respwanNotification,
        missionRevive: missionRevive,
        donotPassDue: donotPassDue,
        mainHotspot: experienceDtails?.mainHotspot,
      }).unwrap();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
        setSaveLoading(false);
        navigate("/experience");
      }, 3000);
    } catch (error: any) {
      setShowToastFail(true);
      setToastMessage(
        Array.isArray(error.data.message)
          ? error.data.message[0]
          : error.data.message
      );
      setTimeout(() => {
        setShowToastFail(false);
      }, 3000);
      setSaveLoading(false);
    }
  };

  const onUpdate = async () => {
    // onStarttiemHandlerM(startTime!);
    // onEndtimeHandlerM(endTime!);
    setSaveLoading(true);
    try {
      console.log("START DATE IS ", startDate);
      console.log("END DATE IS ", endDate);
      await updateExperience({
        _id: experienceDtails?._id!,
        title: title!,
        subTitle: subTitle!,
        description: description!,
        image: image!,
        autoComplete: autoCompletion,
        button_link_url: buttonLinkUrl,
        category: category,
        completionType: completionType!,
        educationalPhases: "HS-9",
        endDate: endDate!,
        endExpireDate: endDate!,
        experienceType: experienceType!,
        gem: gem,
        link_url: textUrl,
        organization: organization!,
        project: project!,
        startDate: startDate!,
        subCategory: subCategory,
        tags: {
          driver_one: driverOneSelected,
          driver_two: driverTwoSelected,
        },
        text_url: textLink,
        xpCompletion: xpCompletion!,
        xpView: xpView!,
        notification: notification,
        createDateNotification: createDayNotification,
        daysBefore: daysBeforeNotification,
        daysBeforeNumberDay: daysBeforeNotificationNumber,
        daysOf: daysOfNotification,
        hotSpot_group_id: hotSpot_group_id,
        reSpawn: reSpawn,
        countHotSpot: countHotSpot,
        total_reSpawns: total_reSpawns,
        intervals: intervals,
        weeklyDays: weeklyDays!,
        multiDays: multiDays!,
        dailyTime: dailyTime!,
        dayofMonth: dayofMonth!,
        startDateNotification: startNotification,
        passDueNotification: passDueNotification,
        availableDays: availableDays!,
        respwanNotification: respwanNotification,
        missionRevive: missionRevive,
        donotPassDue: donotPassDue,
        mainHotspot: experienceDtails?.mainHotspot,
      }).unwrap();
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
        setSaveLoading(false);
        navigate("/experience");
      }, 3000);
    } catch (error) {
      setShowToastSuccess(true);
      setTimeout(() => {
        setShowToastSuccess(false);
      }, 3000);
      setSaveLoading(false);
    }
  };

  const onChageNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotification(e.target.checked);
  };

  const onChangeCreateDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateDateNotification(e.target.checked);
  };

  const onChangeDaysBefore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysBeforeNotification(e.target.checked);
  };

  const onChangeDaysBeforeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysBeforeNotificationNumber(Number(e.target.value));
  };

  const onChagneDaysOf = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDaysOfNotification(e.target.checked);
  };

  const onRespwanNotification = () => {
    setRespwanNotification(!respwanNotification);
  };

  return (
    <div style={{ backgroundColor: "#f7f7fa" }}>
      <ExperienceDetailsHeaderContainer
        onSelectHandler={onExperienceSelect}
        experienceType={experienceType}
      />
      <ExperienceDetailsActionContainer
        startDate={startDate}
        onStartDateHandler={onStartDateHandler}
        startTime={startTime}
        onStartTimeHandler={onStartTimeHandler}
        endDate={endDate}
        onEndDateHandler={onEndDateHandler}
        onEndTimeHandler={onEndTimeHandler}
        endTime={endTime}
        project={project}
        category={category}
        subCategory={subCategory}
        onCategoryHandler={onCategoryHandler}
        onSubCategroyHandler={onSubCategoryHandler}
        publish={publish}
      />
      <ExperienceDetailsInfoContainer
        expType={experienceType!}
        title={title}
        id={experienceDtails?._id!}
        onTitleHandler={onTitleHandler}
        subTitle={subTitle}
        onSubTitleHandler={onSubTitleHandler}
        description={description}
        onDescriptionHandler={onDescriptionHandler}
        image={image}
        onImageHandler={onImageHandler}
        completionType={completionType}
        onCompletionTypeHandler={onCompletionTypeHandler}
        onXpCompletionHandler={onHandlerXpCompletion}
        xpCompletion={xpCompletion}
        xpView={xpView}
        onXpViewHandler={onXpViewHandler}
        gem={gem}
        onGemHandler={onGemHandler}
        onSelectCompletionType={onCompletionTypeHandler}
        autoCompletion={autoCompletion}
        onAutoCompletion={onAutoCompletionHandler}
        addLink={addLink}
        onAddLinkHandler={onAddLinkHanlder}
        textLink={textLink}
        onTextLinkHanlder={onTextLinkHanlder}
        texturl={textUrl}
        onTextUrlHandler={onTextUrl}
        onButtonLink={onButtonLinkUrlHandler}
        buttonLink={buttonLinkUrl}
        driverOneItems={driverOneItems}
        driverTwoItems={driverTwoItems}
        driverOneSelected={driverOneSelected}
        onDriverOneSelected={onDriverOneSelected}
        driverTwoSelected={driverTwoSelected}
        onDriverTwoSelected={onDriverTwoSelectd}
        onPublish={onPublish}
        onSave={onSave}
        isUpdated={isUpdated}
        publish={publish}
        onPublishUpdate={onPublishUpdate}
        onSaveUpdate={onSaveUpdate}
        onRemoveCard={onRemoveCard}
        onUpdate={onUpdate}
        onNotification={onNotification}
        onNotificationHandler={onNotificationHandler}
        saveLoading={saveLoading}
        notification={notification!}
        createDayNotification={createDayNotification}
        norificationDaysBefore={daysBeforeNotification}
        notificationDaysBeforeNumber={daysBeforeNotificationNumber!}
        notificationDaysOf={daysOfNotification}
        onChangeCreateDaysNotification={onChangeCreateDate}
        onChangeDaysBefore={onChangeDaysBefore}
        onChangeDaysBeforeNumber={onChangeDaysBeforeNumber}
        onChangeDaysOf={onChagneDaysOf}
        onChangeNotification={onChageNotification}
        onStartDateNotification={onStarDateNotification}
        startDateNotification={startNotification}
        midNotification={midNotification}
        onMidNotification={onMidNotification}
        onPassDueNotification={onPassDueNotification}
        passDueNotification={passDueNotification}
        countHotSpot={countHotSpot}
        intervals={intervals}
        onCountHotSpot={onCountHotSpot}
        onInterval={onInterval}
        onReSpawn={onReSpawn}
        onTotalReSpawns={onTotalReSpawns}
        onWeeklyDays={onWeeklyDays}
        reSpawn={reSpawn}
        total_reSpawns={total_reSpawns}
        weeklyDays={weeklyDays}
        multiDays={multiDays}
        onMultiDays={onMultiDays}
        onDaylitime={onDaylitime}
        dailyTime={dailyTime}
        dayofMonth={dayofMonth}
        onDayOfMonthly={onDayOfMonthly}
        availableDays={availableDays}
        onAvailableDays={onAvailableDays}
        respwanNotification={respwanNotification}
        onRespwanNotification={onRespwanNotification}
        missionRevive={missionRevive}
        onMissionRevive={onMissionRevive}
        onDonotPassDue={onDonotPassDue}
        doNotPassDue={donotPassDue}
      />
      <ExperienceImageDialogPresentation
        openImage={openImage}
        onCloseImage={onCloseImage}
        images={experienceType?.expImages!}
        onImageCardHandler={onImageCardHandler}
      />

      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Experience Save Successfuly" typeToast="Success" />
        </div>
      )}
    </div>
  );
};

export default ExperienceMainDetailsContainer;
