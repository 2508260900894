import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

import { ExperienceType } from "../types/types";

const experienceTypeAdaptor = createEntityAdapter<ExperienceType>({
  selectId: (experienceType) => experienceType._id,
});

interface ExperienceTypeInitialState extends EntityState<ExperienceType> {}

const initialState: ExperienceTypeInitialState =
  experienceTypeAdaptor.getInitialState();

export const extendedExperienceTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllExperienceTypes: builder.query<EntityState<ExperienceType>, void>({
      query: () => `/experience-type`,
      transformResponse: (response: ExperienceType[]) => {
        console.log('experience type images',response)
        return experienceTypeAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        { type: "Experience", id: "LIST" },
        ...result!.ids.map((id) => ({ type: "Experience" as const, id })),
      ],
    }),
  }),
});

export const { useGetAllExperienceTypesQuery } = extendedExperienceTypeApiSlice;
