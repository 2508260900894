import React from "react";
import "./experienceReview.css";
import {
  calculatePercent,
  calulateCircleChartPercent,
} from "../../../../utils/utils";
import CircleChart from "../../../../components/circleChart/CircleChart";
import { Analytic } from "../../../../types/types";
type ExperienceReviewAnalyisPresentationPorps = {
  analytics: Analytic;
};
const ExperienceReviewAnalyisPresentation: React.FC<
  ExperienceReviewAnalyisPresentationPorps
> = ({ analytics }) => {
  return (
    <>
      <div className="review-analyis">
        <div className="analyis-header">
          <div className="completion-rate">
            <p>Completion</p>
            {/* <p>Rate</p> */}
          </div>
          <div className="cirlce-chart">
            <CircleChart    
              scale={0.55}
              left={-30}
              top={-62}
              message=""
              percent={calculatePercent(
                analytics.totalCompleted,
                analytics.totalNotcompleted + analytics.totalView
              )}
              chartPercentCal={calulateCircleChartPercent(
                495,
                calculatePercent(
                  analytics.totalCompleted,
                  analytics.totalNotcompleted + analytics.totalView
                )
              )}
            />
          </div>
        </div>
        <div className="counter-student-container">
          <div className="total-student">
            <p>Total Students</p>
            <p>{analytics.totalNotcompleted + analytics.totalView}</p>
          </div>
          <div className="compelete-ex">
            <p className="complete-ex-text">Compelete</p>
            <p className="complete-ex-value">{analytics.totalCompleted}</p>
            <div className="complete-ex-liner-container">
              <div
                className="compelte-ex-liner-chart"
                style={{
                  width:
                    calculatePercent(
                      analytics.totalCompleted,
                      analytics.totalNotcompleted + analytics.totalView
                    ).toString() + "%",
                }}
              ></div>
            </div>
          </div>
          <div className="not-compeleted-ex">
            <p className="complete-ex-text">Not Compelete</p>
            <p className="complete-ex-value">{analytics.totalNotcompleted}</p>
            <div className="complete-ex-liner-container">
              <div
                className="not-compelte-ex-liner-chart"
                style={{
                  width:
                    calculatePercent(
                      analytics.totalNotcompleted,
                      analytics.totalNotcompleted + analytics.totalView
                    ).toString() + "%",
                }}
              ></div>
            </div>
          </div>
          <div className="view-ex">
            <p className="complete-ex-text">Viewed</p>
            <p className="complete-ex-value">{analytics.totalView}</p>
            <div className="complete-ex-liner-container">
              <div
                className="view-ex-liner-chart"
                style={{
                  width:
                    calculatePercent(
                      analytics.totalView,
                      analytics.totalNotcompleted + analytics.totalView
                    ).toString() + "%",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceReviewAnalyisPresentation;
