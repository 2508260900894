import React from "react";
import StudentActionPresentation from "../../presantation/studentActionPresentation/StudentActionPresentation";
import { StudentActionContainerProps } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";

const StudentActionContainer: React.FC<StudentActionContainerProps> = ({
  onOpenHandler,
  onSetTerm,
  gemAmount,
  onAddAward,
  onGemAmount,
  onXpAmount,
  onXpDescripton,
  xpAmount,
  xpDescriptoin,
  onCloseAward,
  onOpenAward,
  openAward,
  term,
  descriptionError,
}) => {
  return (
    <StudentActionPresentation
      onOpenHandler={onOpenHandler}
      term={term!}
      onSetTerm={onSetTerm}
      gemAmount={gemAmount!}
      onAddAward={onAddAward!}
      onGemAmount={onGemAmount!}
      onXpAmount={onXpAmount!}
      onXpDescripton={onXpDescripton!}
      xpAmount={xpAmount!}
      xpDescriptoin={xpDescriptoin!}
      openAward={openAward}
      onCloseAward={onCloseAward}
      onOpenAward={onOpenAward}
      descriptionError={descriptionError}
    />
  );
};

export default StudentActionContainer;
