import { apiSlice } from "../../../app/api/apiSlice";
import { AuthAdmin } from "../types/auth.types";
interface ILoginParam {
  email: string;
  password: string;
}
export const extendApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<Promise<AuthAdmin>, ILoginParam>({
      query: (loginparam: ILoginParam) => ({
        url: "/authadmin/login",
        credentials: "include",

        method: "POST",
        body: {
          ...loginparam,
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = extendApiSlice;
