import styled from "@emotion/styled";

export const CreateRaffleFormCol = styled.div<{
  alignment?: string;
  Direction?: string;
}>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px;
`;

export const CreateRaffleFormRow = styled.div`
  width: 100%;
  display: flex;
  padding-top: 1px;
`;

export const LabelRaffle = styled.label`
  font-size: 15px;
  color: #707070;
`;

export const SearchRaffleContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
`;

export const RaffleContentContainer = styled.div`
 
  padding-top: 2px;
  max-width: 1720px;
  @media (max-width: 1770px) {
  }
`;

export const RaffleButton = styled.button<{
  spaceLeft?: string;
}>`
  background: #34c38f;
  color: white;
  border: none;
  padding: 10px 17px 10px 14px;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};

  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 165px;
  font-weight: 400;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
    width: 80%;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const SearchRaffle = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ced4da;
  padding-left: 35px;
  text-indent: 10px;

  font-size: 13px;

  &:focus {
    outline: none;
  }
`;

export const RaffleHeaderControle = styled.div`
  width: 95%;
  display: flex;
  margin-left: 50px;
  margin-bottom: -20px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1770px) {
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;

export const RaffleContainer = styled.div`
    width: 90%;
  background: white;
  min-height: calc(100vh - 238px);
  margin-bottom: 30px;
  padding: 10px 0;
  margin-left: 50px;
  
`;

export const PrizeRaffleButton = styled.button<{
  spaceLeft?: string;
}>`
  background: #34c38f;
  color: white;
  border: none;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 210px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 15px;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
