import { Table, TableCell, TableRow } from "@mui/material";
import React from "react";
import { FaRegChartBar } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { StudentButton } from "../expereince.list.presentation/experienceListPresentation.styles";
import { ExperienceCard } from "../../types/types";
import { EntityId } from "@reduxjs/toolkit";
type ExperienceHotSpotSubListPresentationProp = {
  item: ExperienceCard;
  onEdit: (id: EntityId) => void;
  onReview: (id: EntityId) => void;
};
const ExperienceHotSpotSubListPresentation: React.FC<
  ExperienceHotSpotSubListPresentationProp
> = ({ item, onEdit, onReview }) => {
  return (
    <TableRow>
      <TableCell style={{ border: "none" }}></TableCell>
      <TableCell>
        <img
          style={{
            width: 50,
            height: 50,
            borderRadius: 50,
          }}
          src={item.image}
          alt=""
        />{" "}
      </TableCell>
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.description}</TableCell>
      <TableCell>
        <div>{new Date(item.startDate).toLocaleDateString()}</div>
        <div>{new Date(item.endDate).toLocaleDateString()}</div>
      </TableCell>
      <TableCell> - </TableCell>
      <TableCell>{item.xpCompletion}</TableCell>
      <TableCell>{item.experienceType.title}</TableCell>
      <TableCell>{item.completionType.title}</TableCell>
      <TableCell style={{ overflow: "visible" }}>
        <div style={{ display: "flex" }}>
          {/* <StudentButton
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 5,
              marginLeft: 2,
            }}
            onClick={() => {
              onEdit(item._id);
            }}
            spaceLeft={""}
          >
            <span
              className="experience-item-button"
              style={{ paddingRight: 5 }}
            >
              Edit
            </span>
            <MdModeEdit />
          </StudentButton> */}
          <StudentButton
            style={{ display: "flex", alignItems: "center", marginLeft: 3 }}
            onClick={() => {
              onReview(item._id);
            }}
            spaceLeft={""}
          >
            <span
              className="experience-item-button"
              style={{ paddingRight: 5 }}
            >
              Review
            </span>
            <FaRegChartBar />
          </StudentButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ExperienceHotSpotSubListPresentation;
