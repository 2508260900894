import React, { useEffect, useState } from "react";
import { Student, StudentSystemType } from "../../types/types";
import { Category } from "../../../category/types/types";
import StudentSystemListPresentation from "../../presantation/student.system.list.presentation/StudentSystemListPresentation";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { accountSelected, setAccountSelected } from "../../slice/studentSlice";
import {
  CreateStudentFormRow,
  CreateStudentFormCol,
  LabelStudent,
} from "../../presantation/student.details.info.presentation/student.details.info.style";
import SelectField from "../../../../components/design/selectFiled/selectField";
type StudentSystemListContainerProps = {
  student: Student;
};
const StudentSystemListContainer: React.FC<StudentSystemListContainerProps> = ({
  student,
}) => {
  const defaultAccount = useAppSelector(accountSelected);

  const dispatch = useAppDispatch();
  const [account, setAccount] = useState<Partial<StudentSystemType>>();
  const [systems, setSystems] = useState<StudentSystemType[]>([]);
  useEffect(() => {
  

    if (student) {
       if (!defaultAccount) {
        console.log('def',defaultAccount);
        const systemCategories: StudentSystemType[] = [];
        const systemCategory: StudentSystemType[] = [];
        student?.system.forEach((item) => {
          const findCategory = student!.systemCategories!.find(
            (cate) => cate._id === item.category && cate.displayProject === true
          );
          if (findCategory) {
            systemCategories.push({ ...item, category: findCategory });
          } else {
            systemCategory.push({ ...item, categories: [] });
          }
        });

        const categories: Category[] = systemCategories.map(
          (item) => item.category
        ) as Category[];

        let system = [];

        if (!!systemCategories[0]?.project) {
          system = [
            {
              ...systemCategories[0],
              categories: categories!,
            },
            ...systemCategory,
          ];
        } else {
          system = [...systemCategory];
        }
       
        if (student.systemCategories?.length === 0) {
          
        }
     
        console.log('deafult systemssssssssss',system);
        setSystems(system);
        dispatch(setAccountSelected(system[0]));
      
        setAccount(system[0]);
      }else{
          console.log('def',defaultAccount);
        const systemCategories: StudentSystemType[] = [];
        const systemCategory: StudentSystemType[] = [];
        student?.system.forEach((item) => {
          const findCategory = student!.systemCategories!.find(
            (cate) => cate._id === item.category && cate.displayProject === true
          );
          if (findCategory) {
            systemCategories.push({ ...item, category: findCategory });
          } else {
            systemCategory.push({ ...item, categories: [] });
          }
        });

        const categories: Category[] = systemCategories.map(
          (item) => item.category
        ) as Category[];

        let system = [];

        if (!!systemCategories[0]?.project) {
          system = [
            {
              ...systemCategories[0],
              categories: categories!,
            },
            ...systemCategory,
          ];
        } else {
          system = [...systemCategory];
        }
       
        if (student.systemCategories?.length === 0) {
          
        }
     
        console.log('deafult systemssssssssss',system);
        setSystems(system);
        // dispatch(setAccountSelected(defaultAccount));
      
        // setAccount(defaultAccount);
      }
    }
  }, [
         student,
          defaultAccount,   
          account     
        
  ]);

  const OnSelectAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentSystem = systems.find((item) => item._id === e.target.value);

    setAccount(currentSystem);
    dispatch(setAccountSelected(currentSystem));
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
       {systems && (
          <StudentSystemListPresentation
          systems={systems}
          student={student}
          OnSelectAccount={OnSelectAccount}
          accountActive={account!}
        />
       )} 
       
      </div>
    </>
  );
};

export default StudentSystemListContainer;
