import React, { FC, useState } from "react";
import { Organization } from "../../../organization/types/types";
import { SchoolDistrict } from "../../../schoolDistrict/types/types";
import { University } from "../../../unviersity/types/types";
import ProjectAddPresentation from "../../presentation/project.add.presentation/project.add.presentation";
import { ProjectAddContainerProp } from "../../types/types";
import {
  useAddNewProjectMutation,
  useEditProjectMutation,
} from "../../api/projectApiSlice";
const ProjectAddContainer: FC<ProjectAddContainerProp> = ({
  openProjectDialog,
  handleClose,
  handleOpen,
  defaultOrganization,
  actionStatus,
  project,
  refetch,
}) => {
  const [addNewProject, { isLoading, isError, isSuccess }] =
    useAddNewProjectMutation();
  const [
    editProject,
    {
      isLoading: isProjectEditLoading,
      isError: isProjectEditError,
      isSuccess: isProjectEditSuccess,
    },
  ] = useEditProjectMutation();
  const [image, setImage] = useState<string>(
    actionStatus === "Edit"
      ? project!.image
      : "https://api.gradify.app/static/download.ff.png"
  );
  const [title, setTitle] = useState<string>(
    actionStatus === "Edit" ? project!.title : ""
  );
  const [projectType, setProjectType] = useState<
    | "Secondary"
    | "Post Secondary"
    | "Special Project"
    | "Other-Secondary"
    | "Other-Post Project"
    | "Event"
  >(actionStatus === "Edit" ? project!.project_type : "Secondary");
  const [projectSchoolDistrict, setProjectSchoolDistrict] =
    useState<Partial<SchoolDistrict> | null>(
      actionStatus === "Edit" ? project!.schoolDistrict : null
    );
  const [projectUniversity, setProjectUniversity] =
    useState<Partial<University> | null>(null);
  const [condition, setCondition] = useState<"Editable" | "Locked" | "Default">(
    actionStatus === "Edit" ? project!.condition : "Editable"
  );
  const [status, setStatus] = useState<"Active" | "Stasis" | "Completed">(
    actionStatus === "Edit" ? project!.status : "Active"
  );
  const [rewardSystem, setRewardSystem] = useState<boolean>(
    actionStatus === "Edit" ? project!.reward_system : true
  );
  const [surveySystem, setSurveySystem] = useState<boolean>(
    actionStatus === "Edit" ? project!.survey_system : true
  );
  const [localizedReward, setLocalizedReward] = useState<boolean>(
    actionStatus === "Edit" ? project!.localized_reward : false
  );
  const [localizedSurvey, setLocalizedSurvey] = useState<boolean>(
    actionStatus === "Edit" ? project!.localized_survey : false
  );
  const [chartView, setChartView] = useState<"Academic" | "Standard">(
    actionStatus === "Edit" ? project!.chartView : "Academic"
  );
  const [organizations, setOrganization] = useState<Partial<Organization>[]>(
    actionStatus === "Edit" ? project!.organizations : [defaultOrganization!]
  );
  const [openColl, setOpenColl] = useState<boolean>(false);

  const onOpenColl = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setOpenColl(true);
    } else {
      setOpenColl(false);
    }
  };

  const onChangeImageHandler = (url: string) => {
    setImage(url);
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onChangeProjectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProjectType(
      e.target.value as
        | "Secondary"
        | "Post Secondary"
        | "Special Project"
        | "Other-Secondary"
        | "Other-Post Project"
    );
  };

  const onChangePorjectSchoolDistrict = (
    newSchoolDistrict: Partial<SchoolDistrict>
  ) => {
    setProjectSchoolDistrict(newSchoolDistrict);
  };

  const onChangeCondition = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCondition(e.target.value as "Editable" | "Locked");
  };

  const onChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value as "Active" | "Stasis" | "Completed");
  };

  const onChangeSetRewardSystem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setRewardSystem(true);
    } else {
      setRewardSystem(false);
    }
  };

  const onChangeSurveySystem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setSurveySystem(true);
    } else {
      setSurveySystem(false);
    }
  };

  const onChangeLocalizeRewardSystem = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalizedReward(e.target.checked);
  };

  const onChangeUniversiytProject = (university: Partial<University>) => {
    setProjectUniversity(university);
  };

  const onChangeLocalizedSurvey = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalizedSurvey(e.target.checked);
  };

  const onChangeChartView = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setChartView(e.target.value as "Academic" | "Standard");
  };

  const onAddOrganizationCollaborative = (
    organization: Partial<Organization>
  ) => {
    const existOrganization = organizations.find(
      (org: Partial<Organization>) => organization._id === org._id
    );
    if (!existOrganization) {
      setOrganization([...organizations, organization]);
    }
  };

  const onRemoveOrganizationCollabrotive = (
    organization: Partial<Organization>
  ) => {
    const updateOrganizations = organizations.filter(
      (orga: Partial<Organization>) => orga._id !== organization._id
    );
    setOrganization(updateOrganizations);
  };

  const canSave = title && !isLoading && !isProjectEditLoading;
  const createProjectHandler = async () => {
    try {
      if (canSave) {
        await addNewProject({
          title,
          project_type: projectType!,
          condition,
          schoolDistrict: projectSchoolDistrict!,
          status,
          reward_system: rewardSystem,
          localized_reward: localizedReward,
          survey_system: surveySystem,
          localized_survey: localizedSurvey,
          image,
          organizations: organizations!,
          university: projectUniversity!,
          chartView: chartView,
        }).unwrap();

        setImage("https://api.gradify.app/static/download.ff.png");
        setTitle("");
        setProjectType("Secondary");
        setProjectSchoolDistrict(null);
        setProjectUniversity(null);
        setCondition("Editable");
        setStatus("Active");
        setRewardSystem(true);
        setSurveySystem(true);
        setLocalizedReward(true);
        setLocalizedSurvey(true);
        setChartView("Academic");
        setOrganization([defaultOrganization!]);
        refetch();

        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateProjectHandler = async () => {
    try {
      if (canSave) {
        await editProject({
          _id: project!._id,
          title,
          project_type: projectType!,
          condition,
          schoolDistrict: projectSchoolDistrict!,
          status,
          reward_system: rewardSystem,
          localized_reward: localizedReward,
          survey_system: surveySystem,
          localized_survey: localizedSurvey,
          image,
          organizations: organizations!,
          university: projectUniversity!,
          chartView: chartView,
        }).unwrap();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProjectAddPresentation
      openProjectDialog={openProjectDialog}
      handleClose={handleClose}
      handleOpen={handleOpen}
      defaultImage={image}
      onChangeProjectImage={onChangeImageHandler}
      onChangeSchoolDistrict={onChangePorjectSchoolDistrict!}
      projectType={projectType!}
      onChangeProjectType={onChangeProjectType!}
      onChangeUniversiytProject={onChangeUniversiytProject!}
      condition={condition!}
      onChangeCondition={onChangeCondition}
      status={status!}
      onChangeStatus={onChangeStatus!}
      rewardSystem={rewardSystem}
      onChangeSetRewardSystem={onChangeSetRewardSystem}
      localizedReward={localizedReward}
      onChangeLocalizeRewardSystem={onChangeLocalizeRewardSystem}
      surveySystem={surveySystem}
      onChangeSurveySystem={onChangeSurveySystem}
      localizedSurvey={localizedSurvey}
      onChangeLocalizedSurvey={onChangeLocalizedSurvey}
      chartView={chartView}
      onChangeChartView={onChangeChartView}
      title={title}
      onChangeTitle={onChangeTitle!}
      organizations={organizations}
      onAddOrganizationCollaborative={onAddOrganizationCollaborative}
      onRemoveOrganizationCollabrotive={onRemoveOrganizationCollabrotive}
      createProjectHandler={createProjectHandler}
      actionStatus={actionStatus}
      updateProjectHandler={updateProjectHandler}
      openColl={openColl}
      onOpenColl={onOpenColl}
      schoolDistrictId={projectSchoolDistrict}
    />
  );
};

export default ProjectAddContainer;
