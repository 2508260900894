import React from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { selectOrganizationById } from "../../../api/orgApiSlice";
import OrganizationDetailsActionPresentation from "../../../presantation/organization.details.presentation/organization.details.action.presentation/organization.details.action.presentation";
import { OrganizationDetailsActionContainerProps } from "../../../types/types";
const OrganizationDetailsActionContainer: React.FC<
  OrganizationDetailsActionContainerProps
> = ({ organization, handleOpen }) => {

  return (
    <OrganizationDetailsActionPresentation
      organization={organization}
      handleOpen={handleOpen}
    />
  );
};

export default OrganizationDetailsActionContainer;
