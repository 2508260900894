import React, { useEffect, useState } from "react";
import HomeProjectAllPresentation from "../../presentation/HomeProjectAllPresentation/HomeProjectAllPresentation";
import { HomeProjectAllContainerProps } from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  selectAllProject,
  useGetAllProjectByOrganizationQuery,
  useLazyGetAllProjectByOrganizationQuery,
} from "../../../project/api/projectApiSlice";
import { EntityState } from "@reduxjs/toolkit";
import { Project } from "../../../project/types/types";
import { setProject } from "../../appSlice/appslice";
import { useNavigate } from "react-router-dom";
import { useAddRecentMutation } from "../../api/apiSlice";
import Loading from "../../../../components/Loading/Loading";

const HomeProjectAllContainer: React.FC<HomeProjectAllContainerProps> = ({
  organizationID,
  term,
  onTerm,
  projects,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [getAllProjectByOrganization, { isLoading, isError, isSuccess }] =
    useLazyGetAllProjectByOrganizationQuery();

  const [
    addRecent,
    {
      isLoading: recentLoading,
      isError: recentError,
      isSuccess: recentSuccess,
    },
  ] = useAddRecentMutation();
  const [projectsState, setProjectState] =
    useState<EntityState<Project> | null>(null);
  useEffect(() => {
    console.log("Projects", projects);
    setProjectState(projects);
  }, [projects]);
  useEffect(() => {
    (async () => {
      if (organizationID) {
        try {
          const projects = await getAllProjectByOrganization({
            organizationId: organizationID,
            term: term,
          }).unwrap();
          setProjectState(projects!);
        } catch (error) {}
      }
    })();
  }, [organizationID, term]);

  const onSelectProject = async (project: Partial<Project> | null) => {
    try {
      await addRecent({
        organization: organizationID as any,
        project: project!,
      }).unwrap();
      dispatch(setProject(project));
      navigation("/student");
    } catch (error) {}
  };

  let content;
  if (isLoading || recentLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <h1>SomeThing Went Wrong</h1>;
  } else if (isSuccess || projects?.ids?.length! > 0) {
    content = (
      <HomeProjectAllPresentation
        project={projectsState!}
        onSelectProject={onSelectProject}
      />
    );
  }

  return <>{content}</>;
};

export default HomeProjectAllContainer;
