import React, { useState } from "react";
import { Student, StudentSystemType } from "../../students/types/types";
import {
  useGetAllExperienceStudentQuery,
  useUpdateExperienceStudentMutation,
} from "../api/experienceForStudentApi";
import ExpereinceForStudentPresentation from "../presentation/ExpereinceForStudentPresentation";
import { useAppSelector } from "../../../app/hooks";
import { accountSelected } from "../../students/slice/studentSlice";
import { ExperienceCard } from "../../experience/types/types";
import Loading from "../../../components/Loading/Loading";
type ExperienceForStudentcontainerProps = {
  student: Student;
};
const ExperienceForStudentcontainer: React.FC<
  ExperienceForStudentcontainerProps
> = ({ student }) => {
  const account = useAppSelector(accountSelected);
  console.log("reload exstudent");
  const {
    data: expList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllExperienceStudentQuery(
    {
      currentSystem: JSON.stringify(account),
      studentId: student?._id as string,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  console.log("Error", isError);
  const [
    updateExperience,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateExperienceStudentMutation();

  const onChangeComplete = async (experience: ExperienceCard) => {
    console.log("complete", experience.completion?.complete);
    try {
      await updateExperience({
        experience: experience,
        student: student,
        complete: !experience.completion?.complete,
        currentSystem: account!,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  let content;
  if (updateLoading || isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <ExpereinceForStudentPresentation
        explist={expList!}
        onChangeComplete={onChangeComplete}
      />
    );
  }
  return <>{content}</>;
};

export default ExperienceForStudentcontainer;
