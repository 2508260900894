import styled from "@emotion/styled";

export const UploadImageStyle = styled.img`
  width: 103px;
  height: 103px;
  border-radius: 50px;
  object-fit: contain;
`;

export const UploadImageStyleShop = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;
export const AdminHexImage = styled.img`
  position: absolute;
     top: -24px;
    left: -26px;
`;

export const ImageUploadEditButtton = styled.button`
  display: flex;
  height: 32px;
  border-radius: 4px;
  width: 60px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  position: absolute;
  right: -25px;
  transition-duration:300ms;
  top: 10px;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const ImageUploadEditButttonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const ImageUploadEditButttonIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

interface IImageUploadWarpper {
  alignment?: string;
  Direction?: string;
  shop?: boolean;
}

export const ImageUPloadWrapper = styled.div<IImageUploadWarpper>`
  position: relative;
  display:flex;
  width: ${({ shop }) => (shop ? "100%" : "70%")};
`;
