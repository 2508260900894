import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Layout from "./components/layout/Layout";
import Login from "./features/auth/pages/Login";
import RequierAuth from "./features/auth/utils/RequierAuth";
import OrganizationDetailsPage from "./features/organization/pages/organization.details/organization.details.page";
import OrganizationPage from "./features/organization/pages/OrganizationPage";
import ProjectDetailsPage from "./features/project/pages/project.details.page/project.details.page";
import AdminPage from "./features/admin/pages/admin.page";
import AdminDetailsPage from "./features/admin/pages/admin.details.page";
import StudentPage from "./features/students/pages/StudentPage";
import HomePage from "./features/home/pages/HomePage";
import ExperienceMainPage from "./features/experience/pages/ExperienceMainPage";
import ExperienceDetailsPage from "./features/experience/pages/ExperienceDetailsPage";
import ExperienceReviewPage from "./features/experience/pages/ExperienceReviewPage";
import SettingPage from "./features/setting/pages/SettingPage";
import ShopPage from "./features/shop/page/ShopPage";
import ShopDetailsPage from "./features/shop/page/ShopDetailsPage";
import RaffelTicketPage from "./features/raffelTickets/page/RaffelTicketPage";
import RaffelTicketDetailsPage from "./features/raffelTickets/page/RaffelTicketDetailsPage";
import StudentDetailsPage from "./features/students/pages/StudentDetailsPage";
//route system setting
function App() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route element={<RequierAuth />}>
        <Route element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="organization">
            <Route index element={<OrganizationPage />} />
            <Route
              path=":organizationId"
              element={<OrganizationDetailsPage />}
            />
          </Route>

          <Route path="admin">
            <Route index element={<AdminPage />} />
            <Route path=":adminId" element={<AdminDetailsPage />} />
          </Route>

          <Route path="project">
            <Route path=":projectId" element={<ProjectDetailsPage />} />
          </Route>

          <Route path="student">
            <Route index element={<StudentPage />} />
            <Route path=":id" element={<StudentDetailsPage />} />
          </Route>
          <Route path="experience">
            <Route index element={<ExperienceMainPage />} />
            <Route path="expdetails">
              <Route index element={<ExperienceDetailsPage />} />
              <Route path=":status" element={<ExperienceDetailsPage />} />
            </Route>
            <Route path="expReview">
              <Route path=":id" element={<ExperienceReviewPage />} />
            </Route>
          </Route>
          <Route path="setting">
            <Route index element={<SettingPage />} />
          </Route>
          <Route path="shop">
            <Route index element={<ShopPage />} />
            <Route path=":id" element={<ShopDetailsPage />} />
          </Route>
          <Route path="raffelticket">
            <Route index element={<RaffelTicketPage />} />
            <Route path=":id" element={<RaffelTicketDetailsPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
