import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import React, { FC } from "react";
import { FaWindowClose } from "react-icons/fa";
import { SelectFieldStyle } from "../../../../components/design/selectFiled/selectFied.styles";
import ShowListInTagFormat from "../../../../components/showListInTagFormat/ShowListInTagFormat";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import SelectOrganizationListContainer from "../../../organization/container/select.organization.list.container/select.organization.list.container";
import {
  CreateOrganizationContainer,
  CreateOrganizationForm,
  CreateOrganizationFormRow,
  CreateOrganizationFormCol,
  OrganizationInput,
  LabelOrganization,
  OrgainzationsListContainer,
  OrgainzationsListProject,
  OrgainzationsListCloseProject,
  OrganizationButon,
} from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import SearchSchoolDistrictContainer from "../../../schoolDistrict/searchSchoolDistrict/search.schoolDestict.container";
import SearchUniversityContainer from "../../../unviersity/container/university.container";
import { ProjectAddPresentationProps } from "../../types/types";
const ProjectAddPresentation: FC<ProjectAddPresentationProps> = ({
  handleClose,
  handleOpen,
  openProjectDialog,
  defaultImage,
  onChangeProjectImage,
  onChangeSchoolDistrict,
  projectType,
  onChangeProjectType,
  onChangeUniversiytProject,
  condition,
  onChangeCondition,
  status,
  onChangeStatus,
  rewardSystem,
  onChangeSetRewardSystem,
  localizedReward,
  onChangeLocalizeRewardSystem,
  localizedSurvey,
  onChangeLocalizedSurvey,
  onChangeSurveySystem,
  surveySystem,
  chartView,
  onChangeChartView,
  title,
  onChangeTitle,
  organizations,
  onAddOrganizationCollaborative,
  onRemoveOrganizationCollabrotive,
  createProjectHandler,
  actionStatus,
  updateProjectHandler,
  openColl,
  onOpenColl,
  schoolDistrictId,
}) => {
  console.log("organizations", organizations);
  return (
    <Dialog
      open={openProjectDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CreateOrganizationContainer className="form-Organization-add">
        <CreateOrganizationForm>
          <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
            <CreateOrganizationFormCol
              alignment="flex-start"
              style={{ paddingLeft: 0, position: "relative" }}
              Direction={""}
            >
              <UploadImageContainer
                defaultImage={defaultImage}
                onChangeImageUrlState={onChangeProjectImage}
              />
            </CreateOrganizationFormCol>
            <CreateOrganizationFormCol
              Direction="column"
              style={{ alignItems: "center", width: "100%" }}
              alignment={""}
            >
              <CreateOrganizationFormCol
                Direction="row"
                alignment="sttart"
                style={{ alignItems: "center" }}
              >
                <OrganizationInput
                  Width="485px"
                  Height="39px"
                  FontFamily="Rajdhani"
                  Size="32px"
                  Weight="700"
                  name="ProjectName"
                  placeholder="project title"
                  value={title}
                  onChange={onChangeTitle}
                  SpacingLR={""}
                  SpacingTB={""}
                />
              </CreateOrganizationFormCol>
              <CreateOrganizationFormCol
                Direction="column"
                style={{ paddingLeft: 0, width: 485 }}
                alignment={""}
              ></CreateOrganizationFormCol>
            </CreateOrganizationFormCol>
          </CreateOrganizationFormRow>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                maxWidth: 620,
                flexDirection: "column",
              }}
            >
              <CreateOrganizationFormRow
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginTop: 16,
                  flexWrap: "wrap",
                }}
              >
                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Project Type</LabelOrganization>
                  <SelectFieldStyle
                    name=""
                    id=""
                    onChange={onChangeProjectType}
                    isDisabled={false}
                    defaultValue={projectType!}
                  >
                    <option value="Secondary">Secondary</option>
                    <option value="Post Secondary">Post Secondary</option>
                    <option value="Special Project">Special Project</option>
                    <option value="Other-Secondary">Other-Secondary</option>
                    <option value="Event">Event</option>
                    <option value="Other-Post Project">
                      Other-Post Project
                    </option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
                {projectType == "Secondary" ? (
                  <CreateOrganizationFormCol Direction="column" alignment={""}>
                    <LabelOrganization>School District</LabelOrganization>
                    <SearchSchoolDistrictContainer
                      onChangeState={onChangeSchoolDistrict!}
                      schoolDistrictID={schoolDistrictId}
                    />
                  </CreateOrganizationFormCol>
                ) : projectType === "Post Secondary" ? (
                  <CreateOrganizationFormCol Direction="column" alignment={""}>
                    <LabelOrganization>University Project</LabelOrganization>
                    <SearchUniversityContainer
                      onChangeState={onChangeUniversiytProject!}
                    />
                  </CreateOrganizationFormCol>
                ) : null}

                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Condition</LabelOrganization>
                  <SelectFieldStyle
                    name=""
                    id=""
                    onChange={onChangeCondition}
                    isDisabled={false}
                  >
                    <option value="Editable">Editable</option>
                    <option value="Locked">Locked</option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Status</LabelOrganization>
                  <SelectFieldStyle
                    name=""
                    id=""
                    onChange={onChangeStatus}
                    isDisabled={false}
                  >
                    <option value="Active">Active</option>
                    <option value="Stasis">Stasis</option>
                    <option value="Completed">Completed</option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
                {organizations[0].reward_system && (
                  <CreateOrganizationFormCol Direction="column" alignment={""}>
                    <LabelOrganization>Reward System</LabelOrganization>
                    <SelectFieldStyle
                      name=""
                      id=""
                      onChange={onChangeSetRewardSystem}
                      isDisabled={false}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </SelectFieldStyle>
                  </CreateOrganizationFormCol>
                )}

                {/* <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Localized Rewards</LabelOrganization>
                  <Checkbox
                    color="default"
                    size="small"
                    style={{ width: 36 }}
                    checked={localizedReward}
                    onChange={onChangeLocalizeRewardSystem}
                  />
                </CreateOrganizationFormCol> */}
                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Survey System</LabelOrganization>
                  <SelectFieldStyle
                    name=""
                    id=""
                    onChange={onChangeSurveySystem}
                    isDisabled={false}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
                {/* <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Localized Survey</LabelOrganization>
                  <Checkbox
                    color="default"
                    size="small"
                    style={{ width: 36 }}
                    checked={localizedSurvey}
                    onChange={onChangeLocalizedSurvey}
                    value={""}
                  />
                </CreateOrganizationFormCol> */}
              </CreateOrganizationFormRow>
              <CreateOrganizationFormRow
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginTop: 10,
                  flexWrap: "wrap",
                }}
              >
                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Chart View</LabelOrganization>
                  <SelectFieldStyle
                    onChange={onChangeChartView}
                    isDisabled={false}
                  >
                    <option value="Academic">Academic</option>
                    <option value="Standard">Standard</option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
              </CreateOrganizationFormRow>
            </div>
            <div
              style={{ width: 1, backgroundColor: "#707070", height: 160 }}
            ></div>
            <div style={{ display: "flex", maxWidth: 350 }}>
              <CreateOrganizationFormRow
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                  marginTop: 20,
                  flexWrap: "wrap",
                }}
              >
                <CreateOrganizationFormCol Direction="column" alignment={""}>
                  <LabelOrganization>Collaborative</LabelOrganization>
                  <SelectFieldStyle isDisabled={false} onChange={onOpenColl}>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </SelectFieldStyle>
                </CreateOrganizationFormCol>
                {openColl && (
                  <CreateOrganizationFormCol Direction="column" alignment={""}>
                    <LabelOrganization>Organization</LabelOrganization>
                    <SelectOrganizationListContainer
                      onSelectOrganization={onAddOrganizationCollaborative}
                    />
                  </CreateOrganizationFormCol>
                )}

                <CreateOrganizationFormCol
                  Direction="column"
                  style={{ width: "94%" }}
                  alignment={""}
                >
                  <LabelOrganization>Organizations</LabelOrganization>
                  <OrgainzationsListContainer>
                    <ShowListInTagFormat
                      list={organizations}
                      onRemove={onRemoveOrganizationCollabrotive}
                      unRemovabelFist={true}
                    />
                  </OrgainzationsListContainer>
                </CreateOrganizationFormCol>
              </CreateOrganizationFormRow>
            </div>
          </div>
          <CreateOrganizationFormRow
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 30,
              marginBottom: 30,
              justifyContent: "center",
            }}
          >
            <CreateOrganizationFormCol
              style={{ justifyContent: "flex-end", paddingRight: 10 }}
              alignment={""}
              Direction={""}
            >
              <OrganizationButon
                onClick={async () => {
                  if (actionStatus === "Add") {
                    await createProjectHandler!();
                  } else {
                    await updateProjectHandler!();
                  }
                  handleClose();
                }}
              >
                SAVE
              </OrganizationButon>
            </CreateOrganizationFormCol>
            <CreateOrganizationFormCol
              style={{ paddingLeft: 30 }}
              alignment={""}
              Direction={""}
            >
              <OrganizationButon onClick={handleClose}>
                Cancel
              </OrganizationButon>
            </CreateOrganizationFormCol>
          </CreateOrganizationFormRow>
        </CreateOrganizationForm>
      </CreateOrganizationContainer>
    </Dialog>
  );
};

export default ProjectAddPresentation;
