import React, { FC, useRef } from "react";
import { UploadImagePresentationProps } from "../../../types/types";
import {
  ImageUPloadWrapper,
  ImageUploadEditButtton,
  ImageUploadEditButttonIcon,
  ImageUploadEditButttonText,
  UploadImageStyle,
  UploadImageStyleShop,
  AdminHexImage,
} from "./uploadimage.presentation.styles";
import { Icon } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { MdEdit } from "react-icons/md";

import { images } from "../../../constants";
import { Hexagon } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
const UPloadImagePresentation: FC<UploadImagePresentationProps> = ({
  imageUrl,
  onChagne,
  shop,
  hexShape,
  loading
}) => {
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <>
    {loading ? (
      <Loading />
    ) : (
      <ImageUPloadWrapper shop>
      {shop ? (
        <UploadImageStyleShop src={imageUrl} alt={imageUrl} />
      ) : (
        <UploadImageStyle src={imageUrl} alt={imageUrl} />
      )}
      {hexShape ? <AdminHexImage src={images.hexAsset} alt="gradegy" /> : null}
      {/* add the accept to limit just image can select */}
      <input
        type="file"
        onChange={onChagne}
        style={{ display: "none" }}
        ref={fileRef}
        accept="image/*"
      />
      <ImageUploadEditButtton
        type="button"
        onClick={() => {
          if (fileRef.current) {
            fileRef.current.click();
          }
        }}
      >
        <ImageUploadEditButttonText>Edit</ImageUploadEditButttonText>
        <ImageUploadEditButttonIcon>
          <MdEdit color="#34C38F" size={17} />
        </ImageUploadEditButttonIcon>
      </ImageUploadEditButtton>
    </ImageUPloadWrapper>
    ) }
    
    </>
  );
};

export default UPloadImagePresentation;
