import { createEntityAdapter, EntityId, EntityState } from "@reduxjs/toolkit";
import { Fuses } from "./type";
import { apiSlice } from "../../app/api/apiSlice";

export const fusesAdaptor = createEntityAdapter<Fuses>({
  selectId: (data) => data._id,
});

interface FusesInitialState extends EntityState<Fuses> {}

const initialState: FusesInitialState = fusesAdaptor.getInitialState();

export const fusesApiBuilder = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFuses: builder.query<
      EntityState<Fuses>,
      {
        currentSystem: string;
        student: string;
      }
    >({
      query: (data) =>
        `/fuses/foradmin?currentSystem=${data.currentSystem}&student=${data.student}`,
      transformResponse: (response: any) => {
        console.log("fuses respnse", response);
        return fusesAdaptor.setOne(initialState, response);
      },
      providesTags: ["Fuses"],
    }),
  }),
});

export const { useGetFusesQuery, useLazyGetFusesQuery } = fusesApiBuilder;
