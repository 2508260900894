import React, { useState } from "react";
import {
  Flag,
  HeaderContainer,
  HeaderProfileAction,
  HeaderTitle,
  LayoutHeaderContainer,
  ProfileImage,
  ProfileInfo,
  SettingContainer,
  SettingItem,
} from "./header.styles";
import { HeaderProps } from "../../types/types";
import { AiOutlineSetting } from "react-icons/ai";
import { BiBell } from "react-icons/bi";
import { FaSignOutAlt } from "react-icons/fa";
import { selectAdmin } from "../../features/auth/authSlice/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { loguot } from "../../features/auth/authThunks/authThunks";
import { useNavigate } from "react-router-dom";
const Header: React.FC<HeaderProps> = ({ title }) => {
  const admin = useAppSelector(selectAdmin);
  const dispatch = useAppDispatch();
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const navigation = useNavigate();
  return (
    <HeaderContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <LayoutHeaderContainer spacing={0}>
          {/* <LayoutHeaderContainer spacing={110}>
            <Flag src={require("../../assets/image/flag.png")} />
          </LayoutHeaderContainer> */}
          <LayoutHeaderContainer spacing={2}>
            {/* <BiBell color={"#707070"} size={24} /> */}
          </LayoutHeaderContainer>
          <LayoutHeaderContainer spacing={35}>
            <ProfileImage src={admin?.image} />
            <ProfileInfo>{admin?.fullName}</ProfileInfo>
          </LayoutHeaderContainer>
          <LayoutHeaderContainer
            spacing={10}
            onClick={() => {
              setOpenSetting(!openSetting);
            }}
          >
            <AiOutlineSetting
              color={"#555B6D"}
              className="setting_icon"
              size={24}
            />
            {openSetting ? (
              <SettingContainer>
                <SettingItem
                  onClick={() => {
                    dispatch(loguot());
                    navigation("/login");
                  }}
                >
                  <FaSignOutAlt size={24} style={{ marginRight: 10 }} /> Sign Out
                </SettingItem>
              </SettingContainer>
            ) : null}
          </LayoutHeaderContainer>
      </LayoutHeaderContainer>

    </HeaderContainer>
  );
};

export default Header;
