import React from "react";
import { useGetAllRecentQuery } from "../../api/apiSlice";
import RecentPresentation from "../../presentation/RecentPresentation/RecentPresentation";
import { Project } from "../../../project/types/types";
import { useAppDispatch } from "../../../../app/hooks";
import { setProject } from "../../appSlice/appslice";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { Organization } from "../../../organization/types/types";
import Loading from "../../../../components/Loading/Loading";
type HomeRecentContainerProps = {
  onSelectOrganization: (organization: Partial<Organization | null>) => void;
};
const HomeRecentContainer: React.FC<HomeRecentContainerProps> = ({
  onSelectOrganization,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const {
    data: recents,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllRecentQuery();

  const onSelectProject = (project: Partial<Project> | null) => {
    dispatch(setProject(project));
    navigation("/student");
  };
  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <h1>Something Went Wrong</h1>;
  } else if (isSuccess) {
    content = (
      <RecentPresentation
        recents={recents!}
        onSelectProject={onSelectProject}
        onSelectOrganization={onSelectOrganization}
      />
    );
  }
  return <>{content}</>;
};

export default HomeRecentContainer;
