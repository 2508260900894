import { Paper } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Loading from "../../../../components/Loading/Loading";
import {
  useAddAwardMutation,
  useRemoveAwardMutation,
} from "../../../awards/api/awardsApi";
import ExperienceForStudentcontainer from "../../../experiencesForStudent/container/ExperienceForStudentcontainer";
import { selectedProject } from "../../../home/appSlice/appslice";
import { useGetAStudentByIdQuery } from "../../api/studentApi";
import { accountSelected, setAccountSelected } from "../../slice/studentSlice";
import { Student, StudentProjectReport } from "../../types/types";
import StudentDetailsChartContainer from "../student.details.chart.container/StudentDetailsChartContainer";
import StudentSystemListContainer from "../student.system.list.container/StudentSystemListContainer";
import StudentAddContainer from "../studentAddContainer/StudentAddContainer";
import StudentAwardListContainer from "../studentAwardListContainer/StudentAwardListContainer";
import StudentDetailsGameContainer from "../StudentDetailsGameContainer/StudentDetailsGameContainer";
import StudentInfoDetailsContainer from "../StudentDetailsInfoContainer/StudentInfoDetailsContainer";
type StudentDetailsContainerProps = {
  id: EntityId;
};
const StudentDetailsContainer: React.FC<StudentDetailsContainerProps> = ({
  id,
}) => {
  const account = useAppSelector(accountSelected);
  const projectApp = useAppSelector(selectedProject);
  const dispatch = useAppDispatch();
  const {
    data: student,
    isLoading,
    isError,
    isSuccess,
  } = useGetAStudentByIdQuery(id!);


  const [
    addAward,
    { isLoading: awardLoading, isError: awardError, isSuccess: awardSuccess },
  ] = useAddAwardMutation();

  const [
    removeAward,
    {
      isLoading: removeLoading,
      isError: removeError,
      isSuccess: removeSuccess,
    },
  ] = useRemoveAwardMutation();

  const [projectReport, setProjectReport] =
    useState<StudentProjectReport | null>({
      xps: 0,
      gems: 0,
      notCompletedTasks: 0,
      passDueTasks: 0,
      project: account?.project!,
    });

  const [xpDescription, setXpDescription] = useState<string | null>(null);
  const [xpAmount, setXpAmount] = useState<number | null>(null);
  const [gemAmount, setGemAmount] = useState<number | null>(null);
  const [selectStudents, setSelectStudents] = useState<Student[]>([]);
  const [openAwardPanel, setOpenAwardPanel] = useState<boolean>(false);
  const [openManuallyAwardPanel, setOpenManuallyAwardPanel] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(setAccountSelected(null));
  }, []);

  useEffect(() => {
    if (student) {
      console.log('student active')
      if (account) {
        console.log('active account')
        const projectReport = student.entities[id!]!.projectReport!.find(
          (item) => item.project === account.project
        );
        if (projectReport) {
          setProjectReport(projectReport);
        } else {
          setProjectReport({
            xps: 0,
            gems: 0,
            notCompletedTasks: 0,
            passDueTasks: 0,
            project: account.project,
          });
        }
      }
      setSelectStudents([student.entities[student.ids[0]]!]);
    }
  }, [account, student]);

  const [open, setOpen] = useState<boolean>(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onXpDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpDescription(e.target.value);
  };

  const onXpAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpAmount(Number(e.target.value));
  };

  const onGemAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGemAmount(Number(e.target.value));
  };

  const onAddAwardHandler = async () => {
    console.log("acouunt award",account)
    try {
    const response =   await addAward({
        description: xpDescription!,
        xp: xpAmount! ? xpAmount! : 0,
        gem: gemAmount! ? gemAmount! : 0,
        project: account?.project as any,
        students: selectStudents,
      }).unwrap();
      setGemAmount(0);
      setXpAmount(0);
      setXpDescription("");
      onCloseAward();
    } catch (error) {}
  };

  const onOpenAward = () => {
    setOpenAwardPanel(true);
  };

  const onManuallyOpenAward = () => {
    setOpenManuallyAwardPanel(true);
  };

  const onManuallyCloseAward = () => {
    setOpenManuallyAwardPanel(false);
  };

  const onCloseAward = () => {
    setOpenAwardPanel(false);
  };

  const onRemoveAwardHandler = async (id: EntityId) => {
    try {
      await removeAward(id).unwrap();
    } catch (error) {}
  };

  let content;
  if (isLoading || awardLoading || removeLoading) {
    content = <Loading />;
  } else if (isSuccess && projectReport) {
    content = (
      <>
        <StudentSystemListContainer student={student.entities[id]!} />
        <StudentDetailsChartContainer
          student={student.entities[id]!}
          projectReport={projectReport!}
        />
        <StudentInfoDetailsContainer
          student={student.entities[id]!}
          onOpen={onOpen}
        />
        <StudentDetailsGameContainer
          student={student.entities[id]!}
          projectReport={projectReport!}
          gemAmount={gemAmount!}
          onAddAward={onAddAwardHandler!}
          onGemAmount={onGemAmount!}
          onXpAmount={onXpAmount!}
          onXpDescripton={onXpDescription!}
          xpAmount={xpAmount!}
          xpDescriptoin={xpDescription!}
          openAward={openAwardPanel}
          onCloseAward={onCloseAward}
          onOpenAward={onOpenAward}
        />
        <Paper
          elevation={3}
          style={{
            marginTop: 10,
            borderRadius: 12,
            padding: "25px 22px",
            margin: "10px 35px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ExperienceForStudentcontainer student={student.entities[id]!} />
          <StudentAwardListContainer
            student={student.entities[id]!}
            onRemoveAward={onRemoveAwardHandler}
            gemAmount={gemAmount!}
            onAddAward={onAddAwardHandler!}
            onGemAmount={onGemAmount!}
            onXpAmount={onXpAmount!}
            onXpDescripton={onXpDescription!}
            xpAmount={xpAmount!}
            xpDescriptoin={xpDescription!}
            openAward={openManuallyAwardPanel}
            onCloseAward={onManuallyCloseAward}
            onOpenAward={onManuallyOpenAward}
          />
        </Paper>

        <StudentAddContainer
          student={student.entities[id]!}
          status="Edit"
          onClose={onClose}
          open={open}
        />
      </>
    );
  }

  return <>{content}</>;
};

export default StudentDetailsContainer;
