import {
  EntityAdapter,
  EntityState,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { Team } from "../types/types";
import { apiSlice } from "../../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RootState } from "../../../app/store";

const teamAdaptor = createEntityAdapter<Team>({
  selectId: (team) => team._id,
});

interface TeamInitialState extends EntityState<Team> {}

const initialState: TeamInitialState = teamAdaptor.getInitialState();

export const extendTeamApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTeam: builder.query<EntityState<Team>, void>({
      query: () => `/team`,
      transformResponse: (response: Team[]) => {
        return teamAdaptor.setAll(initialState, response);
      },
      providesTags: (
        result: EntityState<Team> | undefined,
        error: FetchBaseQueryError | undefined,
        arg: void
      ) => {
        return [
          { type: "Team", id: "LIST" },
          ...result!.ids.map((id) => ({
            type: "Team" as const,
            id,
          })),
        ];
      },
    }),
  }),
});

export const { useGetAllTeamQuery } = extendTeamApi;

export const selectTeamResult = extendTeamApi.endpoints.getAllTeam.select();

export const selectedTeamData = createSelector(
  selectTeamResult,
  (teamResult) => teamResult.data
);

export const { selectAll: selectAllTeam } = teamAdaptor.getSelectors(
  (state: RootState) => selectedTeamData(state) ?? initialState
);
