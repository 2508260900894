import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

export interface IExperienceCard {
  backColor: string;
}
export const ExperienceDetailsHeaderWrapper = styled.div`
  width: 100%;
  padding: 18px 30px 0 30px;
`;

export const ExpereinceHeaderRow = styled.div`
  background-color: black;

  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExperienceAdvanceDay = styled.div`
  width: 26px;
  height: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 3px solid #707070;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 400;
  margin-right: 5px;
  margin-top: 5px;
`;

export const ExperienceAdvanceRandom = styled.div`
  padding: 4px 14px;
  border: 3px solid #34c38f;
  border-radius: 20px;
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  &:hover {
    background-color: #34c38f;
    color: white;
    cursor: pointer;
  }
`;

export const ExperienceAdvanceIntervalsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 5px;
  align-items: center;
`;
export const ExperienceHeaderCol = styled.div`
  width: 20%;
  padding: 30px;
`;

export const ExperienceHeaderIconCol = styled.div`
  width: 20%;
  padding: 30px;
  text-align: right;
`;

export const ExperienceHeaderMidCol = styled.div`
  width: 60%;
  padding: 30px;
`;

export const ExperienceHeaderLabel = styled.h1`
  font-size: 72px;
  color: white;
  line-height: 0.5;
  text-align: center;
`;

export const ExperienceHeaderSubtitle = styled.p`
  font-size: 24px;
  color: white;
  text-align: center;
  padding-top: 16px;
  position: relative;
  right: 30px;
`;

export const ExperienceDetailsActionWrapper = styled.div`
  width: 100%;
`;

export const ExperienceDetailsActionRow = styled.div`
  padding: 2px;
  background: #fff;
    width: calc(100% - 62px);
    margin: 0 31px 10px 31px;
    border-radius: 0 0 10px 10px;
    padding-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,0.18);
    
`;

const breatheAnimation = keyframes`
 0% { opacity: 0 ; height: 0; }
 30% { opacity: 0 ; height: 0; }
 40% { opacity: 0 ; height: auto; }
 100% { opacity: 1 ; height: auto; }
`;

const closebox = keyframes`
 0% { opacity: 1 ; height: auto; }

 100% { opacity: 0 ; height: 0; }
`;

export const ExperienceDetailsActionCol = styled.div<{ Direction: string }>`
  display: flex;
  padding: 10px 4px;
  align-items: center;
  flex-direction: ${({ Direction }) => Direction};
  justify-content: flex-end;
  flex-wrap: wrap;
  transition: width 0.2s ease-in;

  .exp-setting.motivational > div {
    animation-name: ${breatheAnimation};
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
  &.boxActive {
    width: 5% !important;

    .exp-setting.motivational > div {
      animation-name: ${closebox};
      animation-duration: 0.01s;
      animation-fill-mode: forwards;
    }
  }
  @media (max-width: 1300px) {
    align-items: flex-start;
  }
`;

export const ExperienceDetailsDateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExperienceStartDateWrapper = styled.div`
  width: 60%;
  padding: 15px;
`;

export const ExperienceEndDateWrapper = styled.div`
  width: 50%;
  padding: 15px;
`;

export const ExperienceDetailsFilterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExperienceDetailsFilterCol = styled.div`
  width: 100%;
  padding: 15px 5px;
`;

export const ExperienceInfoWrapper = styled.div`
  width: 100%;
  padding: 18px 30px;
`;

export const ExperienceInfoRow = styled.div`
  background: #f7f7fa;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
`;

export const ExperienceInfoCol1 = styled.div<IExperienceCard>`
  background: linear-gradient(to bottom, ${({ backColor }) => backColor}, #fff);
  width: 100%;
  max-width: 445px;
  margin-top: -150px;
  position: relative;
  padding-bottom: 11px;
  border-radius: 15px;
  border: 10px solid white;
  display: flex;
  flex-direction: column;
  box-shadow:0 3px 6px rgba(0, 0, 0,0.18);
  @media (max-width: 1500px) {
    margin-top: 0;
  }
`;
export const ExperienceInfoCol2 = styled.div`
  width: 25%;
  height: 500px;
  padding: 15px 30px;
  position: relative;
  top: -130px;
`;
export const ExperienceInfoCol3 = styled.div`
  width: 25%;
  height: 500px;
  padding: 15px 30px;
  position: relative;
  top: -130px;
`;
export const ExperienceInfoCol4 = styled.div`
  width: 25%;
  height: 500px;
  background-color: purple;
`;

export const ExpCardTitle = styled.input`
  width: 100%;
  padding: 24px;
  text-align: center;
  border: none;
  color: white;
  background: transparent;
  font-size: 24px;
  font-weight: 900;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: white;
    font-size: 24px;
  }
`;

export const ExpCardSubTitle = styled.input`
  width: 100%;
  padding: 16px;
  text-align: center;
  border: none;
  color: white;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: white;
    font-size: 20px;
  }
`;

export const DescriptionTextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  text-align: left;
  border: none;
  color: white;
  background: transparent;
  font-size: 14px;
  &::placeholder {
    color: white;
    font-size: 14px;
  }
`;

export const LabelExperience = styled.label`
  font-family: poppins;
  font-size: 13px;
  color: #272727;
  width: 100%;
`;

export const ExperienceCardDetailsInfo = styled.div`
  width: 100%;
  flex: 3;
`;
export const ExperienceCardDetailsAction = styled.div`
  flex: 1;
  width: 100%;
  background: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const ShadowBox = styled.div<{ disable: any }>`
  flex-direction: column;
  width: calc(100% - 10px);
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  align-items: stretch;
  margin: 5px 0;

  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  > div {
    opacity: ${({ disable }) => (disable ? "0.3" : "1")};
  }
`;

export const SearchExperienceImageContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-left: 0px;
  margin-left:50px;
`;

export const SearchExperienceImage = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ced4da;
  padding-left: 35px;
  text-indent: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;

  &:focus {
    outline: none;
  }
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
`;