import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Button, IconButton, Paper, Switch } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import React from "react";
import { FaLock } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import {
  Box,
  Column,
  InnerCol,
  Row,
  SubTitle,
  SwitchLabel,
} from "../../../../assets/style/General.style";
import ShowCanView from "../../../../components/showCanView/ShowCanView";
import FilterByProjectContainer from "../../../project/container/filter.by.project.container/FilterByProjectContainer";
import {
  AdminAccessUniversityPresentationProps,
  CategoryPermissionType,
  ProjectPermissionType,
} from "../../types/types";
import {
  SchoolDistrictCol1,
  SchoolDistrictCol2,
  SchoolDistrictCol3,
  SchoolDistrictLabel,
  SchoolDistrictSectionCol,
  SchoolDistrictSectionRow,
  SchoolDistrictWrapper,
  ShowCanEditItemCol,
  ShowCanEditItems,
  ShowCanEditItemsRow,
  ShowCanEditTitle,
  ShowCanEditWrapper,
} from "../admin.access.schoolDisrict.presentation/admin.access.schooldistrict.styles";
import { CircleIcon } from "../admin.access.special.presentation/admin.access.special.presentation.styles";
import { HighSchoolLabel } from "../admin.add.presentation/admin.add.presentation.styles";
const label = { inputProps: { "aria-label": "Switch demo" } };
const AdminAccessUniversityPresentation: React.FC<
  AdminAccessUniversityPresentationProps
> = ({
  admin,
  onExpereinceCheck,
  onProjectHanlder,
  onSaveHandler,
  onStoreCheck,
  onSurveyCheck,
  onUserCheck,
  projectID,
  showCanEdit,
  onAddAccessPermission,
  onClickProject,
  onProjectRemove,
  projectCanView,
}) => {
  return (
    <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
      <Row
        Direction="column"
        Width="40%"
        tabletWidth={"100%"}
        minWidth={""}
        alignment={""}
      >
        <Column width={""} minWidth={""} alignment={""} Direction={""}>
          <SubTitle>University</SubTitle>
        </Column>
        <Column
          style={{ flexWrap: "wrap" }}
          width={""}
          minWidth={""}
          alignment={""}
          Direction={""}
        >
          <InnerCol
            Direction="column"
            style={{ paddingTop: 20 }}
            align={""}
            alignment={""}
          >
            <FilterByProjectContainer
              organizationID={admin?.organization?._id as EntityId}
              onSelectProject={onProjectHanlder!}
              filterdBy="Post Secondary"
            />
          </InnerCol>
          <InnerCol
            style={{ padding: "15px 10px 0 0" }}
            align="center"
            alignment={""}
            Direction={""}
          >
            <CircleIcon onClick={onAddAccessPermission}>
              {false ? (
                <FaLock color="#fff" size={14} />
              ) : (
                <IoMdAdd color="#fff" size={14} />
              )}
            </CircleIcon>
          </InnerCol>
        </Column>
      </Row>
      <Row
        Width="30%"
        tabletWidth={"50%"}
        minWidth={""}
        alignment={""}
        Direction={""}
      >
        <Column width={""} minWidth={""} alignment={""} Direction={""}>
          <ShowCanView
            projectAccess={projectCanView}
            onProjectRemove={onProjectRemove}
            onClickProject={onClickProject}
          />
        </Column>
      </Row>

      <Row
        Width="20%"
        tabletWidth={"40%"}
        minWidth={"240px"}
        alignment={""}
        Direction={""}
      >
        {showCanEdit && (
          <>
            <Column
              width="fit-content"
              style={{ justifyContent: "end" }}
              minWidth={""}
              alignment={""}
              Direction={""}
            >
              <ShowCanEditTitle>
                {(showCanEdit as ProjectPermissionType).project
                  ? (showCanEdit as ProjectPermissionType).project.title
                  : (showCanEdit as CategoryPermissionType).category.title}
              </ShowCanEditTitle>
            </Column>
            <Column
              Direction="column"
              style={{ minWidth: "210px" }}
              width={""}
              minWidth={""}
              alignment={""}
            >
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>Users</SwitchLabel>
                <Switch
                  {...label}
                  checked={showCanEdit.users}
                  onChange={onUserCheck}
                />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>Experience cards</SwitchLabel>
                <Switch
                  {...label}
                  checked={showCanEdit.experience}
                  onChange={onExpereinceCheck}
                />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>surveys</SwitchLabel>
                <Switch
                  {...label}
                  checked={showCanEdit.survey}
                  onChange={onSurveyCheck}
                />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>stores</SwitchLabel>
                <Switch
                  {...label}
                  checked={showCanEdit.store}
                  onChange={onStoreCheck}
                />
              </InnerCol>
              <InnerCol align={""} alignment={""} Direction={""}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#34c38f" }}
                  onClick={onSaveHandler}
                >
                  Save
                </Button>
              </InnerCol>
            </Column>
          </>
        )}
      </Row>
    </Box>
  );
};

export default AdminAccessUniversityPresentation;
