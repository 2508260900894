import React from "react";
import AdminActionPresentation from "../../presentation/admin.action.presentation/AdminActionPresentation";
import { AdminActionContainerProps } from "../../types/types";

const AdminActionContainer: React.FC<AdminActionContainerProps> = ({
  openAdminAddState,
  openAdminAddHandler,
  term,
  setTerm,
}) => {
  return (
    <AdminActionPresentation
      openAdminAddState={openAdminAddState}
      openAdminAddHandler={openAdminAddHandler}
      term={term}
      setTerm={setTerm}
    />
  );
};

export default AdminActionContainer;
