import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import Header from "../../../components/header/Header";
import { selectedProject } from "../../home/appSlice/appslice";
import AdminDetailsContainer from "../conatiner/admin.details.container/AdminDetailsContainer";

const AdminDetailsPage = () => {
  const { adminId } = useParams();
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();
  console.log("adminID", adminId);
  // useEffect(() => {
  //   if (!project) {
  //     navigate("/");
  //   }else{

  //   }
  // }, [project]);

  return (
    <>
      {/* {project && ( */}
      <>
        <Header title="Admin Details" />
        <AdminDetailsContainer adminId={adminId!} />
      </>
      {/* )} */}
    </>
  );
};

export default AdminDetailsPage;
