import { useParams } from "react-router";
import Header from "../../../../components/header/Header";
import OrganizationDetailsMainContainer from "../../container/Organization.details.main.container/organization.details.main.container";

const OrganizationDetailsPage = () => {
  const { organizationId } = useParams();
  console.log(organizationId);

  return (
    <div>
      <Header title="Organization" />
      <OrganizationDetailsMainContainer organizationId={organizationId!} />
    </div>
  );
};

export default OrganizationDetailsPage;
