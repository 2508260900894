import styled from "@emotion/styled";

export const ProjectAllWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:0 30px;
  flex-wrap:wrap;
`;
export const HomeProjectAllTitle = styled.h3`
   font-family: Poppins;
    color: rgb(73, 80, 87);
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    padding: 10px 0px;
`;
