import React from "react";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import {
  InfoSection,
  InfoTitle,
  InfoItem,
  InfoText,
  InfoTextArea,
  DigitalCodeSection,
  DigitalCodeTitle,
  DigitalCodeItem,
  AddItemWrapper,
  AddItemBtn,
  StockIdText,
  Wrapper,
} from "../../raffelTickets/presentation/RaffelTicketMiddelColPresentation/raffel.midldle.style";

import { Switch } from "@material-ui/core";
type InventoryMiddelPresentationProps = {
  onAddItemClick: () => void;
  serialNumber: string | null;
  digitalCode: string | null;
  showDigitalCode: boolean | null;
  onSerialNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDigitalCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onShowDigitalCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const InventoryMiddelPresentation: React.FC<
  InventoryMiddelPresentationProps
> = ({
  onAddItemClick,
  digitalCode,
  onDigitalCode,
  onSerialNumber,
  serialNumber,
  showDigitalCode,
  onShowDigitalCode,
}) => {
  return (
    <Wrapper>
      <InfoSection>
        <InfoTitle>Manually Add</InfoTitle>
        <InfoItem>
          <InfoText>Stock_ID</InfoText>
          <StockIdText
            style={{ border: "none", outline: "none", fontSize: "10px" }}
          >
            Auto Genarate Stock ID
          </StockIdText>
        </InfoItem>
        <InfoItem>
          <InfoText>Serial Number</InfoText>
          <InfoTextArea
            name="serialNumber"
            value={serialNumber!}
            onChange={onSerialNumber}
          />
        </InfoItem>
      </InfoSection>
      <DigitalCodeSection>
        <DigitalCodeTitle>Digital Codes</DigitalCodeTitle>
        <DigitalCodeItem>
          <CustomSwitch
            isToggle={showDigitalCode!}
            onChangeToggle={onShowDigitalCode}
            style={undefined}
          />
          {showDigitalCode && (
            <>
              <InfoText>Digital Code</InfoText>
              <InfoTextArea
                value={digitalCode!}
                name="digitalCode"
                onChange={onDigitalCode}
              />
            </>
          )}

          {/* <DigitalCodeAddBtn>Add</DigitalCodeAddBtn> */}
        </DigitalCodeItem>
      </DigitalCodeSection>
      <AddItemWrapper>
        <AddItemBtn onClick={onAddItemClick}>Add Item</AddItemBtn>
      </AddItemWrapper>
    </Wrapper>
  );
};

export default InventoryMiddelPresentation;
