import React, { useState } from "react";
import {
  SettingMainContainer,
  SettingHeaderControle,
  TabButton,
} from "./setting.presentation.styles";
import SettingTabs from "../../../components/SettingTab/SettingTab";

type SettingPresentationProps = {
  firstTab: string;
  secondTab: string;
  learnerTiming?: string;
  changeLearnerTimingHandler?: (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  changeStartLearnerDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerStartDate?: Date;
  changeEndLearnerDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerEndDate: Date;
  changeLearnerProgressDaysAgo: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  progressDaysAgo: number;
  onProgressScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  progressScore: string;
  momentumLabel: string;
  onMomentumLabel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  momentumDaysAgo: number;
  onMomentumDaysAgo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  academicScoreType?: string | null;
  onAcademicScoreType?: (t: string) => void;
  chartViewType?: string | null;
  onChartViewType?: (t: string) => void;
  termStartDate?: Date;
  termEndDate?: Date;
  onTermStartDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTermEndDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectedTerms?: (terms: string[]) => void;
  selectedTerm?: string[];
};
const SettingPresentation: React.FC<SettingPresentationProps> = ({
  firstTab,
  secondTab,
  learnerTiming,
  changeLearnerTimingHandler,
  changeStartLearnerDate,
  learnerStartDate,
  changeEndLearnerDate,
  learnerEndDate,
  changeLearnerProgressDaysAgo,
  progressDaysAgo,
  onProgressScore,
  progressScore,
  momentumLabel,
  onMomentumLabel,
  momentumDaysAgo,
  onMomentumDaysAgo,
  onSave,
  academicScoreType,
  onAcademicScoreType,
  chartViewType,
  onChartViewType,
  termEndDate,
  termStartDate,
  onTermEndDate,
  onTermStartDate,
  onSelectedTerms,
  selectedTerm,
}) => {
  const [page, setPage] = useState(1);
  let driversCondition = false;
  let thirdCondition = false;
  const firstPageHandler = () => {
    return setPage(1);
  };
  const pageSecondHandler = () => {
    return setPage(2);
  };
  const thirdPageHandler = () => {
    return setPage(3);
  };
  if (page == 1) {
    driversCondition = true;
  } else if (page == 2) {
    thirdCondition = true;
  }
  return (
    <SettingMainContainer>
      <SettingHeaderControle>
        <TabButton
          onClick={() => firstPageHandler()}
          condition={driversCondition}
        >
          {firstTab}
        </TabButton>
        <TabButton
          onClick={() => pageSecondHandler()}
          condition={thirdCondition}
        >
          {secondTab}
        </TabButton>
      </SettingHeaderControle>
      <SettingTabs
        condition={page}
        learnerTiming={learnerTiming}
        changeLearnerTimingHandler={changeLearnerTimingHandler!}
        changeStartLearnerDate={changeStartLearnerDate!}
        learnerStartDate={learnerStartDate!}
        changeEndLearnerDate={changeEndLearnerDate}
        learnerEndDate={learnerEndDate}
        changeLearnerProgressDaysAgo={changeLearnerProgressDaysAgo}
        progressDaysAgo={progressDaysAgo}
        onProgressScore={onProgressScore}
        progressScore={progressScore}
        momentumLabel={momentumLabel}
        onMomentumLabel={onMomentumLabel}
        momentumDaysAgo={momentumDaysAgo}
        onMomentumDaysAgo={onMomentumDaysAgo}
        onSave={onSave}
        onAcademicScoreType={onAcademicScoreType}
        academicScoreType={academicScoreType}
        chartViewType={chartViewType}
        onChartViewType={onChartViewType}
        onTermEndDate={onTermEndDate}
        onTermStartDate={onTermStartDate}
        termEndDate={termEndDate}
        termStartDate={termStartDate}
        onSelectedTerms={onSelectedTerms}
        selectedTerm={selectedTerm}
      />
    </SettingMainContainer>
  );
};

export default SettingPresentation;
