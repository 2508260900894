import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { CompletionType } from "../types/types";
import SelectField from "../../../components/design/selectFiled/selectField";

type SelectCompletionTypePresenationProps = {
  completionTypes: EntityState<CompletionType>;
  onSelectCompletionType: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  completionType?: Partial<CompletionType> | null;
};
const SelectCompletionTypePresentation: React.FC<
  SelectCompletionTypePresenationProps
> = ({ completionTypes, onSelectCompletionType, completionType }) => {
  return (
    <SelectField
      onChange={onSelectCompletionType}
      defaultValue={completionType?._id as string}
    >
      {completionTypes.ids.map((id) => (
        <option key={id} value={id}>
          {completionTypes.entities[id]?.title}
        </option>
      ))}
    </SelectField>
  );
};

export default SelectCompletionTypePresentation;
