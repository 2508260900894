import React, { useEffect, useState } from "react";
import ShopListPresentation from "../presentation/ShopListPresentation/ShopListPresentation";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { ShopType } from "../types/type";
import { useNavigate } from "react-router-dom";
type ShopListProps = {
  shopList: EntityState<ShopType> | null;
  onSort: (fieldName: string) => void;
  sort: string;
  page?:number;
  limit?:number;
  onPage?:(pageNumber:number) => void;
  onLimit?:(limitNumber:number) => void;
  shopCount:number;
};
const ShopListContainer: React.FC<ShopListProps> = ({ shopList, onSort, sort,page,onPage,limit,onLimit , shopCount}) => {
  const navigation = useNavigate();
  const onEditReview = (id: EntityId) => {
    navigation(`/shop/${id}`);
  };

  return (
    <ShopListPresentation
      shopList={shopList!}
      onEditReview={onEditReview!}
      onSort={onSort}
      sort={sort}
      page={page}
      limit={limit}
      onLimit={onLimit}
      onPage={onPage}
      shopCount={shopCount}
    />
  );
};

export default ShopListContainer;
