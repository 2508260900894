import {
  createSelector,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import { apiSlice } from "../../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Project } from "../types/types";
import { IResponseList } from "../../../types/types";
import { RootState, store } from "../../../app/store";
import { setMetaData } from "../projectSlice/projectSlice";

const projectAdaptor = createEntityAdapter<Project>({
  selectId: (project) => project._id,
});

interface ProjectInitialState extends EntityState<Project> {}

const initialState: ProjectInitialState = projectAdaptor.getInitialState();

export const extendProjectApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query<
      EntityState<Project>,
      {
        term?: string;
        page?: string;
        limit?: string;
      }
    >({
      query: (data) =>
        `/project?term=${data.term}&page=${data.page}&limit=${data.limit}&sort=-createdAt`,
      transformResponse: (response: IResponseList<Project[]>) => {
        return projectAdaptor.setAll(initialState, response.data);
      },
      providesTags: ["Project"],
    }),
    getAllProjectByOrganization: builder.query<
      EntityState<Project>,
      {
        organizationId: EntityId;
        term?: string;
      }
    >({
      query: (data) =>
        `/project/${data.organizationId}?term=${
          data.term ? data.term : "null"
        }&sort=-createdAt`,
      transformResponse: (response: IResponseList<Project[]>) => {
        const projectData = response.data;
        const projectMetaData = response.metadata;
        store.dispatch(setMetaData(projectMetaData));
        return projectAdaptor.setAll(initialState, projectData);
      },
      providesTags: ["Project"],
    }),
    getProjectById: builder.query<EntityState<Project>, EntityId>({
      query: (projectId: EntityId) => `/project/projectdetails/${projectId}`,
      transformResponse: (response: Project) => {
        const loadedData = response;
        return projectAdaptor.setOne(initialState, loadedData);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "Project" as const, id })),
      ],
    }),

    addNewProject: builder.mutation<EntityState<Project>, Partial<Project>>({
      query: (project) => ({
        url: "/project",
        method: "POST",
        body: project,
      }),
      transformResponse: (response: Project) => {
        return projectAdaptor.addOne(initialState, response);
      },
      invalidatesTags: [
        { type: "Project", id: "LIST" },
        { type: "Organization", id: "LIST" },
      ],
    }),

    editProject: builder.mutation<EntityState<Project>, Partial<Project>>({
      query: (project) => ({
        url: `/project/${project._id}`,
        method: "PUT",
        body: project,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Project", id: arg._id },
      ],
    }),
  }),
});

export const {
  useGetAllProjectByOrganizationQuery,
  useLazyGetAllProjectByOrganizationQuery,
  useAddNewProjectMutation,
  useGetProjectByIdQuery,
  useLazyGetProjectByIdQuery,
  useEditProjectMutation,
  useGetAllProjectsQuery,
  useLazyGetAllProjectsQuery,
} = extendProjectApi;

export const selectProjectResult = (
  state: RootState,
  organizationId: EntityId
) =>
  extendProjectApi.endpoints.getAllProjectByOrganization.select({
    organizationId: organizationId,
  })(state);

export const selectProjectData = createSelector(
  selectProjectResult,
  (projectResult) =>
    projectAdaptor.getSelectors(() => projectResult?.data ?? initialState)
);

export const selectAllProject = createSelector(
  selectProjectData,
  (result) => result.selectAll
);

export const selectProjectsByIds = createSelector(
  selectProjectData,
  (result) => result.selectIds
);

export const selectProjectById = createSelector(
  selectProjectData,
  (result) => result.selectById
);

export const selectProjectEntites = createSelector(
  selectProjectData,
  (result) => result.selectEntities
);

// export const {
//   selectAll: selectAllProjects,
//   selectById: selectAllProjectsById,
//   selectIds: selectAllProjectByIds,
// } = projectAdaptor.getSelectors(
//   (state: RootState) => selectProjectData(state) ?? initialState
// );
