import styled from "@emotion/styled";

export const SpecialProjectWrapper = styled.div`
  width: 100%;
  padding: 15px;
`;

export const SpecialProjectRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SpecialProjectCol = styled.div`
  width: 33.33%;
`;

export const SpecialProjectTitle = styled.h4`
  color: blue;
  padding-bottom: 20px;
`;
export const CircleIcon = styled.div`
    background-color:#34C38F;
    width:28px;
    height:28px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
`;