import React, { useEffect, useState } from "react";
import CSSettingPresentation from "../cs.setting.presentation/cs.setting.presentation";
import { useAppSelector } from "../../../app/hooks";
import {
  selectCategoryId,
  selectCategoryStatus,
} from "../../category/categorySlice/categorySlice";
import {
  selectSubCategoryIdByClick,
  selectSubCategoryStatus,
} from "../../subcategory/subCategorySlice/subCategorySlice";
import { RootState } from "../../../app/store";

import {
  selectById,
  useEditCategoryMutation,
} from "../../category/api/categoryApiSlice";
import { CSSettingContainerProp } from "../types/types";
import {
  selectAllSubCategoryById,
  useEditSubCategoryMutation,
} from "../../subcategory/api/subCategoryApiSlice";

const CSSettingContainer: React.FC<CSSettingContainerProp> = ({
  projectId,
  rewardSystem,
}) => {
  const categoryId = useAppSelector(selectCategoryId);
  const subCategoryId = useAppSelector(selectSubCategoryIdByClick);

  const catgoryIdStatus = useAppSelector(selectCategoryStatus);
  const subCategoryIdStatus = useAppSelector(selectSubCategoryStatus);

  const category = useAppSelector((state: RootState) =>
    selectById(state, projectId!)(state, categoryId! ? categoryId! : "")
  );

  const subCategory = useAppSelector((state: RootState) =>
    selectAllSubCategoryById(state, categoryId! ? categoryId! : "")(
      state,
      subCategoryId! ? subCategoryId! : ""
    )
  );

  const [editCategory, { isLoading, isError, isSuccess }] =
    useEditCategoryMutation();

  const [
    editSubCategory,
    {
      isLoading: subCategoryLoading,
      isError: subCategoryError,
      isSuccess: subCategorySuccess,
    },
  ] = useEditSubCategoryMutation();

  const [title, setTitle] = useState<string>(
    catgoryIdStatus
      ? category?.title!
      : subCategory?.title
      ? subCategory?.title!
      : ""
  );
  const [displayProject, setDisplayProject] = useState<boolean>(
    catgoryIdStatus && category?.displayProject
      ? category?.displayProject
      : false
  );
  const [localize_reward, setLocalizeReward] = useState<boolean>(
    catgoryIdStatus
      ? category?.localize_reward!
      : subCategory?.localize_reward
      ? subCategory?.localize_reward
      : false
  );

  const [coins, setCoins] = useState<boolean>(
    catgoryIdStatus
      ? category?.coins!
      : subCategory?.coins
      ? subCategory.coins
      : false
  );

  const [store, setStore] = useState<boolean>(
    catgoryIdStatus
      ? category?.store!
      : subCategory?.store
      ? subCategory!.store
      : false
  );

  const [active, setActive] = useState<boolean>(
    catgoryIdStatus
      ? category?.active!
      : subCategory?.active
      ? subCategory!.active
      : false
  );

  useEffect(() => {
    setTitle(
      catgoryIdStatus
        ? category?.title!
        : subCategory?.title
        ? subCategory.title
        : ""
    );
    setDisplayProject(
      catgoryIdStatus && category?.displayProject
        ? category?.displayProject
        : false
    );
    setLocalizeReward(
      catgoryIdStatus
        ? category?.localize_reward!
        : subCategory?.localize_reward
        ? subCategory?.localize_reward
        : false
    );

    setCoins(
      catgoryIdStatus
        ? category?.coins!
        : subCategory?.coins
        ? subCategory.coins
        : false
    );

    setStore(
      catgoryIdStatus
        ? category?.store!
        : subCategory?.store
        ? subCategory!.store
        : false
    );

    setActive(
      catgoryIdStatus
        ? category?.active!
        : subCategory?.active
        ? subCategory!.active
        : false
    );
  }, [category, subCategory, catgoryIdStatus, subCategoryIdStatus]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onDisplayProjectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayProject(e.target.checked);
  };

  const onLocalizedRewardHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalizeReward(e.target.checked);
  };

  const onCoinsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoins(e.target.checked);
  };

  const onStoreHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStore(e.target.checked);
  };

  const onActiveHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActive(e.target.checked);
  };

  const onSaveHandler = async () => {
    try {
      if (catgoryIdStatus) {
        await editCategory({
          _id: categoryId!,
          title,
          localize_reward,
          displayProject,
          coins,
          store,
          active,
        }).unwrap();
      } else {
        console.log("subCategoryid", subCategoryId!);
        await editSubCategory({
          _id: subCategoryId!,
          title,
          localize_reward,
          coins,
          store,
          active,
        }).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  let content;
  if (category) {
    content = (
      <CSSettingPresentation
        title={title}
        displayProject={displayProject}
        localized_reward={localize_reward}
        coins={coins}
        store={store}
        active={active}
        onTitleHandler={onChangeTitle}
        onDisplayProjectHandler={onDisplayProjectHandler}
        onLocalizedRewardtHandler={onLocalizedRewardHandler}
        onCoinsHandler={onCoinsHandler}
        onStoreHandler={onStoreHandler}
        onActiveHandler={onActiveHandler}
        categoryStatus={catgoryIdStatus}
        subCategoryStatus={subCategoryIdStatus}
        updateHandler={onSaveHandler}
        projectReward={rewardSystem}
      />
    );
  } else {
    content = <p>Please select Category for check the setting</p>;
  }

  return <>{content}</>;
};

export default CSSettingContainer;
