import React from "react";
type FilterExperienceProps = {
  listStatus: string;
  onPublish: () => void;
  onSave: () => void;
};
const FilterExperience: React.FC<FilterExperienceProps> = ({
  listStatus,
  onPublish,
  onSave,
}) => {
  return (
    <div className="filter-container">
      <div className="publish " onClick={onPublish}></div>
      <div className="saved " onClick={onSave}></div>
      {listStatus === "publish" ? (
        <div className="expsActive" style={{ left: 0 }}></div>
      ) : (
        <div className="expsActive" style={{ left: "148px" }}></div>
      )}
    </div>
  );
};

export default FilterExperience;
