import React from "react";
import StudentDetailsInfoPresentation from "../../presantation/student.details.info.presentation/StudentDetailsInfoPresentation";
import { Student } from "../../types/types";
type StudentInfoDetailsContainerProp = {
  student: Student;
  onOpen: () => void;
};
const StudentInfoDetailsContainer: React.FC<
  StudentInfoDetailsContainerProp
> = ({ student, onOpen }) => {
  return <StudentDetailsInfoPresentation student={student} onOpen={onOpen} />;
};

export default StudentInfoDetailsContainer;
