import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetARaffelTicketQuery } from "../api/raffelTicketApiSlice";
import RaffelTicketDetailsContainer from "../container/RaffelTicketDetailsContainer";
import Header from "../../../components/header/Header";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";

const RaffelTicketDetailsPage = () => {
  const { id } = useParams();
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project) {
      navigate("/");
    }
  }, [project]);

  return (
    <>
      {project && (
        <>
          <Header title="Raffle Ticket Item" />
          <RaffelTicketDetailsContainer id={id!} />;
        </>
      )}
    </>
  );
};

export default RaffelTicketDetailsPage;
