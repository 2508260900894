import React from "react";
import "./xpPanel.css";
import { CSSProperties } from "@mui/material/styles/createMixins";
type XpPanelPresentationProps = {
  xpAmount: number | null;
  gemAmount: number | null;
  xpDescription: string | null;
  onXpAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGemAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onXpDescription: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddAward: () => void;
  descriptionError?: boolean;
  style?: CSSProperties;
  onCloseAward: () => void;
};
const XpPanelPresentation: React.FC<XpPanelPresentationProps> = ({
  xpAmount,
  gemAmount,
  xpDescription,
  onXpAmount,
  onGemAmount,
  onXpDescription,
  onAddAward,
  onCloseAward,
  style,
  descriptionError,
}) => {

  console.log(onCloseAward)
  return (
    <>
      <div className="xpContainer" onClick={onCloseAward}></div>
      <div className="xpPanle" style={style}>
        <div className="xpPanel_header">Add XP</div>
        <div className="xp_description">
          <input
            type="text"
            name="description"
            placeholder="Description"
            className="xpDescription"
            value={xpDescription!}
            onChange={onXpDescription}
            style={{ border: descriptionError ? "1px solid red" : "none" }}
          />
          {descriptionError && (
            <ul
              style={{
                paddingLeft: "30px",
              }}
            >
              <li style={{ color: "red" }}>Discription is required</li>
            </ul>
          )}
        </div>
        <div className="xp_description">
          <input
            type="text"
            name="amount"
            placeholder="Xp Amount"
            className="xpDescription"
            value={xpAmount!}
            onChange={onXpAmount!}
          />
          <div className="xp_description">
            <input
              type="text"
              name="gem"
              placeholder="Gem Amount"
              className="xpDescription"
              value={gemAmount!}
              onChange={onGemAmount!}
            />
          </div>
          <button className="addxpbutton" onClick={onAddAward}>
            +
          </button>
        </div>
        <div className="xpDate">
          <p className="date_text">Date</p>
          <p className="date_content">
            {new Date().getMonth() + 1}/{new Date().getDate()}/
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </>
  );
};

export default XpPanelPresentation;
