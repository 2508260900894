import React from "react";
import {
  ButtonWrapper,
  AddBtn,
  DeleteBtn,
  UpdateBtn,
} from "../shop.edit.review.header.style";
import { images } from "../../../../../constants";
type ShopEditReeviewButtonControlPresentationProp = {
  onUpdateShop: () => void;
  onRemoveShop: () => void;
  onCancel:() => void;
};
const ShopEditReviewButtonControlPresentation: React.FC<
  ShopEditReeviewButtonControlPresentationProp
> = ({ onUpdateShop, onRemoveShop,onCancel }) => {
  return (
    <ButtonWrapper>
      <AddBtn onClick={onUpdateShop} variant="contained">
        UPDATE
        {/* {pageStatus === "ADD_STOCK_SAVING" ? "Loading" : "Add"} */}
      </AddBtn>
      <DeleteBtn onClick={onRemoveShop}>
        <img src={images.TarshIcon} alt="trash"></img>
      </DeleteBtn>
      <UpdateBtn variant="contained" onClick={onCancel}>
        CANCEL
        {/* {pageStatus === "UPDATE_SAVING" ? "Updating" : "Update"} */}
      </UpdateBtn>
    </ButtonWrapper>
  );
};

export default ShopEditReviewButtonControlPresentation;
