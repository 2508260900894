import React, { useEffect, useState } from "react";
import {
  Title,
  Wrapper,
  BlackHeader,
  TitleWrapper,
  Desc,
  WhiteHeader,
  SectionCategoryWrapper,
  SectionWrapper,
  TitleText,
  DetailText,
  PickedUpText,
  PickedUpValue,
  Store,
  WrapperHeader,
} from "./shop.edit.review.header.style";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { images } from "../../../../constants";
import { Project } from "../../../project/types/types";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import { EntityId } from "@reduxjs/toolkit";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
type ShopEditReviewHeaderPresentationProps = {
  inStockNum?: number;
  pickedUpNum?: number;
  notPickedUpNum?: number;
  soldNum?: number;
  headerTitle: string;
  category?: Partial<Category> | null;
  onCategory?: (category: Partial<Category> | null) => void;
  subCategory?: Partial<SubCategory> | null;
  onSubCategory?: (subCategory: Partial<SubCategory> | null) => void;
  project: Partial<Project>;
  defaultValue?: string;
};
const ShopEditReviewHeaderPresentation: React.FC<
  ShopEditReviewHeaderPresentationProps
> = ({
  inStockNum,
  pickedUpNum,
  notPickedUpNum,
  soldNum,
  headerTitle,
  category,
  onCategory,
  subCategory,
  onSubCategory,
  project,
  defaultValue,
}) => {
  const [checkCat, setCheckCat] = useState<Partial<Category> | null>(category!);

  return (
    <WrapperHeader>
      <BlackHeader>
        <TitleWrapper>
          <Title>{headerTitle!}</Title>
          <Desc>Reward system</Desc>
        </TitleWrapper>
        <Store src={images.StoreIcon} />
      </BlackHeader>
      <WhiteHeader>
        <SectionCategoryWrapper>
          <Desc style={{ color: "#000", fontSize: 13 }}>Category</Desc>

          <FilterCategoryContainer
            projectID={project!._id!}
            selectedDefault={category ? (category?._id! as string) : "All"}
            onSelectCategory={onCategory!}
          />
        </SectionCategoryWrapper>
        <SectionCategoryWrapper>
          <Desc style={{ color: "#000", fontSize: 13 }}>Sub Category</Desc>
          <FilterSubCategoryContainer
            categoryID={category?._id!}
            defaultValue={subCategory!}
            onSelectSubCategory={onSubCategory!}
          />
        </SectionCategoryWrapper>
        <SectionWrapper>
          <TitleText>In Stock</TitleText>
          <DetailText>{inStockNum}</DetailText>
        </SectionWrapper>
        <SectionWrapper>
          <ProgressBar strokeWidth={14} percentage={Math.floor(60) || 0} />
        </SectionWrapper>
        <SectionWrapper style={{ marginTop: "50px", marginLeft: "-103px" }}>
          <PickedUpText>Not Picked up</PickedUpText>
          <PickedUpValue>{notPickedUpNum}</PickedUpValue>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText>Picked up</TitleText>
          <DetailText>{pickedUpNum}</DetailText>
        </SectionWrapper>
        <SectionWrapper>
          <TitleText>Sold</TitleText>
          <DetailText>{soldNum}</DetailText>
        </SectionWrapper>
      </WhiteHeader>
    </WrapperHeader>
  );
};

export default ShopEditReviewHeaderPresentation;
