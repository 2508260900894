import React from "react";
import { OrganizationDetailsActionPresentationProps } from "../../../types/types";
import { Paper } from "@material-ui/core";
import { MdEdit } from "react-icons/md";
import {
  OrganizationInfo,
  OrganizationPic,
  OrganizationProjectEditButtton,
  OrganizationEditButttonText,
  OrganizationEditButttonIcon,
} from "../../organization.add.presentation/organization.add.styles";
import PolygonChart from "../../../../../components/PolygonChart/PolygonChart";
const OrganizationDetailsActionPresentation: React.FC<
  OrganizationDetailsActionPresentationProps
> = ({ organization, handleOpen }) => {
  console.log("organization leader contact", organization?.lead_contact);
  return (
    <Paper
      elevation={3}
      className="st-info"
      style={{ flexDirection: "column", marginTop: 15 }}
    >
      <OrganizationInfo>
        <OrganizationPic>
          <PolygonChart
            XP={3499}
            studentPic={organization!.image}
            color="#000"
            style={{ transform: "scale(0.35)", top: -98, left: -17 }}
          />
        </OrganizationPic>
        <div style={{ paddingLeft: 40 }}>
          <h1 style={{ fontWeight: 600 }}>{organization!.title}</h1>
          <OrganizationProjectEditButtton onClick={handleOpen}>
            <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
            <OrganizationEditButttonIcon>
              <MdEdit color="#34C38F" size={12} />
            </OrganizationEditButttonIcon>
          </OrganizationProjectEditButtton>
        </div>
      </OrganizationInfo>
      <div style={{ display: "flex", paddingTop: 25 }}>
        <div
          className="student-info first-col"
          style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
        >
          <div style={{ width: "12%" }}>
            <h3>Organization Type</h3>
            <p>{organization?.organization_type}</p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>Organization ID </h3>
            <p>{organization?._id}</p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>Lead contact</h3>
            <p>
              {organization?.lead_contact
                ? organization?.lead_contact?.fullName
                : "Not Detect"}
            </p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>UFCS Member</h3>
            <p>{organization?.ufcs_member ? "Yes" : "No"}</p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>Paid</h3>
            <p>{organization?.paid}</p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>School district</h3>
            <p>
              {" "}
              {organization!.school_district
                ? organization!.school_district.agancy_name
                : "N/A"}
            </p>
          </div>
          <div style={{ width: "12%" }}>
            <h3>University</h3>
            <p>
              {organization!.university
                ? organization!.university.instnm
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default OrganizationDetailsActionPresentation;
