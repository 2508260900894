import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { ShopType } from "../types/type";
import { IResponseList } from "../../../types/types";
import { url } from "inspector";
import { store } from "../../../app/store";
import { setMetaData } from "./shopSlice";

const shopAdaptor = createEntityAdapter<ShopType>({
  selectId: (shop) => shop._id,
});

interface ShopInitialState extends EntityState<ShopType> {}

const initialState: ShopInitialState = shopAdaptor.getInitialState();

export const extendedShopApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllShop: builder.query<
      EntityState<ShopType>,
      {
        project: EntityId;
        category?: EntityId;
        subCategory?: EntityId;
        page?: number;
        limit?: number;
        sort?: string;
        term?: string;
      }
    >({
      query: (item) =>
        `/shop?project=${item.project}&category=${
          item.category ? item.category : "null"
        }&subCategory=${item.subCategory ? item.subCategory : "null"}&term=${
          item.term
        }&sort=${item.sort}&page=${item.page}&limit=${item.limit}`,
      transformResponse: (response: IResponseList<ShopType[]>) => {
        const respnoseData = response.data;
        const responseMetaData = response.metadata;

        store.dispatch(setMetaData(responseMetaData));

        return shopAdaptor.setAll(initialState, respnoseData);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Shop",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Shop" as const, id })),
      ],
    }),

    createShop: builder.mutation<EntityState<ShopType>, Partial<ShopType>>({
      query: (shop) => ({
        url: `/shop`,
        method: "POST",
        body: shop,
      }),
      transformResponse: (response: ShopType) => {
        return shopAdaptor.addOne(initialState, response);
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "Shop",
          id: "LIST",
        },
        {
          type: "Shop",
          id: arg._id,
        },
      ],
    }),

    updateShop: builder.mutation<EntityState<ShopType>, Partial<ShopType>>({
      query: (shop) => ({
        url: `/shop/${shop._id}?state=${shop.state}&addStock=${shop.addStock}`,
        method: "PUT",
        body: shop,
      }),
      transformResponse: (response: ShopType) => {
        return shopAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "Shop",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "Shop" as const, id: arg._id })),
      ],
    }),

    getAShop: builder.query<EntityState<ShopType>, EntityId>({
      query: (id) => `/shop/${id}`,
      transformResponse: (response: ShopType) => {
        return shopAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "Shop",
          id: arg,
        },
      ],
    }),

    deleteShop: builder.mutation<EntityState<ShopType>, EntityId>({
      query: (id) => ({
        url: `/shop/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response: ShopType) => {
        return shopAdaptor.removeOne(initialState, response._id);
      },
      invalidatesTags: ["Shop"],
    }),
  }),
});

export const {
  useGetAllShopQuery,
  useCreateShopMutation,
  useLazyGetAllShopQuery,
  useGetAShopQuery,
  useUpdateShopMutation,
  useDeleteShopMutation,
} = extendedShopApiSlice;
