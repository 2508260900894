import {
  createSelector,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../../app/api/apiSlice";
import { RootState } from "../../../app/store";
import { Category } from "../types/types";
import { store } from "../../../app/store";
import { setMetaData } from "../categorySlice/categorySlice";
import { IResponseList, SearchResult } from "../../../types/types";

export const categoryAdaptor = createEntityAdapter<Category>({
  selectId: (category) => category._id,
});

interface CategoryInitialState extends EntityState<Category> {}

const initialState: CategoryInitialState = categoryAdaptor.getInitialState();

export const extendedCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategoryByProject: builder.query<EntityState<Category>, EntityId>({
      query: (projectId) => `/category/${projectId}`,
      transformResponse: (response: IResponseList<Category[]>) => {
        const categoryData = response.data;
        const categoryMetaData = response.metadata;
        store.dispatch(setMetaData(categoryMetaData));
        return categoryAdaptor.setAll(initialState, categoryData);
      },
      providesTags: (result, error, arg) => {
        return [
          { type: "Category", id: "LIST" },
          ...result!.ids.map((id) => ({ type: "Category" as const, id })),
        ];
      },
    }),

    addCategoryToProject: builder.mutation<
      EntityState<Category>,
      Partial<Category>
    >({
      query: (category) => ({
        url: "/category",
        method: "POST",
        body: category,
      }),
      transformResponse: (response: Category) => {
        return categoryAdaptor.setOne(initialState, response);
      },
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    editCategory: builder.mutation<EntityState<Category>, Partial<Category>>({
      query: (category) => ({
        url: `/category/${category._id}`,
        method: "PUT",
        body: category,
      }),
      transformResponse: (response: Category) => {
        return categoryAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg._id },
      ],
    }),

    searchCategory: builder.query<
      SearchResult[],
      { projectId: EntityId; term: string }
    >({
      query: (searchInput) =>
        `/category/search/${searchInput.projectId}?term=${searchInput.term}`,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    removeCategory: builder.mutation<EntityState<Category>, EntityId>({
      query: (id) => ({
        url: `/category/${id}`,
        method: "DELETE",
        body: id,
      }),
      transformResponse: (response: Category) => {
        return categoryAdaptor.removeOne(initialState, response._id);
      },
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetAllCategoryByProjectQuery,
  useLazyGetAllCategoryByProjectQuery,
  useAddCategoryToProjectMutation,
  useEditCategoryMutation,
  useLazySearchCategoryQuery,
  useRemoveCategoryMutation,
} = extendedCategoryApiSlice;

export const selectCategoryResult = (state: RootState, projectId: EntityId) =>
  extendedCategoryApiSlice.endpoints.getAllCategoryByProject.select(projectId)(
    state
  );

export const selectCategoryData = createSelector(
  selectCategoryResult,
  (categoryResult) =>
    categoryAdaptor.getSelectors(() => categoryResult?.data ?? initialState)
);

export const selectAll = createSelector(
  selectCategoryData,
  (result) => result.selectAll
);

export const selectByIds = createSelector(
  selectCategoryData,
  (result) => result.selectIds
);

export const selectById = createSelector(
  selectCategoryData,
  (result) => result.selectById
);
