import { FC } from "react";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import SelectField from "../../../../components/design/selectFiled/selectField";
import SearchAdminContainer from "../../../admin/conatiner/admin.search.container/admin.search.container";
import { Admin } from "../../../admin/types/types";
import SearchSchoolDistrictContainer from "../../../schoolDistrict/searchSchoolDistrict/search.schoolDestict.container";
import { SchoolDistrict } from "../../../schoolDistrict/types/types";
import SearchUniversityContainer from "../../../unviersity/container/university.container";
import { University } from "../../../unviersity/types/types";
import { OrganizationAddPresentationProps } from "../../types/types";
import {
  CreateOrganizationForm,
  CreateOrganizationFormCol,
  CreateOrganizationFormRow,
  DialogStyle,
  LabelOrganization,
  OrganizationButon,
  OrganizationInput,
} from "./organization.add.styles";
import { images } from "../../../../constants";

const OrganizationAddPresentation: FC<
  OrganizationAddPresentationProps<Admin, SchoolDistrict, University>
> = ({
  openStatusAddDisplay,
  handleClose,
  onChagneImageUrl,
  onChangeSchoolDistrictId,
  onChangeUniversityId,
  onOrganizationTypeHandler,
  onChangeSearchAdmin,
  onActiveChange,
  onPaidChange,
  onRewardSystemChange,
  onSurvaySystemChange,
  onUfcsMemberChange,
  organizationType,
  onTitleChange,
  onShfChange,
  organizationSave,
  title,
  shf,
  active,
  organizationImageUrl,
  organization_type,
  paid,
  reward_system,
  status,
  survey_system,
  ufcs_member,
  lead_contact,
  schoolDistrictId,
  saveLoading,
  errorFileds,
}) => {
  console.log("errorFileds", errorFileds);
  return (
    <DialogStyle
      open={openStatusAddDisplay}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CreateOrganizationForm>
        <CreateOrganizationFormRow style={{ paddingLeft: 40 }}>
          <CreateOrganizationFormCol
            alignment="flex-start"
            style={{ paddingLeft: 0, position: "relative", paddingRight: 50 }}
            Direction={""}
          >
            <UploadImageContainer
              defaultImage={organizationImageUrl}
              onChangeImageUrlState={onChagneImageUrl}
            />
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol
            Direction="column"
            alignment="sttart"
            style={{ alignItems: "center" }}
          >
            <OrganizationInput
              Width="485px"
              Height="39px"
              FontFamily="Rajdhani"
              Size="32px"
              Weight="700"
              name="title"
              placeholder="Name of Organization here"
              value={title}
              onChange={onTitleChange}
              SpacingLR={""}
              SpacingTB={""}
            />
            <p style={{ color: "red" }}>{errorFileds?.title}</p>

            <OrganizationInput
              Width="120px"
              Height="33px"
              FontFamily="Rajdhani"
              Size="13px"
              Weight="400"
              name="short_form_name"
              placeholder="Short Form Name"
              value={shf}
              onChange={onShfChange}
              style={{ marginTop: 5 }}
              SpacingLR={""}
              SpacingTB={""}
            />
            <p style={{ color: "red" }}>{errorFileds?.shf}</p>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>

        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 16 }}
        >
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>Organization Type</LabelOrganization>
            <SelectField
              defaultValue={organization_type}
              onChange={onOrganizationTypeHandler}
            >
              <option value="Secondary">Secondary</option>
              <option value="Post Secondary">Post Secondary</option>
              <option value="CBO">CBO</option>
              <option value="Other">Other</option>
            </SelectField>
          </CreateOrganizationFormCol>

          {organizationType === "Secondary" ? (
            <CreateOrganizationFormCol Direction="column" alignment={""}>
              <LabelOrganization style={{ marginBottom: 3 }}>
                School District
              </LabelOrganization>
              <SearchSchoolDistrictContainer
                onChangeState={onChangeSchoolDistrictId!}
                schoolDistrictID={schoolDistrictId}
              />
            </CreateOrganizationFormCol>
          ) : organizationType === "Post Secondary" ? (
            <CreateOrganizationFormCol Direction="column" alignment={""}>
              <LabelOrganization>University</LabelOrganization>
              <SearchUniversityContainer
                onChangeState={onChangeUniversityId!}
              />
            </CreateOrganizationFormCol>
          ) : null}
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>UFCS Member</LabelOrganization>
            <SelectField
              defaultValue={ufcs_member ? "Yes" : "No"}
              onChange={onUfcsMemberChange}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </SelectField>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization style={{ marginBottom: 3 }}>
              Lead Contact
            </LabelOrganization>
            <SearchAdminContainer
              onChangeState={onChangeSearchAdmin}
              lead_contact={lead_contact}
            />
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
        <CreateOrganizationFormRow
          style={{ paddingRight: 20, paddingLeft: 20, marginTop: 20 }}
        >
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>Paid</LabelOrganization>
            <SelectField defaultValue={paid} onChange={onPaidChange}>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Free">Free</option>
            </SelectField>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>Reward System</LabelOrganization>
            <SelectField
              defaultValue={reward_system ? "Yes" : "No"}
              onChange={onRewardSystemChange}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </SelectField>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>Survey System</LabelOrganization>
            <SelectField
              defaultValue={survey_system ? "Yes" : "No"}
              onChange={onSurvaySystemChange}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </SelectField>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol Direction="column" alignment={""}>
            <LabelOrganization>Active</LabelOrganization>
            <SelectField
              defaultValue={active ? "Yes" : "No"}
              onChange={onActiveChange}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </SelectField>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
        <CreateOrganizationFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 48,
            marginBottom: 30,
            justifyContent: "center",
          }}
        >
          <CreateOrganizationFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
            alignment={""}
            Direction={""}
          >
            <OrganizationButon
              disabled={saveLoading}
              variant="contained"
              onClick={async () => {
                await organizationSave();
              }}
            >
              {saveLoading ? (
                <img
                  src={images.saveLoadingSpinner}
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  alt="this is test"
                />
              ) : (
                "Save"
              )}
            </OrganizationButon>
          </CreateOrganizationFormCol>
          <CreateOrganizationFormCol
            style={{ paddingLeft: 30 }}
            alignment={""}
            Direction={""}
          >
            <OrganizationButon onClick={handleClose}>Cancel</OrganizationButon>
          </CreateOrganizationFormCol>
        </CreateOrganizationFormRow>
      </CreateOrganizationForm>
    </DialogStyle>
  );
};

export default OrganizationAddPresentation;
