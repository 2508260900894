import React from "react";
import { useGetAllCompletionTypeQuery } from "../api/completionTypeApiSlice";
import SelectCompletionTypePresentation from "../SelectCompletionTypePresentation/SelectCompletionTypePresentation";
import { CompletionType } from "../types/types";
type SelectCompletionTypeContainerProps = {
  onSelectCompletionType: (completionType: Partial<CompletionType>) => void;
  completionType?: Partial<CompletionType>;
};
const SelectCompletionTypeContainer: React.FC<
  SelectCompletionTypeContainerProps
> = ({ onSelectCompletionType, completionType }) => {
  const {
    data: completionTypes,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllCompletionTypeQuery();

  const onSelectCompletion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectCompletionType(completionTypes?.entities[e.target.value]!);
  };
  return (
    <>
      {isSuccess && (
        <SelectCompletionTypePresentation
          completionTypes={completionTypes}
          onSelectCompletionType={onSelectCompletion}
          completionType={completionType}
        />
      )}
    </>
  );
};

export default SelectCompletionTypeContainer;
