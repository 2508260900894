import React, { FC } from "react";
import { Link } from "react-router-dom";

import { OrganizationItemPresentationProps } from "../../../types/types";
import { TableCell, TableRow } from "@mui/material";
const OrganizationItemPresentation: FC<OrganizationItemPresentationProps> = ({
  organization,
}) => {
  return (
    <TableRow>
      <TableCell>
        <img style={{maxWidth:60}} src={organization.image} alt={organization.image} width={50} />
      </TableCell>
      <TableCell>
        <Link style={{ color: "#495057", fontSize: 15, textDecoration: "none" }} to={`/organization/${organization._id}`}>
          {organization.title}
        </Link>
      </TableCell>
      <TableCell>{organization.ufcs_member ? "Yes" : "No"}</TableCell>
      <TableCell>{organization.paid}</TableCell>
      <TableCell>{organization.organization_type ? "Yes" : "No"}</TableCell>
      <TableCell>
        {organization.projects_number ? organization.projects_number : "0"}
      </TableCell>
      <TableCell>{organization.reward_system ? "Yes" : "No"}</TableCell>
      <TableCell>{organization.survey_system ? "Yes" : "No"}</TableCell>
    </TableRow>
  );
};

export default OrganizationItemPresentation;
