import React, { useEffect, useState } from "react";

type CountDownTimerProps = {
  drawDate: Date;
};
const CountDownTimer: React.FC<CountDownTimerProps> = ({ drawDate }) => {
  const [days, setDays] = useState<number | null>(null);
  const [hours, setHours] = useState<number | null>(null);
  const [min, setMin] = useState<number | null>(null);
  const [second, setSecond] = useState<number | null>(null);
  const [showCont, setShowCount] = useState<boolean>(true);
  const [timeOver, setTimeOver] = useState<boolean>(false);

  useEffect(() => {
    let date = drawDate?.getTime() - new Date().getTime();
    const day = date / (1000 * 60 * 60 * 24);
    setDays(Math.floor(day));
    const remainDay = date % (1000 * 60 * 60 * 24);
    const hour = remainDay / (1000 * 60 * 60);
    setHours(Math.floor(hour));
    const remainHours = remainDay % (1000 * 60 * 60);
    const m = remainHours / (1000 * 60);
    setMin(Math.floor(m));
    const reminM = remainHours % (1000 * 60);
    setSecond(Math.floor(reminM / 1000));
    if (day <= 0) {
      setTimeOver(true);
    }
  }, [drawDate, timeOver]);

  return (
    <div id="clockdiv">
      {timeOver === false ? (
        <>
          <div>
            <span className="days" id="day">
              {days}
            </span>
            <div className="smalltext">days</div>
          </div>
          <div>
            <span className="hours" id="hour">
              {hours}
            </span>
            <div className="smalltext">hrs</div>
          </div>
          <div>
            <span className="minutes" id="minute">
              {min}
            </span>
            <div className="smalltext">min</div>
          </div>
        </>
      ) : (
        <div>
          <h3
            style={{
              fontSize: "18px",
              color: "GrayText",
            }}
          >
            Time Is Over
          </h3>
        </div>
      )}
    </div>
  );
};

export default CountDownTimer;
