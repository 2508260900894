import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { Category, FilterCategoryPresentationProps } from "../../types/types";
import {
  StudentFillterLabel,
  StudentFillterSelec,
} from "../../../students/presantation/student.filter.presentation/StudentFilterPresentation.style";

const FilterCategoryPresentation: React.FC<FilterCategoryPresentationProps> = ({
  categories,
  onSelectCategory,
  selectedDefault,
  page,
}) => {
  // console.log("DDDDefault", defaultValue);
  // const [dValue, setDValue] = useState<string>(defaultValue);

  // useEffect(() => {
  //   onSelectCategory(defaultValue);
  // }, [defaultValue, categories]);
  // console.log("dValue", dValue);

  return (
    <div>
      {/* {categories.ids.length === 1 ? (
        <p>{categories.entities[categories.ids[0]]?.title}</p>
      ) : ( */}

      <StudentFillterSelec
        onChange={(e) => onSelectCategory(e.target.value)}
        defaultValue={selectedDefault}
      >
        <option value="All">All</option>
        {page === "shop" || page === "raffle"
          ? categories.ids.map(
              (id) =>
                categories.entities[id]?.store && (
                  <option
                    key={categories.entities[id]?._id}
                    value={categories.entities[id]?._id}
                  >
                    {categories.entities[id]?.title}
                  </option>
                )
            )
          : categories.ids.map((id) => (
              <option
                key={categories.entities[id]?._id}
                value={categories.entities[id]?._id}
              >
                {categories.entities[id]?.title}
              </option>
            ))}
      </StudentFillterSelec>

      {/* )} */}
    </div>
  );
};

export default FilterCategoryPresentation;
