import React, { FC } from "react";
import OrganizationActionPresentation from "../presantation/organization.action.presentation/organization.action.presentation";
import { OrganizationActionProps } from "../types/types";
const OrganizationActionsContainer: FC<OrganizationActionProps> = ({
  handleOpen,
  term,
  onTerm,
}) => {
  return (
    <OrganizationActionPresentation
      handleOpen={handleOpen}
      term={term}
      onTerm={onTerm}
    />
  );
};

export default OrganizationActionsContainer;
