import styled from "@emotion/styled";

export const AdminActionWrapper = styled.div`
  width: 90%;
  padding: 0 15px;
  margin-left:25px;
  margin-bottom:10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const AdminActionCol = styled.div`
  width: 30%;
  padding: 0 15px;
`;

export const AdminButton = styled.button<{spaceLeft:string}>`
  background: #34c38f;
  color:#fff;
  border: none;
  padding: 6px 10px 5px 37px;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: "Poppins";
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 300;

  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;