import React from "react";
import { Award } from "../../../awards/types/type";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { FaTrashAlt } from "react-icons/fa";
type StudentAwardItemPresenationProps = {
  award: Award;
  onRemoveAward: (id: EntityId) => void;
};
const StudentAwardItemPresentation: React.FC<
  StudentAwardItemPresenationProps
> = ({ award, onRemoveAward }) => {
  return (
    <TableRow>
      <TableCell style={{
        borderBottom: 0,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        overflow: "hidden",
        display: "block",
        paddingTop: 11
      }}>{award.description}</TableCell>
      <TableCell style={{ borderBottom: 0 }}>{award.xp}</TableCell>
      <TableCell style={{ borderBottom: 0 }}>{award.gem}</TableCell>
      <TableCell style={{ color: "#495057", borderBottom: 0 }}>
        <IconButton
          onClick={() => {
            onRemoveAward(award._id);
          }}
        >
          <FaTrashAlt color="#000" size={21} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default StudentAwardItemPresentation;
