import CountDownTimer from "../../../../../components/CountDownTimer/CountDownTimer";
import { images } from "../../../../../constants";
import FilterCategoryContainer from "../../../../category/container/filter.category.container/FilterCategoryContainer";
import { Category } from "../../../../category/types/types";
import { Project } from "../../../../project/types/types";
import { Student } from "../../../../students/types/types";
import FilterSubCategoryContainer from "../../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { SubCategory } from "../../../../subcategory/types/types";
import {
  BlackHeader,
  CountDown,
  DateWrapper,
  Desc,
  DescWrapper,
  Raffle,
  SectionCategoryWrapper,
  Sold,
  Title,
  TitleWrapper,
  WhiteHeader,
  Winners,
  WinnersName,
  Wrapper,
} from "./raffel.header.style";

type RaffelTicketHeaaderPresentationProps = {
  category: Partial<Category> | null;
  onCategory: (category: Partial<Category> | null) => void;
  subCategory: Partial<SubCategory> | null;
  onSubCategory: (subCategory: Partial<SubCategory> | null) => void;
  project: Partial<Project> | null;
  startDate: Date | null;
  onStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void;

  startTime: string | null;
  onStartTime: (e: React.ChangeEvent<HTMLInputElement>) => void;

  endDate: Date | null;
  onEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void;

  endTime: string | null;
  onEndTime: (e: React.ChangeEvent<HTMLInputElement>) => void;

  drawDate: Date | null;
  onDrawDate: (e: React.ChangeEvent<HTMLInputElement>) => void;

  drawTime: string | null;
  onDrawTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  winners: Student[];
  defaultValue?: string;
};
const RaffelTicketHeaderPresentation: React.FC<
  RaffelTicketHeaaderPresentationProps
> = ({
  category,
  subCategory,
  onCategory,
  onSubCategory,
  project,
  startDate,
  startTime,
  onStartDate,
  onStartTime,
  endDate,
  onEndDate,
  endTime,
  onEndTime,
  onDrawDate,
  onDrawTime,
  drawDate,
  drawTime,
  winners,
  defaultValue,
}) => {
  return (
    <Wrapper>
      <BlackHeader>
        <TitleWrapper>
          <Title>Raffle Ticket</Title>
          <Desc>Reward system</Desc>
        </TitleWrapper>
        <Raffle src={images.RaffelIcon} />
      </BlackHeader>
      <WhiteHeader>
        <div
          style={{
            display: "flex",
            height: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
            minWidth: "24%",
          }}
        >
          <SectionCategoryWrapper>
            <FilterCategoryContainer
              projectID={project!._id!}
              onSelectCategory={onCategory}
              selectedDefault={category?._id as string}
            />
            {/* <SelectComponent
              options={categories}
              noShowSlectText
              name="All"
              selectedValue={selectCategory}
              selected={true}
              onChange={(e) => {
                console.log(e.target.value);
                onChangeSelectCategory(e.target.value);
              }}
              style={{ minWidth: 160 }}
            /> */}
          </SectionCategoryWrapper>
          <SectionCategoryWrapper>
            <FilterSubCategoryContainer
              categoryID={category?._id!}
              defaultValue={subCategory!}
              onSelectSubCategory={onSubCategory}
            />
            {/* <SelectComponent
              options={subCategoryOptions}
              noShowSlectText
              name="All"
              selected={true}
              selectedValue={selectedSubCategoryId}
              onChange={onSubCategoryChanged}
              style={{ minWidth: 160 }}
            /> */}
          </SectionCategoryWrapper>
        </div>

        <DateWrapper>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Start Date
            </p>
            <div>
              <input
                type="date"
                name="startDate"
                id="startDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={startDate?.toISOString().split("T")[0]}
                onChange={onStartDate}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="startTime"
                id="startTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={startTime!}
                onChange={onStartTime}
              />
            </div>
          </div>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Close Date
            </p>
            <div>
              <input
                type="date"
                name="closeDate"
                id="closeDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={endDate?.toISOString().split("T")[0]}
                onChange={onEndDate}
                // value={closeDate}
                // onChange={(e) => onCloseDateChange(e)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="closeTime"
                id="closeTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={endTime!}
                onChange={onEndTime}
                // value={closeTime}
                // onChange={(e) => onCloseTimeChange(e)}
              />
            </div>
          </div>
          <div style={{ paddingRight: 10 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 15, marginBottom: 0 }}>
              Drawing Date
            </p>
            <div>
              <input
                type="date"
                name="drawDate"
                id="drawDate"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={drawDate?.toISOString().split("T")[0]}
                onChange={onDrawDate}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="darwTime"
                id="drawTime"
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                value={drawTime!}
                onChange={onDrawTime}
                // value={drawTime}
                // onChange={(e) => onDrawTimeChange(e)}
              />
            </div>
          </div>
        </DateWrapper>
        <DescWrapper className="counter-responsive">
          <CountDown>CountDown</CountDown>
          <CountDownTimer drawDate={drawDate!} />

          <Winners>WINNER(s)</Winners>
          <div>{winners.map((item) => item.fullName)}</div>
        </DescWrapper>
        <DescWrapper>
          <Sold>Sold</Sold>
          <Sold style={{ color: "#B7B7BC" }}>{0}</Sold>
        </DescWrapper>
      </WhiteHeader>
    </Wrapper>
  );
};

export default RaffelTicketHeaderPresentation;
