import styled from "@emotion/styled";

// export const SelectFieldStyle = styled.select<{
//   isDisabled: boolean;
//   defaultValue?: string;
// }>`
//   padding: 5px 15px;
//   border: 1px solid #ced4da;
//   font-size: 14px;
//   font-family: poppins;
//   border-radius: 5px;
//   color: #495057;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
//   margin-top: 10px;
//   margin-right: 10px;
//   position: relative;
//   background: ${({ isDisabled }) =>
//     isDisabled
//       ? `url("../../../assets/image/lock.png") no-repeat white`
//       : `url("../../../assets/image/ArrowShape.png") no-repeat white`};
//   background-position: bottom
//     ${({ isDisabled }) => (isDisabled ? "0px" : "13px")} right
//     ${({ isDisabled }) => (isDisabled ? "-10px" : "6px")};
//   font-weight: 300;
//   width: 173px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   &:focus {
//     border-color: #ced4da;
//     box-shadow: none;
//     outline: none;
//   }
//   option {
//     font-family: poppins;
//     font-size: 13px;
//     color: #ced4da;
//   }
// `
export const SelectFieldStyle = styled.select<{ isDisabled: boolean }>`
  padding: 5px 15px;
  border: 1px solid #ced4da;
  font-size: 14px;
  font-family: poppins;
  border-radius: 5px;
  color: #495057;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-top: 3px;
  margin-right: 10px;
  position: relative;
  background: ${({ isDisabled }) =>
    isDisabled
      ? `url("../../../assets/image/lock.png") no-repeat white`
      : `url("../../../assets/image/ArrowShape.png") no-repeat white`};
  background-position: bottom
    ${({ isDisabled }) => (isDisabled ? "0px" : "13px")} right
    ${({ isDisabled }) => (isDisabled ? "-10px" : "6px")};
  font-weight: 300;
  width: 173px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }
  option {
    font-family: poppins;
    font-size: 13px;
    color: #ced4da;
  }

  @media (max-width: 1700px) {
    width: 130px;
  }

  @media (max-width: 1400px) {
    width: 115px;
  }

  @media (max-width: 1300px) {
    width: 105px;
    padding: 5px;
  }
`;
