import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";

export const CreateStudentContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateStudentFormRow = styled.div`
  width: 95%;
  height: 50px;
  margin-left: 35px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
  @media (max-width: 1300px) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

export const CreateStudentFormCol = styled.div<{
  alignment: string;
  Direction: string;
}>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px;
`;

export const StudentInput = styled.input<{
  SpacingLR: string;
  Width: string;
  Height: string;
  FontFamily: string;
  Size: string;
  Weight: string;
  SpacingTB: string;
}>`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;
  font-family: ${({ FontFamily }) => FontFamily};
  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid #ced4da;
  color: #070708;
  max-width: ${({ Width }) => (Width ? Width : "235px")};
`;
export const SwitchLabelStudent = styled.label`
  font-family: Poppins;
  font-weight: 400;
  font-size: 13px;
  color: #74788d;
  padding-right: 7px;
`;
export const SaveButton = styled(Button)`
  color: #fff;
  background-color: #0078ff;
  border: none;
  border-radius: 50px;
  padding: 10px 46px;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  &:hover {
    background-color: #3996ff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border: none;
  }
  &:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #3996ff;
    border: none;
  }
  &:focus {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border: none;
  }
`;
