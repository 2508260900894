import { createSlice } from "@reduxjs/toolkit";
import { MetaData } from "../../../types/types";
import { EntityId } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
interface IinitialState {
  metadata: MetaData;
  selectedCategoryId: EntityId | null;
  selectCatgoryStatus: boolean;
}

const initialState: IinitialState = {
  metadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
  selectedCategoryId: null,
  selectCatgoryStatus: false,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    changeSelectCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    changeSelectCategoryStatus: (state, action) => {
      state.selectCatgoryStatus = action.payload;
    },
  },
});

export const selectCategoryId = (state: RootState) =>
  state.category.selectedCategoryId;
export const selectCategoryStatus = (state: RootState) =>
  state.category.selectCatgoryStatus;
export const {
  setMetaData,
  changeSelectCategoryId,
  changeSelectCategoryStatus,
} = categorySlice.actions;
export default categorySlice.reducer;
