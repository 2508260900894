import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// config Rtk query for using fetching endpoints
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
   baseUrl: "https://api.gradify.app/api",
 //  baseUrl: "http://localhost:6800/api",
    credentials: "include",
  }),
  // tag caches
  tagTypes: [
    "Admin",
    "Organization",
    "SchoolDistrict",
    "HighSchool",
    "University",
    "AdminSearch",
    "Project",
    "Category",
    "SubCategory",
    "Team",
    "Student",
    "Experience",
    "CompletionType",
    "ExperienceCard",
    "StudentCompletionPhoto",
    "LearnerSetting",
    "Shop",
    "RaffelTicket",
    "ProfileChart",
    "LeaderBoard",
    "ExperienceStudent",
    "Recent",
    "Award",
    "Bag",
    "Inventory",
    "Xp",
    "CourseEnrollment",
    "GemStudent",
    "Fuses",
    "Crystal",
  ],

  endpoints: (builder) => ({}),
});
