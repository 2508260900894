import { TableRow, TableCell, Checkbox, Switch } from "@mui/material";
import React from "react";
import { Student } from "../../../students/types/types";
import { images } from "../../../../constants";
type ExperienceStudemtItemProps = {
  student: Student;
  onCompleteChange: (student: Student, completion: boolean) => void;
  switchLoading: boolean;
};
const ExperienceStudentItem: React.FC<ExperienceStudemtItemProps> = ({
  student,
  onCompleteChange,
  switchLoading,
}) => {
  return (
    <TableRow>
      <TableCell>
        {" "}
        <Checkbox
        //   checked={selectState}
        // onChange={() => setSelectState(!selectState)}
        />
      </TableCell>
      <TableCell>
        {" "}
        <p className="item-student-name-ex">{student.fullName}</p>{" "}
      </TableCell>
      <TableCell>
        {/* <span
          className={
            item.complete
              ? item.complete.complete
                ? "student-list-ex-com"
                : "student-list-ex-com-not"
              : "student-list-ex-com-not"
          }
        >
          {item.complete
            ? item.complete.complete
              ? "Compeleted"
              : "Not Compeleted"
            : "Not Compeleted"}
        </span> */}
      </TableCell>
      <TableCell>
        {student?.completion
          ? new Date(student.completion.updatedAt).toLocaleDateString()
          : "---"}
      </TableCell>
      <TableCell>
        <Switch
          disabled={switchLoading}
          checked={student.completion?.complete}
          onChange={() => {
            onCompleteChange(student, !student.completion?.complete);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ExperienceStudentItem;
