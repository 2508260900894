import React, { useEffect, useState } from "react";
import AdminAccessSpecialPresentation from "../../presentation/admin.access.special.presentation/AdminAccessSpecialPresentation";
import {
  Admin,
  AdminAccessSpecialContainerProps,
  CategoryPermissionType,
  ProjectPermissionType,
  SubCategoryPermissionType,
} from "../../types/types";
import { Project } from "../../../project/types/types";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { useUpdateAdminMutation } from "../../adminApiSlice/adminApiSlice";

const AdminAccessSpecialContainer: React.FC<
  AdminAccessSpecialContainerProps
> = ({ admin }) => {
  const [updateAdmin, { isLoading, isSuccess, isError }] =
    useUpdateAdminMutation();
  const [projectID, setProjectID] = useState<EntityId | null>(null);
  const [categoryID, setCategoryID] = useState<EntityId | null>(null);
  const [subCategoryID, setSubCategoryID] = useState<EntityId | null>(null);
  const [specialProjects, setSpecialProject] = useState<Project[]>([]);
  const [specialCategory, setSpecialCategory] = useState<Category[]>([]);
  const [specialSubCategory, setSpecialSubCategory] = useState<SubCategory[]>(
    []
  );

  const [projectCanView, setProjectCanView] = useState<ProjectPermissionType[]>(
    []
  );
  const [categoryCanView, setCategoryCanView] = useState<
    CategoryPermissionType[]
  >([]);

  const [subCategoryCanView, setSubCategoryCanView] = useState<
    SubCategoryPermissionType[]
  >([]);

  const [showCanEdit, SetShowCanEdit] = useState<
    | ProjectPermissionType
    | CategoryPermissionType
    | SubCategoryPermissionType
    | null
  >(null);
  useEffect(() => {
    if (admin) {
      setSpecialProject(
        admin.projects
          ? admin.projects!.filter((project) => {
              return project.project_type === "Special Project";
            })
          : []
      );
      setSpecialCategory(
        admin.categories
          ? admin.categories!.filter((category) => !category.school_level)
          : []
      );

      setSpecialSubCategory(admin?.subcategories!);
    }
  }, [admin]);

  useEffect(() => {
    if (specialProjects?.length > 0) {
      const projectPermission = admin!.projectAccess!.filter((project) => {
        const findProject = specialProjects?.find(
          (sProject) => sProject._id === project.project
        );
        if (findProject) {
          return project.accessType === "Full";
        } else {
          return false;
        }
      });
      setProjectCanView(
        projectPermission!.map((project) => {
          const findProject = specialProjects?.find(
            (sProject) => sProject._id === project.project
          );

          return {
            ...project,
            project: findProject!,
          };
        })
      );
    }
  }, [specialProjects]);

  useEffect(() => {
    if (specialCategory?.length > 0) {
      const categoryPermiseion = admin!.categoryAccess!.filter((category) => {
        const findCategory = specialCategory.find(
          (sCategory) => sCategory._id === category.category
        );

        if (findCategory) {
          return category.accessType === "Full";
        } else {
          return false;
        }
      });
      setCategoryCanView(
        categoryPermiseion.map((category) => {
          const findCategory = specialCategory.find(
            (sCategory) => sCategory._id === category.category
          );

          return {
            ...category,
            category: {
              _id: findCategory?._id,
              title: findCategory?.title,
            },
          };
        })
      );
    }
  }, [specialCategory]);

  useEffect(() => {
    if (specialSubCategory?.length > 0) {
      const subCategoryPermission = admin!.subCategoryAccess!.filter(
        (subCategory) => {
          const id = subCategory.subcategory as string;
          const findSubCategory = specialSubCategory.find(
            (sSubCategory) => sSubCategory._id.toString() === id
          );

          if (findSubCategory) {
            return subCategory.accessType === "Full";
          } else {
            return false;
          }
        }
      );
      setSubCategoryCanView(
        subCategoryPermission!.map((subcategory) => {
          const findSubCategory = specialSubCategory.find(
            (sSubCategory) => sSubCategory._id === subcategory.subcategory
          );

          return {
            ...subcategory,
            subcategory: {
              _id: findSubCategory!._id!,
              title: findSubCategory!.title,
            },
          };
        })
      );
    }
  }, [specialSubCategory]);

  const onProjectHandler = (project: Partial<Project> | null) => {
    setProjectID(project!._id!);
  };

  const onCategoryHandler = (category: Partial<Category> | null) => {
    setCategoryID(category?._id!);
  };

  const onSubCategoryHanlder = (subCategory: Partial<SubCategory> | null) => {
    setSubCategoryID(subCategory!._id!);
  };

  const onAddAccessPermission = async () => {
    let newProjectAccess: ProjectPermissionType[] = [...admin.projectAccess!];
    let newCategoryAccess: CategoryPermissionType[] = [
      ...admin.categoryAccess!,
    ];
    let newSubCategoryAccess: SubCategoryPermissionType[] = [
      ...admin.subCategoryAccess!,
    ];
    if (projectID && !categoryID) {
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      if (exist) {
        newProjectAccess = admin.projectAccess!.map((pr) => {
          return pr.project === exist.project
            ? (pr = { ...exist, accessType: "Full" })
            : pr;
        });

        newCategoryAccess = admin.categoryAccess!.filter((category) => {
          const find = admin!.categories?.find(
            (cat) => cat._id === category.category
          );
          if (find) {
            return find.project !== projectID;
          } else {
            return false;
          }
        });
        setProjectCanView(newProjectAccess);
        setCategoryCanView(newCategoryAccess);
      } else {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];

        setProjectCanView(newProjectAccess);
      }
    } else if (projectID && categoryID && !subCategoryID) {
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      if (exist && exist!.accessType === "Full") {
        newProjectAccess = [...admin.projectAccess!];
        setProjectCanView(newProjectAccess);
      } else if (exist && exist.accessType === "Partial") {
        const existCategory = admin.categoryAccess!.find(
          (category) => category.category === categoryID
        );

        if (!existCategory) {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [
            ...admin.categoryAccess!,
            {
              category: { _id: categoryID.toString() },
              accessType: "Full",
              users: false,
              experience: false,
              survey: false,
              store: false,
            },
          ];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
        } else {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [...admin.categoryAccess!];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
        }
      } else if (!exist) {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Partial",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];
        newCategoryAccess = [
          ...admin.categoryAccess!,
          {
            category: { _id: categoryID.toString() },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];

        setProjectCanView(newProjectAccess);
        setCategoryCanView(newCategoryAccess);
      }
    } else if (projectID && categoryID && subCategoryID) {
      const exist = admin.projectAccess!.find(
        (project) => project.project === projectID
      );

      if (exist && exist!.accessType === "Full") {
        newProjectAccess = [...admin.projectAccess!];
        setProjectCanView(newProjectAccess);
      } else if (exist && exist.accessType === "Partial") {
        const existCategory = admin.categoryAccess!.find(
          (category) => category.category === categoryID
        );

        if (existCategory && existCategory!.accessType === "Full") {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [...admin.categoryAccess!];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
        } else if (existCategory && existCategory!.accessType === "Partial") {
          const subCategoryExist = admin.subCategoryAccess!.find(
            (subcategory) => subcategory.subcategory === subCategoryID
          );

          if (!subCategoryExist) {
            newProjectAccess = [...admin.projectAccess!];
            newCategoryAccess = [...admin.categoryAccess!];
            newSubCategoryAccess = [
              ...admin.subCategoryAccess!,
              {
                subcategory: { _id: subCategoryID },
                accessType: "Full",
                users: false,
                experience: false,
                survey: false,
                store: false,
              },
            ];
            setProjectCanView(newProjectAccess);
            setCategoryCanView(newCategoryAccess);
            setSubCategoryCanView(newSubCategoryAccess);
          } else {
            newProjectAccess = [...admin.projectAccess!];
            newCategoryAccess = [...admin.categoryAccess!];
            newSubCategoryAccess = [...admin.subCategoryAccess!];
            setProjectCanView(newProjectAccess);
            setCategoryCanView(newCategoryAccess);
            setSubCategoryCanView(newSubCategoryAccess);
          }
        } else if (!existCategory) {
          newProjectAccess = [...admin.projectAccess!];
          newCategoryAccess = [
            ...admin.categoryAccess!,
            {
              category: { _id: categoryID },
              accessType: "Partial",
              users: false,
              experience: false,
              survey: false,
              store: false,
            },
          ];
          newSubCategoryAccess = [
            ...admin.subCategoryAccess!,
            {
              subcategory: { _id: subCategoryID },
              accessType: "Full",
              users: false,
              experience: false,
              survey: false,
              store: false,
            },
          ];
          setProjectCanView(newProjectAccess);
          setCategoryCanView(newCategoryAccess);
          setSubCategoryCanView(newSubCategoryAccess);
        }
      } else if (!exist) {
        newProjectAccess = [
          ...admin.projectAccess!,
          {
            project: { _id: projectID.toString() },
            accessType: "Partial",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];
        newCategoryAccess = [
          ...admin.categoryAccess!,
          {
            category: { _id: categoryID },
            accessType: "Partial",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];
        newSubCategoryAccess = [
          ...admin.subCategoryAccess!,
          {
            subcategory: { _id: subCategoryID },
            accessType: "Full",
            users: false,
            experience: false,
            survey: false,
            store: false,
          },
        ];
        setProjectCanView(newProjectAccess);
        setCategoryCanView(newCategoryAccess);
        setSubCategoryCanView(newSubCategoryAccess);
      }
    }
    try {
      const newAdmin: Partial<Admin> & { status: boolean } = {
        _id: admin._id,
        projectAccess: newProjectAccess!,
        categoryAccess: newCategoryAccess!,
        subCategoryAccess: newSubCategoryAccess!,
        status: true,
      };

      await updateAdmin({ ...newAdmin, status: true }).unwrap();
    } catch (error) {}
  };

  const onProjectRemove = async (project: ProjectPermissionType) => {
    const projectPermission = admin!.projectAccess!.filter(
      (pro) => pro.project !== project.project._id
    );

    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: projectPermission!,
      categoryAccess: admin!.categoryAccess!,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setProjectCanView(projectPermission);
    } catch (error) {}
  };

  const onCategoryRemove = async (category: CategoryPermissionType) => {
    const categoryPermission = admin!.categoryAccess!.filter(
      (cat) => cat.category !== category.category._id
    );
    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: admin.projectAccess!,
      categoryAccess: categoryPermission!,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setCategoryCanView(categoryPermission!);
    } catch (error) {}
  };

  const onSubCategoryRemove = async (
    subcategory: SubCategoryPermissionType
  ) => {
    const subCategoryPermission = admin!.subCategoryAccess!.filter(
      (subCat) => subCat.subcategory !== subcategory.subcategory._id
    );
    const newAdmin: Partial<Admin> & { status: boolean } = {
      _id: admin._id,
      projectAccess: admin.projectAccess!,
      categoryAccess: admin.categoryAccess!,
      subCategoryAccess: subCategoryPermission,
      status: true,
    };

    try {
      await updateAdmin({ ...newAdmin, status: true }).unwrap();
      setSubCategoryCanView(subCategoryPermission);
    } catch (error) {}
  };

  const onClickProject = async (project: ProjectPermissionType) => {
    SetShowCanEdit(project as ProjectPermissionType);
  };

  const onClickCategory = async (category: CategoryPermissionType) => {
    SetShowCanEdit(category as CategoryPermissionType);
  };

  const onClickSubCategory = async (subcategory: SubCategoryPermissionType) => {
    SetShowCanEdit(subcategory as SubCategoryPermissionType);
  };

  const onUserCheck = async () => {
    SetShowCanEdit({ ...showCanEdit!, users: !showCanEdit!.users! });
  };

  const onExpereinceCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, experience: !showCanEdit?.experience });
  };

  const onStoreCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, store: !showCanEdit?.store });
  };

  const onSurveyCheck = () => {
    SetShowCanEdit({ ...showCanEdit!, survey: !showCanEdit?.survey });
  };

  const onSaveHandler = async () => {
    let newProjectAccess = [...admin.projectAccess!];
    let newCategoryAccess = [...admin.categoryAccess!];
    let newSubCategoryAccess = [...admin.subCategoryAccess!];
    if ((showCanEdit as ProjectPermissionType).project) {
      newProjectAccess = newProjectAccess.map((proj) => {
        if (
          proj.project === (showCanEdit as ProjectPermissionType).project._id
        ) {
          proj = showCanEdit as ProjectPermissionType;
          return proj;
        } else {
          return proj;
        }
      });
    } else if ((showCanEdit as CategoryPermissionType).category) {
      newCategoryAccess = newCategoryAccess.map((cate) => {
        if (
          cate.category === (showCanEdit as CategoryPermissionType).category._id
        ) {
          cate = showCanEdit as CategoryPermissionType;
          return cate;
        } else {
          return cate;
        }
      });
    } else if ((showCanEdit as SubCategoryPermissionType).subcategory) {
      newSubCategoryAccess = newSubCategoryAccess.map((sub) => {
        if (
          sub.subcategory ===
          (showCanEdit as SubCategoryPermissionType).subcategory._id
        ) {
          sub = showCanEdit as SubCategoryPermissionType;
          return sub;
        } else {
          return sub;
        }
      });
    }
    try {
      await updateAdmin({
        _id: admin._id,
        projectAccess: newProjectAccess,
        categoryAccess: newCategoryAccess,
        subCategoryAccess: newSubCategoryAccess,
        status: true,
      }).unwrap();
    } catch (error) {}
  };

  return (
    <AdminAccessSpecialPresentation
      admin={admin}
      onProjectHanlder={onProjectHandler}
      projectID={projectID}
      onCategoryHandler={onCategoryHandler}
      categoryID={categoryID}
      onSubCategoryHandler={onSubCategoryHanlder}
      subCategory={subCategoryID}
      projectCanView={projectCanView}
      categoryCanview={categoryCanView}
      subCategoryCanView={subCategoryCanView}
      onAddAccessPermission={onAddAccessPermission}
      onProjectRemove={onProjectRemove}
      onCategoryRemove={onCategoryRemove}
      onSubCategoryRemove={onSubCategoryRemove}
      onClickProject={onClickProject}
      onClickCategory={onClickCategory}
      onClickSubCategory={onClickSubCategory}
      showCanEdit={showCanEdit}
      onUserCheck={onUserCheck}
      onExpereinceCheck={onExpereinceCheck}
      onStoreCheck={onStoreCheck}
      onSurveyCheck={onSurveyCheck}
      onSaveHandler={onSaveHandler}
    />
  );
};

export default AdminAccessSpecialContainer;
