import React from "react";
import { useGetExperienceHotspotSublistQuery } from "../../api/experienceCardApiSlice";
import { useAppSelector } from "../../../../app/hooks";
import { selectedProject } from "../../../home/appSlice/appslice";
import { useNavigate } from "react-router-dom";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import ExperienceHotSpotSubListPresentation from "../../presentation/experienceHotSpotSubListPresentation/ExperienceHotSpotSubListPresentation";
type ExprienceHotSpotSubListProp = {
  hotspotgroupid: string;
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  expPublish: boolean;
  expSave: boolean;
  term: string;
  onEdit: (id: EntityId) => void;
  onReview: (id: EntityId) => void;
};
const ExperienceHotSpotSubList: React.FC<ExprienceHotSpotSubListProp> = ({
  hotspotgroupid,
  category,
  subCategory,
  expPublish,
  expSave,
  term,
  onEdit,
  onReview,
}) => {
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();
  console.log(category);

  const { data, isLoading, isError, isSuccess } =
    useGetExperienceHotspotSublistQuery({
      hotspotgroupid: hotspotgroupid!,
      project: project!._id!,
      category: category?._id!,
      subCategory: subCategory?._id,
      expPublish: expPublish!,
      expSave: expSave!,
      term: term,
    });
  console.log("data", data!);
  return (
    <>
      {isSuccess && (
        <>
          {data!.ids!.map((id) => (
            <ExperienceHotSpotSubListPresentation
              key={id}
              item={data.entities[id]!}
              onEdit={onEdit}
              onReview={onReview}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ExperienceHotSpotSubList;
