import styled from "@emotion/styled";

export const ShowCanViewWrapper = styled.div`
  width: 100%;
  border: 1px solid #000;
  min-height: 120px;
  border-radius: 15px;
  padding: 15px;
`;

export const ShowCanViewItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const ShowCanViewItem = styled.p`
  min-width: 40%;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  margin: 10px;
`;

export const ShowCanViewRemoveButton = styled.span`
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 60px;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: 700;
  cursor: pointer;
`;
