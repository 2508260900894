import React from "react";
import ExperienceListPresentation from "../../presentation/expereince.list.presentation/ExperienceListPresentation";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { ExperienceCard } from "../../types/types";
import { useNavigate } from "react-router-dom";

// ExperienceListContianer props
type ExperienceListContainerPorps = {
  list: EntityState<ExperienceCard>;
  onSort: (fieldName: string) => void;
  sort: string;
  page?: number;
  limit?: number;
  onPage?: (pageNumber: number) => void;
  onLimit?: (limitNumber: number) => void;
};

// ExperineceListContainer component that is follow the container persentation pattern this is container component for experience list
const ExperiecneListContainer: React.FC<ExperienceListContainerPorps> = ({
  list,
  onSort,
  sort,
  page,
  limit,
  onPage,
  onLimit,
}) => {
  // useNavgation hook for navigatie between page
  const navigation = useNavigate();

  //onEditHandler is a handler for when you hit on edit button on list

  const onEditHanlder = (id: EntityId) => {
    navigation(`/experience/expdetails/${id}`);
  };

  //onReviewHandler is an event handler for when you hit the button of Review on the exp list
  const onReviewHandler = (id: EntityId) => {
    navigation(`/experience/expReview/${id}`);
  };
  return (
    // return the persentation
    <ExperienceListPresentation
      list={list}
      onEdit={onEditHanlder}
      onReview={onReviewHandler}
      onSort={onSort}
      sort={sort}
      page={page}
      limit={limit}
      onLimit={onLimit}
      onPage={onPage}
    />
  );
};

export default ExperiecneListContainer;
