import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import React, { FC, SyntheticEvent } from "react";
import SearchField from "../../../components/SearchField/SearchField";
import {
  SearchResult,
  SearchSchoolDistrictContainerProps,
} from "../../../types/types";
import { useLazySearchUniversityQuery } from "../api/universityApi";
import { University } from "../types/types";

const SearchUniversityContainer: FC<
  SearchSchoolDistrictContainerProps<University>
> = ({ onChangeState }) => {
  const [options, setOptions] = React.useState<SearchResult[]>([]);

  const [searchUniversity, { isLoading, error }] =
    useLazySearchUniversityQuery();

  const onFetch = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    try {
      const newOptions = await searchUniversity(value).unwrap();

      setOptions(newOptions);
    } catch (error) {}
  };

  const onChagneHandler = (
    event: SyntheticEvent<Element, Event>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value?.id) {
      console.log(value.id);
      onChangeState({ _id: value.id, instnm: value.label });
    }
  };

  return (
    <SearchField
      options={options}
      loading={isLoading}
      handleInputChange={onFetch}
      onChangeHandle={onChagneHandler}
    />
  );
};

export default SearchUniversityContainer;
