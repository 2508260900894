import React, { useState } from "react";
import AdminInfoContainer from "../admin.info.container/AdminInfoContainer";
import { AdminDetailsContainerProps } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectAdminById,
  useGetAdminByIdQuery,
} from "../../adminApiSlice/adminApiSlice";
import { RootState } from "../../../../app/store";
import AdminAccessSchoolDistrictContainer from "../admin.access.schoolDistrict.container/AdminAccessSchoolDistrictContainer";
import AdminAccessSpecialContainer from "../admin.access.special.container/AdminAccessSpecialContainer";
import AdminAccessUniversityContainer from "../admin.access.university.container/AdminAccessUniversityContainer";
import {
  Box,
  Column,
  EditButtonIcon,
  EditButtonText,
  Row,
  Title,
} from "../../../../assets/style/General.style";
import { MdEdit } from "react-icons/md";
import { EditButtonAccess } from "../../presentation/admin.access.schoolDisrict.presentation/admin.access.schooldistrict.styles";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import AdminAddContainer from "../admin.add.container/AdminAddContainer";
import { EntityId } from "@reduxjs/toolkit";

const AdminDetailsContainer: React.FC<AdminDetailsContainerProps> = ({
  adminId,
}) => {
  const [showAddAdmin, setShowAddAdmin] = useState<boolean>(false);

  const { data, isLoading, isError, isSuccess } = useGetAdminByIdQuery(
    adminId as EntityId
  );

  const admin = useAppSelector((state: RootState) =>
    selectAdminById(state, adminId)
  );

  console.log("ADDDDDDDDMIN", data?.entities[data?.ids[0]]!);

  const [showPermission, setShowPermission] = useState<boolean>(false);

  const openHandler = () => {
    setShowAddAdmin(true);
  };

  const closeHandler = () => {
    setShowAddAdmin(false);
  };

  return (
    <>
      {isSuccess && data.entities[data.ids[0]]! && (
        <>
          <AdminInfoContainer
            admin={data.entities[data.ids[0]]!}
            openEdit={openHandler}
            open={showAddAdmin}
          />
          {showAddAdmin && (
            <AdminAddContainer
              openAddAdminState={showAddAdmin}
              openAddAdminHandler={openHandler}
              closeHandler={closeHandler}
              authAdmin={data.entities[data.ids[0]]!}
              status="Edit"
            />
          )}

          {data.entities[data.ids[0]]!.adminLevel === "ADMIN" ? (
            <>
              <Box
                style={{
                  flexDirection: "column",
                  width: "calc(100% - 40px)",
                  margin: "0 20px 10px 20px",
                }}
              >
                <Row
                  Width={""}
                  minWidth={""}
                  alignment={""}
                  Direction={""}
                  tabletWidth={""}
                >
                  <Column
                    width="45%"
                    alignment="flex-start"
                    style={{ paddingLeft: 20 }}
                    minWidth={""}
                    Direction={""}
                  >
                    <Title HideTablet={""}>Access</Title>
                    <EditButtonAccess
                      style={{ marginLeft: "33px" }}
                      onClick={() => {
                        setShowPermission(true);
                      }}
                    >
                      <EditButtonText>Edit</EditButtonText>
                      <EditButtonIcon>
                        <MdEdit color="#34C38F" size={12} />
                      </EditButtonIcon>
                    </EditButtonAccess>
                  </Column>
                  <Column
                    style={{ visibility: `${!true ? "hidden" : "visible"}` }}
                    width="35%"
                    alignment="center"
                    minWidth={""}
                    Direction={""}
                  >
                    <Title HideTablet={""}>Can View</Title>
                  </Column>
                  <Column
                    style={{ visibility: `${!true ? "hidden" : "visible"}` }}
                    width="30%"
                    alignment="center"
                    minWidth={""}
                    Direction={""}
                  >
                    <Title HideTablet={""}>Can Edit</Title>
                  </Column>
                </Row>

                {showPermission && (
                  <>
                    <AdminAccessSchoolDistrictContainer
                      admin={data.entities[data.ids[0]]!}
                    />
                    <AdminAccessUniversityContainer
                      admin={data.entities[data.ids[0]]!}
                    />
                    <AdminAccessSpecialContainer
                      admin={data.entities[data.ids[0]]!}
                    />
                  </>
                )}
              </Box>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default AdminDetailsContainer;
