import React from "react";
import ExperienceDetailsInfoPresentation from "../../presentation/experience.details.presentation/ExperienceDetailsInfoPresentation";
import { ExperienceType } from "../../../experiencetype/types/types";
import { CompletionType } from "../../../completion-type/types/types";
import { ExperienceCard } from "../../types/types";
type ExperienceDetailsInfoContainerProps = {
  expType: Partial<ExperienceType>;
  title: string | null;
  onTitleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  subTitle: string | null;
  onSubTitleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string | null;
  onDescriptionHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  image: string;
  onImageHandler: () => void;
  completionType: Partial<CompletionType>;
  onCompletionTypeHandler: (comType: Partial<CompletionType>) => void;
  xpCompletion: number;
  onXpCompletionHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  xpView: number;
  onXpViewHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number;
  onGemHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectCompletionType: (completionType: Partial<CompletionType>) => void;
  autoCompletion: boolean;
  onAutoCompletion: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addLink: boolean;
  onAddLinkHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textLink: string | null;
  onTextLinkHanlder: (e: React.ChangeEvent<HTMLInputElement>) => void;
  texturl: string | null;
  onTextUrlHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonLink: string | null;
  onButtonLink: (e: React.ChangeEvent<HTMLInputElement>) => void;
  driverOneItems: { value: string; label: string }[];
  driverTwoItems: { value: string; label: string }[];
  driverOneSelected: string | null;
  onDriverOneSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  driverTwoSelected: string | null;
  onDriverTwoSelected: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onSave: () => void;
  onPublish: () => void;
  isUpdated: boolean;
  publish: boolean;
  onPublishUpdate: () => void;
  onSaveUpdate: () => void;
  onRemoveCard: () => void;
  onUpdate: () => void;
  onNotification: boolean;
  onNotificationHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  saveLoading: boolean;
  notification: boolean;
  createDayNotification: boolean;
  norificationDaysBefore: boolean;
  notificationDaysBeforeNumber: number;
  notificationDaysOf: boolean;
  onChangeNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCreateDaysNotification: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChangeDaysBefore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDaysBeforeNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDaysOf: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startDateNotification: boolean;
  onStartDateNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  midNotification: boolean;
  onMidNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  passDueNotification: boolean;
  onPassDueNotification: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reSpawn: boolean;
  countHotSpot: number;
  total_reSpawns: number | null;
  intervals: string;
  weeklyDays: string | null;
  onReSpawn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountHotSpot: (count: number) => void;
  onTotalReSpawns: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInterval: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onWeeklyDays: (day: string) => void;
  multiDays: string[] | null;
  onMultiDays: (day: string) => void;
  dailyTime: string | null;
  onDaylitime: (time: string) => void;
  dayofMonth: string | null;
  onDayOfMonthly: (e: React.ChangeEvent<HTMLInputElement>) => void;
  availableDays: number;
  onAvailableDays: (n: number) => void;
  respwanNotification?: boolean;
  onRespwanNotification: () => void;
  missionRevive: boolean;
  onMissionRevive: () => void;
  onDonotPassDue?: () => void;
  doNotPassDue?: boolean;
  id?: string | null | undefined | number;
};
const ExperienceDetailsInfoContainer: React.FC<
  ExperienceDetailsInfoContainerProps
> = ({
  id,
  expType,
  title,
  onTitleHandler,
  subTitle,
  onSubTitleHandler,
  description,
  onDescriptionHandler,
  image,
  onImageHandler,
  completionType,
  onCompletionTypeHandler,
  xpCompletion,
  onXpCompletionHandler,
  xpView,
  onXpViewHandler,
  gem,
  onGemHandler,
  onSelectCompletionType,
  autoCompletion,
  onAutoCompletion,
  addLink,
  onAddLinkHandler,
  textLink,
  onTextLinkHanlder,
  texturl,
  onTextUrlHandler,
  buttonLink,
  onButtonLink,
  driverOneItems,
  driverTwoItems,
  driverOneSelected,
  onDriverOneSelected,
  driverTwoSelected,
  onDriverTwoSelected,
  onPublish,
  onSave,
  isUpdated,
  publish,
  onPublishUpdate,
  onSaveUpdate,
  onRemoveCard,
  onUpdate,
  onNotification,
  onNotificationHandler,
  saveLoading,
  createDayNotification,
  norificationDaysBefore,
  notification,
  notificationDaysBeforeNumber,
  notificationDaysOf,
  onChangeCreateDaysNotification,
  onChangeDaysBefore,
  onChangeDaysBeforeNumber,
  onChangeDaysOf,
  onChangeNotification,
  midNotification,
  onMidNotification,
  onPassDueNotification,
  onStartDateNotification,
  passDueNotification,
  startDateNotification,
  countHotSpot,
  intervals,
  onCountHotSpot,
  onInterval,
  onReSpawn,
  onTotalReSpawns,
  onWeeklyDays,
  reSpawn,
  total_reSpawns,
  weeklyDays,
  multiDays,
  onMultiDays,
  onDaylitime,
  dailyTime,
  dayofMonth,
  onDayOfMonthly,
  availableDays,
  onAvailableDays,
  respwanNotification,
  onRespwanNotification,
  missionRevive,
  onMissionRevive,
  onDonotPassDue,
  doNotPassDue,
}) => {
  return (
    <ExperienceDetailsInfoPresentation
      title={title}
      onTitleHandler={onTitleHandler}
      expType={expType}
      subTitle={subTitle}
      onSubTitleHandler={onSubTitleHandler}
      description={description}
      onDescriptionHandler={onDescriptionHandler}
      image={image}
      onImageHandler={onImageHandler}
      completionType={completionType}
      onCompletionTypeHandler={onCompletionTypeHandler}
      xpCompletion={xpCompletion}
      onXpCompletionHandler={onXpCompletionHandler}
      xpView={xpView}
      onXpViewHandler={onXpViewHandler}
      gem={gem}
      onGemHandler={onGemHandler}
      onSelectCompletionType={onSelectCompletionType}
      autoCompletion={autoCompletion}
      onAutoCompletion={onAutoCompletion}
      addLink={addLink}
      onAddLinkHandler={onAddLinkHandler}
      textLink={textLink}
      onTextLinkHanlder={onTextLinkHanlder}
      texturl={texturl}
      onTextUrlHandler={onTextUrlHandler}
      buttonLink={buttonLink}
      onButtonLink={onButtonLink}
      driverOneItems={driverOneItems}
      driverTwoItems={driverTwoItems}
      driverOneSelected={driverOneSelected}
      onDriverOneSelected={onDriverOneSelected}
      driverTwoSelected={driverTwoSelected}
      onDriverTwoSelected={onDriverTwoSelected}
      onPublish={onPublish}
      onSave={onSave}
      isUpdated={isUpdated}
      publish={publish}
      onPublishUpdate={onPublishUpdate}
      onSaveUpdate={onSaveUpdate}
      onRemoveCard={onRemoveCard}
      onUpdate={onUpdate}
      onNotification={onNotification}
      onNotificationHandler={onNotificationHandler}
      saveLoading={saveLoading}
      createDayNotification={createDayNotification}
      norificationDaysBefore={norificationDaysBefore}
      notification={notification}
      notificationDaysBeforeNumber={notificationDaysBeforeNumber}
      notificationDaysOf={notificationDaysOf}
      onChangeCreateDaysNotification={onChangeCreateDaysNotification}
      onChangeDaysBefore={onChangeDaysBefore}
      onChangeDaysBeforeNumber={onChangeDaysBeforeNumber}
      onChangeDaysOf={onChangeDaysOf}
      onChangeNotification={onChangeNotification}
      startDateNotification={startDateNotification}
      onStartDateNotification={onStartDateNotification}
      midNotification={midNotification}
      onMidNotification={onMidNotification}
      passDueNotification={passDueNotification}
      onPassDueNotification={onPassDueNotification}
      countHotSpot={countHotSpot}
      intervals={intervals}
      onCountHotSpot={onCountHotSpot}
      onInterval={onInterval}
      onReSpawn={onReSpawn}
      onTotalReSpawns={onTotalReSpawns}
      onWeeklyDays={onWeeklyDays}
      reSpawn={reSpawn}
      total_reSpawns={total_reSpawns}
      weeklyDays={weeklyDays}
      multiDays={multiDays}
      onMultiDays={onMultiDays}
      dailyTime={dailyTime}
      onDailyTime={onDaylitime}
      dayofMonth={dayofMonth}
      onDayOfMonthly={onDayOfMonthly}
      availableDays={availableDays}
      onAvailableDays={onAvailableDays}
      respwanNotification={respwanNotification}
      onRespwanNotification={onRespwanNotification}
      missionRevive={missionRevive}
      onMissionRevive={onMissionRevive}
      onDonotPassDue={onDonotPassDue}
      doNotPassDue={doNotPassDue}
      id={id}
    />
  );
};

export default ExperienceDetailsInfoContainer;
