import React from "react";
import {
  AdminFilterCol,
  AdminFilterRow,
  AdminFilterWarrper,
  FilterTitle,
} from "./admin.filter.presentation.styles";
import { AdminFilterPresentationProps } from "../../types/types";
import SelectOrganizationListContainer from "../../../organization/container/select.organization.list.container/select.organization.list.container";
import FilterByOrganizationContainer from "../../../organization/container/filter.by.organization.container/FilterByOrganization.container";
import FilterByProjectContainer from "../../../project/container/filter.by.project.container/FilterByProjectContainer";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";

const AdminFilterPresentation: React.FC<AdminFilterPresentationProps> = ({
  organizationID,
  projectID,
  categoryID,
  onSelectOrganizationHandler,
  onSelectProjectHandler,
  onSelectCategoryHandler,
  onSelectSubCategoryHandler,
}) => {
  return (
    <AdminFilterWarrper>
      <AdminFilterRow>
        <AdminFilterCol>
          <FilterTitle>Organization</FilterTitle>
          <FilterByOrganizationContainer
            onSelectOrganization={onSelectOrganizationHandler}
          />
        </AdminFilterCol>
        {organizationID ? (
          <AdminFilterCol>
            <FilterTitle>Project</FilterTitle>

            <FilterByProjectContainer
              organizationID={organizationID}
              onSelectProject={onSelectProjectHandler}
            />
          </AdminFilterCol>
        ) : null}

        {projectID ? (
          <AdminFilterCol>
            <FilterTitle>Category</FilterTitle>
            <FilterCategoryContainer
              projectID={projectID!}
              onSelectCategory={onSelectCategoryHandler}
            />
          </AdminFilterCol>
        ) : null}
        {categoryID ? (
          <AdminFilterCol>
            <FilterTitle>SubCategory</FilterTitle>
            <FilterSubCategoryContainer
              categoryID={categoryID}
              onSelectSubCategory={onSelectSubCategoryHandler}
            />
          </AdminFilterCol>
        ) : null}
      </AdminFilterRow>
    </AdminFilterWarrper>
  );
};

export default AdminFilterPresentation;
