import React from "react";
import { ProfileChart } from "../types/type";
import { Circle } from "@mui/icons-material";
import CircleChart from "../../../components/circleChart/CircleChart";
import { calulateCircleChartPercent } from "../../../utils/utils";
type ProfileChartsPresentationProp = {
  chartData: ProfileChart;
};
const ProfileChartsPresentation: React.FC<ProfileChartsPresentationProp> = ({
  chartData,
}) => {
  return (
    <>
      <CircleChart
        top={47}
        left={-20}
        scale={1}
        title={"Progress"}
        description="Score"
        chartPercentCal={calulateCircleChartPercent(
          495,
          chartData.progress.current
        )}
        percent={Math.floor(chartData.progress.current)}
        // lastChartPercentCal={lastchartPercentCal}
      />
      <CircleChart
        top={47}
        left={-100}
        scale={1}
        title={"Momentum"}
        description="Score"
        chartPercentCal={calulateCircleChartPercent(
          495,
          chartData.momentum.current
        )}
        percent={chartData.momentum.current}
        lastChartPercentCal={calulateCircleChartPercent(
          495,
          chartData.momentum.last
        )}
        lastPercent={chartData.momentum.last}
      />
    </>
  );
};

export default ProfileChartsPresentation;
