import React from "react";
import AdminListPresentation from "../../presentation/admin.list.presentation/AdminListPresentation";
import { AdminListContainerProps } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdminIds } from "../../adminApiSlice/adminApiSlice";

const AdminListContainer: React.FC<AdminListContainerProps> = ({
  adminList,
  page,
  limit,
  setPage,
  setLimit,
  sort,
  onSort,
}) => {
  const adminsSelector = useAppSelector(selectAdminIds);

  return (
    <AdminListPresentation
      adminList={adminList}
      page={page}
      limit={limit}
      setPage={setPage}
      setLimit={setLimit}
      sort={sort}
      onSort={onSort}
    />
  );
};

export default AdminListContainer;
