import React from "react";
import { SubCategoryListPresentationProp } from "../../types/types";
import ShowListInTagFormat from "../../../../components/showListInTagFormat/ShowListInTagFormat";
const SubCategoryListPresentation: React.FC<
  SubCategoryListPresentationProp
> = ({ subcategories, onSubCategoryClickHandler }) => {
  return (
    <ShowListInTagFormat
      list={subcategories}
      selectItemHanlder={onSubCategoryClickHandler}
    />
  );
};

export default SubCategoryListPresentation;
