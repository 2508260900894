import React, { useEffect, useState } from "react";
import {
  DriverMainBox,
  DriverMainLeftBox,
  DriverTitle,
  DriverSmallTitle,
  SettingProgressItem,
  SettingTermsContainer,
  SettingTermsItem,
  DriverColumn,
  SettingApplyButton,
  DriverText,
  SettingTermButton,
  SettingTermItemButton,
  SettingTermItemButtonIcon,
  DriverTextArea,
  DriverRow,
  DriverSaveButton,
} from "../../features/setting/settingPresentation/setting.presentation.styles";
import SelectField from "../design/selectFiled/selectField";
import { IoIosAddCircle } from "react-icons/io";
import {
  useGetCourseEnrollmentByProjectQuery,
  useUpdateCourseEnrollmentByNmaeMutation,
  useUpdateCourseEnrollmentProjectMutation,
} from "../../features/courseEnrollment/api/coursenrollmentApi";
import { selectedProject } from "../../features/home/appSlice/appslice";
import { useAppSelector } from "../../app/hooks";
import { get } from "http";
import { images } from "../../constants";

type LearnerProps = {
  studentTiming?: string;
  changeLearnerTimingHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  changeStartLearnerDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerStartDate: Date;
  changeEndLearnerDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerEndDate: Date;
  changeLearnerProgressDaysAgo: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  progressDaysAgo: number;

  momentumLabel: string;
  onMomentumLabel: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onProgressScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  progressScore: string;

  momentumDaysAgo: number;
  onMomentumDaysAgo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  academicScoreType?: string | null;
  onAcademicScoreType?: (t: string) => void;
  chartViewType?: string | null;
  onChartViewType?: (t: string) => void;
  termStartDate?: Date;
  termEndDate?: Date;
  onTermStartDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTermEndDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectedTerm?: (terms: string[]) => void;
  selectedTerm?: string[];
};
const Learner: React.FC<LearnerProps> = ({
  studentTiming,
  changeLearnerTimingHandler,
  changeStartLearnerDate,
  learnerStartDate,
  changeEndLearnerDate,
  learnerEndDate,
  changeLearnerProgressDaysAgo,
  progressDaysAgo,
  onProgressScore,
  progressScore,
  momentumLabel,
  onMomentumLabel,
  momentumDaysAgo,
  onMomentumDaysAgo,
  onSave,
  academicScoreType,
  onAcademicScoreType,
  chartViewType,
  onChartViewType,
  onTermEndDate,
  onTermStartDate,
  termEndDate,
  termStartDate,
  onSelectedTerm,
  selectedTerm,
}) => {
  const project = useAppSelector(selectedProject);
  const [termFilter, setTermFilter] = useState<string[]>(
    selectedTerm ? selectedTerm : []
  );
  const {
    data: getCourseEnrollment,
    isLoading,
    isError,
    isSuccess,
  } = useGetCourseEnrollmentByProjectQuery({
    project: String(project?._id),
    startDate: String(termStartDate),
    endDate: String(termEndDate),
    term: JSON.stringify(termFilter),
  });

  console.log("courseEnrollment", getCourseEnrollment);

  const [
    updateCourseEnrollmentProject,
    {
      isLoading: updateLoading,
      isError: ErrorLoading,
      isSuccess: successLoading,
    },
  ] = useUpdateCourseEnrollmentProjectMutation();

  const [
    updateCourseEnrollmentByName,
    {
      isLoading: loadingUpdateName,
      isError: errorUpdateName,
      isSuccess: successUpdateName,
    },
  ] = useUpdateCourseEnrollmentByNmaeMutation();

  const [updateAllWhereYouShouldBe, setUpdateWhereYouShouldBe] = useState<
    number | null
  >(null);

  useEffect(() => {
    console.log("***selected Term", selectedTerm);
    if (selectedTerm!.length > 0) {
      setTermFilter(selectedTerm!);
    }
  }, [selectedTerm]);

  const onUpdateAllWhereYouShuoldBe = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUpdateWhereYouShouldBe(Number(e.target.value));
  };

  const updateCourseEnrollmentProjectHandler = async () => {
    try {
      console.log(updateAllWhereYouShouldBe);
      await updateCourseEnrollmentProject({
        project: String(project?._id!),
        where_you_should_be: updateAllWhereYouShouldBe!,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DriverMainBox style={{ marginTop: 0 }}>
        <DriverMainLeftBox>
          <div className="personal">
            <DriverTitle>Progress Score </DriverTitle>
            <DriverColumn Direction="row" alignment="flex-start">
              <DriverText>Change Lable </DriverText>
              <DriverTextArea
                value={progressScore}
                onChange={onProgressScore}
                // value={timeRangeValue.progressLabel}
                // onChange={(e) => {
                //   setTimeRangeValue({
                //     ...timeRangeValue,
                //     progressLabel: e.target.value,
                //   });
                // }}
                placeholder="Progress score"
                style={{ marginLeft: 25, width: 230 }}
              />
            </DriverColumn>
            <DriverRow style={{ marginTop: -10, justifyContent: "flex-start" }}>
              <DriverColumn>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  Timing
                </p>
                <div style={{ minWidth: 120 }}></div>

                {/* <SelectComponent
                  options={options}
                  name="timing"
                  selectedValue={timeRangeValue.typeTime}
                  style={{ paddingLeft: 5, paddingTop: 5, paddingBottom: 5 }}
                  onChange={(e) => {
                    setTimeRangeValue({
                      ...timeRangeValue,
                      typeTime: e.target.value,
                    });
                    setStudentTiming(e.target.value);
                  }}
                /> */}
                <SelectField
                  defaultValue={studentTiming}
                  onChange={changeLearnerTimingHandler}
                >
                  <option value="All">All</option>
                  <option value="Dates for Range">Dates for Range</option>
                  <option value="# of Days"># of Days</option>
                </SelectField>
              </DriverColumn>

              {studentTiming === "# of Days" && (
                <>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Day Ago
                    </p>
                    <input
                      style={{
                        width: 40,
                        textAlign: "center",
                        marginTop: -2,
                        color: "#FF3135",
                      }}
                      type="text"
                      placeholder="15"
                      id="days"
                      name="days"
                      value={progressDaysAgo}
                      onChange={changeLearnerProgressDaysAgo}
                    />
                  </DriverColumn>
                </>
              )}

              {studentTiming === "Dates for Range" && (
                <>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      Start Date
                    </p>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      style={{
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={
                        new Date(learnerStartDate)?.toISOString().split("T")[0]
                      }
                      onChange={changeStartLearnerDate!}
                    />
                  </DriverColumn>
                  <DriverColumn>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 14,
                        marginBottom: 0,
                      }}
                    >
                      End Date
                    </p>
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      style={{
                        padding: 5,
                        border: "1px solid #CED4DA",
                        fontSize: 12,
                        fontFamily: "Poppins",
                        borderRadius: 5,
                        color: "#495057",
                      }}
                      value={
                        new Date(learnerEndDate)?.toISOString().split("T")[0]
                      }
                      onChange={changeEndLearnerDate}
                    />
                  </DriverColumn>
                </>
              )}
            </DriverRow>
          </div>

          <div className="connection-score" style={{ marginTop: "18%" }}>
            <DriverColumn style={{ paddingLeft: 0, paddingTop: "10%" }}>
              <DriverTitle>Momentum Score </DriverTitle>
              <DriverColumn Direction="row" alignment="flex-start">
                <DriverText>Change Lable </DriverText>
                <DriverTextArea
                  value={momentumLabel}
                  onChange={onMomentumLabel}
                  //   value={timeRangeValue.momentumLabel}
                  //   onChange={(e) => {
                  //     setTimeRangeValue({
                  //       ...timeRangeValue,
                  //       momentumLabel: e.target.value,
                  //     });
                  //   }}
                  placeholder="Momentum score"
                  style={{ marginLeft: 25, width: 230 }}
                />
              </DriverColumn>
              <DriverColumn style={{ marginLeft: 10 }}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 7,
                  }}
                >
                  Days Ago
                </p>
                <input
                  style={{
                    width: 40,
                    textAlign: "center",
                    marginTop: -2,
                    color: "#9DA4AC",
                  }}
                  type="text"
                  placeholder="-"
                  id="daysAgo"
                  name="daysAgo"
                  value={momentumDaysAgo}
                  onChange={onMomentumDaysAgo}
                  //   value={timeRangeValue.momentum_days}
                  //   onChange={(e) => {
                  //     setTimeRangeValue({
                  //       ...timeRangeValue,
                  //       momentum_days: parseInt(e.target.value),
                  //     });
                  //   }}
                />
              </DriverColumn>
            </DriverColumn>
          </div>
        </DriverMainLeftBox>

        <DriverMainLeftBox>
          <div className="key">
            <DriverColumn>
              <DriverTitle>Charts View </DriverTitle>
              {/* <SelectComponent
                options={chartView}
                selectedValue={project?.chartView}
                onChange={(e) => {
                  dispatch(
                    updateProjectAction(selectedProjectId, {
                      chartView: e.target.value,
                    })
                  );
                }}
                style={{
                  paddingTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 6,
                  minWidth: 129,
                  fontSize: 10,
                  backgroundPosition: "bottom 10px right 6px",
                }}
              /> */}
              <SelectField
                defaultValue={chartViewType!}
                onChange={(e) => {
                  onChartViewType!(e.target.value);
                }}
              >
                <option value="Standard">Standard</option>
                <option value="Academic">Academic</option>
              </SelectField>
              {chartViewType === "Academic" && (
                <>
                  <DriverTitle>Academic View Type </DriverTitle>
                  <SelectField
                    defaultValue={academicScoreType!}
                    onChange={(e) => {
                      onAcademicScoreType!(e.target.value);
                    }}
                  >
                    <option value="Default Gradegy">Default Gradegy</option>
                    <option value="ASU Acceleration">ASU Acceleration</option>
                  </SelectField>
                </>
              )}
            </DriverColumn>

            <DriverColumn>
              <DriverSmallTitle>Course Data Import Type </DriverSmallTitle>

              <SelectField defaultValue={chartViewType!} onChange={() => {}}>
                <option value="Standard">Standard</option>
                <option value="Academic">Academic</option>
              </SelectField>
            </DriverColumn>
            <DriverColumn>
              <DriverSmallTitle>Registaration Date Range </DriverSmallTitle>
              <DriverColumn style={{maxWidth:180}}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  Start Date
                </p>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  style={{
                    padding: 5,
                    border: "1px solid #CED4DA",
                    fontSize: 12,
                    fontFamily: "Poppins",
                    borderRadius: 5,
                    color: "#495057",
                  }}
                  value={new Date(termStartDate!)?.toISOString().split("T")[0]}
                  onChange={onTermStartDate!}
                />
              </DriverColumn>
              <DriverColumn style={{maxWidth:180}}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: 14,
                    marginBottom: 0,
                  }}
                >
                  End Date
                </p>
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  style={{
                    padding: 5,
                    border: "1px solid #CED4DA",
                    fontSize: 12,
                    fontFamily: "Poppins",
                    borderRadius: 5,
                    color: "#495057",
                  }}
                  value={new Date(termEndDate!)?.toISOString().split("T")[0]}
                  onChange={onTermEndDate}
                />
              </DriverColumn>
            </DriverColumn>

            <DriverColumn
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <DriverSmallTitle style={{ width: "100%", paddingBottom: 2 }}>
                This Term{" "}
              </DriverSmallTitle>

              <SelectField
                defaultValue={chartViewType!}
                onChange={(e) => {
                  const existFilter = termFilter.find(
                    (item) => item === e.target.value
                  );
                  if (!existFilter)
                    setTermFilter([...termFilter, e.target.value]);
                  onSelectedTerm!([...termFilter, e.target.value]);
                }}
              >
                {getCourseEnrollment?.entities["undefined"]?.term.map(
                  (item) => (
                    <option value={item._id.term} key={item._id.term}>{`${
                      item._id.term
                    }
              (${new Date(item.registration_date).toLocaleDateString(
                "US-en"
              )})`}</option>
                  )
                )}
                {/* <option value="Standard">Self Paced</option>
                <option value="Academic">Academic</option> */}
              </SelectField>
              <SettingTermButton>
                <IoIosAddCircle color="#34C38F" size="35" />
              </SettingTermButton>
            </DriverColumn>

            <DriverColumn>
              {/* <DriverSmallTitle style={{ fontSize: 16 }}>
                Term{" "}
              </DriverSmallTitle> */}
              <SettingTermsContainer>
                {termFilter.map((item) => (
                  <SettingTermsItem key={item}>
                    {item}{" "}
                    <SettingTermItemButton>
                      <SettingTermItemButtonIcon
                        onClick={() => {
                          setTermFilter(termFilter.filter((i) => item !== i));
                        }}
                        src={images.closeIcon}
                        alt={item}
                      />
                    </SettingTermItemButton>{" "}
                  </SettingTermsItem>
                ))}
                {/* {getCourseEnrollment?.entities["undefined"]?.term.map(item => <SettingTermsItem key={item._id.term}>{item._id.term}</SettingTermsItem>)} */}

                {/* <SettingTermsItem>Spring C 2023</SettingTermsItem> */}
              </SettingTermsContainer>
            </DriverColumn>

            <DriverColumn>
              <DriverSmallTitle
                style={{
                  fontSize: 15,
                  display: "flex",
                  justifyContent: " space-between",
                  maxWidth: 460,
                  alignItems: "center",
                  paddingBottom:8
                }}
              >
                Progress Where They Should Be
                <input
                  style={{
                    width: 85,
                    textAlign: "center",
                    marginTop: -2,
                    color: "#9DA4AC",
                    height: "fit-content",
                  }}
                  type="text"
                  placeholder="0-100"
                  id=""
                  name=""
                  value={updateAllWhereYouShouldBe!}
                  onChange={onUpdateAllWhereYouShuoldBe}
                />
                <SettingApplyButton>Apply All</SettingApplyButton>
              </DriverSmallTitle>
              <SettingTermsContainer>
                {getCourseEnrollment?.entities[
                  "undefined"
                ]?.courseenrollment?.map((item) => (
                  <SettingProgressItem key={item._id}>
                    <p>{item._id}</p>
                    <input
                      style={{
                        width: 85,
                        textAlign: "center",
                        marginTop: -2,
                        color: "#9DA4AC",
                        height: "fit-content",
                      }}
                      type="text"
                      placeholder="0-100"
                      id=""
                      name=""
                      value={item.where_you_should_be}
                      onChange={async (e) => {
                        console.log(e.target.value);
                        if (e.target.value.length >= 2) {
                          console.log("inside", e.target.value);
                          try {
                            await updateCourseEnrollmentByName({
                              project: String(project?._id),
                              where_you_should_be: Number(e.target.value),
                              courseName: item._id,
                            }).unwrap();
                          } catch (err) {}
                        }
                      }}
                    />
                  </SettingProgressItem>
                ))}
                {/* <SettingProgressItem>
                  <p>
                    Computer Applications and Information Technology
                    (self-paced)
                  </p>
                  <input
                    style={{
                      width: 85,
                      textAlign: "center",
                      marginTop: -2,
                      color: "#9DA4AC",
                      height: "fit-content",
                    }}
                    type="text"
                    placeholder="0-100"
                    id=""
                    name=""
                    value={""}
                    onChange={() => {}}
                  />
                </SettingProgressItem>

                <SettingProgressItem>
                  <p>Computer Applications </p>
                  <input
                    style={{
                      width: 85,
                      textAlign: "center",
                      marginTop: -2,
                      color: "#9DA4AC",
                      height: "fit-content",
                    }}
                    type="text"
                    placeholder="0-100"
                    id=""
                    name=""
                    value={""}
                    onChange={() => {}}
                  />
                </SettingProgressItem>

                <SettingProgressItem>
                  <p>Computer Applications and Information </p>
                  <input
                    style={{
                      width: 85,
                      textAlign: "center",
                      marginTop: -2,
                      color: "#9DA4AC",
                      height: "fit-content",
                    }}
                    type="text"
                    placeholder="0-100"
                    id=""
                    name=""
                    value={""}
                    onChange={() => {}}
                  />
                </SettingProgressItem> */}
              </SettingTermsContainer>
            </DriverColumn>

            <DriverColumn>
              <DriverSmallTitle style={{ fontSize: 18 }}>
                Online Course URL{" "}
              </DriverSmallTitle>
              <input
                style={{
                  minWidth: 240,
                  textAlign: "center",
                  marginTop: -2,
                  color: "#9DA4AC",
                  height: "fit-content",
                  width: "fit-content",
                  minHeight: 30,
                }}
                type="text"
                placeholder="None"
                id=""
                name=""
                value={""}
                onChange={() => {}}
              />
            </DriverColumn>
          </div>
        </DriverMainLeftBox>
      </DriverMainBox>
      <DriverColumn
        Direction="row"
        alignment="center"
        style={{ marginTop: "4%", width: "35%", marginLeft: "29%" }}
      >
        <DriverSaveButton
          style={{ marginRight: "5%" }}
          onClick={() => {
            console.log(updateAllWhereYouShouldBe);
            onSave();
            updateCourseEnrollmentProjectHandler();
          }}
        >
          SAVE
        </DriverSaveButton>
        <DriverSaveButton onClick={() => {}}>Cancel</DriverSaveButton>
      </DriverColumn>
    </>
  );
};

export default Learner;
