import { EntityId, EntityState } from "@reduxjs/toolkit";
import React from "react";
import { Award } from "../../../awards/types/type";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StudentAwardItemPresentation from "./StudentAwardItemPresentation";
import { FaPlus } from "react-icons/fa";
import { StudentButton } from "../../../experience/presentation/expereince.list.presentation/experienceListPresentation.styles";
import XpPanelPresentation from "../xpPaneelPresentation/XpPanelPresentation";
type StudentAwardListPresentationProps = {
  awardsList: EntityState<Award>;
  onRemoveAward: (id: EntityId) => void;
  xpDescriptoin: string | null;
  xpAmount: number | null;
  gemAmount: number | null;

  onXpDescripton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onXpAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGemAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenAward: () => void;
  onCloseAward: () => void;
  openAward: boolean;
  onAddAward: () => void;
};
const StudentAwardListPresentation: React.FC<
  StudentAwardListPresentationProps
> = ({
  awardsList,
  onRemoveAward,
  onAddAward,
  onCloseAward,
  onGemAmount,
  onOpenAward,
  gemAmount,
  onXpAmount,
  onXpDescripton,
  openAward,
  xpAmount,
  xpDescriptoin,
}) => {
  return (
    <>
      <div style={{ position: "relative", width: "49%" }}>
        <div className="awarded-table">
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 14 }}
          >
            <h3 className="awarded-title">Manually Awarded Points </h3>
            <StudentButton
              style={{ borderRadius: 50, marginRight: 10, marginLeft: 10 }}
              onClick={onOpenAward}
              spaceLeft={""}
            >
              <FaPlus style={{ marginRight: 5 }} size={27} />{" "}
              <span>Award Points</span>{" "}
            </StudentButton>
            <div style={{ position: "relative" }}>
              {openAward ? (
                <XpPanelPresentation
                  gemAmount={gemAmount!}
                  onAddAward={onAddAward}
                  onGemAmount={onGemAmount!}
                  onXpAmount={onXpAmount!}
                  onXpDescription={onXpDescripton!}
                  onCloseAward={onCloseAward}
                  xpAmount={xpAmount!}
                  xpDescription={xpDescriptoin!}
                  style={{ top: "50px", left: "-200px" }}
                />
              ) : null}
            </div>
          </div>
          <TableContainer style={{ maxHeight: 320 }}>
            <Table className="awarder-container" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>XP</TableCell>
                  <TableCell>Gem</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {awardsList!.ids.map((id) => (
                  <StudentAwardItemPresentation
                    key={id}
                    award={awardsList.entities[id]!}
                    onRemoveAward={onRemoveAward}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default StudentAwardListPresentation;
