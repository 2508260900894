import React, { useEffect, useState } from "react";
import StudentFilterContainer from "../container/student.filter.container/StudentFilterContainer";
import Header from "../../../components/header/Header";
import StudentActionContainer from "../container/studentActionContainer/StudentActionContainer";
import StudentAddContainer from "../container/studentAddContainer/StudentAddContainer";
import { useGetAllTeamQuery } from "../../team/teamApiSlice/teamApiSlice";
import StudentListContainer from "../container/student.list.container/StudentListContainer";
import {
  useGetAllStudentsQuery,
  useLazyGetAllStudentsQuery,
} from "../api/studentApi";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { Student } from "../types/types";
import { useAddAwardMutation } from "../../awards/api/awardsApi";
import Loading from "../../../components/Loading/Loading";
import { useNavigate, useNavigation } from "react-router-dom";
import { selectAdmin } from "../../auth/authSlice/authSlice";

const StudentPage = () => {
  const navigate = useNavigate();
  const projectApp = useAppSelector(selectedProject);
  const admin = useAppSelector(selectAdmin);
  console.log(admin);
  const { data: teams, isLoading, isError, isSuccess } = useGetAllTeamQuery();
  const [
    addAward,
    { isLoading: awardLoading, isError: awardError, isSuccess: awardSuccess },
  ] = useAddAwardMutation();

  const [openStudent, setOpenStudent] = useState<boolean>(false);
  const [stautsState, setStatusState] = useState<string>("All");
  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );
  const [sort, setSort] = useState<string>("-createdAt");
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [term, setTerm] = useState<string>("null");
  const [xpDescription, setXpDescription] = useState<string | null>(null);
  const [xpDescriptionRequrie, setXpDescriptionRequire] =
    useState<boolean>(false);
  const [xpAmount, setXpAmount] = useState<number | null>(null);
  const [gemAmount, setGemAmount] = useState<number | null>(null);
  const [selectStudents, setSelectStudents] = useState<Student[]>([]);
  const [openAwardPanel, setOpenAwardPanel] = useState<boolean>(false);
  const [students, setStudents] = useState<EntityState<Student> | null>(null);

  const [studentListData, setStudentListData] =
    useState<EntityState<Student> | null>(null);

  const [showAddStudentButton, setShowAddStudentButton] =
    useState<boolean>(false);

  const {
    data: studentList,
    isSuccess: studentSuccess,
    isError: studentError,
    isLoading: studentLoading,
  } = useGetAllStudentsQuery({
    projectId: projectApp?._id!,
    category: category ? category!._id! : "null",
    subCategory: subCategory ? subCategory!._id : "null",
    limit: limit.toString(),
    page: page.toString(),
    sort: sort,
    term: term,
    status: stautsState,
  });

  const [
    studenyDataList,
    { isLoading: stuIsLoading, isSuccess: stuIsSuccess, isError: stuIsError },
  ] = useLazyGetAllStudentsQuery();

  useEffect(() => {
    (async () => {
      try {
        const stuList = await studenyDataList({
          projectId: projectApp?._id!,
          category: category ? category!._id! : "null",
          subCategory: subCategory ? subCategory!._id : "null",
          limit: limit.toString(),
          page: page.toString(),
          sort: sort,
          term: term,
          status: stautsState,
        }).unwrap();

        setStudentListData(stuList);
      } catch (error) {}
    })();
  }, [studentListData, term, sort, category, subCategory, studentList]);

  useEffect(() => {
    setStudents(studentList!);
    if (term !== "null" && page > 1) {
      console.log("term", term);
      console.log("page run this");
      setPage(1);
    } else {
      console.log("page run that");
      setPage(page);
    }
  }, [studentList]);

  useEffect(() => {
    console.log("studetns state in use effect", students);
  }, [students]);

  useEffect(() => {
    if (!projectApp) {
      navigate("/");
    }
  }, [projectApp]);

  useEffect(() => {
    if (admin) {
      const projectPermession = admin.projectAccess?.find(
        (item) => String(item.project) === String(projectApp?._id)
      );
      console.log("projectPermission", projectPermession);
      if (projectPermession) {
        if (projectPermession?.accessType === "Full") {
          setShowAddStudentButton(projectPermession.users!);
        }
      }

      if (admin.categoryAccess?.length === 1) {
        if (admin.categoryAccess[0].accessType === "Full") {
          setCategory({ _id: admin.categoryAccess[0].category as EntityId });
        } else {
          setCategory({ _id: admin.categoryAccess[0].category as EntityId });
          setSubCategory({
            _id: admin.subCategoryAccess![0].subcategory! as EntityId,
          });
        }
      }
    }
  }, [admin]);

  const onSelectActiveStauts = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setStatusState(e.target.value);
  };
  const onOpenClickHandler = () => {
    setOpenStudent(true);
  };

  const onCategory = (categorySelected: Partial<Category> | null) => {
    console.log("categorySelected", categorySelected);
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelectd: Partial<SubCategory> | null) => {
    console.log("sub", subCategorySelectd);
    setSubCategory(subCategorySelectd);
  };

  const onPage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onLimit = (limitNumber: number) => {
    setLimit(limitNumber);
  };
  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };
  const onSetTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setTerm(e.target.value);
    } else {
      setTerm("null");
    }
  };

  const onCloseClickHandler = () => {
    setOpenStudent(false);
  };

  const onSelectStudents = (
    e: React.ChangeEvent<HTMLInputElement>,
    student: Student
  ) => {
    console.log("checked", e.target.checked);
    if (e.target.checked) {
      setSelectStudents([...selectStudents, student]);
    } else if (!e.target.checked) {
      console.log("this run");
      const result = selectStudents.filter((item) => item._id !== student._id);
      setSelectStudents(result);
    }
  };

  const onXpDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpDescription(e.target.value);
  };

  const onXpAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXpAmount(Number(e.target.value));
  };

  const onGemAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGemAmount(Number(e.target.value));
  };

  const onAddAwardHandler = async () => {
    try {
      if (xpDescription === null) {
        setXpDescriptionRequire(true);
        return;
      }
      await addAward({
        description: xpDescription!,
        xp: xpAmount! ? xpAmount : 0,
        gem: gemAmount! ? gemAmount : 0,
        project: projectApp!._id! as any,
        students: selectStudents,
      }).unwrap();
      onCloseAward();
      setXpDescriptionRequire(false);
      setXpAmount(null);
      setGemAmount(null);
      setXpDescription(null);
    } catch (error) {}
  };

  const onOpenAward = () => {
    setOpenAwardPanel(true);
  };

  const onCloseAward = () => {
    setOpenAwardPanel(false);
  };
  let content;
  if (studentLoading || isLoading || awardLoading) {
    content = <Loading />;
  } else {
    content = (
      <>
        <StudentFilterContainer
          project={projectApp!}
          category={category!}
          onSelectActive={onSelectActiveStauts}
          onSelectCategory={onCategory}
          onSelectSubCategory={onSubCategory}
        />
        <StudentActionContainer
          term={term!}
          onOpenHandler={onOpenClickHandler}
          onSetTerm={onSetTerm}
          gemAmount={gemAmount!}
          onAddAward={onAddAwardHandler!}
          onGemAmount={onGemAmount!}
          onXpAmount={onXpAmount!}
          onXpDescripton={onXpDescription!}
          xpAmount={xpAmount!}
          xpDescriptoin={xpDescription!}
          openAward={openAwardPanel}
          onCloseAward={onCloseAward}
          onOpenAward={onOpenAward}
          descriptionError={xpDescriptionRequrie}
        />
        <StudentAddContainer
          open={openStudent}
          onClose={onCloseClickHandler}
          deafultTeam={teams!.entities[teams!.ids[0]]}
        />

        {studentSuccess && students && (
          <StudentListContainer
            term={term}
            onSort={onSort}
            sort={sort}
            students={studentListData!}
            studentsSuccess={studentSuccess}
            onSelectStudents={onSelectStudents}
            onChangeLimit={onLimit}
            limit={limit}
            page={page}
            onPage={onPage}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Header title="Student" />
      {content}
    </>
  );
};

export default StudentPage;
