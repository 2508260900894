import React from "react";
import { Bag } from "../../../../bag/types/types";
import { Switch } from "@mui/material";
import { TdBody } from "./shop.right.col.style";
type ShopEditReviewStudentPresentationsProps = {
  itemBag: Bag;
  OnPickupBagClick: (picked: boolean, bag: Partial<Bag>) => void;
};
const ShopEditReviewStudentPresentations: React.FC<
  ShopEditReviewStudentPresentationsProps
> = ({ itemBag, OnPickupBagClick }) => {
  console.log("itemBag", itemBag);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "0 40px",
        }}
      >
        <TdBody style={{ justifyContent: "flex-start" }}>
          {String(itemBag?.student)}
        </TdBody>
        <TdBody>
          {itemBag?.createdAt &&
            new Date(
              new Date(itemBag?.createdAt)?.toISOString()
            )?.toLocaleDateString("en-US")}
        </TdBody>
        <TdBody>
          <Switch
            checked={itemBag.pickedUp}
            onChange={(e) => {
              OnPickupBagClick(!itemBag.pickedUp, itemBag);
            }}
          />
        </TdBody>
        <TdBody>
          {itemBag.updatedAt
            ? new Date(itemBag.updatedAt).toLocaleString("en-US")
            : "---"}
        </TdBody>
        <TdBody style={{ fontSize: "10px" }}>{itemBag.sotck_id}</TdBody>
      </div>
    </>
  );
};

export default ShopEditReviewStudentPresentations;
