import React from "react";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { StudentFilterPresentationProps } from "../../types/types";
import SelectField from "../../../../components/design/selectFiled/selectField";
import {
  StudentFillterLabel,
  StudentFillterSelec,
} from "./StudentFilterPresentation.style";

const StudentFilterPresentation: React.FC<StudentFilterPresentationProps> = ({
  categoryID,
  projectID,
  onSelectCategory,
  onSelectSubCategory,
  onSelectActiveStatus,
}) => {
  return (
    <div
      style={{
        display: "flex",
        marginTop: 10,
        marginLeft: 40,
        maxWidth: 1531,
      }}
    >
      <div
        style={{
          padding: "0 15px",
          width: "212px",
        }}
      >
        <StudentFillterLabel>Category</StudentFillterLabel>
        <FilterCategoryContainer
          projectID={projectID!}
          selectedDefault={categoryID as string}
          onSelectCategory={onSelectCategory}
        />
      </div>
      <div
        style={{
          padding: "0 15px",
          width: "200px",
        }}
      >
        <StudentFillterLabel>Sub Category</StudentFillterLabel>
        <FilterSubCategoryContainer
          categoryID={categoryID}
          onSelectSubCategory={onSelectSubCategory}
        />
      </div>
      <div
        style={{
          padding: "0 15px",
          width: "200px",
        }}
      >
        <StudentFillterLabel>Status</StudentFillterLabel>
        <StudentFillterSelec onChange={onSelectActiveStatus}>
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="InActive">InActive</option>
        </StudentFillterSelec>
      </div>

      <div
        style={{
          padding: "0 15px",
          width: "200px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StudentFillterLabel>Tag</StudentFillterLabel>
          <StudentFillterSelec onChange={() => {}}>
            <option value="All">All</option>
          </StudentFillterSelec>
        </div>
      </div>
    </div>
  );
};

export default StudentFilterPresentation;
