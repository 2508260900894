import React from "react";
import { IoMdAdd } from "react-icons/io";
import { CategoryAddPresentationProps } from "../../types/types";
import { ProjectSection, ProjectInput, ProjectCircleIcon } from "../categorychagneLabelpresentation/category.changelabel.presentation.style";
const CategoryAddPresentation: React.FC<CategoryAddPresentationProps> = ({
  title,
  onChangeTitle,
  onSave,
}) => {
  return (
    <ProjectSection style={{ marginTop: 45 }} alignment={""} Direction={""}>
      <ProjectInput
        type="text"
        name="category"
        id="category"
        value={title}
        onChange={onChangeTitle}
      />
      <ProjectCircleIcon
        style={{ marginLeft: 12, cursor: "pointer" }}
        onClick={onSave}
      >
        <IoMdAdd color="#fff" size={14} />
      </ProjectCircleIcon>
    </ProjectSection>

  );
};

export default CategoryAddPresentation;
