import React from "react";
import { HomeProjectAllPresentationProps } from "../../types/types";
import HomeProjectItemPresentation from "../HomeProjectItemPresentation/HomeProjectItemPresentation";
import {
  HomeProjectAllTitle,
  ProjectAllWrapper,
} from "./home.project.all.presenation.style";

const HomeProjectAllPresentation: React.FC<HomeProjectAllPresentationProps> = ({
  project,
  onSelectProject,
}) => {

  return (
    <div style={{ marginTop: "15px" }}>
      <HomeProjectAllTitle style={{ padding: "17px  30px" }}>
        ALL
      </HomeProjectAllTitle>
      <ProjectAllWrapper>
 
        {project?.ids.map((id) => (
          <HomeProjectItemPresentation
            key={id}
            projectItem={project.entities[id]!}
            onSelectProject={onSelectProject}
          />
        ))}
      </ProjectAllWrapper>
    </div>
  );
};

export default HomeProjectAllPresentation;
