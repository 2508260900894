import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import React, { FC, SyntheticEvent, useState } from "react";
import { useLazySearchAdminQuery } from "../../adminApiSlice/adminApiSlice";
import { SearchResult } from "../../../../types/types";
import { SearchSchoolDistrictContainerProps } from "../../../../types/types";
import SearchField from "../../../../components/SearchField/SearchField";
import { Admin } from "../../types/types";

const SearchAdminContainer: FC<SearchSchoolDistrictContainerProps<Admin>> = ({
  onChangeState,
  lead_contact,
}) => {
  const [options, setOptions] = React.useState<SearchResult[]>([]);

  const [searchAdmin, { isLoading, error }] = useLazySearchAdminQuery();

  const onFetch = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    try {
      if (value) {
        const newOptions = await searchAdmin(value).unwrap();

        setOptions(newOptions);
      }
    } catch (error) {}
  };

  const onChagneHandler = (
    event: SyntheticEvent<Element, Event>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value?.id) {
      onChangeState({ _id: value.id, fullName: value.label });
    }
  };

  return (
    <SearchField
      options={options}
      loading={isLoading}
      handleInputChange={onFetch}
      onChangeHandle={onChagneHandler}
      lead_contact={lead_contact}
    />
  );
};

export default SearchAdminContainer;
