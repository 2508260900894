import React from "react";
import { HomeProjectItemPresentationProps } from "../../types/types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  CardItemWarpper,
  PorjectItemTypeWrapper,
  ProjectItemDetailsWrapper,
  ProjectItemTitleWrapper,
} from "./home.project.item.presentation";
import { images } from "../../../../constants";
import { useAppDispatch } from "../../../../app/hooks";
import { setOrganization } from "../../appSlice/appslice";
const HomeProjectItemPresentation: React.FC<
  HomeProjectItemPresentationProps
> = ({
  projectItem,
  onSelectProject,
  onSelectOrganization,
  orgaizationaItem,
}) => {
  const dispatch = useAppDispatch();
  return (
    <CardItemWarpper
      onClick={() => {
        if (orgaizationaItem) {
          onSelectOrganization!(orgaizationaItem!);
          dispatch(setOrganization(orgaizationaItem));
        }

        onSelectProject(projectItem);
      }}
    >
      <CardMedia
        sx={{
          height: 174,
          objectFit: "contain",
          width: "50%",
          backgroundSize: "contain",
        }}
        image={projectItem?.image}
        title={projectItem?.title}
      />
      <CardContent
        sx={{
          border: "3px solid rgb(255, 255, 255)",
          width: "97%",
          marginTop: "-23px",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          padding: "0 !important",
        }}
      >
        <ProjectItemTitleWrapper>{projectItem?.title}</ProjectItemTitleWrapper>
        <PorjectItemTypeWrapper>
          {projectItem?.project_type}
        </PorjectItemTypeWrapper>
        <ProjectItemDetailsWrapper>
          <p>{projectItem?.studentsNumber} Students</p>
          <span>
            <img src={images?.shopIconImage} alt={projectItem?.title} />
          </span>
          <span>
            <img src={images?.surveyIconImage} alt={projectItem?.title} />
          </span>
        </ProjectItemDetailsWrapper>
      </CardContent>
    </CardItemWarpper>
  );
};

export default HomeProjectItemPresentation;
