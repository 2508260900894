import styled from "@emotion/styled";
import { Card } from "@mui/material";

export const ProjectItemTitleWrapper = styled.p`
font-family: "Rajdhani";
font-size: 24px;
font-weight: 600;
color: rgb(255, 255, 255);
line-height: 24px;
margin: 0px;
padding: 6px 12px;
background-color: rgb(62, 63, 94);
border-radius: 12px 12px 0 0;
text-align: left;
`;

export const PorjectItemTypeWrapper = styled.p`
background-color: rgb(11, 196, 184);
font-family: Rajdhani;
font-size: 15px;
font-weight: 600;
color: rgb(255, 255, 255);
line-height: 15px;
margin: 0px;
padding: 6px 12px;
text-align: left;
`;

export const ProjectItemDetailsWrapper = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-family: Rajdhani;
    font-size: 26px;
    line-height: 26px;
    margin: 0px;
    padding-right: 20px;
  }
  & > span {
    padding: 0 5px;
  }
`;

export const CardItemWarpper = styled(Card)`
    width: clamp(260px, 98%, 365px);
    box-shadow: rgb(0 0 0 / 18%) 0px 3px 6px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin-top:15px;
    margin-bottom:50px;
    border: none;
    background-color: white;
`;
