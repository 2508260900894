import React from "react";
import ExperienceDetailsHeaderPresentation from "../../presentation/experience.details.presentation/ExperienceDetailsHeaderPresentation";
import { ExperienceType } from "../../../experiencetype/types/types";
type ExperienceDetailsHeaderContainerProps = {
  onSelectHandler: (exptype: Partial<ExperienceType> | null) => void;
  experienceType?: Partial<ExperienceType> | null;
};
const ExperienceDetailsHeaderContainer: React.FC<
  ExperienceDetailsHeaderContainerProps
> = ({ onSelectHandler, experienceType }) => {
  return (
    <ExperienceDetailsHeaderPresentation
      onSelectHandler={onSelectHandler}
      experienceType={experienceType}
    />
  );
};

export default ExperienceDetailsHeaderContainer;
