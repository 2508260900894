import { TableRow, TableCell } from "@mui/material";
import React from "react";
import { MdEdit } from "react-icons/md";
import EditButton from "../../../../components/EditButton";
import {
  EditButtonText,
  EditButtonIcon,
} from "../../../experience/presentation/experience.review.presentation/experience.review.styles";
import { RaffelTicket } from "../../types/types";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
type RaffelticketListItemProps = {
  raffel: RaffelTicket;
  editHandler: (id: EntityId) => void;
};
const RaffelTicketListItem: React.FC<RaffelticketListItemProps> = ({
  raffel,
  editHandler,
}) => {
  const user = useAppSelector(selectAdmin);
  return (
    <TableRow style={{ borderBottom: "2px solid #EFF2F7" }}>
      <TableCell style={{ borderBottom: 0 }}>
        <img
          src={raffel!.image}
          alt="asternuts"
          width="50"
          height="50"
          style={{ borderRadius: 50 }}
        />
      </TableCell>
      <TableCell style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>{raffel!.title!}</TableCell>
      <TableCell 
       style={{
        color: "#797E83", borderBottom: 0 ,
                    maxWidth: 370,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins',
              }}>
        {raffel!.description}
      </TableCell>
      <TableCell
        style={{
          borderBottom: 0,
          whiteSpace: "normal",
    
          maxWidth: 120,
          textAlign: "center",
          fontSize: 12,
          justifyContent: "center",
          fontFamily: 'Poppins',
        }}
      >
        {new Date(raffel!.startDate).toISOString().split("T")[0]}
      </TableCell>
      <TableCell style={{ borderBottom: 0,maxWidth: 120,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins', }}>
        {new Date(raffel!.endDate).toISOString().split("T")[0]}
      </TableCell>
      <TableCell style={{ borderBottom: 0,maxWidth: 120,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins', }}>
        {new Date(raffel!.drawDate).toISOString().split("T")[0]}
      </TableCell>

      <TableCell style={{ borderBottom: 0,maxWidth: 120,
                    textAlign: "center",
                    fontSize: 12,
                    justifyContent: "center",
                    fontFamily: 'Poppins', }} className="addXp">
        {/* {haveAccess && ( */}
        <EditButton
          style={{ marginLeft: "33px", width: "120px" }}
          onClick={() => {
            editHandler(raffel._id!);
          }}
        >
          <EditButtonText>Edit/Review</EditButtonText>
          <EditButtonIcon>
            <MdEdit color="#34C38F" size={12} />
          </EditButtonIcon>
        </EditButton>
        {/* )} */}
      </TableCell>
    </TableRow>
  );
};

export default RaffelTicketListItem;
