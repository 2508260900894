import React from "react";
import { Wrapper } from "./shopmidcol.styles";
import ShopInfoPresentation from "./ShopInfoPresentation";
import ShopAvailablePresentation from "./ShopAvailablePresentation";
import { store } from "../../../../../app/store";
import ShopEditInventoryPresenation from "./ShopEditInventoryPresenation";
type ShopEditReviewPresentationMidColPresentationProps = {
  costPerItem: number;
  onCostPerItem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unLockLevel: number;
  onUnlockLevel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimitState: boolean;
  onPurchaseLimitState: (e: React.ChangeEvent<HTMLInputElement>) => void;
  purchaseLimit: number;
  onPurchaseLimit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWheel: boolean;
  initialStock: number;
  currentStock: number;
  addStock: number;
  onAddStock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddStockHandler: () => void;
  onEditInventory: () => void;
};
const ShopEditReviewPresentationMidColPresenttation: React.FC<
  ShopEditReviewPresentationMidColPresentationProps
> = ({
  costPerItem,
  onCostPerItem,
  gem,
  onGem,
  unLockLevel,
  onUnlockLevel,
  purchaseLimitState,
  onPurchaseLimitState,
  purchaseLimit,
  onPurchaseLimit,
  store,
  onStore,
  prizeWheel,
  onPrizeWheel,
  addStock,
  currentStock,
  initialStock,
  onAddStock,
  onAddStockHandler,
  onEditInventory,
}) => {
  return (
    <Wrapper>
      <ShopInfoPresentation
        costPerItem={costPerItem}
        onCostPerItem={onCostPerItem}
        gem={gem}
        onGem={onGem}
        unLockLevel={unLockLevel}
        onUnlockLevel={onUnlockLevel}
        purchaseLimitState={purchaseLimitState}
        onPurchaseLimitState={onPurchaseLimitState}
        purchaseLimit={purchaseLimit}
        onPurchaseLimit={onPurchaseLimit}
      />
      <ShopAvailablePresentation
        store={store!}
        onStore={onStore}
        prizeWheel={prizeWheel}
        onPrizeWheel={onPrizeWheel}
      />
      <ShopEditInventoryPresenation
        addStock={addStock}
        onAddStock={onAddStock}
        currentStock={currentStock}
        initialStock={initialStock}
        onAddStockHandler={onAddStockHandler}
        onEditInventory={onEditInventory}
      />
    </Wrapper>
  );
};

export default ShopEditReviewPresentationMidColPresenttation;
