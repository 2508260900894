import { Switch } from "@mui/material";
import React from "react";
import { ExperienceCard } from "../../experience/types/types";
import "./experience.for.student.css";
type ExperienceForStudentItemPresnetationProps = {
  experience: ExperienceCard;
  onCompleteChange: (experience: ExperienceCard) => void;
};

const ExperienceForStudentItemPresentation: React.FC<
  ExperienceForStudentItemPresnetationProps
> = ({ experience, onCompleteChange }) => {
  return (
    <div className="st-info-experience-box-container">
      <div>
        <Switch
          checked={experience.completion?.complete}
          onChange={() => {
            onCompleteChange(experience);
          }}
          // isToggle={completeState}
          // onChangeToggle={changeCompeleteSwitchHandler}
        />
      </div>

      <p
        style={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {experience.title}
      </p>
      <p
        style={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {experience.description}
      </p>
      <p>
        {experience.completion
          ? new Date(experience?.completion?.updatedAt!)
              .toISOString()
              .split("T")[0]
          : "---"}
        {/* {changeExpState
        ? new Date(changeExpState.updatedAt).toLocaleDateString()
        : "---"} */}
      </p>
    </div>
  );
};

export default ExperienceForStudentItemPresentation;
