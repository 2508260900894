import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import React, { FC, SyntheticEvent, useState } from "react";
import { useLazySearchSchoolDistrictByNameQuery } from "../api/schoolDistrictApiSlice";
import { SearchResult } from "../../../types/types";
import { SearchSchoolDistrictContainerProps } from "../../../types/types";
import SearchField from "../../../components/SearchField/SearchField";
import { SchoolDistrict } from "../types/types";

const SearchSchoolDistrictContainer: FC<
  SearchSchoolDistrictContainerProps<SchoolDistrict>
> = ({ onChangeState, schoolDistrictID }) => {
  const [options, setOptions] = React.useState<SearchResult[]>([]);

  const [searchSchoolDistrictByName, { isLoading, error }] =
    useLazySearchSchoolDistrictByNameQuery();

  const onFetch = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    try {
      const newOptions = await searchSchoolDistrictByName(value).unwrap();

      setOptions(newOptions);
    } catch (error) {}
  };

  const onChagneHandler = (
    event: SyntheticEvent<Element, Event>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value?.id) {
      onChangeState({ _id: value.id, agancy_name: value.label });
    }
  };

  return (
    <SearchField
      options={options}
      loading={isLoading}
      handleInputChange={onFetch}
      onChangeHandle={onChagneHandler}
      schoolDistrictID={schoolDistrictID!}
    />
  );
};

export default SearchSchoolDistrictContainer;
