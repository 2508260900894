import { createEntityAdapter, EntityId, EntityState } from "@reduxjs/toolkit";

import { apiSlice } from "../../../app/api/apiSlice";
import { Crystal } from "../types";

export const fusesAdaptor = createEntityAdapter<Crystal>({
  selectId: (data) => data._id,
});

interface FusesInitialState extends EntityState<Crystal> {}

const initialState: FusesInitialState = fusesAdaptor.getInitialState();

export const fusesApiBuilder = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCrystals: builder.query<
      EntityState<Crystal>,
      {
        currentSystem: string;
        student: string;
      }
    >({
      query: (data) =>
        `/focuse-crystals/crystal?currentSystem=${data.currentSystem}&student=${data.student}`,
      transformResponse: (response: any) => {
        return fusesAdaptor.setOne(initialState, response);
      },
      providesTags: ["Crystal"],
    }),
  }),
});

export const { useGetCrystalsQuery, useLazyGetCrystalsQuery } = fusesApiBuilder;
