import { createSelector, createSlice } from "@reduxjs/toolkit";
import { AuthAdmin, IAuthinitialState } from "../types/auth.types";
import { getAdminStatus, loguot } from "../authThunks/authThunks";
import { RootState } from "../../../app/store";
const initialState: IAuthinitialState = {
  admin: null,
  status: "idle",
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAdminStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.admin = action.payload as AuthAdmin;
      })
      .addCase(getAdminStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload!;
      })
      .addCase(loguot.fulfilled, (state, action) => {
        state.admin = null;
      });
  },
});

export default authSlice.reducer;

//selectors
export const selectAdmin = createSelector(
  (state: RootState) => state.auth.admin,
  (admin) => admin
);

export const selectStatus = (state: RootState) => state.auth.status;
