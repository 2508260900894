import React, { useEffect, useState } from "react";
import FilterCategoryPresentation from "../../presentation/FilterCategoryPresentation/FilterCategoryPresentation";
import { FilterCategoryContainerProps } from "../../types/types";
import { useGetAllCategoryByProjectQuery } from "../../api/categoryApiSlice";

const FilterCategoryContainer: React.FC<FilterCategoryContainerProps> = ({
  projectID,
  onSelectCategory,
  selectedDefault,
  page,
}) => {
  console.log("PROJECT ID", projectID);
  const {
    data: categories,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllCategoryByProjectQuery(projectID!);

  const onSelectCategoryValue = (value: string) => {
    if (value === "All") {
      onSelectCategory(null);
    } else {
      onSelectCategory(categories?.entities[value]!);
    }
  };
  if (isLoading) {
    console.log("isloainddg", isLoading);
    return <p>Loading...</p>;
  }
  return (
    <>
      <>
        <FilterCategoryPresentation
          categories={categories!}
          onSelectCategory={onSelectCategoryValue}
          selectedDefault={selectedDefault!}
          page={page}
        />
      </>
    </>
  );
};

export default FilterCategoryContainer;
