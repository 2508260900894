import styled from "@emotion/styled";


export const NumberItemText = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  display: flex;
  justify-self: center;
  align-self: center;
`;

export const AvailableSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 132px;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const AvailableSectionTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
  @media (max-width: 1300px) {
    font-size: 20px;
  }
`;
export const AvailableSectionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 50%;
  gap: 10px;
  margin-bottom: 10px;
`;


export const AvailableText = styled.div`
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
`;
export const SerialNumberSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 61px;
  width: 390px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;


export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 430px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  gap: 12px;
  background-color: white;
  @media (max-width: 1650px) {
    width: 360px;
  }
`;
export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  flex: 1;
  margin-top: 12px;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const InfoTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: center;
`;
export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 13px;
`;
export const InfoText = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
`;
export const InfoTextArea = styled.input`
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  width: 145px;
  height: 30px;
  margin-right: 12px;
  text-align: center;
  & ::placeholder {
    text-align: center;
  }
`;
export const DigitalCodeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 390px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  flex: 1;
  @media (max-width: 1650px) {
    width: 340px;
  }
`;
export const DigitalCodeTitle = styled.div`
  display: flex;
  align-self: center;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
export const DigitalCodeItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
export const StockIdText = styled.div`
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: center;
  width: 50%;
`;
export const AddItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const AddItemBtn = styled.button`
  width: 205px;
  height: 48px;
  background-color: #34c38f;
  color: white;
  border-radius: 12px;
  border: none;
  outline: none;
`;
export const DigitalCodeAddBtn = styled.button`
  width: 175px;
  height: 32px;
  margin-bottom:5px;
  background-color: #34c38f;
  color: white;
  border-radius: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-weight: 600;
`;
