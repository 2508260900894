import { createSlice } from "@reduxjs/toolkit";
import { Organization } from "../../organization/types/types";
import { Project } from "../../project/types/types";
import { RootState } from "../../../app/store";

interface IInitialState {
  organization: Partial<Organization> | null;
  project: Partial<Project> | null;
}

const initialState: IInitialState = {
  organization: null,
  project: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
  },
});

export const selectedProject = (state: RootState) => state.app.project;
export const selectedOrganization = (state: RootState) =>
  state.app.organization;

export const { setOrganization, setProject } = appSlice.actions;
export default appSlice.reducer;
