import React from "react";
import { ProjectInput, ProjectLabel, ProjectSection } from "../../../category/presentation/categorychagneLabelpresentation/category.changelabel.presentation.style";

const SubCategoryChangeLabelPresentation = () => {
  return (
    <ProjectSection
      style={{ display: "block", margin: "0 auto" }} alignment={""} Direction={""}  >
      <h3 className="st-info-title text-center">SubCategory</h3>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ProjectLabel htmlFor="survey" style={{ marginTop: 5 }}>
          Change Label
        </ProjectLabel>
        <ProjectInput
          type="text"
          id="subcategiry"
          value={""}
          onChange={() => {
          }}
          style={{ marginLeft: 5 }}
        />
      </div>
    </ProjectSection>

  );
};

export default SubCategoryChangeLabelPresentation;
