import React from "react";
import { AdminItemPresentationProps } from "../../types/types";
import { TableCell, TableRow } from "@mui/material";
import { AdminListItemImageStyle } from "./admin.item.presentation.styles";
import { Link } from "react-router-dom";

const AdminItemPresentation: React.FC<AdminItemPresentationProps> = ({
  item,
}) => {
  return (
    <TableRow>
      <TableCell>
        <AdminListItemImageStyle src={item.image} />
      </TableCell>
      <TableCell>
        <Link style={{ color: "#495057", fontSize: 15, textDecoration: "none" }} to={`/admin/${item._id}`}>{item.fullName}</Link>
      </TableCell>
      <TableCell>{item.adminLevel}</TableCell>
      <TableCell>
        {item.organization ? item.organization.title : "All"}
      </TableCell>
      <TableCell>
        {item.projects!.length > 0
          ? item.projects!.map((it) => <p>{it.title}</p>)
          : "All"}
      </TableCell>
      <TableCell>Categories</TableCell>
      <TableCell>SubCategories</TableCell>
    </TableRow>
  );
};

export default AdminItemPresentation;
