import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { selectCategoryId } from "../../../category/categorySlice/categorySlice";
import SubCategoryChangeLabelContainer from "../subcategory.changelabel.container/subcategory.changelabel.container";
import SubCategoryAddContainer from "../subcategory.add.container/subcategory.add.container";
import { useGetAllSubCategoryByCategoryIdQuery } from "../../api/subCategoryApiSlice";
import SubCategoryListContainer from "../subcategory.list.container/subcategory.list.container";

const SubCategoryContainer = () => {
  const categoryId = useAppSelector(selectCategoryId);

  return (
    <div style={{width:"30%"}}>
      <SubCategoryChangeLabelContainer />
      <SubCategoryAddContainer categoryId={categoryId!} />
      <SubCategoryListContainer />
    </div>
  );
};

export default SubCategoryContainer;
