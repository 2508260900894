import React, { useEffect, useState } from "react";
import "./student.xp.css";
import { images } from "../../constants";
// import shiled from "../../assets/image/profile-level.png";
type StudentXpProps = {
  xp: number;
};
const StudentXp: React.FC<StudentXpProps> = ({ xp }) => {
  const [level, setLevel] = useState(0);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const result = xp / 3500;
    const levelNumber = Math.floor(result);
    setLevel(levelNumber);
    const percentProgress = Number((result - levelNumber).toFixed(2)) * 100;
    setPercent(percentProgress);
  }, [xp]);
  return (
    <div className="student-xp">
      <div className="shiled">
        <img src={images.Sheild} alt="shild" />
        <h3>
          <p style={{ fontSize: 14 }}>Level</p>
          <p style={{ fontSize: 30, lineHeight: "20px" }}>{level}</p>
        </h3>
      </div>
      <div className="xp-content">
        <h1 className="xp-content-title">{xp} XP</h1>
        <div className="xp-progress-container">
          <div className="progress-xp" style={{ width: `${percent}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default StudentXp;
