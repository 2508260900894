import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { ExperienceCard } from "../../experience/types/types";
import { Student, StudentSystemType } from "../../students/types/types";
import { IResponseList } from "../../../types/types";

const experienceStudentAdptor = createEntityAdapter<ExperienceCard>({
  selectId: (experience) => experience._id,
});

interface ExperiencesForStudentInitialState
  extends EntityState<ExperienceCard> {}

const initialState: ExperiencesForStudentInitialState =
  experienceStudentAdptor.getInitialState();

export const extendedExperiencesStudentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllExperienceStudent: builder.query<
      EntityState<ExperienceCard>,
      {
        currentSystem: string;
        studentId: string;
      }
    >({
      query: (data) =>
        `experience-card/expereincesstudent?studentId=${data.studentId}&currentSystem=${data.currentSystem}`,
      transformResponse: (response: IResponseList<ExperienceCard[]>) => {
        const expData = response.data;
        const metaData = response.metadata;
        return experienceStudentAdptor.setAll(initialState, response.data);
      },
       //change the provideTags to the simple version
      providesTags: [
       "ExperienceStudent"
      ],
    }),

    updateExperienceStudent: builder.mutation<
      EntityState<ExperienceCard>,
      {
        experience: ExperienceCard;
        student: Student;
        complete: boolean;
        currentSystem: StudentSystemType;
      }
    >({
      query: (data) => ({
        url: `/experience-student-completion`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: ExperienceCard) => {
        console.log("response", response);
        return experienceStudentAdptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "ExperienceStudent",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "ExperienceStudent" as const,
          id,
        })),
        "Student",
        "LeaderBoard",
        "ExperienceCard",
        "ProfileChart",
        "StudentCompletionPhoto",
      ],
    }),
  }),
});

export const {
  useGetAllExperienceStudentQuery,
  useUpdateExperienceStudentMutation,
} = extendedExperiencesStudentApi;
