import styled from "@emotion/styled";
import SelectField from "../../../../components/design/selectFiled/selectField";

export const StudentFillterSelec = styled(SelectField)`
<<<<<<< HEAD
  padding: 3px 20px;
  width: 183px;
  font-size: 12px;
  border: 1px solid rgb(206, 212, 218);
  font-family: Poppins;
  border-radius: 5px;
  color: rgb(73, 80, 87);
  appearance: none;
  margin-right: 11px;
  position: relative;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAABHNCSVQICAgIfAhkiAAAADVJREFUCFtj9AgITWBkYJrPgAT+M/xfuGPDKqA4ECArgEmAxMGSMAVArgNIB0wMLolsLIwNAD2NEgVESBCSAAAAAElFTkSuQmCC)
    right 6px bottom 13px no-repeat white;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  background-position: 96% 60%;
=======
        padding: 5px 20px;
        width: 156px;
        font-size: 12px;
        border: 1px solid rgb(206, 212, 218);
        font-family: Poppins;
        border-radius: 5px;
        color: rgb(73, 80, 87);
        appearance: none;
        margin-right: 11px;
        position: relative;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAABHNCSVQICAgIfAhkiAAAADVJREFUCFtj9AgITWBkYJrPgAT+M/xfuGPDKqA4ECArgEmAxMGSMAVArgNIB0wMLolsLIwNAD2NEgVESBCSAAAAAElFTkSuQmCC) right 6px bottom 13px no-repeat white;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top:5px;
            background-position: 96% 60%;

>>>>>>> f7b141227a78ef43ba474972c717e0e86b3211b3
`;

export const StudentFillterLabel = styled.label`
  font-family: Poppins;
  font-size: 15px;
  color: rgb(112, 112, 112);
`;
