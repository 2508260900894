import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
} from "@mui/material";
import React from "react";
import { StudentListPresentationProps } from "../../types/types";
import StudentItemPresentation from "./StudentItemPresentation";
import { StudentContainer } from "../../../experience/presentation/expereince.list.presentation/experienceListPresentation.styles";
import { StudentContentContainer } from "./studentlist.styles";
import { images } from "../../../../constants";

const StudentListPresentation: React.FC<StudentListPresentationProps> = ({
  students,
  onEditClick,
  sort,
  onSort,
  onSelectStudents,
  onChangeLimit,
  limit,
  page,
  onPage,
  studentCount,
}) => {
  console.log(sort);
  return (
    <>
      <StudentContainer>
        <StudentContentContainer style={{ width: "100%" }}>
          <Table className="student-table-container" style={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 10 }}>
                  <Checkbox />
                </TableCell>
                <TableCell style={{ width: 14 }}></TableCell>
                <TableCell
                  style={{ cursor: "pointer", textAlign: "left" }}
                  onClick={() => {
                    onSort("fullName");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Name
                    {sort !== "-fullName" && sort !== "fullName" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith("-fullName") ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "fullName" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>

                <TableCell
                  style={{ cursor: "pointer", textAlign: "left" }}
                  onClick={() => {
                    onSort("active");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Status
                    {sort !== "-active" && sort !== "active" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith("-active") ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "active" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }} onClick={() => {}}>
                  Student Score
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort("projectReport.notCompletedTasks");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Not Completed Task
                    {sort !== "-projectReport.notCompletedTasks" &&
                    sort !== "projectReport.notCompletedTasks" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith(
                        "-projectReport.notCompletedTasks"
                      ) ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "projectReport.notCompletedTasks" && (
                        <img src={images.topIcon} />
                      )
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort("projectReport.passDueTasks");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Past Due
                    {sort !== "-projectReport.passDueTasks" &&
                    sort !== "projectReport.passDueTasks" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith("-projectReport.passDueTasks") ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "projectReport.passDueTasks" && (
                        <img src={images.topIcon} />
                      )
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort("xp");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    Level
                    {sort !== "-xp" && sort !== "xp" ? (
                      <img src={images.unSelectIcon} />
                    ) : sort === "-xp" ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "xp" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onSort("xp");
                  }}
                >
                  <div style={{ width: "100%", textAlign: "left" }}>
                    XP
                    {sort !== "-xp" && sort !== "xp" ? (
                      <img src={images.unSelectIcon} />
                    ) : !!sort?.startsWith("-xp") ? (
                      <img src={images.BottomIcon} />
                    ) : (
                      sort === "xp" && <img src={images.topIcon} />
                    )}
                  </div>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Award Points
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.ids.map((id) => (
                <StudentItemPresentation
                  key={id}
                  student={students.entities[id]!}
                  onEditClick={onEditClick}
                  onSelectStudents={onSelectStudents}
                />
              ))}
            </TableBody>
          </Table>
          {studentCount > 24 && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={studentCount}
              rowsPerPage={limit!}
              page={page! - 1}
              onPageChange={(event: unknown, newPage: number) => {
                onPage!(newPage + 1);
              }}
              onRowsPerPageChange={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                onChangeLimit!(parseInt(event.target.value, 10));
              }}
            />
          )}
        </StudentContentContainer>
      </StudentContainer>
    </>
  );
};

export default StudentListPresentation;
