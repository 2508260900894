import React from "react";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import {
  ProjectCatListContainer,
  ProjectSection,
  ProjectBorderBox,
  ProjectFormCol,
  ProjectButton,
} from "../../category/presentation/categorychagneLabelpresentation/category.changelabel.presentation.style";
import { CSSettingPresentationProp } from "../types/types";
const CSSettingPresentation: React.FC<CSSettingPresentationProp> = ({
  title,
  displayProject,
  localized_reward,
  coins,
  store,
  active,
  onTitleHandler,
  onDisplayProjectHandler,
  onLocalizedRewardtHandler,
  onCoinsHandler,
  onStoreHandler,
  onActiveHandler,
  categoryStatus,
  subCategoryStatus,
  updateHandler,
  projectReward,
}) => {
  return (
    <>
      <div style={{ maxWidth: 350 }}>
        <h3
          className="st-info-title"
          style={{ textAlign: "center", marginBottom: 50 }}
        >
          Setting
        </h3>
        <ProjectCatListContainer
          style={{
            padding: 0,
            marginTop: -20,
            minHeight: "529px",
          }}
        >
          {categoryStatus ? (
            <>
              <h2
                style={{
                  background: "grey",
                  width: "100%",
                  textAlign: "center",
                  padding: "5px",
                  color: "white",
                  marginTop: "-10px",
                }}
              >
                {title}
              </h2>
              <div
                className="visibility"
                style={{ width: "100%", padding: 10 }}
              >
                <h5>Visibility</h5>
                <ProjectBorderBox
                  style={{ flexDirection: "column", width: "100%" }}
                >
                  <ProjectSection alignment="space-between" Direction={""}>
                    <p>Display with project</p>
                    <CustomSwitch
                      isToggle={displayProject}
                      onChangeToggle={onDisplayProjectHandler}
                      style={undefined}
                    />
                  </ProjectSection>
                </ProjectBorderBox>
              </div>
              {projectReward && (
                <div
                  className="reward-system"
                  style={{ width: "100%", padding: 10 }}
                >
                  <h5>Reward system</h5>
                  <ProjectBorderBox
                    style={{ flexDirection: "column", width: "100%" }}
                  >
                    <ProjectSection alignment="space-between" Direction={""}>
                      <p>Reward System</p>
                      <CustomSwitch
                        isToggle={store}
                        onChangeToggle={onStoreHandler}
                        style={undefined}
                      />
                    </ProjectSection>
                    {/* <ProjectSection alignment="space-between" Direction={""}>
                    <p >Coins</p>
                    <CustomSwitch
                      isToggle={coins}
                      onChangeToggle={onCoinsHandler} style={undefined} />
                  </ProjectSection>
                  <ProjectSection alignment="space-between" Direction={""}>
                    <p >Store</p>
                    <CustomSwitch
                      isToggle={store}
                      onChangeToggle={onStoreHandler} style={undefined} />
                  </ProjectSection> */}
                  </ProjectBorderBox>
                </div>
              )}

              <div
                className="reward-system"
                style={{ width: "100%", padding: 10 }}
              >
                <h5>Survey system</h5>
                <ProjectBorderBox
                  style={{ flexDirection: "column", width: "100%" }}
                >
                  <ProjectSection alignment="space-between" Direction={""}>
                    <p>Localized Survey</p>
                    <CustomSwitch
                      isToggle={active}
                      onChangeToggle={onActiveHandler}
                      style={undefined}
                    />
                  </ProjectSection>
                </ProjectBorderBox>
              </div>
            </>
          ) : null}
        </ProjectCatListContainer>
      </div>

      <ProjectFormCol
        alignment={"center"}
        style={{
          justifyContent: "space-around",
          marginTop: "2%",
          marginBottom: "2%",
          marginLeft: "35%",
          marginRight: "35%",
          position: "absolute",
          width: "24%",
          bottom: "-95px",
        }}
        Direction={""}
      >
        <ProjectButton onClick={updateHandler}>SAVE</ProjectButton>
        <ProjectButton>Cancel</ProjectButton>
      </ProjectFormCol>
    </>
  );
};

export default CSSettingPresentation;
