import SearchIcon from "@mui/icons-material/Search";
import { Icon } from "@mui/material";
import { SearchInput, SerachInputWrapper } from "./searchinputbox.styles";
import React, { InputHTMLAttributes } from "react";
import { BiSearchAlt } from "react-icons/bi";

type ISearchInputBox = InputHTMLAttributes<HTMLInputElement> & {
  type: string;
  placeholder: string;
  name: string;
};

const SearchInputBox: React.FC<ISearchInputBox> = ({
  type,
  name,
  placeholder,
  size: _,
  ...props
}) => {
  return (
    <SerachInputWrapper>
      <BiSearchAlt
        style={{ position: "absolute", top: 11, left: 18 }}
        color={"#707070"}
      />
      <SearchInput
        type={type}
        name={name}
        placeholder={placeholder}
        {...props}
      />

    </SerachInputWrapper>

  );
};

export default SearchInputBox;
