import React, { useEffect, useState } from "react";
import StudentListPresentation from "../../presantation/student.list.presentation/StudentListPresentation";

import { EntityState } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Student, StudentProjectReport } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import { accountSelected } from "../../slice/studentSlice";
import { setMetaData } from "../../../admin/adminSlice/adminSlice";
type StudentListContainerProps = {
  term: string;
  students: EntityState<Student>;
  sort: string;
  onSort: (fieldName: string) => void;
  studentsSuccess: boolean;
  onSelectStudents: (
    e: React.ChangeEvent<HTMLInputElement>,
    student: Student
  ) => void;
  limit?: number;

  onChangeLimit?: (limitNumber: number) => void;
  page?: number;
  studentCount?: number;
  onPage?: (pageNumber: number) => void;
};
const StudentListContainer: React.FC<StudentListContainerProps> = ({
  term,
  students,
  sort,
  onSort,
  studentsSuccess,
  onSelectStudents,
  onChangeLimit,
  limit,
  page,
  onPage,
}) => {
  const navigation = useNavigate();
  const metaData = useAppSelector(setMetaData);

  const onEditClick = (id: string) => {
    navigation(`/student/${id}`);
  };


  return (
    <>
      {studentsSuccess && students && (
        <StudentListPresentation
          students={students!}
          onEditClick={onEditClick}
          term={term}
          sort={sort}
          onSort={onSort}
          onSelectStudents={onSelectStudents}
          onChangeLimit={onChangeLimit}
          limit={limit}
          page={page}
          onPage={onPage}
          studentCount={metaData?.payload?.admin?.metadata?.toatlCount}
        />
      )}{" "}
    </>
  );
};

export default StudentListContainer;
