import React, { FC, useState } from "react";
import OrganizationAddPresentation from "../presantation/organization.add.presentation/organization.add.presentation";
import { OrganizationAddContainerProps } from "../types/types";
import {
  useAddNewOrganizationMutation,
  useUpdateOrganizationMutation,
} from "../api/orgApiSlice";
import { Admin } from "../../admin/types/types";
import { SchoolDistrict } from "../../schoolDistrict/types/types";
import { University } from "../../unviersity/types/types";
import { EntityId } from "@reduxjs/toolkit";
import Toast from "../../../components/toast/Toast";
import { SchoolDistrictCol1 } from "../../admin/presentation/admin.access.schoolDisrict.presentation/admin.access.schooldistrict.styles";

const OrganizationAddContainer: FC<OrganizationAddContainerProps> = ({
  handleClose,
  openStatusAddDisplay,
  status,
  organization,
}) => {
  const [addNewOrganization, { isLoading, isError }] =
    useAddNewOrganizationMutation();

  const [
    updateOrganization,
    { isLoading: updateIsLoading, isError: updateIsError },
  ] = useUpdateOrganizationMutation();

  const [title, setTitle] = useState<string>(
    status === "EDIT" ? organization!.title : ""
  );
  const [shf, setShf] = useState<string>(
    status === "EDIT" ? organization!.shf : ""
  );
  const [organizationImageUrl, setOrganizaitonImageUrl] = useState<string>(
    status === "EDIT"
      ? organization!.image
      : "https://api.gradify.app/static/download.ff.png"
  );
  const [organization_type, setOrganizationType] = useState<string>(
    status === "EDIT" ? organization!.organization_type : "Secondary"
  );
  const [schoolDistrictId, setSchoolDistrictId] =
    useState<Partial<SchoolDistrict> | null>(
      status === "EDIT" ? organization!.school_district : null
    );
  const [universityId, setUniversityId] = useState<Partial<University> | null>(
    null
  );
  const [ufcs_member, setUfcsMember] = useState<boolean>(
    status === "EDIT" ? organization!.ufcs_member : true
  );
  const [lead_contact, setLoadContact] = useState<Partial<Admin> | null>(
    status === "EDIT" ? organization?.lead_contact! : null
  );
  const [paid, setPaid] = useState<string>(
    status === "EDIT" ? organization!.paid : "Yes"
  );
  const [reward_system, setRewardSystem] = useState<boolean>(
    status === "EDIT" ? organization!.reward_system : true
  );
  const [survey_system, setSurvaySystem] = useState<boolean>(
    status === "EDIT" ? organization!.survey_system : true
  );
  const [active, setActive] = useState<boolean>(
    status === "EDIT" ? organization!.active : true
  );
  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [errorfields, setErrorFields] = useState<{
    title: string | null;
    shf: string | null;
  }>({
    title: null,
    shf: null,
  });

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onShfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShf(e.target.value);
  };
  const onChagneImageUrl = (url: string) => {
    setOrganizaitonImageUrl(url);
  };

  const onChangeSchoolDistictId = (state: Partial<SchoolDistrict>) => {
    setSchoolDistrictId(state);
  };

  const onChangeUniversityId = (state: Partial<University>) => {
    setUniversityId(state);
  };

  const onOrganizationTypeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log(e.target.value);
    setOrganizationType(e.target.value);
  };

  const onChangeSearchAdmin = (state: Partial<Admin>) => {
    setLoadContact(state);
  };

  const onChangeUfcsMember = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUfcsMember(e.target.value === "Yes" ? true : false);
  };

  const onPaidChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaid(e.target.value);
  };

  const onRewardSystemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRewardSystem(e.target.value === "Yes" ? true : false);
  };

  const onSurvaySystemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSurvaySystem(e.target.value === "Yes" ? true : false);
  };

  const onActiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActive(e.target.value === "Yes" ? true : false);
  };

  const canSave = title && shf && !isLoading && !updateIsLoading;

  const organizationSave = async () => {
    setSaveLoading(true);
    try {
      if (canSave) {
        if (organization_type === "Secondary" && !schoolDistrictId) {
          setShowToastFail(true);
          setToastMessage("For Secondary Project school district is required");
          setTimeout(() => {
            setShowToastFail(false);
            setSaveLoading(false);
          }, 3000);
          return;
        }
        if (organization_type === "Post Secondary" && !universityId) {
          setShowToastFail(true);
          setToastMessage("For Post Secondary Project Unversity is required");
          setTimeout(() => {
            setSaveLoading(false);
            setShowToastFail(false);
          }, 3000);
          return;
        }

        await addNewOrganization({
          title,
          shf,
          active,
          lead_contact: lead_contact!,
          organization_type,
          paid,
          reward_system,
          school_district: schoolDistrictId!,
          university: universityId!,
          survey_system,
          ufcs_member,
          image: organizationImageUrl,
        }).unwrap();

        setShowToastSuccess(true);
        setToastMessage("Organization added successfully");
        setTimeout(() => {
          setShowToastSuccess(false);
          setTitle("");
          setShf("");
          setOrganizaitonImageUrl(
            "https://api.gradify.app/static/download.ff.png"
          );

          setOrganizationType("Secondary");
          setSchoolDistrictId(null);
          setUniversityId(null);
          setUfcsMember(true);
          setLoadContact(null);
          setPaid("Yes");
          setRewardSystem(true);
          setSurvaySystem(true);
          setActive(true);
          setSaveLoading(false);
          handleClose();
        }, 3000);
      } else {
        if (!shf) {
          setErrorFields({
            ...errorfields,
            shf: "Please fill shf fields",
          });
        }
        if (!title) {
          setErrorFields({
            ...errorfields,
            title: "Please fill title fields",
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      setShowToastFail(true);
      setToastMessage(error.data.message[0]);
      setTimeout(() => {
        setSaveLoading(false);
        setShowToastFail(false);
      }, 3000);
    }
    setSaveLoading(false);
  };

  const updateOrganizationHandler = async () => {
    try {
      if (canSave) {
        await updateOrganization({
          ...organization,
          title,
          shf,
          active,
          lead_contact: lead_contact!,
          organization_type,
          paid,
          reward_system,
          school_district: schoolDistrictId!,
          university: universityId!,
          survey_system,
          ufcs_member,
          image: organizationImageUrl,
        }).unwrap();

        handleClose();
        setSaveLoading(false);
      }
    } catch (error) {
      console.log("update organizaton", error);
    }
  };

  return (
    <>
      <OrganizationAddPresentation
        openStatusAddDisplay={openStatusAddDisplay}
        handleClose={handleClose}
        onChagneImageUrl={onChagneImageUrl}
        onChangeSchoolDistrictId={onChangeSchoolDistictId}
        onChangeUniversityId={onChangeUniversityId}
        onOrganizationTypeHandler={onOrganizationTypeHandler}
        onChangeSearchAdmin={onChangeSearchAdmin}
        onActiveChange={onActiveChange}
        onPaidChange={onPaidChange}
        onRewardSystemChange={onRewardSystemChange}
        onSurvaySystemChange={onSurvaySystemChange}
        onUfcsMemberChange={onChangeUfcsMember}
        organizationType={organization_type}
        onTitleChange={onTitleChange}
        onShfChange={onShfChange}
        saveLoading={saveLoading}
        organizationSave={
          status === "ADD" ? organizationSave : updateOrganizationHandler
        }
        title={title}
        shf={shf}
        status={status}
        active={active}
        organizationImageUrl={organizationImageUrl}
        organization_type={organization_type}
        paid={paid}
        reward_system={reward_system}
        survey_system={survey_system}
        ufcs_member={ufcs_member}
        lead_contact={lead_contact}
        schoolDistrictId={schoolDistrictId}
        errorFileds={errorfields}
      />
      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Organization Save Successfuly" typeToast="Success" />
        </div>
      )}
    </>
  );
};

export default OrganizationAddContainer;
