import React, { SyntheticEvent, useState } from "react";

import {
  SearchResult,
  SearchSchoolDistrictContainerProps,
} from "../../../types/types";
import { HighSchool } from "../types/types";
import { useLazySearchHigschoolwithagnacyNameQuery } from "../api/highSchoolApiSlice";
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import SearchField from "../../../components/SearchField/SearchField";
type SearchHigSchoolProps = {
  term: string;
  agancyName: string;
  schoolDistrictID?: Partial<HighSchool> | null;
};
const SearchHighSchool: React.FC<
  SearchSchoolDistrictContainerProps<HighSchool> & {
    agancyName: string;
    schoolDistrictID?: Partial<HighSchool> | null;
  }
> = ({ onChangeState, agancyName, schoolDistrictID }) => {
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [searchHigschoolwithagnacyName, { isLoading, isError, isSuccess }] =
    useLazySearchHigschoolwithagnacyNameQuery();

  const onFetch = async (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    try {
      console.log(agancyName);
      const newOptions = await searchHigschoolwithagnacyName({
        term: value ? value : " ",
        agancyName,
      }).unwrap();

      setOptions(newOptions);
    } catch (error) {}
  };

  const onChagneHandler = (
    event: SyntheticEvent<Element, Event>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason
  ) => {
    if (value?.id) {
      onChangeState({ _id: value.id, school_name: value.label });
    }
  };

  return (
    <>
      <SearchField
        options={options}
        loading={isLoading}
        handleInputChange={onFetch}
        onChangeHandle={onChagneHandler}
        schoolDistrictID={schoolDistrictID}
      />
    </>
  );
};

export default SearchHighSchool;
