import React, { useState } from "react";
import FilterByOrganizationContainer from "../../../organization/container/filter.by.organization.container/FilterByOrganization.container";
import { HomeOrganizationPresentationProps } from "../../types/types";
import {
  HomeOrganizationPresentationContainer,
  HomeOrganizationPresentationContent,
  HomeOrganizationPresentationTitle,
  HomeSearchStudentContainer,
  SearchStudent,
  SearchStudentContainer,
} from "./home.organization.presentation.style";
import { BiSearchAlt } from "react-icons/bi";

const HomeOrganizationPresentation: React.FC<
  HomeOrganizationPresentationProps
> = ({ organizatoinID, onSelectOrganization, term, onTerm }) => {
  return (
    <HomeOrganizationPresentationContent>
      <HomeOrganizationPresentationContainer>
        <HomeOrganizationPresentationTitle>
          Select Organizaton
        </HomeOrganizationPresentationTitle>
        <FilterByOrganizationContainer
          onSelectOrganization={onSelectOrganization}
        />
      </HomeOrganizationPresentationContainer>
      <HomeSearchStudentContainer>
        <SearchStudentContainer>
          <BiSearchAlt
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchStudent
            type="text"
            value={term}
            placeholder="Search in all projects"
            onChange={(e) => {
              onTerm(e.target.value);
            }}
          />
        </SearchStudentContainer>
      </HomeSearchStudentContainer>
    </HomeOrganizationPresentationContent>
  );
};

export default HomeOrganizationPresentation;
