import React, {
  LegacyRef,
  SelectHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { SelectFieldStyle } from "./selectFied.styles";
type ISelectField = SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode;
  defaultValue?: string;
  ref?: any;
};
const SelectField: React.FC<ISelectField> = ({
  children,
  defaultValue,

  size: _,
  ...props
}) => {
  const [dValue, setValue] = useState(defaultValue);
  useEffect(() => {
    console.log(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <SelectFieldStyle isDisabled={false} defaultValue={dValue} {...props}>
      {children}
    </SelectFieldStyle>
  );
};

export default SelectField;
