import React from "react";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { Project } from "../../../project/types/types";
import { EntityId } from "@reduxjs/toolkit";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import {
  ExperienceFilterWrapper,
  LeftExperienceFilter,
  RightExperienceFilter,
} from "./experience.filter.sytles";
import { CreateStudentFormRow, CreateStudentFormCol, LabelStudent, CreateStudentAbsoluteRow } from "../../../students/presantation/student.details.info.presentation/student.details.info.style";
import SelectField from "../../../../components/design/selectFiled/selectField";

type ExperienceFilterPresentationProps = {
  projectId: EntityId;
  categoryId: EntityId | null;
  onCategoryHanlder: (category: Partial<Category> | null) => void;
  onSubCategoryHandler: (subcategory: Partial<SubCategory> | null) => void;
};

const ExperienceFilterPresentation: React.FC<
  ExperienceFilterPresentationProps
> = ({ projectId, onCategoryHanlder, onSubCategoryHandler, categoryId }) => {
  return (

    <CreateStudentFormRow style={{marginTop:0}}>
    <CreateStudentFormCol Direction="column">
      <LabelStudent>Category</LabelStudent>
      <FilterCategoryContainer
          projectID={projectId}
          onSelectCategory={onCategoryHanlder}
        />
    </CreateStudentFormCol>
    <CreateStudentFormCol Direction="column">
      <LabelStudent>Sub Category</LabelStudent>
      <FilterSubCategoryContainer
          categoryID={categoryId!}
          onSelectSubCategory={onSubCategoryHandler}
        />
    </CreateStudentFormCol>
    <CreateStudentAbsoluteRow>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Educational Phases</LabelStudent>

        <SelectField>
            <option value="All">All</option>
          </SelectField>
      </CreateStudentFormCol>
      <CreateStudentFormCol Direction="column">
        <LabelStudent>Semester</LabelStudent>
        <SelectField>
            <option value="All">All</option>
          </SelectField>
      </CreateStudentFormCol>
    </CreateStudentAbsoluteRow>
    </CreateStudentFormRow>


   
  );
};

export default ExperienceFilterPresentation;
