import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: 3px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
`;

export const HeaderTitle = styled.h2`
  color: black;
  font-weight: 600;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: rgb(73, 80, 87);
  width: 46%;
  padding-left:35px;
`;

export const HeaderProfileAction = styled.div`
  width: 50%;
  padding: 0 10px;
`;

export const LayoutHeaderContainer = styled.div<{spacing:number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  padding: 0 ${({ spacing }) => spacing + "px"};
  
`;

export const Flag = styled.img`
  width: 24.83px;
  height: 18px;
`;

export const ProfileImage = styled.img`
  width: 37px;
  height: 37px;
  border: 2px solid #E6E1E1;
  border-radius: 50px;
  margin-right: 5px;
`;

export const ProfileInfo = styled.span`
  font-family:  "Poppins", sans-serif;
  font-size: 14px;
  color: #555B6D;
  font-weight: 400;
`;
export const SettingContainer = styled.ul`
  position: absolute;
  top: 68px;
  right: 4px;
  width: 200px;
  background: #fff;
  list-style: none;
  height: 60px;
  padding-left: 0;
  z-index: 1;
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.1);
`;

export const SettingItem = styled.li`
  width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  &:hover {
    background: #000;
    color: #fff;
  }
`;