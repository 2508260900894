import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { LeaderBoard } from "../types/types";
import LeaderBoardItemPresentation from "./LeaderBoardItemPresentation";
type LeaderBoardPresentationProps = {
  leaderBoard: EntityState<LeaderBoard>;
};
const LeaderBoardPresentation: React.FC<LeaderBoardPresentationProps> = ({
  leaderBoard,
}) => {
  return (
    <div className="leaderBoard-container">
      {leaderBoard.ids.map((id, index) => (
        <LeaderBoardItemPresentation
          key={id}
          item={leaderBoard.entities[id]!}
          index={index + 1}
        />
      ))}
    </div>
  );
};

export default LeaderBoardPresentation;
