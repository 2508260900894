import React from "react";
import {
  HeadTh,
  HeadTr,
  Header,
  ListWrapper,
  Title,
  Wrapper,
} from "./raffel.studen.style";
import { EntityState } from "@reduxjs/toolkit";
import { Bag } from "../../../bag/types/types";
import RaffelTicketStudentItemPresenation from "./RaffelTicketStudentItemPresenation";

export type RAffelTicketStuentLisetPresentation = {
  raffelbagList: EntityState<Bag>;
};

const RaffelTicketStudentListPresentation: React.FC<
  RAffelTicketStuentLisetPresentation
> = ({ raffelbagList }) => {

  return (
    <Wrapper>
      <Header>
        <Title>Students</Title>
      </Header>
      <ListWrapper>
        <HeadTr>
          <HeadTh>Student</HeadTh>
          <HeadTh>First Purchase</HeadTh>
          <HeadTh># Tickets</HeadTh>
          <HeadTh>Winner</HeadTh>
          <HeadTh>Picked up?</HeadTh>
          <HeadTh>Date Picked up</HeadTh>
        </HeadTr>

        {raffelbagList.ids.map((id) => (
          <RaffelTicketStudentItemPresenation
            itemBag={raffelbagList.entities[id]!}
          />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

export default RaffelTicketStudentListPresentation;
