import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SettingMainContainer = styled.div`
  width: 95%;
  position: relative;
  display: block;
`;

export const SettingHeaderControle = styled.div`
  width: 100%;
  height: 40px;
  margin-left: 45px;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;

export const TabButton = styled.button<{
  condition: boolean;
}>`
  height: 35px;
  background: white;
  ouline: none;
  border: none;
  font-weight: ${(props) => (props.condition ? "bold" : "none")};
  cursor: pointer;
  margin: 2px;
  padding: 5px;
  font-size: 13px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid rgb(239, 239, 239);
  border-bottom: ${(props) =>
    props.condition ? "20px solid white" : "1plx solid rgb(239,239,239)"};
`;

export const SettingBox = styled.div`
  width: 95%;
  margin-top: -3px;
  margin-left: 45px;
  display: block;
  border: 1px solid rgb(239, 239, 239);
  border-radius: 5px;
`;

export const SettingTitleRow = styled.div`
  display: block;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 20px;
  position: relative;
`;

export const SettingText = styled.p`
  font-size: 16px;
`;
export const DriverText = styled.div`
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  text-align: left;
  margin-left: 12px;
  color: #495057;
`;

//DRIVER

export const DriverTitleBox = styled.div`
  width: 82%;
  display: flex;
  flex-flow: wrap;
  margin: 0 auto;
  margin-left:0;
  padding: 10px;
  position: relative;
  justify-content: center;
  margin-top: 20px;
`;

export const DriverTitle = styled.h1`
  font-size: 34px;
  font-weight: 600;
  padding-bottom:10px;
`;


export const DriverSmallTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  padding-bottom:5px;
`;

export const DriverRow = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  position: relative;
`;


export const SettingTermsContainer = styled.div`
    max-height: 500px;
    overflow: auto;
  display: flex;
  flex-flow: wrap;
  max-width:450px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 12px;
  position: relative;
  border:1px solid #707070;
  border-radius:10px;
`;

export const SettingProgressItem = styled.div`
  display: flex;
  width:100%;
  max-width:450px;
     flex-wrap: nowrap;
    justify-content: space-between;

  padding: 12px;
  position: relative;

`;


export const SettingTermsItem = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 21px 5px 10px;
  margin-right: 13px;
  position: relative;
  min-width:120px;
  border:1px solid #707070;
  border-radius:10px;
`;

export const DriverColumn = styled.div<{
  alignment?: string;
  Direction?: string;
}>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 10px;
  position: relative;
  justify-content: ${({ alignment }) => alignment || "center"};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
`;

export const DriverLabel = styled.label`
  font-size: 13px;
  color: #272727;
  width: 100%;
`;

export const DriverMainBox = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 10px;
  position: relative;
  margin-top: 20px;
`;

export const DriverMainLeftBox = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
  padding: 10px;
  position: relative;
  justify-content: flex-start;
`;
export const SettingTermButton = styled.button`
        background: transparent;
    border: none;
`;

export const SettingTermItemButton = styled.button`
         background: transparent;
    border: none;
        position: absolute;
    right: 3px;
    top: 10px;
`;


export const SettingTermItemButtonIcon = styled.img`
        width:14px;
        height:14px;
`;
export const SettingApplyButton = styled.button`
        background-color: #34C38F;
        color: #fff;
        padding: 6px 10px;
        font-size: 15px;
        border-radius: 30px;
        font-weight: 600;
        white-space: nowrap;
        border: none;
`;

export const DriverMainTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const DriverSaveButton = styled(Button)`
  color: #ffffff;
  color:#fff;
  background-color:#0078FF;
  border:none;
  border-radius:50px;
  padding: 10px 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family:'Poppins';
  font-size:15px;
  
  font-weight:500;
  &:hover {
    background-color: #3996ff;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border:none;
  };
  &:active {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    background-color: #3996ff;
    border:none;

  };
  &:focus {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border:none;
  };
`;
export const DriverTextArea = styled.input`
  font-weight: 400;
  font-family: "Poppins";
  line-height: inherit;
  height: 30px;
  margin-right: 12px;
  text-align: center;
  width: 180px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  text-align: left;
  font-size: 13px;
  padding: 0 10px;
  & ::placeholder {
    text-align: center;
  }
`;
