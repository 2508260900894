import React from "react";
import { RaffleContainer, RaffleContentContainer } from "../raffel.styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { RaffelTicket } from "../../types/types";
import RaffelTicketListItem from "./RaffelTicketListItem";
import images from "../../../../constants/images/images";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import { raffleMetadataSelected } from "../../api/raffleTicketSlice";

type RaffelTicketListPresentationProps = {
  raffelList: EntityState<RaffelTicket>;
  editHandler: (id: EntityId) => void;
  onSort: (fieldName: string) => void;
  sort: string;
  page?: number;
  limit?: number;
  onPage?: (pageNumber: number) => void;
  onLimit?: (limit: number) => void;
};
const RaffelTicketListPresentation: React.FC<
  RaffelTicketListPresentationProps
> = ({
  raffelList,
  editHandler,
  onSort,
  sort,
  page,
  onPage,
  onLimit,
  limit,
}) => {
  const raffleTicketMetadata = useAppSelector(raffleMetadataSelected);
  return (
    <>
      <RaffleContainer>
        <RaffleContentContainer
          style={{
            height: "50%",

            marginLeft: 0,
            flexDirection: "column",
            width: "100%",
            marginTop: -40,
            background: "white",
            paddingTop: 40,
            boxShadow: "rgba(18, 38, 63, 0.03) 0px 10px 20px",
          }}
        >
          <Table
            className="raffle-table-container raffle-table-container"
            style={{ width: "100%" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 14 }}></TableCell>
                <TableCell
                  onClick={() => {
                    onSort("title");
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",

                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    Prize{" "}
                    {sort !== "title" && sort !== "-title" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-title" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                  {/* {sortType?.type === "titleSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
                </TableCell>
                <TableCell
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    onSort("description");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 370,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    Description{" "}
                    {sort !== "description" && sort !== "-description" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-description" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                  {/* {sortType?.type === "desSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
                </TableCell>
                <TableCell
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    onSort("startDate");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    Start Date
                    {sort !== "startDate" && sort !== "-startDate" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-startDate" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                  {/* {sortType?.type === "start_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
                </TableCell>
                <TableCell
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    onSort("endDate");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      alignItems: "center",
                      margin: "0 auto",
                    }}
                  >
                    Close Date
                    {sort !== "endDate" && sort !== "-endDate" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-endDate" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                  {/* {sortType?.type === "end_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
                </TableCell>
                <TableCell
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    onSort("drawDate");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      margin: "0 auto",
                      alignItems: "center",
                    }}
                  >
                    Drawing Date
                    {sort !== "drawDate" && sort !== "-drawDate" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-drawDate" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                  {/* {sortType?.type === "draw_date" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {raffelList!.ids.map((id) => (
                <RaffelTicketListItem
                  raffel={raffelList.entities[id]!}
                  editHandler={editHandler}
                />
              ))}
            </TableBody>
          </Table>
          {raffleTicketMetadata?.toatlCount > 24 && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={raffleTicketMetadata?.toatlCount}
              rowsPerPage={limit!}
              page={page! - 1}
              onPageChange={(event: unknown, newPage: number) => {
                onPage!(newPage + 1);
              }}
              onRowsPerPageChange={(
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                onLimit!(parseInt(event.target.value, 10));
              }}
            />
          )}
        </RaffleContentContainer>
      </RaffleContainer>
    </>
  );
};

export default RaffelTicketListPresentation;
