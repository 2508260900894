import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { InventoryType } from "../types/type";
const inventoryAdaptor = createEntityAdapter<InventoryType>({
  selectId: (inventory) => inventory._id,
});
interface InventoryInitialState extends EntityState<InventoryType> {}

const initialState: InventoryInitialState = inventoryAdaptor.getInitialState();

export const extendApiInventroy = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInventoriesForShop: builder.query<
      EntityState<InventoryType>,
      EntityId
    >({
      query: (id) => `/inventory?shop=${id}`,
      transformResponse: (response: InventoryType[]) => {
        console.log("inventories", response);
        return inventoryAdaptor.setAll(initialState, response);
      },
      providesTags: ["Inventory"],
    }),

    createMenualInventory: builder.mutation<
      EntityState<InventoryType>,
      Partial<InventoryType>
    >({
      query: (inventory) => ({
        url: "/inventory/menualInventory",
        method: "POST",
        body: inventory,
      }),
      transformResponse: (response: InventoryType) => {
        return inventoryAdaptor.addOne(initialState, response);
      },
      invalidatesTags: ["Inventory"],
    }),
  }),
});

export const {
  useGetAllInventoriesForShopQuery,
  useCreateMenualInventoryMutation,
} = extendApiInventroy;
