import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import React, { FC } from "react";
import ProjectItemContainer from "../../container/project.item.container/project.item.container";
import { ProjectListPresentationProps } from "../../types/types";
const ProjectListPresentation: FC<ProjectListPresentationProps> = ({
  projects,
}) => {
  return (
    <div className="st-info-team-container gradegy-organization">
              <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 60 }}>
                <Checkbox
                  color="default"
                  size="small"
                  checked={true}
                  onChange={() => {}}
                />
              </TableCell>
              <TableCell style={{ width: 60 }}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{ maxWidth: 48 }}>Project Type</TableCell>
              <TableCell style={{ paddingLeft: 20 }}>Condition</TableCell>
              <TableCell style={{ maxWidth: 80 }}>Status</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Subcategories</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Reward System</TableCell>
              <TableCell>Survey System</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {projects.ids.map((id: EntityId) => (
                <ProjectItemContainer key={id} project={projects.entities[id]!} />
              ))}
          </TableBody>
        </Table>



    </div>
  );
};

export default ProjectListPresentation;
