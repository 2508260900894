import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { selectAdmin, selectStatus } from "../authSlice/authSlice";
import Loading from "../../../components/Loading/Loading";
const RequierAuth = () => {
  const admin = useAppSelector((state: RootState) => selectAdmin(state));
  const status = useAppSelector(selectStatus);

  const location = useLocation();

  let content;

  if (status === "succeeded") {
    content = <Outlet />;
  } else if (status === "failed") {
    content = <Navigate to="/login" state={{ from: location }} replace />;
  } else if (status === "loading") {
    content = <Loading />;
  }
  return <>{content}</>;
};

export default RequierAuth;
