import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { ExperienceCard } from "../../experience/types/types";
import { Paper } from "@mui/material";
import ExperienceListItemPresentation from "../../experience/presentation/expereince.list.presentation/ExperienceListItemPresentation";
import ExperienceForStudentItemPresentation from "./ExperienceForStudentItemPresentation";

type ExperienceForStudentPresentationProps = {
  explist: EntityState<ExperienceCard>;
  onChangeComplete: (experience: ExperienceCard) => void;
};
const ExpereinceForStudentPresentation: React.FC<
  ExperienceForStudentPresentationProps
> = ({ explist, onChangeComplete }) => {
  return (

    <div className="st-info-experience-box">
      <div className="st-info-experience-box-container">
        <h4>Completion</h4>
        <h4>Item</h4>
        <h4>Description</h4>
        <h4>Completed</h4>
      </div>
      <div style={{maxHeight:430, overflow:"hidden" , overflowY:"scroll"}}>
        {explist.ids.map((id) => (
          <ExperienceForStudentItemPresentation
            key={id}
            experience={explist.entities[id]!}
            onCompleteChange={onChangeComplete}
          />
        ))}
      </div>
    </div>



  );
};

export default ExpereinceForStudentPresentation;
