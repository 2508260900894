import { TableRow, TableCell } from "@mui/material";
import React from "react";
import { MdEdit } from "react-icons/md";

import {
  EditButtonText,
  EditButtonIcon,
} from "../../../experience/presentation/experience.review.presentation/experience.review.styles";
import { ShopType } from "../../types/type";
import EditButton from "../../../../components/EditButton";
import { EntityId } from "@reduxjs/toolkit";
type ShopItemProps = {
  item: ShopType;
  onEditReview: (id: EntityId) => void;
};
const ShopItem: React.FC<ShopItemProps> = ({ item, onEditReview }) => {
  return (
    <TableRow style={{ borderBottom: "2px solid #EFF2F7" }}>
      <TableCell style={{ borderBottom: 0 }}>
        <img
          src={item.image}
          alt="asternuts"
          width="50"
          height="50"
          style={{ borderRadius: 50 }}
        />
      </TableCell>
      <TableCell style={{ color: "#797E83", borderBottom: 0 }}>
        {item.title}
      </TableCell>
      <TableCell
        style={{
          borderBottom: 0,
          whiteSpace: "normal",
          width: "150px",
        }}
      >
        {item.description}
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>{item.current_stock}</TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        {item.store ? "Yes" : "No"}
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>
        {item.prize_wheel ? "Yes" : "No"}
      </TableCell>
      <TableCell style={{ borderBottom: 0 }}>{item.gem}</TableCell>
      <TableCell style={{ borderBottom: 0 }}>{item.unlock_level}</TableCell>
      <TableCell style={{ borderBottom: 0 }} className="addXp">
        {/* {haveAccess && ( */}
        <EditButton
          style={{ marginLeft: "10px", width: "120px" }}
          // onClick={onEditButtonClick}
          onClick={() => {
            onEditReview(item._id);
          }}
        >
          <EditButtonText>Edit/Review</EditButtonText>
          <EditButtonIcon>
            <MdEdit color="#34C38F" size={12} />
          </EditButtonIcon>
        </EditButton>
        {/* )} */}
      </TableCell>
    </TableRow>
  );
};

export default ShopItem;
