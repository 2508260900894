import { EntityId } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { useGetProjectByIdQuery } from "../../../project/api/projectApiSlice";
import { useGetAllCategoryByProjectQuery } from "../../api/categoryApiSlice";
import CategoryAddContainer from "../category.add.container/category.add.container";
import CategoryListContainer from "../category.list.container/category.list.container";
import CategoryChangeLabelContainer from "../categroy.changelabel.container/categoy.chagnelabel.container";
import { ProjectDetailCategoryList } from "../../presentation/categorychagneLabelpresentation/category.changelabel.presentation.style";
import Loading from "../../../../components/Loading/Loading";
function CategoryContainer() {
  const { projectId } = useParams();
  const {
    data: projects,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllCategoryByProjectQuery(projectId!);

  const {
    data: project,
    isLoading: projectLoading,
    isError: projectError,
    isSuccess: projectSucces,
  } = useGetProjectByIdQuery(projectId!);

  console.log(project);

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <div >
        {projectSucces && (
          <CategoryChangeLabelContainer
            project={project?.entities[projectId!]!}
          />
        )}

        <CategoryAddContainer project={project?.entities[projectId!]!} />

        <CategoryListContainer projectId={projectId!} />
      </div>
    );
  } else if (isError) {
    content = <p>Something went worng</p>;
  }

  return <div style={{width:"30%"}}>{content}</div>;
}

export default CategoryContainer;
