import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { Bag } from "../types/types";
import { error } from "console";
import { url } from "inspector";

const bagAdaptor = createEntityAdapter<Bag>({
  selectId: (bag) => bag._id,
});

interface IBagInitialState extends EntityState<Bag> {}

const inititalState: IBagInitialState = bagAdaptor.getInitialState();

export const extendBagApiAdaptor = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBagStudent: builder.query<EntityState<Bag>, EntityId>({
      query: (id) => `/bag/studentsbag?shopId=${id}`,
      transformResponse: (response: Bag[]) => {
        console.log("bagsssssss", response);
        return bagAdaptor.setAll(inititalState, response);
      },
      providesTags: (result, error, arg) => ["Bag"],
    }),
    getAllRaffelBagStudent: builder.query<EntityState<Bag>, EntityId>({
      query: (id) => `/bag/studentsraffelbag?raffelId=${id}`,
      transformResponse: (response: Bag[]) => {
        const ress = response.map((res) => ({
          ...res,
          _id: res.id,
        }));
        return bagAdaptor.setAll(inititalState, ress);
      },
      providesTags: (result, error, arg) => ["Bag"],
    }),
    pickUpBag: builder.mutation<EntityState<Bag>, Partial<Bag>>({
      query: (bag) => ({
        url: `/bag/${bag._id}`,
        method: "PUT",
        body: bag,
      }),
      transformResponse: (response: Bag) => {
        return bagAdaptor.updateOne(inititalState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: ["Bag"],
    }),
  }),
});

export const {
  useGetAllBagStudentQuery,
  usePickUpBagMutation,
  useGetAllRaffelBagStudentQuery,
} = extendBagApiAdaptor;
