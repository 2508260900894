import { title } from "process";
import React from "react";
import { MdEdit } from "react-icons/md";

import {
  Wrapper,
  Header,
  Spinner,
  HeaderImg,
  ShopEditButton,
  ShopEditButtonText,
  ShopEditButtonIcon,
  MiddleWrapper,
  TopWrapper,
  TopInput,
  BottomWrapper,
  LeftItem,
  GemWrapper,
  GemCount,
  Gem,
  LockWrapper,
  LockCount,
  DescTextbox,
  Footer,
  BuyButton,
  ButtonText,
  CancelButton,
  WrapperShopCard,
  Lock,
} from "./shop.edit.review.header.style";
import { images } from "../../../../constants";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
type ShopEditCardPresentationProp = {
  image?: string;
  title?: string;
  onChangeItemImg?: (url: string) => void;
  onTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentStock: number;
  gem: number;
  description: string;
  onDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

  //titleInputRef,
  // onTitleInputChange?:(),
  // gemsNumber,
  // unlockLevel,
  // leftItemNumber,
  // description,
  // onDescriptionChange,
};
const ShopEditCardPresentation: React.FC<ShopEditCardPresentationProp> = ({
  image,
  title,
  onChangeItemImg,
  onTitle,
  currentStock,
  gem,
  description,
  onDescription,
}) => {
  console.log("image", image);
  return (
    <WrapperShopCard>
      <Header style={{ position: "relative" }}>
        <UploadImageContainer
          defaultImage={image!}
          onChangeImageUrlState={onChangeItemImg!}
          shop={true}
        />
      </Header>
      <MiddleWrapper style={{ zIndex: 2 }}>
        <TopWrapper>
          <TopInput
            // ref={titleInputRef}
            onChange={onTitle}
            value={title}
          />
        </TopWrapper>
        <BottomWrapper>
          <LeftItem>{currentStock} left </LeftItem>
          <GemWrapper>
            <GemCount>{gem}</GemCount>
            <Gem src={images.GemIcon} />
          </GemWrapper>
          <LockWrapper>
            <Lock src={images.LockIconWhite} />
            <LockCount>2</LockCount>
          </LockWrapper>
        </BottomWrapper>
      </MiddleWrapper>
      <DescTextbox value={description} onChange={onDescription} />
      <Footer>
        <BuyButton>
          <ButtonText>Buy</ButtonText>
        </BuyButton>
        <CancelButton>
          <ButtonText>Cancel</ButtonText>
        </CancelButton>
      </Footer>
    </WrapperShopCard>
  );
};

export default ShopEditCardPresentation;
