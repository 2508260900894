import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { accountSelected } from "../../students/slice/studentSlice";
import {
  useGetProfileChartQuery,
  useLazyGetProfileChartQuery,
} from "../api/profileChartsApi";
import { Student } from "../../students/types/types";
import ProfileChartsPresentation from "../presentation/ProfileChartsPresentation";
import Loading from "../../../components/Loading/Loading";
import { error } from "console";
type ProfileChartsContainerProps = {
  student: Student;
};
const ProfileChartsContainer: React.FC<ProfileChartsContainerProps> = ({
  student,
}) => {
  const account = useAppSelector(accountSelected);
  const {
    data: chartsData,
    isLoading,
    isError,
    isSuccess,
    error,
  } = useGetProfileChartQuery({
    currentSystem: JSON.stringify(account),
    studentId: student._id.toString(),
  });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = <ProfileChartsPresentation chartData={chartsData} />;
  } else if (isError) {
    console.log(error);
    content = <p>Please make sure you set dates in setting</p>;
  }
  return <>{content}</>;
};

export default ProfileChartsContainer;
