import { apiSlice } from "../../../app/api/apiSlice";
import { XpTypes } from "../types/types";

export const extendedXpApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllXp: builder.query<
      number,
      {
        currentSystem: string;
        studentId: string;
      }
    >({
      query: (data) =>
        `/xp?currentSystemString=${data.currentSystem}&studentIdString=${data.studentId}`,
      providesTags: ["Xp"],
    }),
  }),
});

export const { useGetAllXpQuery, useLazyGetAllXpQuery } = extendedXpApi;
