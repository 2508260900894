import React from "react";
import { ShopContentContainer, ShopWrapper } from "./shop.list.styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { ShopType } from "../../types/type";
import ShopItem from "./ShopItem";
import images from "../../../../constants/images/images";
import { useAppSelector } from "../../../../app/hooks";
import { shopMetadataSelected } from "../../api/shopSlice";
type ShopListPresentationProps = {
  shopList: EntityState<ShopType>;
  onEditReview: (id: EntityId) => void;
  onSort: (fieldName: string) => void;
  sort: string;
  page?: number;
  limit?: number;
  shopCount: number;
  onPage?: (pageNumber: number) => void;
  onLimit?: (limitNumber: number) => void;
};
const ShopListPresentation: React.FC<ShopListPresentationProps> = ({
  shopList,
  onEditReview,
  onSort,
  sort,
  page,
  limit,
  onPage,
  onLimit,
  shopCount,
}) => {
  const shopMeta = useAppSelector(shopMetadataSelected);

  return (
    <ShopWrapper>
      <ShopContentContainer
        style={{
          width: "99%",
          height: "50%",
          paddingLeft: 30,
          marginLeft: 0,
          flexDirection: "column",
        }}
      >
        <Table
          className="student-table-container shop-table-container"
          style={{ width: "100%" }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                onClick={() => {
                  onSort("title");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Prize{" "}
                  {sort !== "title" && sort !== "-title" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-title" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "titleSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("description");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 370,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Description{" "}
                  {sort !== "description" && sort !== "-description" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-description" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "desSort" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("current_stock");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Current Stack{" "}
                  {sort !== "current_stock" && sort !== "-current_stock" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-current_stock" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "current_stock" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("store");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Shop{" "}
                  {sort !== "store" && sort !== "-store" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-store" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "store" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("prize_wheel");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Prize Wheel{" "}
                  {sort !== "prize_wheel" && sort !== "-prize_wheel" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-prize_wheel" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "prize_wheel" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("gem");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Gem{" "}
                  {sort !== "gem" && sort !== "-gem" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "-gem" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "gem" ? (
                  sortType?.isReversed ? (
                    <img src={BottomSelected} alt="" className="sortIcon" />
                  ) : (
                    <img src={TopSelected} alt="" className="sortIcon" />
                  )
                ) : (
                  <img src={Unselected} alt="" className="sortIcon" />
                )} */}
              </TableCell>
              <TableCell
                onClick={() => {
                  onSort("unlock_level");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    maxWidth: 120,
                    textAlign: "center",
                    fontSize: 13,
                    justifyContent: "center",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Level{" "}
                  {sort !== "unlock_level" && sort !== "-unlock_level" ? (
                    <img
                      src={images.unSelectIcon}
                      alt="unselect"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : sort === "unlock_level" ? (
                    <img
                      src={images.BottomIcon}
                      alt="bottom"
                      style={{ paddingLeft: 4 }}
                    />
                  ) : (
                    <img
                      src={images.topIcon}
                      alt="top"
                      style={{ paddingLeft: 4 }}
                    />
                  )}
                </div>
                {/* {sortType?.type === "unlock_level" ? (
                sortType?.isReversed ? (
                  <img src={BottomSelected} alt="" className="sortIcon" />
                ) : (
                  <img src={TopSelected} alt="" className="sortIcon" />
                )
              ) : (
                <img src={Unselected} alt="" className="sortIcon" />
              )} */}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopList.ids.map((id) => (
              <ShopItem
                key={id}
                item={shopList.entities[id]!}
                onEditReview={onEditReview}
              />
            ))}
          </TableBody>
        </Table>
        {shopMeta?.toatlCount > 24 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={shopMeta?.toatlCount}
            rowsPerPage={limit!}
            page={page! - 1}
            onPageChange={(event: unknown, newPage: number) => {
              onPage!(newPage + 1);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              onLimit!(parseInt(event.target.value, 10));
            }}
          />
        )}
      </ShopContentContainer>
    </ShopWrapper>
  );
};

export default ShopListPresentation;
