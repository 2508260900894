import React from "react";
import {
  CategoryPermissionType,
  ProjectPermissionType,
  SubCategoryPermissionType,
} from "../../features/admin/types/types";
import {
  ShowCanViewItem,
  ShowCanViewItemsWrapper,
  ShowCanViewRemoveButton,
  ShowCanViewWrapper,
} from "./show.can.view.style";

type ShowCanViewProps = {
  projectAccess?: ProjectPermissionType[];
  categoryAccess?: CategoryPermissionType[];
  subCategoryAccess?: SubCategoryPermissionType[];
  onProjectRemove?: (project: ProjectPermissionType) => void;
  onCategoryRemove?: (category: CategoryPermissionType) => void;
  onSubCategoryRemove?: (subCategory: SubCategoryPermissionType) => void;
  onClickProject?: (project: ProjectPermissionType) => void;
  onClickCategory?: (category: CategoryPermissionType) => void;
  onClickSubCategory?: (subcategory: SubCategoryPermissionType) => void;
};

const ShowCanView: React.FC<ShowCanViewProps> = ({
  projectAccess,
  categoryAccess,
  subCategoryAccess,
  onClickCategory,
  onClickProject,
  onProjectRemove,
  onCategoryRemove,
  onSubCategoryRemove,
  onClickSubCategory,
}) => {
  return (
    <ShowCanViewWrapper>
      {projectAccess && (
        <ShowCanViewItemsWrapper>
          {projectAccess?.map((project) => (
            <div key={project?.project?._id}>
              {project?.project?.title && (
                <ShowCanViewItem
                  onClick={() => {
                    onClickProject!(project);
                  }}
                >
                  {project?.project?.title}
                  <ShowCanViewRemoveButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onProjectRemove!(project);
                    }}
                  >
                    x
                  </ShowCanViewRemoveButton>
                </ShowCanViewItem>
              )}
            </div>
          ))}
        </ShowCanViewItemsWrapper>
      )}

      {categoryAccess && (
        <ShowCanViewItemsWrapper>
          {categoryAccess?.map((category) => (
            <div key={category?.category?._id}>
              {category?.category?.title && (
                <ShowCanViewItem
                  onClick={() => {
                    onClickCategory!(category);
                  }}
                >
                  {category?.category?.title
                    ? category?.category?.title
                    : "loading"}
                  <ShowCanViewRemoveButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onCategoryRemove!(category);
                    }}
                  >
                    x
                  </ShowCanViewRemoveButton>
                </ShowCanViewItem>
              )}
            </div>
          ))}
        </ShowCanViewItemsWrapper>
      )}

      {subCategoryAccess && (
        <ShowCanViewItemsWrapper>
          {subCategoryAccess?.map((subcategory) => (
            <div key={subcategory.subcategory._id}>
              {subcategory.subcategory.title && (
                <ShowCanViewItem
                  onClick={() => {
                    onClickSubCategory!(subcategory);
                  }}
                >
                  {subcategory.subcategory.title
                    ? subcategory.subcategory.title
                    : "loading"}
                  <ShowCanViewRemoveButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onSubCategoryRemove!(subcategory);
                    }}
                  >
                    x
                  </ShowCanViewRemoveButton>
                </ShowCanViewItem>
              )}
            </div>
          ))}
        </ShowCanViewItemsWrapper>
      )}
    </ShowCanViewWrapper>
  );
};

export default ShowCanView;
