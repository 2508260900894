import styled from "@emotion/styled";


export const ProjectFormCol = styled.div<{ alignment: string, Direction: string }>`
  display:flex;
  justify-content:${({ alignment }) => alignment};
  text-align:left;
  flex-direction:${({ Direction }) => Direction};
  padding:0 10px;
  
`;

export const ProjectInput = styled.input<{
  Width?: string;
  Height?: string;
  Size?: string;
  Weight?: number;
  SpacingTB?: string;
  SpacingLR?: string;
}>`
    width: ${({ Width }) => (Width ? Width : "190px")};
    height: ${({ Height }) => (Height ? Height : "32px")};
    text-align: center;
  
    font-size: ${({ Size }) => Size};
    font-weight: ${({ Weight }) => Weight};
    padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
    border-radius: 5px;
    border: 1px solid #ced4da;
    color: #070708;
    max-width: ${({ Width }) => (Width ? Width : "235px")};
  `;


export const ProjectLabel = styled.label`
font-size: 15px;
font-weight: 500;
color: #707070;


`;

export const ProjectSection = styled.div<{ alignment: string, Direction: string }>`
    display:flex;
    width: 100%;
    justify-content:${({ alignment }) => alignment};
    text-align:left;
    flex-direction:${({ Direction }) => Direction};
    padding:5px 0 ;
`;

export const ProjectCircleIcon = styled.div`
    background-color:#34C38F;
    width:28px;
    height:28px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;


`;

export const ProjectCatListContainer = styled.div`
  width: 95%;
  margin-top:10px;
  max-width:386px;
  border:1px solid #CED4DA;
  border-radius:14px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:17px;
  justify-content: space-around;
  margin-right: 20px;
  max-height:450px;
  overflow: auto;
`;
export const ProjectBorderBox = styled.div`
  width: 96%;
  margin-top:10px;

  border:1px solid #CED4DA;
  border-radius:22px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-wrap:wrap;
  padding:17px;
`;

export const ProjectCatList = styled.div`
  border:1px solid #CED4DA;
  border-radius:12px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin:5px;
  position:relative;
  color: #74788D;
  cursor:pointer;
  max-width: 46%;
    font-size: 13px;
    flex-direction: column;
    border-radius: 5px;
    min-width: 110px;
    min-height: 25px;
    padding: 0 25px 0 7px;
    &:hover{
      background-color:#4ed8a6;
    }
    &:active{
      background-color:#4ed8a6; 
    }
    span{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
`;
export const ProjectCatListClose = styled.div`
position: absolute;
top: 1px;
right: 7px;
color: #313539;
font-family: system-ui;
font-size: 14px;
font-weight: 600;
cursor: pointer;
`;

export const ProjectDetailCategoryList = styled.div`
display: flex;
flex: 1;
flex-wrap: wrap;
margin-top: 10px;
padding: 8px;
border: 1px solid #ced4da;
justify-content: space-between;
border-radius: 15px;
overflow: auto;
max-height: 500px;
min-height:100px;
`;

export const ProjectButton = styled.button`

    color:#fff;
    background-color:#0078FF;
    border:none;
    border-radius:50px;
    padding: 10px 46px;
    font-family:"poppins";
    font-size:17px;
    font-weight:600

`;


export const DefultOptions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 137px;
`;

export const DefultOption = styled.div`
    border: 1px solid #CED4DA;
    font-family:"Poppins";
    font-size:12px;
    color:#74788D;
    padding: 2px;
    margin: 1px;
    border-radius: 2px;
    line-height: 14px;
    border-radius: 4px;
    &:hover{
      background-color:#4ed8a6;
    }
`;


