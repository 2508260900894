import { apiSlice } from "../../../app/api/apiSlice";
import { SearchResult } from "../../../types/types";

export const extendedSchoolDistrictApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchSchoolDistrictByName: builder.query<SearchResult[], string>({
      query: (term: string) => `/school-district/byagancyname?term=${term}`,
      providesTags: (result, error, arg) => [
        { type: "SchoolDistrict", id: "LIST" },
        ...result!.map((item) => ({
          type: "SchoolDistrict" as const,
          id: item.id,
        })),
      ],
    }),
  }),
});

export const { useLazySearchSchoolDistrictByNameQuery } =
  extendedSchoolDistrictApiSlice;
