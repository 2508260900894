import styled from "@emotion/styled";

export const OrganizationActionWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  margin-left:30px;
  align-items: center;
`;

export const OrganizationActionSearchWrapper = styled.div`
  width: 20%;
  padding: 0 10px;
`;

export const OrganizationActionAddButtonWrapper = styled.div`
  width: 30%;
  padding: 0 10px;
`;
export const AddOrganizationButton = styled.div`
background: #34c38f;
cursor:pointer;
color:#fff;
border: none;
padding: 6px 10px 5px 37px;
transition-duration:300ms;
font-family: "Poppins";
font-size: 14px;
display: flex;
align-items: center;
border-radius: 5px;
font-weight: 300;
margin-top: 10px;
@media (max-width: 1770px) {
  font-size: 13px;
  margin-left: 20px;
}
span {
  white-space: nowrap;
}
&:hover{
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  transition-duration:300ms;
  background:#37d39a;
}
`;
