import React from 'react'
import './custom.switch.style.css';

type CustomSwitchPresentationProps = {
    isToggle: any;
    onChangeToggle: any;
    style?: any;
};

const CustomSwitch: React.FC<CustomSwitchPresentationProps> = ({isToggle,onChangeToggle,style}) => {
    return (
        <label style={style ? style : null} className="switch">
            <input type="checkbox" className="switchInput" checked={isToggle} onChange={onChangeToggle}  />
            <span className="slider">
                <span>Yes</span>
                <span>No</span>
            </span>
        </label>
    )
}

export default CustomSwitch
