import React from "react";
import InventoryListPresentation from "../presentation/InventoryListPresentation";
import { useGetAllInventoriesForShopQuery } from "../api/inventoryApi";
import { EntityId } from "@reduxjs/toolkit";
type InventoryListContainerProps = {
  shopId: EntityId;
};
const InventoryListContainer: React.FC<InventoryListContainerProps> = ({
  shopId,
}) => {
  const {
    data: inventoriesList,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllInventoriesForShopQuery(shopId);
  return (
    <>
      {isSuccess && (
        <InventoryListPresentation inventoriesList={inventoriesList} />
      )}
    </>
  );
};

export default InventoryListContainer;
