import React from "react";
import { useGetAllExperienceTypesQuery } from "../api/experienceTypeApiSlice";
import SelectExperienceTypePresentation from "../select.exp.type.presentation/SelectExperienceTypePresentation";
import { ExperienceType } from "../types/types";
type SelectExperienceTypeContainerProps = {
  onSelectHanlder: (exptype: Partial<ExperienceType> | null) => void;
  experienceType?: Partial<ExperienceType> | null;
};
const SelectExperienceTypeContainer: React.FC<
  SelectExperienceTypeContainerProps
> = ({ onSelectHanlder, experienceType }) => {
  const {
    data: exptypes,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllExperienceTypesQuery();

  const onSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectHanlder(exptypes?.entities[e.target.value]!);
  };
  return (
    <>
      {isSuccess && (
        <SelectExperienceTypePresentation
          exptypes={exptypes!}
          onSelected={onSelected}
          defaultExperienceType={experienceType}
        />
      )}
    </>
  );
};

export default SelectExperienceTypeContainer;
