import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import {
  SearchResult,
  SearchSchoolDistrictPresentationProps,
} from "../../types/types";
import { GradegyTextField } from "./SearchField.style";
import { HighSchool } from "../../features/highschool/types/types";

const SearchField: React.FC<SearchSchoolDistrictPresentationProps> = ({
  options,
  loading,
  handleInputChange,
  onChangeHandle,
  schoolDistrictID,
  lead_contact,
}) => {
  const [defaultValue, setDefualtValue] = useState<SearchResult | null>(
    lead_contact
      ? { label: lead_contact.fullName!, id: String(lead_contact._id) }
      : schoolDistrictID
      ? {
          label: schoolDistrictID.agancy_name!,
          id: String(schoolDistrictID._id),
        }
      : null
  );

  console.log("school District ID", schoolDistrictID);
  console.log("organization default value", defaultValue);

  return (
    <Autocomplete
      options={options}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      defaultValue={defaultValue}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <GradegyTextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}

                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onInputChange={handleInputChange}
      onChange={onChangeHandle}
      value={
        schoolDistrictID && {
          id: schoolDistrictID?._id!,
          label: schoolDistrictID?.agancy_name!
            ? schoolDistrictID?.agancy_name!
            : (schoolDistrictID as Partial<HighSchool>).school_name!,
        }
      }
    />
  );
};

export default SearchField;
