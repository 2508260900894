import { createSlice } from "@reduxjs/toolkit";
import { MetaData } from "../../../types/types";
import { RootState } from "../../../app/store";
import { RaffelTicket } from "../types/types";

interface IinitialState {
  raffelMetadata: MetaData;
}

const initialState: IinitialState = {
  raffelMetadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
};

export const raffleSlice = createSlice({
  name: "raffel",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.raffelMetadata = action.payload[0];
    },
  },
});

export const raffleMetadataSelected = (state: RootState) => {
  return state.raffle.raffelMetadata;
};

export const { setMetaData } = raffleSlice.actions;
export default raffleSlice.reducer;
