import React, { FC } from "react";
import ProjectActionPresentation from "../../presentation/project.action.presentation/project.action.presentation";
import { ProjectActionContainerProps } from "../../types/types";
const ProjectActionContainer: FC<ProjectActionContainerProps> = ({
  handleOpen,
  term,
  onTerm,
}) => {
  return (
    <div>
      <ProjectActionPresentation
        handleOpen={handleOpen}
        term={term}
        onTerm={onTerm}
      />
    </div>
  );
};

export default ProjectActionContainer;
