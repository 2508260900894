import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { InventoryType } from "../types/type";
import InvetoryListItem from "./InvetoryListItem";
import { HeadTh, HeadTr, Header, ListWrapper, Title, Wrapper } from "./Inventory.style";
type InventroyListPresentationProps = {
  inventoriesList: EntityState<InventoryType>;
};
const InventoryListPresentation: React.FC<InventroyListPresentationProps> = ({
  inventoriesList,
}) => {
  return (
    <Wrapper>
      <Header>
        <Title>Inventory</Title>

      </Header>
      <ListWrapper  style={{ height: "100%" }}>

          <HeadTr>
            <HeadTh>Stock_id</HeadTh>
            <HeadTh>Serial Number</HeadTh>
            <HeadTh>Digital code</HeadTh>
            <HeadTh>Date Purchased </HeadTh>

            <HeadTh>Picked up </HeadTh>
          </HeadTr>


          <div
          style={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            marginBottom: "6%",
            overflowX: "hidden",
          }}
         >
          {inventoriesList.ids.map((id) => (
            <InvetoryListItem
              key={id}
              inventory={inventoriesList.entities[id]!}
            />
          ))}
        </div>
      </ListWrapper>
    </Wrapper>
  );
};

export default InventoryListPresentation;
