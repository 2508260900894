import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { images } from "../../../../constants";
import { setMetaData } from "../../adminSlice/adminSlice";
import { AdminListPresentationProps } from "../../types/types";
import AdminItemPresentation from "../admin.item.presentation/AdminItemPresentation";

const AdminListPresentation: React.FC<AdminListPresentationProps> = ({
  adminList,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  onSort,
}) => {
  const metaData = useAppSelector(setMetaData);

  return (
    <div
      style={{
        width: "90%",
        background: "#fff",
        marginLeft: 50,
        minHeight: "calc(100vh - 238px)",
      }}
    >
      <div style={{ boxShadow: "0 10px 20px rgba(18, 38, 63, 0.03)" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onSort!("firstName");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Name{" "}
                    {sort !== "firstName" && sort !== "-firstName" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort !== "-firstName" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("adminLevel");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Admin Level{" "}
                    {sort !== "adminLevel" && sort === "adminLevel" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-adminLevel" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("organization.title");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Organizations{" "}
                    {sort !== "organization.title" &&
                    sort === "-organization.title" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-organization.title" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("projects.title");
                  }}
                >
                  <div
                    style={{
                      maxWidth: 120,
                      textAlign: "center",
                      fontSize: 13,
                      justifyContent: "center",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Projects{" "}
                    {sort !== "projects.title" && sort === "-projects.title" ? (
                      <img
                        src={images.unSelectIcon}
                        alt="unselect"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : sort === "-projects.title" ? (
                      <img
                        src={images.BottomIcon}
                        alt="bottom"
                        style={{ paddingLeft: 4 }}
                      />
                    ) : (
                      <img
                        src={images.topIcon}
                        alt="top"
                        style={{ paddingLeft: 4 }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell>Categories</TableCell>
                <TableCell>SubCategories</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminList.ids.map((id) => (
                <AdminItemPresentation item={adminList.entities[id]!} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {metaData?.payload.admin.metadata?.toatlCount > 23 && (
          <TablePagination
            component="div"
            count={metaData?.payload.admin.metadata.toatlCount}
            page={page! - 1}
            rowsPerPage={limit!}
            rowsPerPageOptions={[25, 50, 100]}
            onPageChange={(event: unknown, newPage: number) => {
              setPage!(newPage + 1);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              setLimit!(parseInt(event.target.value, 10));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminListPresentation;
