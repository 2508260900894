import React from "react";
import ProjectDetailsActionPresentation from "../../presentation/project.details.action.presentation/project.details.action.presentation";
import { useGetProjectByIdQuery } from "../../api/projectApiSlice";
import { ProjectDetailsActionContainerProps } from "../../types/types";
const ProjectDetailsActionContainer: React.FC<
  ProjectDetailsActionContainerProps
> = ({ project, handleOpen }) => {
  return (
    <ProjectDetailsActionPresentation
      project={project}
      handleOpen={handleOpen}
    />
  );
};

export default ProjectDetailsActionContainer;
