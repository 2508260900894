import React from "react";
import ExperienceDetailsActionPresentation from "../../presentation/experience.details.presentation/ExperienceDetailsActionPresentation";
import { Project } from "../../../project/types/types";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";

type ExperienceDeatilsActionContainerProps = {
  startDate: Date | null;
  onStartDateHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startTime: string | null;
  onStartTimeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endDate: Date | null;
  onEndDateHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endTime: string | null;
  onEndTimeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  project: Partial<Project> | null;
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  onCategoryHandler: (category: Partial<Category> | null) => void;
  onSubCategroyHandler: (subCategory: Partial<SubCategory> | null) => void;
  publish: boolean;
};

const ExperienceDetailsActionContainer: React.FC<
  ExperienceDeatilsActionContainerProps
> = ({
  startDate,
  onStartDateHandler,
  onStartTimeHandler,
  startTime,
  onEndDateHandler,
  onEndTimeHandler,
  endDate,
  endTime,
  project,
  category,
  subCategory,
  onCategoryHandler,
  onSubCategroyHandler,
  publish,
}) => {
  return (
    <ExperienceDetailsActionPresentation
      startDate={startDate}
      onStartDateHandler={onStartDateHandler}
      startTime={startTime}
      onStartTimeHandler={onStartTimeHandler}
      endDate={endDate}
      endTime={endTime}
      onEndDateHandler={onEndDateHandler}
      onEndTimeHandler={onEndTimeHandler}
      project={project}
      category={category}
      subCategory={subCategory}
      onCategoryHandler={onCategoryHandler}
      onSubCategroyHandler={onSubCategroyHandler}
      publish={publish}
    />
  );
};

export default ExperienceDetailsActionContainer;
