import React, { useState, useEffect } from "react";
import StudentDetailsGamePresentation from "../../presantation/studentdeatilsgamepresentation/StudentDetailsGamePresentation";
import { Student, StudentProjectReport } from "../../types/types";
import { useAppSelector } from "../../../../app/hooks";
import { accountSelected } from "../../slice/studentSlice";
import { useLazyGetAllXpQuery } from "../../../xp/api/xpApi";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
import {
  useGetStudenGemQuery,
  useLazyGetStudenGemQuery,
} from "../../../gem/api/gemApi";
import { selectedProject } from "../../../home/appSlice/appslice";
import { EntityId } from "@reduxjs/toolkit";
import GemNumber from "../../../../components/GemsNumber/GemNumber";
import { useLazyGetFusesQuery } from "../../../fuses/fusesApi";
import { useLazyGetCrystalsQuery } from "../../../crystals/api/crystalApi";
type StudentDetailsGameContainerPros = {
  student: Student;
  projectReport: StudentProjectReport;
  xpDescriptoin: string | null;
  xpAmount: number | null;
  gemAmount: number | null;

  onXpDescripton: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onXpAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGemAmount: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenAward: () => void;
  onCloseAward: () => void;
  openAward: boolean;
  onAddAward: () => void;
};
const StudentDetailsGameContainer: React.FC<
  StudentDetailsGameContainerPros
> = ({
  student,
  projectReport,
  gemAmount,
  onAddAward,
  onCloseAward,
  onGemAmount,
  onOpenAward,
  onXpAmount,
  onXpDescripton,
  openAward,
  xpAmount,
  xpDescriptoin,
}) => {
  const [xpPanel, setXpPanel] = useState<boolean>(false);
  const account = useAppSelector(accountSelected);
  const project = useAppSelector(selectedProject);
  const [getAllXp, { isLoading, isError, isSuccess }] = useLazyGetAllXpQuery();
  const [
    getAllGem,
    { isLoading: gemLoading, isError: gemError, isSuccess: gemSuccess },
  ] = useLazyGetStudenGemQuery();

  const [
    getAllFuses,
    { isLoading: fusesLoading, isError: fusesError, isSuccess: fusesSuccess },
  ] = useLazyGetFusesQuery();

  const [
    getCrystal,
    {
      isLoading: crystalLoading,
      isError: craystalError,
      isSuccess: crystalSuccess,
    },
  ] = useLazyGetCrystalsQuery();

  // const {
  //   data: getAllGem,
  //   isLoading: gemLoading,
  //   isError: gemError,
  //   isSuccess: gemSuccess,
  // } = useGetStudenGemQuery({
  //   projectId: project?._id as string,
  // });

  const [xpNumber, setXpNumber] = useState<number>(0);
  const [gemNumber, setGemNumber] = useState<number>(0);
  const [fusesNumber, setFusesNumber] = useState<number>(0);
  const [crystalNumber, setCrystalNumber] = useState<number>(0);

  useEffect(() => {
    (async () => {
      if (student) {
        try {
          const xpResult = await getAllXp({
            currentSystem: JSON.stringify(account),
            studentId: String(student!._id!),
          }).unwrap();
          console.log("xpResult", xpResult);

          const gemReult = await getAllGem({
            projectId: account?.project as string,
            student: student._id as string,
          }).unwrap();

          const fusesResult = await getAllFuses({
            currentSystem: JSON.stringify(account),
            student: student._id as string,
          }).unwrap();

          const crystalResult = await getCrystal({
            currentSystem: JSON.stringify(account),
            student: student._id as string,
          }).unwrap();

          setXpNumber(xpResult);
          setGemNumber(gemReult.entities[gemReult!.ids![0]]?.gem!);
          setFusesNumber(Number(fusesResult.entities["undefined"]!));
          setCrystalNumber(Number(crystalResult.entities["undefined"]!));
        } catch (error) {}
      }
    })();
  }, [account, student]);

  const onOpenXpPanel = () => {
    setXpPanel(true);
  };

  const onCloseXpPanel = () => {
    setXpPanel(false);
  };
  return (
    <>
      {gemSuccess && (
        <StudentDetailsGamePresentation
          xpPanel={xpPanel}
          onOpenXpPanel={onOpenXpPanel}
          onCloseXpPanel={onCloseXpPanel}
          student={student}
          account={account!}
          projectReport={projectReport!}
          gemAmount={gemAmount!}
          onAddAward={onAddAward}
          onGemAmount={onGemAmount!}
          onXpAmount={onXpAmount!}
          onXpDescripton={onXpDescripton!}
          xpAmount={xpAmount!}
          xpDescriptoin={xpDescriptoin!}
          openAward={openAward}
          onCloseAward={onCloseAward}
          onOpenAward={onOpenAward}
          xpPerProject={xpNumber}
          gemNumber={gemNumber}
          fusesNumber={fusesNumber}
          crystalNumber={crystalNumber}
        />
      )}
    </>
  );
};

export default StudentDetailsGameContainer;
