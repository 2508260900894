import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import StudentDetailsContainer from "../container/studentDetailsContianer/StudentDetailsContainer";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import { shopMetadataSelected } from "../../shop/api/shopSlice";

const StudentDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const projectApp = useAppSelector(selectedProject);

  useEffect(() => {
    if (!projectApp) {
      navigate("/");
    }
  }, [projectApp]);

  return (
    <>
      <Header title="student Details" />
      <StudentDetailsContainer id={id!} />
    </>
  );
};

export default StudentDetailsPage;
