import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  changeSelectCategoryStatus,
  selectCategoryId,
} from "../../../category/categorySlice/categorySlice";
import {
  selectAllSubCategories,
  useGetAllSubCategoryByCategoryIdQuery,
} from "../../api/subCategoryApiSlice";
import { RootState } from "../../../../app/store";
import SubCategoryListPresentation from "../../presentation/subcategory.list.presentation/subcategory.list.presentation";
import { SubCategory } from "../../types/types";
import {
  changeSubCategoryStatus,
  getSubCategoryId,
} from "../../subCategorySlice/subCategorySlice";
import { EntityId } from "@reduxjs/toolkit";

const SubCategoryListContainer = () => {
  const dispatch = useAppDispatch();
  const categoryId = useAppSelector(selectCategoryId);
  const allSubCategoires = useAppSelector((state: RootState) =>
    selectAllSubCategories(state, categoryId!)(state)
  );

  const {data:allSubs,isLoading,isError,isSuccess,refetch} = useGetAllSubCategoryByCategoryIdQuery(categoryId as EntityId)

  const onClickeSubCategoryHandler = (item: Partial<SubCategory>) => {
    dispatch(getSubCategoryId(item._id));
    dispatch(changeSubCategoryStatus(true));
    dispatch(changeSelectCategoryStatus(false));
  };
  return (
    <>
    {isSuccess && (<SubCategoryListPresentation
      subcategories={Object.values(allSubs?.entities!) as SubCategory[]}
      onSubCategoryClickHandler={onClickeSubCategoryHandler}
    />) }
    
    </>
  );
};

export default SubCategoryListContainer;
