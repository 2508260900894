import { createSlice } from "@reduxjs/toolkit";
import { Analytic, MetaData } from "../../../types/types";
import { RootState } from "../../../app/store";
import { ShopType } from "../types/type";

interface IinitialState {
  shopMetadata: MetaData;
}

const initialState: IinitialState = {
  shopMetadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.shopMetadata = action.payload[0];
    },
  },
});

export const shopMetadataSelected = (state: RootState) => {
  return state.shop.shopMetadata;
};

export const { setMetaData } = shopSlice.actions;
export default shopSlice.reducer;
