import {
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";

import { BiSearchAlt } from "react-icons/bi";
import { ExperienceImages } from "../../../experiencetype/types/types";
import { DialogStyle } from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import { SearchExperienceImage, SearchExperienceImageContainer } from "./experience.details.styles";
type ExperienceImageDialogPresentationProps = {
  openImage: boolean;
  onCloseImage: () => void;
  images: ExperienceImages[];
  onImageCardHandler: (url: string) => void;
};
const ExperienceImageDialogPresentation: React.FC<
  ExperienceImageDialogPresentationProps
> = ({ openImage, onCloseImage, images, onImageCardHandler }) => {
  const [searchImages,setSearchImages] = useState<ExperienceImages[]>(images);

  useEffect(() => {
    setSearchImages(images)
  },[images])
  const onChangeSearch= (e:ChangeEvent<HTMLInputElement>) => {

    const result  = images.filter(item => item.title?.includes(e.target.value) || item.tags?.includes(e.target.value));
    console.log('result images',result)
    if(result.length === 0) {
      setSearchImages(images)
    }else{
      setSearchImages(result);
    }
  }
  return (
    <DialogStyle
      open={openImage}
      onClose={onCloseImage}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle 
      style={{fontSize:35 , fontWeight:"bold" , paddingLeft:50}} id="alert-dialog-title">
        Select an Experience Card image
      </DialogTitle>
      <SearchExperienceImageContainer>
          <BiSearchAlt
            spacing={18}
            style={{ position: "absolute", top: 11, left: 18 }}
            color={"#707070"}
          />
          <SearchExperienceImage
            type="text"
            placeholder="Search Student"
           
            onChange={onChangeSearch}
          />
        </SearchExperienceImageContainer>
      <DialogContent sx={{ width: "900px" }}>
        {searchImages.map((image) => (
          <img
            src={image.url}
            height={200}
            onClick={() => {
              onImageCardHandler(image.url);
              onCloseImage();
            }}
            alt={image.url}
          />
        ))}
      </DialogContent>
    </DialogStyle>
  );
};




export default ExperienceImageDialogPresentation;
