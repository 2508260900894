import { EntityId } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import Loading from "../../../components/Loading/Loading";
import { usePickUpBagMutation } from "../../bag/api/bagApi";
import { Bag } from "../../bag/types/types";
import { Category } from "../../category/types/types";
import { selectedProject } from "../../home/appSlice/appslice";
import InventoryContainer from "../../inventories/container/InventoryContainer";
import InventoryListContainer from "../../inventories/container/InventoryListContainer";
import { SubCategory } from "../../subcategory/types/types";
import {
  useDeleteShopMutation,
  useGetAShopQuery,
  useUpdateShopMutation,
} from "../api/shopApiSlice";
import ShopEditCardPresentation from "../presentation/ShopEditReviewPresentation/ShopEditCardPresentation";
import ShopEditReviewButtonControlPresentation from "../presentation/ShopEditReviewPresentation/ShopEditReviewButtonControl/ShopEditReviewButtonControlPresentation";
import ShopEditReviewHeaderPresentation from "../presentation/ShopEditReviewPresentation/ShopEditReviewHeaderPresentation";
import ShopEditReviewPresentationMidColPresenttation from "../presentation/ShopEditReviewPresentation/ShopEditReviewPresentationMidCol/ShopEditReviewPresentationMidColPresenttation";
import ShopEditReviewColPresentation from "../presentation/ShopEditReviewPresentation/ShopEditReviewRightcolPresentation/ShopEditReviewColPresentation";
import {
  ColumnWrapper,
  Wrapper,
} from "../presentation/ShopEditReviewPresentation/shop.edit.review.header.style";
type ShopEditReviewContainerProps = {
  id: EntityId;
};
const ShopEditReviewContainer: React.FC<ShopEditReviewContainerProps> = ({
  id,
}) => {
  const navigation = useNavigate();
  const [
    updateShop,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateShopMutation();

  const [
    deleteShop,
    {
      isLoading: deleteLoading,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteShopMutation();
  const project = useAppSelector(selectedProject);
  const {
    data: shop,
    isLoading,
    isError,
    isSuccess,
    refetch
  } = useGetAShopQuery(id! );

  const [
    pickUpBag,
    {
      isLoading: pickupBagLoading,
      isSuccess: pickUpBagSuccess,
      isError: pickupBagError,
    },
  ] = usePickUpBagMutation();

  const [image, setImage] = useState<Partial<string | null>>(null);

  const [title, setTitle] = useState<string | null>(null);

  const [currentStock, setCurrentStock] = useState<number | null>(null);

  const [gem, setGem] = useState<number | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [constPerItem, setCostPerItem] = useState<number | null>(null);
  const [unLockLevel, setUnLockLevel] = useState<number | null>(null);
  const [purchaseLimitState, setPurchaseLimitState] = useState<boolean>(false);
  const [purchaseLimit, setPurchaseLimit] = useState<number | null>(null);
  const [store, setStore] = useState<boolean>(false);
  const [prizeWheel, setPrizeWheel] = useState<boolean>(false);
  const [addStock, setAddStock] = useState<number>(0);
  const [Inventory, setInventory] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );

  useEffect(() => {
    
    if (shop) {
      setImage(shop.entities[id]!.image);
      setTitle(shop.entities[id]!.title);
      setCurrentStock(shop.entities[id]!.current_stock);
      setGem(shop!.entities[id]!.gem);
      setDescription(shop!.entities[id]!.description);
      setCostPerItem(shop!.entities[id]!.cost_per_item);
      setUnLockLevel(shop!.entities[id]!.unlock_level);
      setPurchaseLimitState(shop!.entities[id]!.purchase_limit_state);
      setPurchaseLimit(shop!.entities[id]!.purchase_limit);
      setStore(shop!.entities[id]!.store);
      setPrizeWheel(shop!.entities[id]!.prize_wheel);
      setCategory(shop!.entities[id]!.category);
      console.log("SHOP CATEGORY", shop!.entities[id]!.category);
      setSubCategory(shop!.entities[id]!.subCategory);
      setDefaultValue(
        shop!.entities[id]!.category
          ? String(shop!.entities[id]!.category._id)
          : "All"
      );
    }
  }, [shop]);

  const onAddStock = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddStock(Number(e.target.value));
  };

  const onAddStockHanlder = async () => {
    setCurrentStock(addStock! + currentStock!);
    await updateShop({
      _id: id!,
      current_stock: addStock! + shop!.entities[id]!.current_stock,
      state: true,
      addStock: addStock,
    });
  };

  const onEditInventoryHandler = () => {
    setInventory(true);
  };

  const onStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStore(e.target.checked);
  };

  const onPrizeWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrizeWheel(e.target.checked);
  };

  const onCostPerItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCostPerItem(Number(e.target.value));
  };

  const onUnlockLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnLockLevel(Number(e.target.value));
  };

  const onPurchaseLimitState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseLimitState(e.target.checked);
  };

  const onPurchaseLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseLimit(Number(e.target.value));
  };

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onGem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGem(Number(e.target.value));
  };

  const onTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onImage = (url: string) => {
    setImage(url);
  };

  const onCategory = (categorySelected: Partial<Category> | null) => {
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelected: Partial<SubCategory> | null) => {
    setSubCategory(subCategorySelected);
  };

  const onUpdateShop = async () => {
    try {
      await updateShop({
        _id: id!,
        title: title!,
        image: image!,
        category: category!,
        cost_per_item: constPerItem!,
        current_stock: currentStock!,
        description: description!,
        gem: gem!,
        prize_wheel: prizeWheel,
        project: project!,
        purchase_limit: purchaseLimit!,
        purchase_limit_state: purchaseLimitState!,
        store: store!,
        subCategory: subCategory!,
        unlock_level: unLockLevel!,
      }).unwrap();
      refetch();
      navigation("/shop");
    } catch (error) {}
  };

  const onRemoveShop = async () => {
    // add confirm when you want delete
    
 
    // eslint-disable-next-line no-restricted-globals
    if(confirm('Are you sure to remove this shop?')){
      try {
        await deleteShop(id).unwrap();
        navigation("/shop");
      } catch (error) {}
    }
    
  };

  const onCancel = async () => {
    navigation('/shop');
  }

  const OnPickupBagClick = async (picked: boolean, bag: Partial<Bag>) => {
    try {
      await pickUpBag({
        _id: bag._id,
        pickedUp: picked,
      }).unwrap();
    } catch (error) {}
  };

  const onShowInventory = () => {
    setInventory(true);
  };

  const onBackInventory = () => {
    setInventory(false);
  };

  let content;
  if (isLoading || isLoadingUpdate || deleteLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <>
        {isSuccess && shop && (
          <Wrapper>
            {pickupBagLoading && <Loading />}

            <ShopEditReviewHeaderPresentation
              headerTitle="Shop"
              project={project!}
              inStockNum={currentStock!}
              notPickedUpNum={0}
              pickedUpNum={0}
              soldNum={0}
              category={shop!.entities[id]!.category}
              onCategory={onCategory}
              subCategory={shop!.entities[id]!.subCategory}
              onSubCategory={onSubCategory}
              defaultValue={defaultValue}
            />

            <ColumnWrapper>
              <ShopEditCardPresentation
                image={image!}
                onChangeItemImg={onImage}
                title={title!}
                onTitle={onTitle}
                currentStock={currentStock!}
                gem={gem!}
                description={description!}
                onDescription={onDescription}
              />
              {Inventory ? (
                <InventoryContainer shopId={id} />
              ) : (
                <ShopEditReviewPresentationMidColPresenttation
                  gem={gem!}
                  onGem={onGem}
                  costPerItem={constPerItem!}
                  onCostPerItem={onCostPerItem}
                  unLockLevel={unLockLevel!}
                  onUnlockLevel={onUnlockLevel}
                  purchaseLimitState={purchaseLimitState}
                  onPurchaseLimitState={onPurchaseLimitState}
                  purchaseLimit={purchaseLimit!}
                  onPurchaseLimit={onPurchaseLimit}
                  store={store}
                  prizeWheel={prizeWheel}
                  onStore={onStore}
                  onPrizeWheel={onPrizeWheel}
                  initialStock={shop!.entities[id]!.inital_stock}
                  currentStock={currentStock!}
                  addStock={addStock}
                  onAddStock={onAddStock}
                  onAddStockHandler={onAddStockHanlder}
                  onEditInventory={onEditInventoryHandler}
                />
              )}

              {Inventory ? (
                <InventoryListContainer shopId={id} />
              ) : (
                <ShopEditReviewColPresentation
                  id={id!}
                  OnPickupBagClick={OnPickupBagClick}
                />
              )}
            </ColumnWrapper>
            <ShopEditReviewButtonControlPresentation
              onUpdateShop={onUpdateShop}
              onRemoveShop={onRemoveShop}
              onCancel={onCancel}
            />
          </Wrapper>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ShopEditReviewContainer;
