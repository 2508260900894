import { RootState } from "../../../../app/store";
import React, { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectProjectById,
  useEditProjectMutation,
} from "../../../project/api/projectApiSlice";
import CategoryChangeLabelPresentation from "../../presentation/categorychagneLabelpresentation/category.changelabel.presentation";
import { CategoryChangeLabelContainerProp } from "../../types/types";
import { EntityId } from "@reduxjs/toolkit";
const CategoryChangeLabelContainer: React.FC<
  CategoryChangeLabelContainerProp
> = ({ project }) => {
  const [editProject, { isLoading, isError, isSuccess }] =
    useEditProjectMutation();

  console.log(project.categoryLabel);
  const [categoryLabel, setCategoryLabel] = useState<string>(
    project?.categoryLabel
  );
  const onCategoryLabelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryLabel(e.target.value);
  };

  const editChagneCategoryLabelHandler = async () => {
    try {
      await editProject({
        _id: project._id,
        categoryLabel,
      }).unwrap;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <CategoryChangeLabelPresentation
        categoryLabel={categoryLabel}
        onChangeCategoryLabel={onCategoryLabelHandler}
        onEditChangeCategoryLabelHandler={editChagneCategoryLabelHandler}
      />
    </div>
  );
};

export default CategoryChangeLabelContainer;
