import React from "react";
import {
  BottomWrapper,
  ButtonText,
  BuyButton,
  CancelButton,
  DescTextbox,
  Footer,
  Gem,
  GemCount,
  GemWrapper,
  Header,
  LeftItem,
  MiddleWrapper,
  TopInput,
  TopWrapper,
  Wrapper,
} from "./raffel.card.item.style";
import CountDownTimer from "../../../../components/CountDownTimer/CountDownTimer";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import { images } from "../../../../constants";
type RaffelTicketCardItemPresentationProps = {
  image: string;
  onImage: (url: string) => void;
  title: string | null;
  onTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  drawDate: Date | null;
  gem: number | null;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string | null;
  onDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};
const RaffelTicketCardItemPresentation: React.FC<
  RaffelTicketCardItemPresentationProps
> = ({
  image,
  onImage,
  title,
  onTitle,
  drawDate,
  gem,
  onGem,
  description,
  onDescription,
}) => {
  return (
    <Wrapper>
      <Header style={{ position: "relative" }}>
        <UploadImageContainer
          defaultImage={image!}
          onChangeImageUrlState={onImage}
          shop={true}
        />
      </Header>
      <MiddleWrapper style={{ zIndex: 2 }}>
        <TopWrapper>
          <TopInput onChange={onTitle} value={title!} />
        </TopWrapper>
        <BottomWrapper>
          <LeftItem>
            {drawDate && <CountDownTimer drawDate={drawDate!} />}
          </LeftItem>
          <GemWrapper>
            <GemCount>{gem}</GemCount>
            <Gem src={images.GemIcon} />
          </GemWrapper>
        </BottomWrapper>
      </MiddleWrapper>
      <DescTextbox value={description!} onChange={onDescription} />
      <Footer>
        <BuyButton>
          <ButtonText>Buy</ButtonText>
        </BuyButton>
        <CancelButton>
          <ButtonText>Cancel</ButtonText>
        </CancelButton>
      </Footer>
    </Wrapper>
  );
};

export default RaffelTicketCardItemPresentation;
