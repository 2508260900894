import { createSlice } from "@reduxjs/toolkit";
import { MetaData } from "../../../types/types";

interface IinitialState {
  metadata: MetaData;
}
const initialState: IinitialState = {
  metadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
  },
});

export const { setMetaData } = organizationSlice.actions;
export default organizationSlice.reducer;
