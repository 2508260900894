import React, { useState } from "react";
import { SubCategoryAddContainerProp } from "../../types/types";
import { useAddNewSubCategoryMutation } from "../../api/subCategoryApiSlice";
import SubCategoryAddPresentation from "../../presentation/subcategory.add.presentation/subcategory.add.presentation";
const SubCategoryAddContainer: React.FC<SubCategoryAddContainerProp> = ({
  categoryId,
}) => {
  const [addNewSubCategory, { isLoading, isError, isSuccess }] =
    useAddNewSubCategoryMutation();

  const [title, setTitle] = useState<string>("");

  const titleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onSubCategorySave = async () => {
    try {
      await addNewSubCategory({
        title,
        category: { _id: categoryId },
      }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <SubCategoryAddPresentation
      title={title}
      titleChangeHandler={titleChangeHandler}
      onSubCategorySave={onSubCategorySave}
    />
  );
};

export default SubCategoryAddContainer;
