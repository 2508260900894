import React from "react";
import { Category } from "../../../category/types/types";
import { Project } from "../../../project/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import StudentFilterPresentation from "../../presantation/student.filter.presentation/StudentFilterPresentation";
type StudentFilterContainerProps = {
  project: Partial<Project> | null;
  category: Partial<Category> | null;
  onSelectCategory: (category: Partial<Category> | null) => void;
  onSelectSubCategory: (subCategoyr: Partial<SubCategory> | null) => void;
  onSelectActive: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
const StudentFilterContainer: React.FC<StudentFilterContainerProps> = ({
  project,
  category,
  onSelectActive,
  onSelectCategory,
  onSelectSubCategory,
}) => {
  console.log("Category    sdfskjdfh skhdf", category);
  return (
    <StudentFilterPresentation
      projectID={project?._id!}
      categoryID={category?._id!}
      onSelectCategory={onSelectCategory}
      onSelectSubCategory={onSelectSubCategory}
      onSelectActiveStatus={onSelectActive}
    />
  );
};

export default StudentFilterContainer;
