import React from "react";
import {
  ExperienceDetailsActionCol,
  ExperienceDetailsActionRow,
  ExperienceDetailsActionWrapper,
  ExperienceDetailsDateWrapper,
  ExperienceDetailsFilterCol,
  ExperienceDetailsFilterRow,
  ExperienceEndDateWrapper,
  ExperienceStartDateWrapper,
  LabelExperience,
} from "./experience.details.styles";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Category } from "../../../category/types/types";
import { Project } from "../../../project/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import SelectField from "../../../../components/design/selectFiled/selectField";
import { formatDateToYYYYMMDD } from "../../../../utils/utils";
type ExperienceDetailsActionPresentationProps = {
  startDate: Date | null;
  onStartDateHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startTime: string | null;
  onStartTimeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endDate: Date | null;
  onEndDateHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endTime: string | null;
  onEndTimeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  project: Partial<Project> | null;
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  onCategoryHandler: (category: Partial<Category> | null) => void;
  onSubCategroyHandler: (subCategory: Partial<SubCategory> | null) => void;
  publish: boolean;
  defaultValue?: string;
};
const ExperienceDetailsActionPresentation: React.FC<
  ExperienceDetailsActionPresentationProps
> = ({
  startDate,
  onStartDateHandler,
  startTime,
  onStartTimeHandler,
  endDate,
  defaultValue,
  endTime,
  onEndDateHandler,
  onEndTimeHandler,
  project,
  category,
  subCategory,
  onCategoryHandler,
  onSubCategroyHandler,
  publish,
}) => {
  console.log("SHOWING DATE IN ", formatDateToYYYYMMDD(startDate!));
  return (
    <ExperienceDetailsActionWrapper>
      <ExperienceDetailsActionRow
        style={{ display: "flex", alignItems: "flex-start" }}
        className="row m-0 w-100"
      >
        <ExperienceDetailsActionCol
          Direction={""}
          className="experience-empty-col"
          style={{ width: "28%", padding: 2 }}
        ></ExperienceDetailsActionCol>
        <ExperienceDetailsActionCol
          Direction={""}
          className="experience-responsive-col"
          style={{
            justifyContent: "center",
            width: "22%",
            padding: 2,
            flexWrap: "nowrap",
          }}
        >
          <div style={{ paddingRight: 20 }}>
            <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
              Start Date
            </p>
            <input
              type="date"
              name="startDate"
              id="startDate"
              value={startDate ? formatDateToYYYYMMDD(startDate!) : ""}
              style={{
                width: 137,
                padding: 5,
                border: "1px solid #CED4DA",
                fontSize: 12,
                fontFamily: "Poppins",
                borderRadius: 5,
                color: "#495057",
              }}
              onChange={onStartDateHandler}
            />

            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="startTime"
                id="startTime"
                value={startTime ? startTime : ""}
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
                onChange={onStartTimeHandler}
              />
            </div>
          </div>
          <div>
            <p style={{ fontFamily: "Poppins", fontSize: 14, marginBottom: 0 }}>
              End Date
            </p>
            <input
              type="date"
              name="endDate"
              id="endDate"
              value={endDate ? formatDateToYYYYMMDD(endDate) : ""}
              onChange={onEndDateHandler}
              style={{
                width: 137,
                padding: 5,
                border: "1px solid #CED4DA",
                fontSize: 12,
                fontFamily: "Poppins",
                borderRadius: 5,
                color: "#495057",
              }}
            />

            <div style={{ marginTop: 20 }}>
              <input
                type="time"
                name="endTime"
                id="endTime"
                value={endTime ? endTime : ""}
                onChange={onEndTimeHandler}
                style={{
                  width: 137,
                  padding: 5,
                  border: "1px solid #CED4DA",
                  fontSize: 12,
                  fontFamily: "Poppins",
                  borderRadius: 5,
                  color: "#495057",
                }}
              />
            </div>
          </div>
        </ExperienceDetailsActionCol>

        <ExperienceDetailsActionCol
          Direction={""}
          className="experience-responsive-col"
          style={{
            width: "17%",
            padding: 2,
            justifyContent: "flex-start",
            paddingLeft: 15,
          }}
        >
          <ExperienceDetailsActionCol Direction="column" style={{ padding: 2 }}>
            <LabelExperience>Category</LabelExperience>
            <FilterCategoryContainer
              projectID={project?._id!}
              onSelectCategory={onCategoryHandler}
              selectedDefault={category?._id! as string}
            />
          </ExperienceDetailsActionCol>
          <ExperienceDetailsActionCol Direction="column" style={{ padding: 2 }}>
            <LabelExperience>Subcategory</LabelExperience>
            <FilterSubCategoryContainer
              categoryID={category?._id!}
              onSelectSubCategory={onSubCategroyHandler}
              defaultValue={subCategory!}
            />
          </ExperienceDetailsActionCol>
        </ExperienceDetailsActionCol>
        <ExperienceDetailsActionCol
          Direction={""}
          className="experience-responsive-col"
          style={{ width: "31%", padding: 2 }}
        >
          <ExperienceDetailsActionCol
            style={{ padding: 11, fontSize: 20, fontWeight: 700 }}
            Direction={""}
          >
            <div>Or</div>
          </ExperienceDetailsActionCol>
          <ExperienceDetailsActionCol
            Direction="column"
            style={{
              padding: 11,
              marginRight: 11,
              borderRightWidth: 2,
              borderRightColor: "#a09696",
              borderRightStyle: "solid",
            }}
          >
            <LabelExperience>Tags</LabelExperience>
            <SelectField onChange={() => {}}>
              <option value="All">All</option>
            </SelectField>
          </ExperienceDetailsActionCol>
          <ExperienceDetailsActionCol Direction="column" style={{ padding: 2 }}>
            <LabelExperience>Education Phases</LabelExperience>
            <SelectField onChange={() => {}}>
              <option value="All">All</option>
            </SelectField>
            <LabelExperience>Semester</LabelExperience>
            <SelectField onChange={() => {}}>
              <option value="All">All</option>
            </SelectField>
          </ExperienceDetailsActionCol>
        </ExperienceDetailsActionCol>
      </ExperienceDetailsActionRow>
    </ExperienceDetailsActionWrapper>
  );
};

export default ExperienceDetailsActionPresentation;
