import React from "react";

import {
  SettingBox,
  SettingText,
  SettingTitleRow,
} from "../../features/setting/settingPresentation/setting.presentation.styles";
import Drivers from "../Drivers/Drivers";
import Learner from "../Learner/Learner";

type SettingTabsProps = {
  condition: number;
  learnerTiming?: string;
  changeLearnerTimingHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  changeStartLearnerDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerStartDate: Date;
  changeEndLearnerDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  learnerEndDate: Date;
  changeLearnerProgressDaysAgo: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  progressDaysAgo: number;
  onProgressScore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  progressScore: string;
  momentumLabel: string;
  onMomentumLabel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  momentumDaysAgo: number;
  onMomentumDaysAgo: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  academicScoreType?: string | null;
  onAcademicScoreType?: (t: string) => void;
  chartViewType?: string | null;
  onChartViewType?: (t: string) => void;
  termStartDate?: Date;
  termEndDate?: Date;
  onTermStartDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTermEndDate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectedTerms?: (terms: string[]) => void;
  selectedTerm?: string[];
};
const SettingTabs: React.FC<SettingTabsProps> = ({
  condition,
  learnerTiming,
  changeLearnerTimingHandler,
  changeStartLearnerDate,
  learnerStartDate,
  changeEndLearnerDate,
  learnerEndDate,
  changeLearnerProgressDaysAgo,
  progressDaysAgo,
  onProgressScore,
  progressScore,
  momentumLabel,
  onMomentumLabel,
  momentumDaysAgo,
  onMomentumDaysAgo,
  onSave,
  academicScoreType,
  onAcademicScoreType,
  chartViewType,
  onChartViewType,
  onTermEndDate,
  onTermStartDate,
  termEndDate,
  termStartDate,
  onSelectedTerms,
  selectedTerm,
}) => {
  let showBox = null;
  switch (condition) {
    case 1:
      showBox = (
        <Drivers
        // categoryOptions=""
        // onCategoryChanged=""
        // onStartDateChange=""
        // onEndDateChange=""
        // endDate=""
        // selfTags=""
        // onSelfTagsChange=""
        // futureTags=""
        // onFutureTagsChange=""
        // connectionTags=""
        // onConnectionTagsChange=""
        // emotionalTags=""
        // onEmotionalTagsChange=""
        // socialTags=""
        // onSocialTagsChange=""
        // financialTags=""
        // onChangeFinancialTags=""
        // academicTags=""
        // onAcademicTagsChange=""
        // situationalTags=""
        // onSituationalTags=""
        />
      );
      break;
    case 2:
      showBox = (
        <Learner
          studentTiming={learnerTiming}
          changeLearnerTimingHandler={changeLearnerTimingHandler}
          changeStartLearnerDate={changeStartLearnerDate}
          learnerStartDate={learnerStartDate}
          changeEndLearnerDate={changeEndLearnerDate}
          learnerEndDate={learnerEndDate}
          changeLearnerProgressDaysAgo={changeLearnerProgressDaysAgo}
          progressDaysAgo={progressDaysAgo}
          onProgressScore={onProgressScore}
          progressScore={progressScore}
          momentumLabel={momentumLabel}
          onMomentumLabel={onMomentumLabel}
          momentumDaysAgo={momentumDaysAgo}
          onMomentumDaysAgo={onMomentumDaysAgo}
          onSave={onSave}
          onAcademicScoreType={onAcademicScoreType}
          academicScoreType={academicScoreType}
          chartViewType={chartViewType}
          onChartViewType={onChartViewType}
          onTermEndDate={onTermEndDate}
          onTermStartDate={onTermStartDate}
          termEndDate={termEndDate}
          termStartDate={termStartDate}
          onSelectedTerm={onSelectedTerms}
          selectedTerm={selectedTerm}
          // categoryOptions=""
          // onCategoryChanged=""
          // onStartDateChange=""
          // onEndDateChange=""
          // endDate=""
          // selfTags=""
          // onSelfTagsChange=""
          // futureTags=""
          // onFutureTagsChange=""
          // connectionTags=""
          // onConnectionTagsChange=""
          // emotionalTags=""
          // onEmotionalTagsChange=""
          // socialTags=""
          // onSocialTagsChange=""
          // financialTags=""
          // onChangeFinancialTags=""
          // academicTags=""
          // onAcademicTagsChange=""
          // situationalTags=""
          // onSituationalTags=""
        />
      );
      break;

    default:
      showBox = (
        <SettingTitleRow>
          <h1>Hi</h1>
          <SettingText>Hey Im page 1 !</SettingText>
        </SettingTitleRow>
      );
  }
  return <SettingBox>{showBox}</SettingBox>;
};

export default SettingTabs;
