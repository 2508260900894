import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Spinner = styled.div<{
  spinnerSize?: string;
}>`
  width: 144px;
  height: 144px;
  ::after {
    content: "";
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 2px solid #d3d3d3;
    background: transparent;
    width: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    height: ${({ spinnerSize }) => `${spinnerSize || 24}px`};
    border-radius: 50%;
    align-self: center;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 611px;
  width: 430px;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  z-index: 1;
  background-color: white;
  margin-top: -115px;
  @media (max-width: 1650px) {
    margin-top: 0;
  }
  @media (max-width: 1650px) {
    width: 360px;
  }
  @media (max-width: 1500px) {
    width: 43%;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
  margin-top: 22px;
`;
export const HeaderImg = styled.img`
  width: 100%;
  height: 130px;
  object-fit: cover;
`;
export const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 120px;
  width: 80%;
  border-radius: 16px;
  background-color: #3e3f5e;
  border: 4px solid #fff;
  margin-top: -15px;
`;
export const TopWrapper = styled.div`
  height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TopInput = styled.input`
  height: 51px;
  width: 297px;
  background-color: #4e4f76;
  border-radius: 8px;
  font-family: "Rajdhani";
  font-size:30px;
  font-weight:bold;
  outline: none;
  border: none;
  color: white;
  text-align: center;
  overflow: hidden;
  ::placeholder {
    font-size: 39px;
    font-weight: 500;
    font-family: "Rajdhani";
    line-height: inherit;
    color: white;
  }
`;
export const BottomWrapper = styled.div`
  height: 47px;
  width: 100%;
  background-color: #0bc4b8;
  border-radius: 8px;
  display: flex;
`;
export const DescTextbox = styled.textarea`
  width: 85%;
  height: 197px;
  max-width:85%;
  margin-top: 38px;
  background-color: #fdfdfd;
  box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 13%);
  border-radius: 4px;
  border: none;
  outline: none;
  margin-bottom: 11.5px;
  font-size: 26px;
  font-weight: 600;
  font-family: "Rajdhani";
  text-align: center;
  color: #3e3f5e;
  padding-top:18px;
  ::placeholder {
    font-size: 16px;
    font-weight: 500;
    font-family: "Rajdhani";
    line-height: inherit;
  }
  line-height: normal;
  line-height: 32px;
  padding: 20px;
  padding-top: 32px;
  @media (max-width: 1300px) {
    width: 100%;
    font-size: 21px;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 62px;
  width: 335px;
  flex-direction: row;
  box-shadow: 0 3px 6px rgb(0 0 0 / 18%);
  border-radius: 8px;
  margin-bottom: 32px;
`;
export const ButtonText = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-family: "Rajdhani";
  color: white;
`;
export const BuyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  width: 335px;
  background-color: #4ed386;
  border-radius: 8px;
  position: absolute;
  border: none;
  outline: none;
  pointer-events: none;
  font-family: "Rajdhani";
  font-weight: 600;
  padding-right: 60px;
`;
export const CancelButton = styled.button`
  height: 62px;
  width: 114px;
  background-color: #f4284f;
  border-radius: 8px;
  position: relative;
  right: 0;
  border: none;
  outline: none;
  pointer-events: none;
`;
export const LeftItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  padding-top: 4px;
  padding-left: 10%;
  color: white;
  margin-left: 5px;
`;
export const GemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 50px;
  border-radius: 35px;
  border: 3px solid white;
  color: white;
  background-color: black;
  position: relative;
  top: 34px;
  padding: 0 15px;
  right: 36% ;
  @media (max-width: 1650px) {
    right: 40% ;
  }
  @media (max-width: 1500px) {
    right: 40% ;
  }
`;
export const GemCount = styled.div`
  font-size: 30px;
  font-weight: 700;
  font-family: "Rajdhani";
  color: white;
  @media (max-width: 1650px) {
    font-size: 23px;
    padding-left: 5px;
  }

`;
export const LockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 59px;
  border-radius: 8px;
  border: 3px solid white;
  color: white;
  background-color: #34c38f;
  position: relative;
  top: 25px;
  left: 18%;
  @media (max-width: 1650px) {
    left: 5%;
  }
`;
export const LockCount = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani";
  color: #34c38f;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const Lock = styled.img``;
export const Gem = styled.img`
max-width:30px;
padding-left:3px
`;
export const RaffleEditButton = styled.button`
  display: flex;
  height: 32px;
  border-radius: 4px;
  width: 60px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  position: absolute;
  right: 8px;
  top: 6px;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const RaffleProjectEditButton = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const RaffleEditButtonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const RaffleEditButtonIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;
