import React from "react";
import {
  CreateShopContainer,
  CreateShopForm,
  CreateShopFormCol,
  CreateShopFormRow,
  ImagContainer,
  LabelShop,
  ShopButon,
  ShopEditButtton,
  ShopEditButttonIcon,
  ShopEditButttonText,
  ShopImage,
  ShopInput,
  Spinner,
  SuggestShopText,
  SwitchLabelShop,
} from "./shop.add.presentation";
import { MdEdit } from "react-icons/md";
import { Switch } from "@mui/material";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { Project } from "../../../project/types/types";
import { EntityId } from "@reduxjs/toolkit";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import CustomSwitch from "../../../../components/switch/CustomSwitch";
import { images } from "../../../../constants";

type ShopAddPresentationProps = {
  closeHandler: () => void;
  image?: string;
  onImage?: (url: string) => void;
  title: string | null;
  onTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialStock: number | null;
  onInitialStock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  unlockLevel: number | null;
  onUnlockLevel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  description: string | null;
  onDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  costPerItems: number | null;
  onCostPerItems: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number | null;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWeel: boolean;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  category: Partial<Category> | null;
  onCategory: (e: Partial<Category> | null) => void;
  subCategory: Partial<SubCategory> | null;
  onSubCategory: (e: Partial<SubCategory> | null) => void;
  project: Partial<Project>;
  onSave: () => void;
  saveLoading: boolean;
};
const ShopAddPresentation: React.FC<ShopAddPresentationProps> = ({
  closeHandler,
  image,
  onImage,
  title,
  onTitle,
  initialStock,
  onInitialStock,
  unlockLevel,
  onUnlockLevel,
  description,
  onDescription,
  costPerItems,
  onCostPerItems,
  gem,
  onGem,
  store,
  onStore,
  prizeWeel,
  onPrizeWheel,
  category,
  onCategory,
  subCategory,
  onSubCategory,
  project,
  onSave,
  saveLoading,
}) => {
  return (
    <CreateShopContainer className="form-Shop-add">
      <CreateShopForm>
        <div style={{ display: "flex" }}>
          <CreateShopFormRow
            style={{ paddingLeft: 20, width: "auto", flexDirection: "column" }}
          >
            <CreateShopFormCol
              alignment="flex-start"
              style={{ paddingLeft: 0, position: "relative" }}
            >
              <UploadImageContainer
                defaultImage={image}
                onChangeImageUrlState={onImage!}
              />
            </CreateShopFormCol>
            <CreateShopFormCol
              Direction="column"
              style={{ paddingBottom: 10, paddingTop: 20 }}
            >
              <LabelShop style={{ fontSize: 18 }}>Available In:</LabelShop>
            </CreateShopFormCol>
            <CreateShopFormCol
              Direction="row"
              style={{ flexDirection: "column", paddingLeft: 2, minWidth: 150 }}
            >
              <CreateShopFormCol
                Direction="row"
                style={{
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  paddingBottom: 10,
                }}
              >
                <SwitchLabelShop>Store</SwitchLabelShop>
                <CustomSwitch
                  style={""}
                  isToggle={store}
                  onChangeToggle={onStore}
                />
              </CreateShopFormCol>
              <CreateShopFormCol
                Direction="row"
                style={{ justifyContent: "space-between", maxWidth: "100%" }}
              >
                <SwitchLabelShop style={{ whiteSpace: "nowrap" }}>
                  Prize Wheel
                </SwitchLabelShop>
                <CustomSwitch
                  style={""}
                  isToggle={prizeWeel}
                  onChangeToggle={onPrizeWheel}
                />
              </CreateShopFormCol>
            </CreateShopFormCol>
          </CreateShopFormRow>
          <CreateShopFormRow style={{ flexDirection: "column" }}>
            <CreateShopFormCol>
              <CreateShopFormCol
                style={{
                  paddingRight: 5,
                  paddingLeft: 12,
                  marginTop: 35,
                  flexWrap: "wrap",
                  maxWidth: 360,
                  height: 180,
                }}
              >
                <CreateShopFormCol Direction="column" style={{ width: "100%" }}>
                  <LabelShop>Item Title</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    name="itemName"
                    placeholder="Item Name"
                    value={title!}
                    onChange={onTitle}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol
                  Direction="column"
                  style={{ maxWidth: "50%", paddingBottom: 5 }}
                >
                  <LabelShop>Stock</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    name="itemName"
                    placeholder="Initial Stock"
                    value={initialStock!}
                    onChange={onInitialStock}
                  />
                </CreateShopFormCol>

                <CreateShopFormCol
                  Direction="column"
                  style={{ maxWidth: "50%", paddingBottom: 5 }}
                >
                  <LabelShop>Unlock Level</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    name="itemName"
                    placeholder="Average User Level"
                    value={unlockLevel!}
                    onChange={onUnlockLevel}
                  />
                </CreateShopFormCol>

                <div style={{ display: "flex", marginTop: 50 }}>
                  <CreateShopFormCol Direction="column">
                    <LabelShop>Category</LabelShop>
                    <FilterCategoryContainer
                      projectID={project!._id! as EntityId}
                      onSelectCategory={onCategory}
                      page="shop"
                    />

                    {/* <SelectComponent
                    style={{ width: "100%", height: 35 }}
                    options={categories}
                    name="category"
                    selected={true}
                    onChange={(e) => {
                      onChangeCategory(e.target.value);
                    }}
                  /> */}
                  </CreateShopFormCol>

                  <CreateShopFormCol Direction="column">
                    <LabelShop>SubCategory</LabelShop>
                    <FilterSubCategoryContainer
                      categoryID={category?._id!}
                      onSelectSubCategory={onSubCategory!}
                    />
                    {/* <SelectComponent
                    style={{ width: "85%", height: 35 }}
                    options={""}
                    name="subCategory"
                    selected={true}
                    onChange={(e) => {}}
                  /> */}
                  </CreateShopFormCol>
                </div>
              </CreateShopFormCol>

              <div
                style={{
                  width: 1,
                  backgroundColor: "#707070",
                  height: "92%",
                  paddingTop: 20,
                }}
              ></div>

              <CreateShopFormCol
                style={{
                  paddingRight: 10,
                  paddingLeft: 15,
                  marginTop: 20,
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
              >
                <h2 style={{ textAlign: "center", fontSize: 28 }}>Cost</h2>
                <CreateShopFormCol Direction="column">
                  <LabelShop>Cost Per Item </LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    name="cost"
                    placeholder="Dollar Amount "
                    value={costPerItems!}
                    onChange={onCostPerItems}
                  />
                </CreateShopFormCol>

                <SuggestShopText>Suggested Gems</SuggestShopText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    -<sub> Gems</sub>
                  </div>
                </div>

                <CreateShopFormCol Direction="column">
                  <LabelShop>Gems</LabelShop>
                  <ShopInput
                    Width="100%"
                    Height="39px"
                    name="cost"
                    placeholder="Set Your Own Price "
                    value={gem!}
                    onChange={onGem}
                  />
                </CreateShopFormCol>
              </CreateShopFormCol>
            </CreateShopFormCol>

            <CreateShopFormCol
              style={{ flexDirection: "column", marginTop: 80 }}
            >
              <LabelShop>Description</LabelShop>
              <textarea
                style={{ width: "95%", minHeight: 80, borderColor: "#e5e9ec" }}
                value={description!}
                onChange={onDescription}
              />
            </CreateShopFormCol>
          </CreateShopFormRow>
        </div>
        <CreateShopFormRow
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 30,
            marginBottom: 10,
            justifyContent: "center",
          }}
        >
          <CreateShopFormCol
            style={{ justifyContent: "flex-end", paddingRight: 10 }}
          >
            <ShopButon
              onClick={onSave}
              variant="contained"
              disabled={saveLoading}
              style={{ padding: "12px 50px", width: 145 }}
            >
              {saveLoading ? (
                <img
                  src={images.saveLoadingSpinner}
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  alt="this is test"
                />
              ) : (
                "Save"
              )}
            </ShopButon>
          </CreateShopFormCol>
          <CreateShopFormCol style={{ paddingLeft: 30 }}>
            <ShopButon
              style={{ padding: "12px 50px" }}
              onClick={closeHandler}
              variant="contained"
            >
              Cancel
            </ShopButon>
          </CreateShopFormCol>
        </CreateShopFormRow>
      </CreateShopForm>
    </CreateShopContainer>
  );
};

export default ShopAddPresentation;
