import React from "react";
import { ExperienceCard } from "../../types/types";
import EditButton from "../../../../components/EditButton";
import "./experienceReview.css";
import { EntityId } from "@reduxjs/toolkit";
type ExperienceReviewCardHeaderPresentationProps = {
  experience: ExperienceCard;
  onEdit: (id: EntityId) => void;
  
};
const ExpereinceReviewCardHeaderPresentation: React.FC<
  ExperienceReviewCardHeaderPresentationProps
> = ({ experience, onEdit }) => {
  return (
    <div style={{}}>
      <div
        className="review-wrapper"
        style={{ background: experience?.experienceType?.color }}
      >
        <div className="review-edit-button">
          <EditButton
            style={{
              borderWidth: 5,
              borderRadius: 10,
              width: 90,
              height: 58,
              borderStyle: "solid",
              borderColor: "#fff",
              fontSize: 24,
            }}
            onClick={() => {
              onEdit(experience._id);
            }}
          />
        </div>
        <div className="review_title">
          <h1>{experience?.title}</h1>
          <h3>{experience?.description}</h3>
        </div>

        <div className="review_image">
          <img
            className="review_image_img"
            src={experience.image}
            alt="experienceCard"
          />
          <div style={{ paddingBottom: 20 }}>
            <span className="xp-content-review">
              {experience?.xpCompletion}
            </span>
            <span className="xp-text">XP</span>
          </div>
        </div>
        <div className="review_icon">
          <div className="review_icon_exp">
            <img src={experience.experienceType.icon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpereinceReviewCardHeaderPresentation;
