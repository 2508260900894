import React from "react";
import {
  AvailableSection,
  AvailableTitle,
  AvailableItem,
  AvailableText,
} from "./shopmidcol.styles";
import { Switch } from "@mui/material";
import CustomSwitch from "../../../../../components/switch/CustomSwitch";
type ShopAvailablePresenationProp = {
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWheel: boolean;
};
const ShopAvailablePresentation: React.FC<ShopAvailablePresenationProp> = ({
  store,
  onStore,
  prizeWheel,
  onPrizeWheel,
}) => {
  return (
    <AvailableSection>
      <AvailableTitle>Available</AvailableTitle>
      <AvailableItem>
        <AvailableText>Shop</AvailableText>

        <CustomSwitch
          style={""}
          isToggle={store}
          onChangeToggle={onStore}
        />
      </AvailableItem>
      <AvailableItem>
        <AvailableText>Prize Wheel</AvailableText>

        <CustomSwitch
          style={""}
          isToggle={prizeWheel}
          onChangeToggle={onPrizeWheel}
        />
      </AvailableItem>
    </AvailableSection>
  );
};

export default ShopAvailablePresentation;
