import { Paper } from "@mui/material";
import React, { useEffect } from "react";
import PolygonChart from "../../../../components/PolygonChart/PolygonChart";
import CircleChart from "../../../../components/circleChart/CircleChart";
import {
  Student,
  StudentProjectReport,
  StudentSystemType,
} from "../../types/types";
import StudentDetailFilter from "../StudentDetailFilter";

type StudentDetailsChartPresentationProps = {
  student: Student;
  account: StudentSystemType;
  projectReport: StudentProjectReport;
};
const StudentDetailsChartPresentation: React.FC<
  StudentDetailsChartPresentationProps
> = ({ student, account, projectReport }) => {

  return (
    <Paper elevation={3} className="st-info" >
      <div className="student-container">
        <div className="student-name">
          <h2>{student.firstName}</h2>
          <h2 style={{ paddingLeft: 15 }}>{student.lastName}</h2>
        </div>
        <PolygonChart
          XP={projectReport?.xps + 1}
          studentPic={student.image}
          color={student?.team?.color}
        />
      </div>
      <div className="student-cahrt-filter-container">
    
                <StudentDetailFilter/>


        <div style={{ display: "flex" }}>
          <div
            className="fourth-student-info forth-student-score"
            style={{ paddingLeft: "8%" }}
          >
            <CircleChart />
            {/* {console.log("Score", scores)}
            {scores && (
              <>
                <StudentScore
                  scale={1}
                  title="Student Score"
                  left={1}
                  top={1}
                />
              </>
            )} */}
          </div>
          <div
            className="fourth-student-info forth-student-scores"
            style={{
              width: "49%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* {scores && ( */}
            <>
              <CircleChart                     
                        textWeight={200}
                        scale={0.8}
                        left={-73}
                        top={-35}
                        />
          <CircleChart 
                        textWeight={200}
                        className="development-chart"
                        scale={0.8}
                        top={-35}
                        left={-143}
                        />
          <CircleChart 
                        textWeight={200}
                        className="collage"
                        scale={0.8}
                        left={-240}
                        top={-35}
                        />
            </>
            {/* )} */}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default StudentDetailsChartPresentation;
