export const calculatePercent = (value: number, total: number) => {
  return Math.floor((value / total) * 100);
};

export const calulateCircleChartPercent = (total: number, percent: number) => {
  var result;
  if (percent == 100) {
    result = 1;
  } else {
    const calPercent = (total * percent) / 100;
    result = total - calPercent;
  }

  return result;
};

export function formatDateToYYYYMMDD(date: Date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
}
