import styled from "@emotion/styled";

export const OrganizationsContainer = styled.div`
  width: 100%;
  background: #fff;
  min-height:calc(100vh - 238px);
`;
export const OrganizationsHeaderControle = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  @media (max-width: 1770px) {
    
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
`;
export const SearchOrganizations = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #CED4DA;
  padding-left: 35px;
  text-indent: 10px;
  font-family: 'poppins';
  font-size: 13px;

  &:focus {
    outline: none;
  }
`;
export const SearchOrganizationsContainer = styled.div`
  width: 227px;
  height: 36px;
  position:relative;
  padding-left:30px;
  padding-top:5px;

`;
export const OrganizationsButton = styled.button`
  background:#34C38F;
  color:#fff;
  border: none;
  padding: 10px 19px 10px 41px;
  transition-duration:300ms;
  margin-left: 55px;
  font-family: 'poppins';
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight:300;
  @media (max-width: 1300px) {

    margin-left:40px !important;
  } 
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left:20px;
  } ;
  span{
    white-space: nowrap;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
export const OrganizationsContentContainer = styled.div`

  padding-top:2px;
  margin-left: 50px;
  max-width: 1650px;
  @media (max-width:1300px) {
    margin-left:20px;
  }
`;
