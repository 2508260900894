import { Alert, Stack } from "@mui/material";
import React from "react";
type ToastProps = {
  typeToast: "Success" | "Fail";
  message: string;
};
const Toast: React.FC<ToastProps> = ({ typeToast, message }) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {typeToast === "Success" && (
        <Alert variant="outlined" severity="success">
          {message}
        </Alert>
      )}

      {typeToast === "Fail" && (
        <Alert variant="outlined" severity="error">
          {message}
        </Alert>
      )}
    </Stack>
  );
};

export default Toast;
