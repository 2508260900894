import React from "react";
import "./gems.css";
// import collagecoins from '../../assets/image/collagecoins.png';

import { images } from "../../constants";
type GemNumberProp = {
  gems: number;
};
const GemNumber: React.FC<GemNumberProp> = ({ gems }) => {
  return (
    <div className="coins-student">
      <img src={images.gem} alt="" style={{ width: 35 }} />
      <p className="coins-number">{gems}</p>
      <p className="coins">Gem</p>
    </div>
  );
};

export default GemNumber;
