import { EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { CompletionType } from "../types/types";

const completionTypeAdaptor = createEntityAdapter<CompletionType>({
  selectId: (completionType) => completionType._id,
});

interface InitialStateCompletionType extends EntityState<CompletionType> {}

const initialState: InitialStateCompletionType =
  completionTypeAdaptor.getInitialState();

export const extendedCompletionTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompletionType: builder.query<EntityState<CompletionType>, void>({
      query: () => `/completion-type`,
      transformResponse: (response: CompletionType[]) => {
        return completionTypeAdaptor.setAll(initialState, response);
      },
      providesTags: (result, error, arg) => [
        {
          type: "CompletionType",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({ type: "CompletionType" as const, id })),
      ],
    }),
  }),
});

export const { useGetAllCompletionTypeQuery } = extendedCompletionTypeApi;
