import styled from "@emotion/styled";

export const SchoolDistrictWrapper = styled.div`
  width: 100%;
  padding: 15px;
`;

export const SchoolDistrictInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SchoolDistrictLabel = styled.h2`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 50px;
  color: blue;
`;

export const SchoolDistrictCol1 = styled.div`
  width: 33%;
`;

export const SchoolDistrictCol2 = styled.div`
  width: 33%;
`;

export const SchoolDistrictCol3 = styled.div`
  width: 33%;
`;

export const SchoolDistrictSectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SchoolDistrictSectionCol = styled.div`
  width: 200px;
`;

export const ShowCanEditWrapper = styled.div`
  width: 100%;
`;

export const ShowCanEditTitle = styled.h4`
  text-align: center;
`;

export const ShowCanEditItems = styled.div`
  width: 100%;
  padding-top: 15px;
`;

export const ShowCanEditItemsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowCanEditItemCol = styled.div`
  width: 50%;
  text-align: center;
`;



export const EditButtonAccess = styled.button`
  display: flex;
  height: 36px;
  border-radius: 4px;
  width: 70px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-duration:300ms;
  border: none;
  @media (max-width: 1300px) {
    width: fit-content !important;
    padding-left:7px;
    padding-right:7px;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

