import React from "react";
import { SelectOrganizationListPresentationProps } from "../../types/types";
import { EntityId } from "@reduxjs/toolkit";
import { HomeOrganizationPresentationSelec } from "./FilterByOrganizationPresentation.style";
const FilterByOrganizationPresentation: React.FC<
  SelectOrganizationListPresentationProps
> = ({ organizationList, onSelectOrganization, selectedDefault }) => {
  return (
    <HomeOrganizationPresentationSelec
      onChange={onSelectOrganization}
      defaultValue={selectedDefault}
    >
      <option style={{ color: "#8d8d8d" }} value="All">
        All
      </option>
      {organizationList.ids.map((id: EntityId) => {
        return (
          <option key={id} value={id} style={{ color: "#8d8d8d" }}>
            {organizationList.entities[id]?.title}
          </option>
        );
      })}
    </HomeOrganizationPresentationSelec>
  );
};

export default FilterByOrganizationPresentation;
