import React, { useState, useEffect } from "react";
import { StudentAddContainerProps, StudentSystemType } from "../../types/types";
import StudentAddPresentation from "../../presantation/studentAddPresentation/StudentAddPresentation";
import { Team } from "../../../team/types/types";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectedOrganization,
  selectedProject,
} from "../../../home/appSlice/appslice";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { title } from "process";
import { SchoolDistrict } from "../../../schoolDistrict/types/types";
import { HighSchool } from "../../../highschool/types/types";
import { University } from "../../../unviersity/types/types";
import {
  useCreateStudentMutation,
  useUpdateStudentbyIdMutation,
} from "../../api/studentApi";
import Toast from "../../../../components/toast/Toast";
import { EntityId } from "@reduxjs/toolkit";
import { useLazySearchSchoolDistrictByNameQuery } from "../../../schoolDistrict/api/schoolDistrictApiSlice";
import { Project } from "../../../project/types/types";
import { selectProjectById } from "../../../project/api/projectApiSlice";

const StudentAddContainer: React.FC<StudentAddContainerProps> = ({
  open,
  onClose,
  status,
  student,
  deafultTeam,
}) => {
  const [createStudent, { isLoading, isError, isSuccess }] =
    useCreateStudentMutation();
  const [
    updateStudent,
    {
      isLoading: updateLoading,
      isError: updateError,
      isSuccess: updateSuccess,
    },
  ] = useUpdateStudentbyIdMutation();

  const [
    searchSchoolDistrictByName,
    { isLoading: SearchLoading, error: errorSearching },
  ] = useLazySearchSchoolDistrictByNameQuery();

  const selectedHomeProjects = useAppSelector(selectedProject);
  const selectedHomeOrganization = useAppSelector(selectedOrganization);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [team, setTeam] = useState<Partial<Team> | null>(deafultTeam!);
  const [educationalPhases, setEducationalPhase] = useState<string>("HS-9");
  const [system, setSystem] = useState<StudentSystemType[]>([]);
  const [openSystem, setOpenSystem] = useState<boolean>(false);
  const [selectedProjectt, setSelectedProjectt] =
    useState<Partial<Project> | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<Partial<Category> | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<Partial<SubCategory> | null>(null);

  const [schoolDistrictID, setSchoolDistrictID] =
    useState<Partial<SchoolDistrict> | null>(null);

  const [highSchool, setHighschool] = useState<Partial<HighSchool> | null>(
    null
  );

  const [studentSchoolDistrictId, setStudentSchoolDistrictId] = useState<
    string | null
  >(null);

  const [studentSchoolEmail, setStudentSchoolEmail] = useState<string | null>(
    null
  );

  const [university, setUniversity] = useState<Partial<University> | null>(
    null
  );

  const [universityStudentID, setUniversityStudentID] = useState<string | null>(
    null
  );

  const [universityEmail, setUniversityEmail] = useState<string | null>(null);

  const [studentUniversityId, setStudentUniversityId] = useState<string | null>(
    null
  );

  const [studentUniversityEmail, setStudentUniversityEmail] = useState<
    string | null
  >(null);

  const [firstGen, setFirstGen] = useState<boolean>(false);
  const [text, setText] = useState<boolean>(false);
  const [call, setCall] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [working, setWorking] = useState<boolean>(false);
  const [age, setAge] = useState<Date | null>(null);
  const [gender, setGender] = useState<string>("Male");
  const [workingDescription, setWorkingDescription] = useState<string | null>(
    null
  );

  const [showToastFail, setShowToastFail] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToastSuccess, setShowToastSuccess] = useState<boolean>(false);
  const [progress, setProgress] = useState<boolean>(false);
  const [external_link_one, set_external_link_one] = useState<string | null>(
    null
  );
  const [external_link_two, set_external_link_two] = useState<string | null>(
    null
  );

  useEffect(() => {
    console.log(schoolDistrictID);
    console.log(highSchool);
  }, [schoolDistrictID, highSchool]);
  useEffect(() => {
    if (status) {
      setFirstName(student!.firstName);
      setLastName(student!.lastName);
      setEmail(student!.email);
      setPassword(student!.password);
      setPhone(student!.phone);
      setEducationalPhase(student!.educationalPhases!);
      setTeam(student!.team);
      setSystem(student!.system);
      setStudentSchoolDistrictId(
        student?.studentSchoolDistrict!.studentSchoolDistrictId!
      );
      setStudentSchoolEmail(
        student?.studentSchoolDistrict.studentSchoolDistrictEmail!
      );
      setStudentUniversityId(student?.studentUniversity.studentUniversityID!);
      setStudentUniversityEmail(
        student?.studentUniversity.studentUniversityEmail!
      );

      set_external_link_one(student?.external_link_one!);
      set_external_link_two(student?.external_link_two!);
    }
  }, [status]);

  useEffect(() => {
    console.log(
      "******************* System ****************************",
      system
    );
  }, [system]);

  const onSelectedProjectHandler = (project: Partial<Project> | null) => {
    console.log("****Project", project);
    setSelectedProjectt(project);
  };
  const onEducationalPhaseHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setEducationalPhase(e.target.value);
  };
  const onWorkingDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingDescription(e.target.value);
  };

  const onGenderHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGender(e.target.value);
  };

  const onFirstGenHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setFirstGen(true);
    } else if (e.target.value === "No") {
      setFirstGen(false);
    }
  };

  const onTextHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.checked);
  };

  const onCallHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCall(e.target.checked);
  };

  const onSendEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(e.target.checked);
  };

  const onWorkingHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Yes") {
      setWorking(true);
    } else if (e.target.value === "No") {
      setWorking(false);
    }
  };

  const onExternalLinkHandlerOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_external_link_one(e.target.value);
  };

  const onExternalLinkHandlerTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_external_link_two(e.target.value);
  };
  const onFirstnameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const onLastNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const onEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onPhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onSelectTeamHandler = (team: Partial<Team> | null) => {
    console.log(team);
    setTeam(team);
  };

  const onOpenSystemHandler = () => {
    setOpenSystem(true);
  };

  const onCloseSystemHandler = () => {
    setOpenSystem(false);
  };

  const onSelectedCategory = (category: Partial<Category> | null) => {
    setSelectedCategory(category);
  };

  const onSelectedSubCategory = (subCategory: Partial<SubCategory> | null) => {
    setSelectedSubCategory(subCategory);
  };

  const onSchoolDistrictIDHandler = (
    schoolDistrict: Partial<SchoolDistrict> | null
  ) => {
    setSchoolDistrictID(schoolDistrict);
  };

  const onAddSystem = async () => {
    if (selectedProjectt) {
      const findSystem = system.find(
        (item) =>
          item.organization._id === selectedHomeOrganization?._id &&
          item.project._id === selectedProjectt?._id &&
          item.category?._id === selectedCategory?._id &&
          item.subcategory?._id === selectedSubCategory?._id
      );

      if (findSystem) {
      } else {
        console.log("RUUUUUUUUUUUUUN THIS ONE");
        setSystem([
          ...system,
          {
            organization: selectedHomeOrganization!,
            project: selectedProjectt!,
            category: selectedCategory!,
            subcategory: selectedSubCategory!,
            xps: 0,
            gems: 0,
            eventProject:
              selectedHomeProjects?.project_type === "Event" ? true : false,
          },
        ]);
      }

      let text = selectedHomeProjects?.title!;
      console.log("TEXT", text);
      let arr = text.split(" ").filter((x) => x !== "");
      console.log("Arrr", arr);
      let finalText = "";

      for (let item of arr) {
        finalText += item + " ";
      }

      if (selectedProjectt.project_type === "Secondary") {
        if (selectedProjectt.schoolDistrict) {
          const searchSchool = await searchSchoolDistrictByName(
            selectedProjectt.title!
          ).unwrap();

          setSchoolDistrictID({
            _id: searchSchool[0].id,
            agancy_name: searchSchool[0].label,
          });
          setHighschool({
            _id: selectedCategory?._id! as string,
            school_name: selectedCategory?.title!,
          });
        }
      }

      setSelectedCategory(null);
      setSelectedSubCategory(null);
    }
  };

  const onRemoveItemInSystem = (item: StudentSystemType) => {
    console.log("item", item);
    console.log("system", system);
    const newSystem = system.filter((sysItem) => {
      return sysItem !== item;
    });

    // console.log(newSystem);

    setSystem(newSystem);
  };

  const onHighSchoolHandler = (highschool: Partial<HighSchool> | null) => {
    setHighschool(highSchool);
  };

  const onStudentSchoolDistrictID = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStudentSchoolDistrictId(e.target.value);
  };

  const onStudentSchoolDistrictEmail = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStudentSchoolEmail(e.target.value);
  };

  const onUniversityHandler = (university: Partial<University> | null) => {
    setUniversity(university);
  };

  const onStudentUniversityID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentUniversityId(e.target.value);
  };

  const onStudentUniversityEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentUniversityEmail(e.target.value);
  };

  const onAgeHandler = (newValue: Date | null) => {
    console.log(newValue);
    setAge(newValue);
  };

  const onSaveHandler = async () => {
    if (status !== "Edit") {
      console.log("Add is Running");
      if (system.length === 0) {
        setToastMessage("System should be fill at least one");
        setShowToastFail(true);
        setTimeout(() => {
          setShowToastFail(false);
        }, 3000);
        throw new Error();
      }
      try {
        setProgress(true);
        await createStudent({
          firstName: firstName!,
          lastName: lastName!,
          fullName: firstName + " " + lastName,
          email: email!.toLowerCase().trim(),
          password: password!,
          adminLevel: "USER",
          active: true,
          educationalPhases: educationalPhases,
          system: system,
          birthDay: age!,
          studentSchoolDistrict: {
            schoolDistrictID: schoolDistrictID!,
            studentSchoolDistrictId: studentSchoolDistrictId!,
            studentSchoolDistrictEmail: studentSchoolEmail!,
            highschool: highSchool!,
          },
          image: "https://api.gradify.app/static/download.ff.png",
          backgroundBanner:
            "https://api.gradify.app/static/resizefile-1684825339302-277566432.png",
          studentUniversity: {
            university: university!,
            studentUniversityEmail: studentUniversityEmail!,
            studentUniversityID: studentUniversityId!,
          },

          firstGen: firstGen,
          preferredContact: {
            call: call,
            text: text,
            email: sendEmail,
          },
          gender: gender!,
          phone: phone!,
          team: team!,
          working: working,
          workingWhere: workingDescription!,
          external_link_one: external_link_one!,
          external_link_two: external_link_two!,
        }).unwrap();
        setShowToastSuccess(true);
        setTimeout(() => {
          setShowToastSuccess(false);
          setProgress(false);
          setFirstName(null);
          setLastName(null);
          setEmail(null);
          setPassword(null);
          setPhone(null);
          setTeam(deafultTeam!);
          setEducationalPhase("HS-9");
          setSystem([]);
          setSelectedCategory(null);
          setSelectedSubCategory(null);
          setSchoolDistrictID(null);
          setHighschool(null);
          setStudentSchoolDistrictId(null);
          setStudentSchoolEmail(null);
          setUniversity(null);
          setUniversityStudentID(null);
          setUniversityEmail(null);
          setStudentUniversityId(null);
          setStudentUniversityEmail(null);
          setFirstGen(false);
          setText(false);
          setCall(false);
          setSendEmail(false);
          setWorking(false);
          setAge(null);
          setGender("Male");
          setWorkingDescription(null);

          onClose();
        }, 3000);
      } catch (error: any) {
        console.log("THIS IS ERROR :", error.message);
        setProgress(false);
        console.log(error);
        if (error.status === 400) {
          setToastMessage(error.data.message[0]);
        } else {
          setToastMessage(error.data.message);
        }
        setShowToastFail(true);
        setTimeout(() => {
          setShowToastFail(false);
        }, 3000);
      }
    } else if (status === "Edit") {
      if (system.length === 0) {
        console.log("**************************   run this one");
        setToastMessage("System should be fill at least one");
        setShowToastFail(true);
        setTimeout(() => {
          setShowToastFail(false);
        }, 3000);
        throw new Error();
      }
      try {
        setProgress(true);
        await updateStudent({
          _id: student!._id!,
          firstName: firstName!,

          lastName: lastName!,
          fullName: firstName + " " + lastName,
          email: email!,
          password: password!,
          adminLevel: "USER",
          active: true,
          educationalPhases: educationalPhases,
          system: system,

          studentSchoolDistrict: {
            schoolDistrictID: schoolDistrictID!,
            studentSchoolDistrictId: studentSchoolDistrictId!,
            studentSchoolDistrictEmail: studentSchoolEmail!,
            highschool: highSchool!,
          },

          studentUniversity: {
            university: university!,
            studentUniversityEmail: studentUniversityEmail!,
            studentUniversityID: studentUniversityId!,
          },

          phone: phone!,
          team: team!,
          external_link_one: external_link_one!,
          external_link_two: external_link_two!,
        }).unwrap();
        setShowToastSuccess(true);

        setTimeout(() => {
          setShowToastSuccess(false);
          setProgress(false);

          onClose();
          setShowToastSuccess(false);
          onClose();
        }, 3000);
      } catch (error: any) {
        setProgress(false);
        console.log(error);
        if (error.status === 400) {
          setToastMessage(error.data.message[0]);
        } else {
          setToastMessage(error.data.message);
        }
        setShowToastFail(true);
        setTimeout(() => {
          setShowToastFail(false);
        }, 3000);
      }
    }
  };

  const onCancelHandler = () => {
    setShowToastSuccess(false);
    setProgress(false);
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPassword(null);
    setPhone(null);
    setTeam(deafultTeam!);
    setEducationalPhase("HS-9");
    setSystem([]);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSchoolDistrictID(null);
    setHighschool(null);
    setStudentSchoolDistrictId(null);
    setStudentSchoolEmail(null);
    setUniversity(null);
    setUniversityStudentID(null);
    setUniversityEmail(null);
    setStudentUniversityId(null);
    setStudentUniversityEmail(null);
    setFirstGen(false);
    setText(false);
    setCall(false);
    setSendEmail(false);
    setWorking(false);
    setAge(null);
    setGender("Male");
    setWorkingDescription(null);
    setProgress(false);
  };

  return (
    <>
      <StudentAddPresentation
        open={open}
        onClose={onClose}
        status={status!}
        firstName={firstName!}
        lastName={lastName!}
        email={email!}
        password={password!}
        phone={phone!}
        onFirstNameHandler={onFirstnameHandler}
        onLastNameHandler={onLastNameHandler}
        onEmailHanlder={onEmailHandler}
        onPasswordHandler={onPasswordHandler}
        onPhoneHandler={onPhoneHandler}
        onTeamSelectHandler={onSelectTeamHandler}
        system={system}
        openSystem={openSystem}
        onOpenSystem={onOpenSystemHandler}
        onCloseSystem={onCloseSystemHandler}
        selectedHomeOrganization={selectedHomeOrganization!}
        selectedHomeProject={selectedHomeProjects!}
        onAddSystem={onAddSystem}
        onSelectedCategory={onSelectedCategory}
        onSelectedSubCategory={onSelectedSubCategory}
        selectedCategory={selectedCategory}
        onRemove={onRemoveItemInSystem}
        onSchoolDistrictID={onSchoolDistrictIDHandler}
        schoolDistrictID={schoolDistrictID}
        highSchool={highSchool}
        onHighSchoolHandler={onHighSchoolHandler}
        studentSchoolDistrictId={studentSchoolDistrictId}
        studentSchoolEmail={studentSchoolEmail}
        onStudentSchoolDistrictId={onStudentSchoolDistrictID}
        onStudentSchoolEmail={onStudentSchoolDistrictEmail}
        university={university}
        onUniversityHandler={onUniversityHandler}
        studentUniversityID={studentUniversityId}
        onStudetnUniversityID={onStudentUniversityID}
        universityEmail={studentUniversityEmail}
        onUniversityEmail={onStudentUniversityEmail}
        onFirstGenHanlder={onFirstGenHandler}
        firstGen={firstGen}
        text={text}
        onTextHandler={onTextHandler}
        call={call}
        onCallHandler={onCallHandler}
        sendEmail={sendEmail}
        onSendEmailHandler={onSendEmailHandler}
        working={working}
        onWorkingHandler={onWorkingHandler}
        age={age}
        onAgeHanlder={onAgeHandler}
        gender={gender}
        onGenderHandler={onGenderHandler}
        workingDescription={workingDescription}
        onWorkginDescription={onWorkingDescription}
        onEducationalPhaseHandler={onEducationalPhaseHandler}
        onSaveStudent={onSaveHandler}
        isloading={isLoading}
        progress={progress}
        educationalPhases={educationalPhases}
        team={team!}
        student={student!}
        onSelectedProject={onSelectedProjectHandler}
        selectedProject={selectedProjectt}
        external_link_one={external_link_one!}
        external_link_two={external_link_two!}
        onExternalLinkOneHandler={onExternalLinkHandlerOne}
        onExternalLinkTwoHandler={onExternalLinkHandlerTwo}
        onCancelHandler={onCancelHandler}
      />

      {showToastFail && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message={toastMessage!} typeToast="Fail" />
        </div>
      )}
      {showToastSuccess && (
        <div
          style={{
            position: "absolute",
            bottom: 30,
            right: 10,
            width: "500px",
            zIndex: 20000,
            background: "white",
          }}
        >
          <Toast message="Student Save Successfuly" typeToast="Success" />
        </div>
      )}
    </>
  );
};

export default StudentAddContainer;
