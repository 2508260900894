import React from "react";
import Header from "../../../components/header/Header";
import SettingContainer from "../settingContainer/SettingContainer";

const SettingPage = () => {
  return (
    <>
      <Header title="Setting" />
      <SettingContainer />
    </>
  );
};

export default SettingPage;
