import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const StudentContainer = styled.div`
  width: 90%;
  margin-left: 50px;
  background: ${({ color }) => color};
  min-height: calc(100vh - 238px);
  @media (max-width: 1300px) {
    margin-left: 20px;
    width: 95%;
  }
`;

export const StudentButton = styled.button<{
  spaceLeft: string;
}>`
  background: #34c38f;
  color: white;
  border: none;
  padding: 10px 14px 10px 8px;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 300;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
  }

  &:disabled{
    color: whitesmoke;
    background-color: #cbcbcb;
    pointer-events: none;
  }
`;
