import { title } from "process";
import React, { useEffect } from "react";
import { MdEdit } from "react-icons/md";
import {
  CreateRaffleFormRow,
  CreateRaffleFormCol,
  LabelRaffle,
  RaffleContainer,
} from "../raffel.styles";
import {
  CreateRaffleContainer,
  CreateRaffleForm,
  ImagContainer,
  Spinner,
  RaffleImage,
  RaffleEditButtton,
  RaffleEditButttonText,
  RaffleEditButttonIcon,
  SwitchLabelRaffle,
  RaffleInput,
  DateWrapper,
  RaffleButon,
} from "./raffel.add.style";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import { Switch } from "@mui/material";
import { store } from "../../../../app/store";
import { Category } from "../../../category/types/types";
import { SubCategory } from "../../../subcategory/types/types";
import { Project } from "../../../project/types/types";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import CustomSwitch from "../../../../components/switch/CustomSwitch";
import { images } from "../../../../constants";
type RaffelTicketAddPresentationProps = {
  openAdd: boolean;
  onOpenAdd: () => void;
  onClose: () => void;
  image: string;
  onImage: (url: string) => void;
  store: boolean;
  onStore: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prizeWheel: boolean;
  onPrizeWheel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title: string | null;
  onTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  gem: number | null;
  onGem: (e: React.ChangeEvent<HTMLInputElement>) => void;
  numberOfWinner: number | null;
  onNumberOfWinner: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startDate: Date | null;
  onStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endDate: Date | null;
  onEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  drawDate: Date | null;
  onDrawDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startTime: string | null;
  onStartTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  endTime: string | null;
  onEndTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  drawTime: string | null;
  onDrawTime: (e: React.ChangeEvent<HTMLInputElement>) => void;
  category: Partial<Category> | null;
  onCategory: (category: Partial<Category> | null) => void;
  subCategory: Partial<SubCategory> | null;
  onSubCategory: (subCategory: Partial<SubCategory> | null) => void;
  description: string | null;
  onDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  project: Partial<Project> | null;
  onSave: () => void;
  saveLoading: boolean;
};
const RaffelTicketAddPresentation: React.FC<
  RaffelTicketAddPresentationProps
> = ({
  openAdd,
  onClose,
  onOpenAdd,
  image,
  onImage,
  defaultValue,
  store,
  onStore,
  prizeWheel,
  onPrizeWheel,
  title,
  onTitle,
  numberOfWinner,
  onNumberOfWinner,
  gem,
  onGem,
  startDate,
  onStartDate,
  endDate,
  onEndDate,
  drawDate,
  onDrawDate,
  startTime,
  onStartTime,
  endTime,
  onEndTime,
  drawTime,
  onDrawTime,
  category,
  onCategory,
  subCategory,
  onSubCategory,
  description,
  onDescription,
  project,
  onSave,
  saveLoading,
}) => {
  return (
    <RaffleContainer
      style={{
        display: openAdd ? "block" : "none",
      }}
    >
      <CreateRaffleContainer className="form-Raffle-add">
        <CreateRaffleForm>
          <div style={{ display: "flex" }}>
            <CreateRaffleFormRow
              style={{
                paddingLeft: 20,
                width: "auto",
                flexDirection: "column",
              }}
            >
              <CreateRaffleFormCol
                alignment="flex-start"
                style={{ paddingLeft: 0, position: "relative" }}
              >
                <UploadImageContainer
                  defaultImage={image}
                  onChangeImageUrlState={onImage}
                />
              </CreateRaffleFormCol>

              <CreateRaffleFormCol
                Direction="column"
                style={{ paddingBottom: 10, paddingTop: 20 }}
              >
                <LabelRaffle style={{ fontSize: 18 }}>
                  Available In:
                </LabelRaffle>
              </CreateRaffleFormCol>
              <CreateRaffleFormCol
                Direction="row"
                style={{
                  flexDirection: "column",
                  paddingLeft: 5,
                  minWidth: 190,
                }}
              >
                <CreateRaffleFormCol
                  Direction="row"
                  style={{
                    justifyContent: "space-between",
                    maxWidth: "100%",
                    paddingBottom: 10,
                  }}
                >
                  <SwitchLabelRaffle>Store</SwitchLabelRaffle>
                  <CustomSwitch
                    style={""}
                    isToggle={store}
                    onChangeToggle={onStore}
                  />
                </CreateRaffleFormCol>
                <CreateRaffleFormCol
                  Direction="row"
                  style={{ justifyContent: "space-between", maxWidth: "100%" }}
                >
                  <SwitchLabelRaffle>Prize Wheel</SwitchLabelRaffle>
                  <CustomSwitch
                    style={""}
                    isToggle={prizeWheel}
                    onChangeToggle={onPrizeWheel}
                  />
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>
            </CreateRaffleFormRow>

            <CreateRaffleFormRow style={{ flexDirection: "column" }}>
              <CreateRaffleFormCol style={{ justifyContent: "space-between" }}>
                <CreateRaffleFormCol
                  style={{
                    paddingRight: 5,
                    paddingLeft: 12,
                    marginTop: 20,
                    flexWrap: "wrap",
                    maxWidth: 360,
                    height: 100,
                  }}
                >
                  <CreateRaffleFormCol
                    Direction="column"
                    style={{ width: "100%" }}
                  >
                    <LabelRaffle>Item Title</LabelRaffle>
                    <RaffleInput
                      Width="100%"
                      Height="39px"
                      name="itemName"
                      placeholder="item name"
                      value={title!}
                      onChange={onTitle}
                    />
                  </CreateRaffleFormCol>
                </CreateRaffleFormCol>

                <CreateRaffleFormCol
                  style={{
                    paddingRight: "21%",
                    paddingLeft: 15,
                    marginTop: 20,
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  <CreateRaffleFormCol Direction="column">
                    <LabelRaffle>Gem Number</LabelRaffle>
                    <RaffleInput
                      Width="100%"
                      Height="39px"
                      name="Gems"
                      placeholder="Gems "
                      value={gem!}
                      onChange={onGem}
                    />
                  </CreateRaffleFormCol>
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>
              <CreateRaffleFormCol style={{ flexDirection: "row" }}>
                <LabelRaffle style={{ fontSize: 18, marginRight: 20 }}>
                  Number Of Winners
                </LabelRaffle>
                <RaffleInput
                  Width="40%"
                  Height="39px"
                  name="numberOfWinner"
                  placeholder="-"
                  value={numberOfWinner!}
                  onChange={onNumberOfWinner}
                />
              </CreateRaffleFormCol>
              <CreateRaffleFormCol style={{ flexDirection: "column" }}>
                <DateWrapper>
                  <div style={{ paddingRight: 10 }}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 15,
                        marginBottom: 0,
                      }}
                    >
                      Start Date
                    </p>
                    <div>
                      <input
                        type="date"
                        name="startDate"
                        id="startDate"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={startDate?.toISOString().split("T")[0]}
                        onChange={onStartDate}
                      />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <input
                        type="time"
                        name="startTime"
                        id="startTime"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={startTime!}
                        onChange={onStartTime}
                      />
                    </div>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 15,
                        marginBottom: 0,
                      }}
                    >
                      Close Date
                    </p>
                    <div>
                      <input
                        type="date"
                        name="closeDate"
                        id="closeDate"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={endDate?.toISOString().split("T")[0]}
                        onChange={onEndDate}
                      />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <input
                        type="time"
                        name="closeTime"
                        id="closeTime"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={endTime!}
                        onChange={onEndTime}
                      />
                    </div>
                  </div>
                  <div style={{ paddingRight: 10 }}>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 15,
                        marginBottom: 0,
                      }}
                    >
                      Drawing Date
                    </p>
                    <div>
                      <input
                        type="date"
                        name="drawDate"
                        id="drawDate"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={drawDate?.toISOString().split("T")[0]}
                        onChange={onDrawDate}
                      />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <input
                        type="time"
                        name="darwTime"
                        id="drawTime"
                        style={{
                          width: 137,
                          padding: 5,
                          border: "1px solid #CED4DA",
                          fontSize: 12,
                          fontFamily: "Poppins",
                          borderRadius: 5,
                          color: "#495057",
                        }}
                        value={drawTime!}
                        onChange={onDrawTime}
                      />
                    </div>
                  </div>
                </DateWrapper>
                <CreateRaffleFormCol
                  style={{ paddingLeft: 0, marginBottom: 10 }}
                >
                  <CreateRaffleFormCol
                    Direction="column"
                    style={{ paddingLeft: 0 }}
                  >
                    <LabelRaffle>Category</LabelRaffle>
                    <FilterCategoryContainer
                      projectID={project?._id!}
                      onSelectCategory={onCategory}
                      selectedDefault={defaultValue}
                      page="raffle"
                    />
                    {/* <SelectComponent
                    style={{ width: "100%", height: 35 }}
                    options={categories}
                    name="category"
                    selected={true}
                    onChange={(e) => {
                      onChangeCategory(e.target.value);
                    }}
                  /> */}
                  </CreateRaffleFormCol>

                  <CreateRaffleFormCol Direction="column">
                    <LabelRaffle>SubCategory</LabelRaffle>
                    <FilterSubCategoryContainer
                      categoryID={category?._id!}
                      onSelectSubCategory={onSubCategory}
                      defaultValue={subCategory!}
                    />
                    {/* <SelectComponent
                    style={{ width: "85%", height: 35 }}
                    options={""}
                    name="subCategory"
                    selected={true}
                    onChange={(e) => {}}
                  /> */}
                  </CreateRaffleFormCol>
                </CreateRaffleFormCol>
              </CreateRaffleFormCol>
              <CreateRaffleFormCol style={{ flexDirection: "column" }}>
                <LabelRaffle>Description</LabelRaffle>
                <textarea
                  style={{
                    width: "95%",
                    minHeight: 80,
                    borderColor: "#e5e9ec",
                  }}
                  value={description!}
                  onChange={onDescription}
                />
              </CreateRaffleFormCol>
            </CreateRaffleFormRow>
          </div>
          <CreateRaffleFormRow
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 30,
              marginBottom: 10,
              justifyContent: "center",
            }}
          >
            <CreateRaffleFormCol
              style={{ justifyContent: "flex-end", paddingRight: 10 }}
            >
              <RaffleButon
                variant="contained"
                disabled={saveLoading}
                onClick={onSave}
                style={{ padding: "12px 50px" }}
              >
                {saveLoading ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    alt="this is test"
                  />
                ) : (
                  "Save"
                )}
              </RaffleButon>
            </CreateRaffleFormCol>
            <CreateRaffleFormCol style={{ paddingLeft: 30 }}>
              <RaffleButon style={{ padding: "12px 50px" }} onClick={onClose}>
                Cancel
              </RaffleButon>
            </CreateRaffleFormCol>
          </CreateRaffleFormRow>
        </CreateRaffleForm>
      </CreateRaffleContainer>
    </RaffleContainer>
  );
};

export default RaffelTicketAddPresentation;
