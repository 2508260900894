import styled from "@emotion/styled";

export const StudnetSystemWrapper = styled.div`
width: 98%;
margin-top: 10px;
max-width: 835px;
border: 1px solid rgb(206, 212, 218);
border-radius: 22px;
display: flex;
-webkit-box-align: center;
align-items: center;
flex-wrap: wrap;
padding: 20px;
-webkit-box-pack: start;
justify-content: flex-start;
margin-right: 20px;
max-height: 185px;
overflow: overlay;
`;

export const StudentSystemItemStyle = styled.div`

`;
