const shopIconImage = require("../../assets/image/shopIcon.png");
const surveyIconImage = require("../../assets/image/survayIcon.png");
const TitleLinkImage = require("../../assets/image/title-link.png");
const DownloadIcon = require("../../assets/image/download.png");
const DownloadsIcon = require("../../assets/image/downloads.png");
const StoreIcon = require("../../assets/image/storeIcon.png");
const GemIcon = require("../../assets/image/gemIcon.png");
const LockIcon = require("../../assets/image/lock.png");
const LockIconWhite = require("../../assets/image/lockIcon.png");
const TarshIcon = require("../../assets/image/trashIcon.png");
const ArrowIcon = require("../../assets/image/ArrowShape.png");
const RaffelIcon = require("../../assets/image/raffleIcon.png");
const UnlockIcon = require("../../assets/image/unlockIcon.png");
const Sheild = require("../../assets/image/shield.png");
const Crystal = require("../../assets/image/crystal.png");
const Fuses = require("../../assets/image/fuses.png");
const BottomIcon = require("../../assets/image/bottomSelected.png");
const topIcon = require("../../assets/image/topSelected.png");
const unSelectIcon = require("../../assets/image/unselected.png");
const hexAsset = require("../../assets/image/HexAsset.png");
const closeIcon = require("../../assets/image/close-icon.png");
const LoadingSpinner = require("../../assets/image/rolling.gif");
const saveLoadingSpinner = require("../../assets/image/Rolling-1s-51px.gif");
const fuze = require("../../assets/image/fuze.png");
const crystal = require("../../assets/image/item.png");
const gem = require("../../assets/image/gemss.png");
export default {
  shopIconImage,
  fuze,
  crystal,
  closeIcon,
  gem,
  surveyIconImage,
  TitleLinkImage,
  DownloadIcon,
  LockIconWhite,
  StoreIcon,
  GemIcon,
  LockIcon,
  TarshIcon,
  ArrowIcon,
  RaffelIcon,
  UnlockIcon,
  Sheild,
  Crystal,
  Fuses,
  BottomIcon,
  unSelectIcon,
  topIcon,
  hexAsset,
  LoadingSpinner,
  saveLoadingSpinner,
  DownloadsIcon
};
