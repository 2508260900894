import React from "react";
import {
  StudentProjectReport,
  StudentSystemType,
} from "../../students/types/types";
import { useGetLeaderBoardQuery } from "../api/leaderBoardApi";
import LeaderBoardPresentation from "../presentation/LeaderBoardPresentation";
type LeaderBoardContainerProps = {
  account: StudentSystemType;
  projectReport: StudentProjectReport;
};
const LeaderBoardContainer: React.FC<LeaderBoardContainerProps> = ({
  account,
  projectReport,
}) => {
  const {
    data: leaderBoards,
    isLoading,
    isError,
    isSuccess,
  } = useGetLeaderBoardQuery(JSON.stringify(account));
  return (
    <>{isSuccess && <LeaderBoardPresentation leaderBoard={leaderBoards} />}</>
  );
};

export default LeaderBoardContainer;
