import React from "react";
import { LeaderBoard } from "../types/types";
import "./leaderboardItem.css";
type LeaderBoardItemPresentationProps = {
  item: LeaderBoard;
  index: number;
};
const LeaderBoardItemPresentation: React.FC<
  LeaderBoardItemPresentationProps
> = ({ item, index }) => {
  return (
    <div className="leader-board-item">
      <div className="leader-board-item-content">
        <div className="leader-board-item-index">{index}</div>
      </div>
      <div className="leader-board-item-content leader-board-item-xp-container">
        <p className="leader-board-item-name">{item.fullName + " "}</p>
        <div className="leader-board-item-xp-content">
          <p className="leader-board-item-xp">{item.xp ? item.xp : 0}</p>
          <p className="leader-board-item-xp-text">XP</p>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardItemPresentation;
