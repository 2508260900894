import { spawn } from "child_process";
import { FaWindowClose } from "react-icons/fa";
import { ProjectSection, ProjectCatList, ProjectCatListClose, ProjectDetailCategoryList } from "../../features/category/presentation/categorychagneLabelpresentation/category.changelabel.presentation.style";
import { ProjectCatListContainer } from "../../features/organization/presantation/organization.add.presentation/organization.add.styles";
import { Organization } from "../../features/organization/types/types";
import {
  ShowListInTagFromatClose,
  ShowListInTagFromatContainerStyle,
  ShowListInTagFromatStyle,
} from "./show.list.in.tag.format.styles";
import { IShowListTagFormat } from "./show.list.in.tag.format.types";
function ShowListInTagFormat<T>(props: IShowListTagFormat<T>) {
  return (


    <ProjectDetailCategoryList>
      {props.list.map((item: any, index: number) => (
        <ProjectCatList

          key={item?._id}
          onClick={() => props.selectItemHanlder!(item)}
        >
          <span
            style={{
              maxWidth: 100,
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              textAlign: "start",
            }}

          >
            {item.title}
          </span>
          {props.unRemovabelFist && index === 0 ? null : (
            <ProjectCatListClose
              onClick={(e) => {
                e.stopPropagation();

                props.onRemove!(item);
              }}
            >
              <FaWindowClose color={"#707070"} />
            </ProjectCatListClose>
          )}
        </ProjectCatList>
      ))}
    </ProjectDetailCategoryList>




  );
}

export default ShowListInTagFormat;
