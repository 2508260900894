import { useCallback, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { useGetAllProjectByOrganizationQuery } from "../../../project/api/projectApiSlice";
import ProjectContainer from "../../../project/container/project.container";
import {
  selectOrganizationById,
  useGetAnOrganizationQuery,
} from "../../api/orgApiSlice";
import { OrganizationItemContainerProps } from "../../types/types";
import OrganizationAddContainer from "../OrganizationAddContainer";
import OrganizationDetailsActionContainer from "./Organization.details.action.container/organization.details.action.container";
import OrganizationDetailsAction from "./Organization.details.action.container/organization.details.action.container";
import { error } from "console";
const OrganizationDetailsMainContainer: React.FC<
  OrganizationItemContainerProps
> = ({ organizationId }) => {
  console.log("orid", organizationId);
  const {
    data: organization,
    isLoading,
    isSuccess,
    isError,
    error: orgError,
  } = useGetAnOrganizationQuery(organizationId);

  const [openStatusAddDisplay, setOpenStatusAddDisplay] =
    useState<boolean>(false);

  const handleClose = useCallback(
    () => setOpenStatusAddDisplay(false),
    [openStatusAddDisplay]
  );

  const handleOpen = useCallback(
    () => setOpenStatusAddDisplay(true),
    [openStatusAddDisplay]
  );
  console.log("orgError", orgError);
  return (
    <>
      {isSuccess && (
        <>
          <OrganizationDetailsActionContainer
            organization={organization!.entities[organizationId]}
            handleOpen={handleOpen}
          />
          <OrganizationAddContainer
            openStatusAddDisplay={openStatusAddDisplay}
            handleClose={handleClose}
            status="EDIT"
            organization={organization!.entities[organizationId]}
          />
          <ProjectContainer
            organization={organization!.entities[organizationId]!}
          />
        </>
      )}
      {isError && <h1>{`something went wrong ggggg`}</h1>}
    </>
  );
};

export default OrganizationDetailsMainContainer;
