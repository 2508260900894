import React, { useState } from "react";
import Header from "../../../../components/header/Header";
import { useGetProjectByIdQuery } from "../../api/projectApiSlice";
import { ProjectDetailsContainerProps } from "../../types/types";
import ProjectAddContainer from "../project.add.container/project.add.container";
import ProjectDetailsActionContainer from "../project.details.action.container/project.details.action.container";
import Loading from "../../../../components/Loading/Loading";
const ProjectDetailsContainer: React.FC<ProjectDetailsContainerProps> = ({
  projectId,
}) => {
  const {
    data: projectDetails,
    isLoading,
    isError,
    isSuccess,
  } = useGetProjectByIdQuery(projectId);

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const handleClose = () => {
    setOpenAddDialog(false);
  };

  const handleOpen = () => {
    setOpenAddDialog(true);
  };

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <>
        <ProjectDetailsActionContainer
          project={projectDetails?.entities[projectId]!}
          handleOpen={handleOpen}
        />
        <ProjectAddContainer
          openProjectDialog={openAddDialog}
          handleClose={handleClose}
          handleOpen={handleOpen}
          actionStatus="Edit"
          project={projectDetails?.entities[projectId]!}
        />
      </>
    );
  } else if (isError) {
    content = <p>something went wrong</p>;
  }
  return <>{content}</>;
};

export default ProjectDetailsContainer;
