import React from "react";
import {
  ExpereinceHeaderRow,
  ExperienceDetailsHeaderWrapper,
  ExperienceHeaderCol,
  ExperienceHeaderIconCol,
  ExperienceHeaderLabel,
  ExperienceHeaderMidCol,
  ExperienceHeaderSubtitle,
} from "./experience.details.styles";
import SelectExperienceTypeContainer from "../../../experiencetype/select.exp.type.container/SelectExperienceTypeContainer";
import { ExperienceType } from "../../../experiencetype/types/types";
import { FaCompass } from "react-icons/fa";
type ExperienceDetailsHeaderPresentationProps = {
  onSelectHandler: (exptype: Partial<ExperienceType> | null) => void;
  experienceType?: Partial<ExperienceType> | null;
};
const ExperienceDetailsHeaderPresentation: React.FC<
  ExperienceDetailsHeaderPresentationProps
> = ({ onSelectHandler, experienceType }) => {
  return (
    <ExperienceDetailsHeaderWrapper>
      <ExpereinceHeaderRow>
        <ExperienceHeaderCol>
          <SelectExperienceTypeContainer
            onSelectHanlder={onSelectHandler}
            experienceType={experienceType}
          />
        </ExperienceHeaderCol>
        <ExperienceHeaderMidCol>
          <ExperienceHeaderLabel>{experienceType?.title ? experienceType?.title :  "Gradegy" }</ExperienceHeaderLabel>
          <ExperienceHeaderSubtitle>Experience Card</ExperienceHeaderSubtitle>
        </ExperienceHeaderMidCol>
        <ExperienceHeaderIconCol>
          <FaCompass color="white" size={60} />
        </ExperienceHeaderIconCol>
      </ExpereinceHeaderRow>
    </ExperienceDetailsHeaderWrapper>
  );
};

export default ExperienceDetailsHeaderPresentation;
