import { useCallback, useState, useTransition } from "react";
import { useGetAllOrganizationQuery } from "../api/orgApiSlice";
import OrganizationActionsContainer from "./OrganizationActionsContainer";
import OrganizationAddContainer from "./OrganizationAddContainer";
import OrganizationListContainer from "./OrganizationListContainer/OrganizationListContainer";
import { useAppSelector } from "../../../app/hooks";
import { selectAdmin } from "../../auth/authSlice/authSlice";

const OrganizationMainContainer = () => {
  const admin = useAppSelector(selectAdmin);
  const [openStatusAddDisplay, setOpenStatusAddDisplay] =
    useState<boolean>(false);

  const { isLoading, isError, isSuccess } = useGetAllOrganizationQuery();

  const handleClose = useCallback(
    () => setOpenStatusAddDisplay(false),
    [openStatusAddDisplay]
  );

  const handleOpen = useCallback(
    () => setOpenStatusAddDisplay(true),
    [openStatusAddDisplay]
  );
  const [isPending, startTransition] = useTransition();
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [term, setTerm] = useState<string>("");
  const [sort, setSort] = useState<string>("-createdAt");

  const onTerm = (text: string) => {
    startTransition(() => setTerm(text));
  };

  const onPage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onLimit = (limit: number) => {
    setLimit(limit);
  };

  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };
  return (
    <>
      <OrganizationActionsContainer
        handleOpen={handleOpen}
        term={term}
        onTerm={onTerm}
      />
      {admin?.adminLevel === "ULTRA" && (
        <OrganizationAddContainer
          openStatusAddDisplay={openStatusAddDisplay}
          handleClose={handleClose}
          status="ADD"
        />
      )}

      <OrganizationListContainer
        page={page}
        limit={limit}
        onPage={onPage}
        onLimit={onLimit}
        term={term}
        onTerm={onTerm}
        sort={sort}
        onSort={onSort}
      />
    </>
  );
};

export default OrganizationMainContainer;
