import React from 'react';
import "./tab.css";

type SettingTabsProps = {
  condition: number|boolean;
  left:any;
  name:string
};

const TabName: React.FC<SettingTabsProps>  = ({condition , left , name})=> {

  return (
    <div className='tab' style={{    
      left:condition? 50: left,
      top:condition? -200:100,
      transform: "rotate(90deg)",
      position:"absolute",
      zIndex: 80,
      fontSize: 15,
      whiteSpace: "nowrap",
      display: condition? "none" : "block",
      fontWeight: "bold",
      transition: "none"}}>{name}</div>
  )
}

export default TabName