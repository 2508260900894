import styled from "@emotion/styled";
import { Paper } from "@material-ui/core";

export const EditButton = styled.button`
  display: flex;
  height: 36px;
  border-radius: 4px;
  width: 70px;
  background-color: #34c38f;
  transition-duration:300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  @media (max-width: 1300px) {
    width: fit-content !important;
    padding-left:7px;
    padding-right:7px;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
export const EditButtonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: #fff;
  margin: 0;
  padding-right: 10px;
  @media (max-width: 1300px) {
    display:none
  }
`;

export const EditButtonIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div<{width:string,minWidth:string,alignment:string,Direction:string}>`
  width: ${({ width }) => (width ? width : "100%")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  flex: 1;
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px 0 0;
  @media (max-width: 1500px) {
    flex-wrap: wrap;
  }
`;

export const InnerCol = styled.div<{align:string,alignment:string,Direction:string}>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 3px 10px 0 0;
  align-items: ${({ align }) => align};
`;

export const Row = styled.div<{Width:string,minWidth:string,alignment:string,Direction:string,tabletWidth:string}>`
  width: ${({ Width }) => (Width ? Width : "100%")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  display: flex;
  padding-top: 1px;
  justify-content: ${({ alignment }) => alignment};
  flex-direction: ${({ Direction }) => Direction};
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    width: ${({ tabletWidth }) => (tabletWidth ? tabletWidth : "")};
  }
`;

export const Box = styled(Paper)`
  padding: 25px 22px;
  position: relative;
  margin: 5px;
  display: flex;
  border-radius: 12px !important;
  justify-content: space-between;
  @media (max-width: 1600px) {
    height: auto;
  }
  @media (max-width: 1330px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1200px) {
    justify-content: flex-start;
  }  `;

export const Header = styled.h1`
  font-size: 44px;
  color: #495057;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

export const SubTitle = styled.h4`
  font-size: 18px;
  color: #003267;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

export const Title = styled.h3<{HideTablet:string}>`
  font-size: 32px;
  color: #495057;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  @media (max-width: 1200px) {
    display:${({ HideTablet }) => (HideTablet ? "none" : "")};
  }
`;

export const Button = styled.button<{}>`
  color: #fff;
  background-color: #0078FF;
  border: none;
  border-radius: 50px;
  padding: 10px 46px;
  font-family: "poppins";
  font-size: 17px;
  font-weight: 600;
`;

export const Label = styled.label`
  font-family:  "poppins";
  font-size: 15px;
  color: #707070;
`;

export const Input = styled.input<{
    Width:string, Height:string,FontFamily:string,
    Size:string,Weight:string,SpacingTB:string,
    SpacingLR:string,marginLR:string
}>`
  width: ${({ Width }) => (Width ? Width : "190px")};
  height: ${({ Height }) => (Height ? Height : "32px")};
  text-align: center;
  font-family: ${({ FontFamily }) => FontFamily};
  font-size: ${({ Size }) => Size};
  font-weight: ${({ Weight }) => Weight};
  padding: ${({ SpacingTB }) => SpacingTB} ${({ SpacingLR }) => SpacingLR};
  border-radius: 5px;
  border: 1px solid #CED4DA;
  color: "#070708";
  max-width: ${({ Width }) => (Width ? Width : "235px")};
  margin: 0 ${({ marginLR }) => marginLR};
`;

export const SwitchLabel = styled.label`
  font-family:'Poppins';
  font-weight: 400;
  font-size: 13px;
  color: #74788d;
  padding-right: 7px;
`;

export const ConstantLabel = styled.h3`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 15px;
  color: #495057;
  padding: 0 12px;
`;

export const ConstantValue = styled.p<{SpacingLR:string}>`
  font-family:'Poppins';
  font-weight: 300;
  font-size: 13px!important;
  max-width: 145px;
  color: #74788d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 ${({ SpacingLR }) => SpacingLR || "12px"};
`;
export const ConstantWrapper = styled.div`
  width: 15%;
  min-width: 136px;
  margin-bottom: 15px;
  border-right: 1px solid #707070;
`;
export const ConstantContent = styled.p`
  width: 15%;
  min-width: 136px;
  margin-bottom: 15px;
  border-right: 1px solid #707070;
`;

export const Square = styled.div<{borderColor:string,borderRadius:string,width:string,height:string}>`
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: space-between;
`;
export const InnerTag = styled.p<{width:string,height:string,margin:string,borderColor:string,borderRadius:string, textColor:string , fontSize:string}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${({ textColor }) => textColor};
  font-size: ${({ fontSize }) => fontSize};
  font-family: "Poppins";
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const ShadowBox = styled.div<{disable:string}>`
  flex-direction: column;
  width: calc(100% - 10px);
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  align-items: stretch;
  margin: 5px 0;
 
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
  > div {
    opacity: ${({ disable }) => (disable ? "0.3" : "1")};
  }
`;
