import React from "react";
import "./index.css";

const StudentDetailFilter: React.FC<{}> =  () => {

  return (
    <div className="student-filter-container">
      <div className="account " onClick={()=>{}}></div>
      <div className="all" onClick={()=>{}}></div>
      <div className="stuActive" ></div>
    </div>
  );
};

export default StudentDetailFilter;
