import styled from "@emotion/styled";
export const CreateShopFormCol = styled.div<{
  alignment?: string;
  Direction?: string;
}>`
  display: flex;
  justify-content: ${({ alignment }) => alignment};
  text-align: left;
  flex-direction: ${({ Direction }) => Direction};
  padding: 0 10px;
`;

export const CreateShopFormRow = styled.div`
  width: 95%;
  height: 40px;
  margin-top: -40px;
  margin-left: 25px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
`;
export const LabelShop = styled.label`
  font-size: 15px;
  color: #707070;
`;

export const SearchShopContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
`;

export const ShopContentContainer = styled.div`
  width: 95%;
  display: flex;
  height: 100px;
  margin-left: 50px;
  margin-bottom: -20px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1770px) {
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
`;

export const ShopButton = styled.button<{
  spaceLeft?: string;
}>`
  background: #34c38f;
  color: white;
  border: none;
  padding: 10px 17px 10px 14px;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};

  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 165px;
  font-weight: 400;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 13px;
    margin-left: 20px;
  }
  span {
    white-space: nowrap;
    width: 80%;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const SearchShop = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ced4da;
  padding-left: 35px;
  text-indent: 10px;

  font-size: 13px;

  &:focus {
    outline: none;
  }
`;

export const ShopHeaderControle = styled.div`
  width: 95%;
  display: flex;
  height: 100px;
  margin-left: 50px;
  margin-bottom: -20px;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1770px) {
  }
  @media (max-width: 1770px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1300px) {
    margin-left: 15px;
  }
`;

export const ShopWrapper = styled.div`
  width: 90%;
  background: white;
  min-height: calc(100vh - 238px);
  margin-bottom: 30px;
  padding: 10px 0;
  margin-left: 50px;
  box-shadow:0 6px 7px -2px rgba(0, 0, 0,0.18);
  margin-top:10px;
  border-radius:15px;
`;

export const PrizeShopButton = styled.button<{
  spaceLeft: string;
}>`
  background: #34c38f;
  color: white;
  border: none;
  transition-duration:300ms;
  margin-left: ${({ spaceLeft }) => spaceLeft};
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  border-radius: 30px;
  width: 210px;
  padding: 8px 7px 8px 21px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1770px) {
    font-size: 15px;
  }
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;
