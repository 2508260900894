import styled from "@emotion/styled";

export const ExperienceReviewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const ExpereinceReviewInnerWrapperStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const EditButtonContainer = styled.button`
  display: flex;
  height: 40px;
  border-radius: 4px;
  width: 80px;
  background-color: #34c38f;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  transition-duration:300ms;
  &:hover{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    transition-duration:300ms;
    background:#37d39a;
  }
`;

export const EditButtonIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
`;

export const EditButtonText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  margin: 0;
  padding-right: 5px;
`;

export const AnalysticsWrapper = styled.div`
  width: 70%;
  height: 100%;
  padding-left: 17px;
`;

export const AnalysticsExperienceWithoutImage = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 45px 0;
`;

export const AnalysticsExperienceWithoutImageDiv = styled.div`
  width: 48%;
  min-width: 470px;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ReviewContainerWrapper = styled.div`
  background: #1766c1;
  width: 100%;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
`;

export const ReviewTitle = styled.div`
  width: 62%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const ReviewTitleH1 = styled.h1`
  width: 100%;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  padding-top: 8px;
  font-size: 37px;
  line-height: 40px;
  @media screen and (max-width: 1300px) {
    .review_title h1 {
      font-size: 20px;
    }
  }
`;

export const ReviewTitleH3 = styled.h3`
  width: 100%;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 7px;
`;

export const SearchExperienceStudentContainer = styled.div`
  width: 34%;
  height: 36px;
  position: relative;
  margin-left: 10px;
`;
export const SearchExperienceStudent = styled.input`
  width: 90%;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #ced4da;
  padding-left: 35px;
  text-indent: 10px;

  font-size: 13px;

  &:focus {
    outline: none;
  }
`;
