import React, { useState } from "react";
import InventoryMiddelPresentation from "../presentation/InventoryMiddelPresentation";
import { EntityId } from "@reduxjs/toolkit";
import { useCreateMenualInventoryMutation } from "../api/inventoryApi";
type InventoryContainerProps = {
  shopId: EntityId;
};
const InventoryContainer: React.FC<InventoryContainerProps> = ({ shopId }) => {
  const [createMenualInventory, { isLoading, isError, isSuccess }] =
    useCreateMenualInventoryMutation();

  const [serialNumber, setSerialNumber] = useState<string | null>(null);
  const [digitalCode, setDigitalCode] = useState<string | null>(null);
  const [showDigitalCode, setShowDigitalCode] = useState<boolean>(false);

  const onSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(e.target.value);
  };
  const onDigitalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDigitalCode(e.target.value);
  };

  const onShowDigitalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDigitalCode(e.target.checked);
  };

  const onAddItemClick = async () => {
    try {
      await createMenualInventory({
        shop: shopId as any,
        serialNumber: serialNumber!,
        digitalCode: digitalCode!,
      });
    } catch (error) {}
  };
  return (
    <InventoryMiddelPresentation
      onAddItemClick={onAddItemClick}
      serialNumber={serialNumber}
      digitalCode={digitalCode}
      onSerialNumber={onSerialNumber}
      onDigitalCode={onDigitalCode}
      onShowDigitalCode={onShowDigitalCode}
      showDigitalCode={showDigitalCode}
    />
  );
};

export default InventoryContainer;
