import React, { useState } from "react";
import HomeOrganizationContainer from "../HomeOrganizatoniContainer/HomeOrganizationContainer";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { Organization } from "../../../organization/types/types";
import HomeProjectAllContainer from "../HomeProjectAllContainer/HomeProjectAllContainer";
import { useAppDispatch } from "../../../../app/hooks";
import { setOrganization } from "../../appSlice/appslice";
import HomeRecentContainer from "../HomeRecentProjectContainer/HomeRecentContainer";
import { useLazyGetAllProjectsQuery } from "../../../project/api/projectApiSlice";
import { Project } from "../../../project/types/types";
const HomeContainer = () => {
  const dispatch = useAppDispatch();
  const [getAllProject, { isLoading, isSuccess, isError }] =
    useLazyGetAllProjectsQuery();

  const [organizatoinID, setOrganizationID] = useState<EntityId | null>(null);
  const [term, setTerm] = useState<string>("");
  const [searchedProject, setSearchProjects] =
    useState<EntityState<Project> | null>(null);
  const onSelectOrganization = (organization: Partial<Organization | null>) => {
    setOrganizationID(organization?._id!);
    dispatch(setOrganization(organization));
  };

  const onTerm = async (text: string) => {
    try {
      setTerm(text);
      const projects = await getAllProject({
        term: text,
      }).unwrap();
      setSearchProjects(projects);
    } catch (error) {}
  };

  return (
    <>
      <HomeOrganizationContainer
        organizatoinID={organizatoinID}
        onSelectOrganization={onSelectOrganization}
        term={term}
        onTerm={onTerm}
      />
      <HomeRecentContainer onSelectOrganization={onSelectOrganization} />
      <HomeProjectAllContainer
        organizationID={organizatoinID}
        term={term}
        onTerm={onTerm}
        projects={searchedProject}
      />
    </>
  );
};

export default HomeContainer;
