import React, { useState, useEffect } from "react";
import RaffelTicketHeaderPresentation from "../presentation/RaffelTicketDetailsPresentation/RaffelTickeHeaderPresentation/RaffelTicketHeaderPresentation";
import { EntityId } from "@reduxjs/toolkit";
import {
  useGetARaffelTicketQuery,
  useRemoveRaffelMutation,
  useUpdateRaffelTicketMutation,
} from "../api/raffelTicketApiSlice";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import RaffelTicketCardItemPresentation from "../presentation/RaffelTicketCardItemPresentation/RaffelTicketCardItemPresentation";
import RaffelTicketMiddleColPresentation from "../presentation/RaffelTicketMiddelColPresentation/RaffelTicketMiddleColPresentation";
import {
  ButtonWrapper,
  ColumnWrapper,
  DeleteBtn,
  UpdateBtn,
} from "../../shop/presentation/ShopEditReviewPresentation/shop.edit.review.header.style";
import RaffelTicketStudentListPresentation from "../presentation/RaffelTicketStudentListPresentation/RaffelTicketStudentListPresentation";
import { useNavigate } from "react-router-dom";
import { images } from "../../../constants";
import { useGetAllRaffelBagStudentQuery } from "../../bag/api/bagApi";
import { Student } from "../../students/types/types";
type RaffelTicketDetalsContainerProps = {
  id: EntityId;
};
const RaffelTicketDetailsContainer: React.FC<
  RaffelTicketDetalsContainerProps
> = ({ id }) => {
  const navigation = useNavigate();
  const {
    data: raffelTicket,
    isLoading,
    isError,
    isSuccess,
  } = useGetARaffelTicketQuery(id!, {
    refetchOnMountOrArgChange: true,
  });
  console.log("raaaaaaaaaaaaaaaaafel", raffelTicket);

  const [
    updateRaffel,
    {
      isLoading: RaffelLoading,
      isError: RaffelError,
      isSuccess: RaffelSuccess,
    },
  ] = useUpdateRaffelTicketMutation();

  const [
    removeRaffle,
    {
      isLoading: loadingRaffel,
      isError: errorRaffel,
      isSuccess: successRaffle,
    },
  ] = useRemoveRaffelMutation();

  const {
    data: raffelBagList,
    isLoading: raffelbagLoading,
    isError: raffelbagError,
    isSuccess: raffelbagSuccess,
  } = useGetAllRaffelBagStudentQuery(id);

  console.log("this is test", raffelBagList);

  const project = useAppSelector(selectedProject);

  const [defaultImage, setDefaultImage] = useState<string>(
    "https://api.gradify.app/static/download.ff.png"
  );
  const [store, setStore] = useState<boolean>(false);
  const [prizeWheel, setPrizeWheel] = useState<boolean>(false);
  const [title, setTitle] = useState<string | null>(null);
  const [gem, setGem] = useState<number | null>(null);
  const [numberOfWinner, setNumberofWinner] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [drawDate, setDrawDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [drawTime, setDrawTime] = useState<string | null>(null);
  const [category, setCategory] = useState<Partial<Category> | null>(
    // raffelTicket?.entities[id]! ? raffelTicket?.entities[id]!.category : null
    null
  );
  const [subCategory, setSubCategory] =
    useState<Partial<SubCategory | null>>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [purchaseLimitState, setPurchaseLimitState] = useState<boolean>(false);
  const [purchaseLimit, setPurchaseLimit] = useState<number | null>(null);
  const [winners, setWinners] = useState<Student[]>([]);

  useEffect(() => {
    if (raffelTicket) {
      console.log("raffelTicket", raffelTicket);
      setDefaultImage(raffelTicket.entities[id]!.image);
      setTitle(raffelTicket.entities[id]!.title);
      setDescription(raffelTicket.entities[id]!.description);
      setGem(raffelTicket.entities[id]!.gem);
      setCategory(raffelTicket.entities[id]!.category);
      console.log(
        "RAFFLE TICKET CATEGORY",
        raffelTicket.entities[id]!.category
      );
      setSubCategory(raffelTicket.entities[id]!.subCategory);
      console.log(
        "RAFFLE TICKET SUBCATEGORY",
        raffelTicket.entities[id]!.subCategory
      );
      setStartDate(new Date(raffelTicket.entities[id]!.startDate));

      const tz = new Date().getTimezoneOffset();
      const time = tz * 60 * 1000;
      const starTimeCovertTime = new Date(
        new Date(raffelTicket.entities[id]!.startDate).getTime() - time
      );
      setStartTime(
        new Date(starTimeCovertTime).toISOString().split("T")[1].substring(0, 5)
      );
      const endDateTimeCovertTime = new Date(
        new Date(raffelTicket.entities[id]!.endDate).getTime() - time
      );
      setEndDate(new Date(raffelTicket.entities[id]!.endDate));
      setEndTime(
        new Date(endDateTimeCovertTime)
          .toISOString()
          .split("T")[1]
          .substring(0, 5)
      );
      const drawDateTimeCovertTime = new Date(
        new Date(raffelTicket.entities[id]!.drawDate).getTime() - time
      );
      setDrawDate(new Date(raffelTicket.entities[id]!.drawDate));
      setDrawTime(
        new Date(drawDateTimeCovertTime)
          .toISOString()
          .split("T")[1]
          .substring(0, 5)
      );
      setPurchaseLimitState(raffelTicket.entities[id]!.purchaseLimitState);
      setPurchaseLimit(Number(raffelTicket.entities[id]?.purchaseLimit));
      setNumberofWinner(Number(raffelTicket.entities[id]!.numberWinner));
      setStore(raffelTicket.entities[id]!.store);
      setPrizeWheel(raffelTicket.entities[id]!.prizeWheel);
      setWinners(raffelTicket.entities[id]!.winners);
    }
  }, [raffelTicket]);

  const onPurchaseLimitState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseLimitState(e.target.checked);
  };

  const onPurchaseLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPurchaseLimit(Number(e.target.value));
  };

  const onDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onCategory = (categorySelected: Partial<Category> | null) => {
    setCategory(categorySelected);
  };

  const onSubCategory = (subCategorySelected: Partial<SubCategory> | null) => {
    setSubCategory(subCategorySelected);
  };

  const onStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("sdflsdjflkds");
    setStartTime(e.target.value);
    const nedate = startDate;

    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);
      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);

      setStartDate(localDate);
    }
  };
  const onEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);

    const nedate = endDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);
      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);
      console.log("localDate", localDate);

      setEndDate(localDate);
    }
  };

  const onDrawTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrawTime(e.target.value);

    const nedate = drawDate;
    if (nedate) {
      const hours = Number(e.target.value.split(":")[0]);
      const min = Number(e.target.value.split(":")[1]);
      nedate.setUTCHours(hours, min);
      const tz = nedate.getTimezoneOffset();
      const time = tz * 60 * 1000;
      const localDate = new Date(nedate.getTime() + time);
      console.log("drawDate", localDate);
      setDrawDate(localDate);
    }
  };

  const onStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("on Start Date");
    const nedate = new Date(e.target.value);

    // console.log("start Time", startTime);

    const hours = Number(startTime?.split(":")[0]);
    const min = Number(startTime?.split(":")[1]);
    nedate.setUTCHours(hours, min);
    const tz = nedate.getTimezoneOffset();
    const time = tz * 60 * 1000;
    const localDate = new Date(nedate.getTime() + time);

    console.log("localDate", localDate);
    setStartDate(localDate);
  };

  const onEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setEndDate(new Date(e.target.value));
    const nedate = new Date(e.target.value);
    const hours = Number(endTime?.split(":")[0]);
    const min = Number(endTime?.split(":")[1]);
    nedate.setUTCHours(hours, min);
    const tz = nedate.getTimezoneOffset();
    const time = tz * 60 * 1000;
    const localDate = new Date(nedate.getTime() + time);

    console.log("localDate", localDate);
    setEndDate(localDate);
  };

  const onDrawDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nedate = new Date(e.target.value);
    const hours = Number(drawTime?.split(":")[0]);
    const min = Number(drawTime?.split(":")[1]);
    nedate.setUTCHours(hours, min);
    const tz = nedate.getTimezoneOffset();
    const time = tz * 60 * 1000;
    const localDate = new Date(nedate.getTime() + time);

    console.log("localDate", localDate);
    setDrawDate(localDate);
    console.log(new Date(e.target.value));
  };

  const onNumberOfWinner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberofWinner(Number(e.target.value));
  };

  const onGem = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGem(Number(e.target.value));
  };

  const onTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStore(e.target.checked);
  };

  const onPrizeWheel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrizeWheel(e.target.checked);
  };

  const onImage = (url: string) => {
    setDefaultImage(url);
  };

  const onSave = async () => {
    try {
      await updateRaffel({
        _id: id!,
        category: category!,
        description: description!,
        drawDate: drawDate!,
        endDate: endDate!,
        gem: gem!,
        image: defaultImage!,
        numberWinner: numberOfWinner!,
        prizeWheel: prizeWheel!,
        project: project!,
        purchaseLimit: purchaseLimit!,
        purchaseLimitState: purchaseLimitState!,
        startDate: startDate!,
        store: store!,
        subCategory: subCategory!,
        title: title!,
      }).unwrap();
      navigation("/raffelticket");
    } catch (error) {}
  };

  const onRemoveRaffle = async (id: EntityId) => {
    try {
      await removeRaffle(id).unwrap();
      navigation("/raffelticket");
    } catch (error) {}
  };

  return (
    <>
      {isSuccess && raffelTicket && (
        <>
          <RaffelTicketHeaderPresentation
            category={raffelTicket.entities[id]!.category}
            onCategory={onCategory}
            subCategory={raffelTicket.entities[id]!.subCategory}
            onSubCategory={onSubCategory}
            project={project!}
            startDate={startDate!}
            onStartDate={onStartDate}
            startTime={startTime!}
            onStartTime={onStartTime}
            endDate={endDate!}
            onEndDate={onEndDate!}
            endTime={endTime!}
            onEndTime={onEndTime}
            drawDate={drawDate}
            onDrawDate={onDrawDate}
            drawTime={drawTime}
            onDrawTime={onDrawTime}
            winners={winners}
          />
          <ColumnWrapper>
            {drawDate && (
              <RaffelTicketCardItemPresentation
                description={description}
                onDescription={onDescription}
                drawDate={drawDate!}
                gem={gem}
                image={defaultImage}
                onGem={onGem}
                onImage={onImage}
                onTitle={onTitle}
                title={title}
              />
            )}
            <RaffelTicketMiddleColPresentation
              gem={gem!}
              onGem={onGem}
              numberOfWinner={numberOfWinner!}
              onNumberOfWinner={onNumberOfWinner}
              onPrizeWheel={onPrizeWheel}
              onPurchaseLimit={onPurchaseLimit}
              onPurchaseLimitState={onPurchaseLimitState}
              onStore={onStore}
              prizeWheel={prizeWheel}
              purchaseLimit={purchaseLimit}
              purchaseLimitState={purchaseLimitState}
              store={store}
            />
            {raffelbagSuccess && (
              <RaffelTicketStudentListPresentation
                raffelbagList={raffelBagList!}
              />
            )}
          </ColumnWrapper>
          <ButtonWrapper>
            <DeleteBtn
              onClick={() => {
                onRemoveRaffle(id);
              }}
            >
              <img src={images.TarshIcon} alt="trash"></img>
            </DeleteBtn>
            <UpdateBtn onClick={onSave} variant="contained">
              UPDATE
              {/* {pageStatus === "UPDATE_SAVING" ? "Updating" : "Update"} */}
            </UpdateBtn>
          </ButtonWrapper>
        </>
      )}
    </>
  );
};

export default RaffelTicketDetailsContainer;
