import { EntityState } from "@reduxjs/toolkit";
import React from "react";
import { Student } from "../../../students/types/types";
import "./experienceReview.css";
import {
  SearchExperienceStudent,
  SearchExperienceStudentContainer,
} from "./experience.review.styles";
import { BiSearchAlt } from "react-icons/bi";
import { ExperienceCard } from "../../types/types";
import DownloadButton from "../../../../components/downloadButton/DownloadButton";
import CheckBoxInput from "../../../../components/checkboxInput/CheckBoxInput";
import Notify from "../../../../components/notify/Notify";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Switch,
  TablePagination,
} from "@mui/material";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import SelectField from "../../../../components/design/selectFiled/selectField";
import ExperienceStudentItem from "./ExperienceStudentItem";
import { useAppSelector } from "../../../../app/hooks";
import { setMetaData } from "../../../admin/adminSlice/adminSlice";
type ExperienceStudentListPresentationProps = {
  studentList: EntityState<Student>;
  experience: ExperienceCard;
  onCompleteChange: (student: Student, completion: boolean) => void;
  switchLoading: boolean;
  term?: string;
  onTerm?: (t: string) => void;
  sort?: string;
  onSort?: (fieldName: string) => void;
  limit?: number;
  onLimit?: (l: number) => void;
  page?: number;
  onpage?: (p: number) => void;
};
const label = { inputProps: { "aria-label": "Switch demo" } };
const ExperienceStudentListPresentaion: React.FC<
  ExperienceStudentListPresentationProps
> = ({
  studentList,
  experience,
  onCompleteChange,
  switchLoading,
  term,
  onTerm,
  sort,
  onSort,
  limit,
  page,
  onpage,
  onLimit,
}) => {
  const metaData = useAppSelector(setMetaData);

  return (
    <div className="ex-student-list-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <SearchExperienceStudentContainer>
          <BiSearchAlt
            style={{ position: "absolute", top: 11, left: 11 }}
            color={"#707070"}
          />
          <SearchExperienceStudent
            type="text"
            style={{ paddingLeft: 20 }}
            placeholder="Search ..."
            onChange={(e) => {
              onTerm!(e.target.value);
            }}
            value={term}
            autoFocus={true}
          />
        </SearchExperienceStudentContainer>
        <h1 className="ex-student-list-title">Students</h1>
        {experience.completionType.title === "Photo" && (
          <>
            {/* <DownloadButton onClick={() => {}} />
            <CheckBoxInput /> */}
          </>
        )}
        {/* <Notify onClick={() => {}} /> */}
        <div className="ex-student-list-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox onChange={() => {}} />
                </TableCell>
                <TableCell
                  onClick={() => {
                    onSort!("fullName");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <span>Students</span>
                  {/* {!assendingSort ? (
                    <FaAngleDown
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"firstName","order":"desc"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("firstName");
                        setPageCount(1);
                      }}
                    />
                  ) : (
                    <FaAngleUp
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"firstName","order":"asce"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("firstName");
                        setPageCount(1);
                      }}
                    />
                  )} */}
                </TableCell>
                <TableCell>
                  <SelectField
                    onChange={(e) => {
                      if (e.target.value === "Completed") {
                        onSort!("completion.complete");
                      }
                    }}
                    style={{
                      width: 120,
                      padding: "0 12px",
                    }}
                  >
                    <option value="All">All</option>
                    <option value="Completed">Completed</option>
                  </SelectField>
                  {/* <SelectComponent
                    options={options}
                    onChange={changeCompletionList}
                    value={selectFilter}
                    style={{
                      padding: "2px 8px",
                      fontSize: 11,
                      fontWeight: 700,
                      width: 118,
                      backgroundPosition: "bottom 11px right 7px",
                    }}
                  /> */}
                </TableCell>
                <TableCell>
                  <span>Date</span>
                  {/* {!assendingSort ? (
                    <FaAngleDown
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"complete.updatedAt","order":"desc"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("complete.updatedAt");
                        setPageCount(1);
                      }}
                    />
                  ) : (
                    <FaAngleUp
                      color="#000"
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(
                          newExperienceReivewAction(
                            experience?.project,
                            experience?.category,
                            experience?._id,
                            1,
                            `{"field":"complete.updatedAt","order":"asce"}`
                          )
                        );

                        setassendingSort(!assendingSort);
                        setSortState("complete.updatedAt");
                        setPageCount(1);
                      }}
                    />
                  )} */}
                </TableCell>
                <TableCell>
                  <Switch {...label} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList!.ids.map((id) => (
                <ExperienceStudentItem
                  key={id}
                  student={studentList.entities[id]!}
                  onCompleteChange={onCompleteChange}
                  switchLoading={switchLoading}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={metaData?.payload?.admin?.metadata?.toatlCount}
            rowsPerPage={limit!}
            page={page! - 1}
            onPageChange={(event: unknown, newPage: number) => {
              onpage!(newPage + 1);
            }}
            onRowsPerPageChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              onLimit!(parseInt(event.target.value, 10));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExperienceStudentListPresentaion;
