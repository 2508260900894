import React from "react";
import { CategoryChangeLabelPresentationProps } from "../../types/types";
import { ProjectSection, ProjectLabel, ProjectInput } from "./category.changelabel.presentation.style";
const CategoryChangeLabelPresentation: React.FC<
  CategoryChangeLabelPresentationProps
> = ({
  categoryLabel,
  onChangeCategoryLabel,
  onEditChangeCategoryLabelHandler,
}) => {
    return (

      <ProjectSection
        style={{ display: "block", margin: "0 auto" }} alignment={""} Direction={""}      >
        <h3 className="st-info-title text-center">Category</h3>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <ProjectLabel htmlFor="survey" style={{ marginTop: 5 }}>
            Change Label
          </ProjectLabel>
          <ProjectInput
            type="text"
            id="changeLabel"
            placeholder="Change CategoryLabel"
            value={categoryLabel}
            onChange={onChangeCategoryLabel}
            style={{
              marginLeft: 5,
            }}
          />
        </div>
      </ProjectSection>

    );
  };

export default CategoryChangeLabelPresentation;
