import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExperienceReviewContainer from "../container/experience.review.container/ExperienceReviewContainer";
import { useGetAExperienceByIdQuery } from "../api/experienceCardApiSlice";
import Header from "../../../components/header/Header";
import { useAppSelector } from "../../../app/hooks";
import { selectedProject } from "../../home/appSlice/appslice";
import Loading from "../../../components/Loading/Loading";

const ExperienceReviewPage = () => {
  const { id } = useParams();
  const project = useAppSelector(selectedProject);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project) {
      navigate("/");
    }
  }, [project]);
  const {
    data: experienceItem,
    isLoading,
    isError,
    isSuccess,
  } = useGetAExperienceByIdQuery(id!);
  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isSuccess) {
    content = (
      <>
        <Header title="Experience Review" />
        {isSuccess && (
          <ExperienceReviewContainer
            experience={experienceItem.entities[id!]!}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ExperienceReviewPage;
