import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { CourseEnrollment } from "../types";

const courseEnrollmentAdaptor = createEntityAdapter<CourseEnrollment>({
  selectId: (data) => data._id,
});

interface ICourseEnrollmentinitialState extends EntityState<CourseEnrollment> {}

const initialState: ICourseEnrollmentinitialState =
  courseEnrollmentAdaptor.getInitialState();

export const courseEnrollmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourseEnrollmentByProject: builder.query<
      EntityState<CourseEnrollment>,
      {
        project: EntityId;
        startDate?:string;
        endDate?:string;
        term?:string
      }
    >({
      query: (data) =>
        `/course-enrollment-grade/project?project=${data.project}&startDate=${data.startDate}&endDate=${data.endDate}&term=${data.term}`,
      transformResponse: (response: CourseEnrollment[]) => {
        return courseEnrollmentAdaptor.setAll(initialState, response);
      },
      providesTags: ["CourseEnrollment"],
    }),
    updateCourseEnrollmentProject: builder.mutation<
      EntityState<CourseEnrollment>,
      {
        project: string;
        where_you_should_be: number;
      }
    >({
      query: (data) => ({
        url: `/course-enrollment-grade/project?project=${data.project}&whereyoushouldbe=${data.where_you_should_be}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["CourseEnrollment"],
    }),
    updateCourseEnrollmentByNmae: builder.mutation<
      EntityState<CourseEnrollment>,
      {
        project: string;
        where_you_should_be: number;
        courseName: string;
      }
    >({
      query: (data) => ({
        url: `/course-enrollment-grade/course?project=${data.project}&whereyoushouldbe=${data.where_you_should_be}&courseName=${data.courseName}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["CourseEnrollment"],
    }),
  }),
});

export const {
  useGetCourseEnrollmentByProjectQuery,
  useUpdateCourseEnrollmentProjectMutation,
  useUpdateCourseEnrollmentByNmaeMutation,
} = courseEnrollmentApi;
