import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const GradegyTextField = styled(TextField)`
  z-index: 500;
  & > div {
    z-index: 10000;
    border: none;
    padding: 4px 15px 5px 1px !important;
    border: 1px solid #ced4da;
    height: 33px;
    font-size: 14px;
    font-family: poppins;
    border-radius: 5px;
    color: #495057;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin-right: 10px;
    background-position: bottom 13px right 6px;
    font-weight: 300;
    width: 173px;
    text-overflow: ellipsis;
  }
  &:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }

`;
