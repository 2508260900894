import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import ExperienceFilterContainer from "../container/experience.filter.container/ExperienceFilterContainer";
import ExperienceActionContainer from "../container/experience.action.container/ExperienceActionContainer";
import { useAppSelector } from "../../../app/hooks";
import { Category } from "../../category/types/types";
import { selectedProject } from "../../home/appSlice/appslice";
import { SubCategory } from "../../subcategory/types/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { ExperienceCard } from "../types/types";
import { useLazyGetAllExperiencesQuery } from "../api/experienceCardApiSlice";
import ExperiecneListContainer from "../container/expereinceListContainer/ExperiecneListContainer";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { selectAdmin } from "../../auth/authSlice/authSlice";

const ExperienceMainPage = () => {
  const project = useAppSelector(selectedProject);
  const projectApp = useAppSelector(selectedProject);
  const admin = useAppSelector(selectAdmin);
  const navigate = useNavigate();

  const [category, setCategory] = useState<Partial<Category> | null>(null);
  const [subCategory, setSubCategory] = useState<Partial<SubCategory> | null>(
    null
  );
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(25);
  const [sort, setSort] = useState<string>("-createdAt");
  const [term, setTerm] = useState<string>("null");
  const [listStatus, setListStatus] = useState<"publish" | "save">("publish");
  const [loading, setLoading] = useState<boolean>(false);
  const [experienceCards, setExperienceCards] =
    useState<EntityState<ExperienceCard> | null>(null);

  const [getAllExperiences, { isLoading, isError, isSuccess }] =
    useLazyGetAllExperiencesQuery();
  useEffect(() => {
    console.log(projectApp);
    if (!projectApp) {
      navigate("/");
    }
  }, [projectApp]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const expList = await getAllExperiences({
          project: project!._id!,
          category: category?._id,
          subCategory: subCategory?._id,
          expPublish: listStatus === "publish",
          expSave: listStatus === "save",
          page,
          limit,
          sort,
          term,
        }).unwrap();
        setExperienceCards(expList);
        setLoading(false);
      } catch (error) {}
    })();
  }, [category, subCategory, page, limit, sort, term, listStatus]);

  useEffect(() => {
    if (admin) {
      const projectPermession = admin.projectAccess?.find(
        (item) => String(item.project) === String(projectApp?._id)
      );

      if (projectPermession) {
        if (projectPermession?.accessType === "Full") {
          //setShowAddStudentButton(projectPermession.users!);
        }
      }

      if (admin.categoryAccess?.length === 1) {
        if (admin.categoryAccess[0].accessType === "Full") {
          setCategory({ _id: admin.categoryAccess[0].category as EntityId });
        } else {
          setCategory({ _id: admin.categoryAccess[0].category as EntityId });
          setSubCategory({
            _id: admin.subCategoryAccess![0].subcategory! as EntityId,
          });
        }
      }
    }
  }, [admin]);

  useEffect(() => {
    // se(studentList!);
    if (term !== "null" && page > 1) {
      console.log("term", term);
      console.log("page run this");
      setPage(1);
    } else {
      console.log("page run that");
      setPage(page);
    }
  }, [term]);

  const onCatgoryHandler = (category: Partial<Category> | null) => {
    setCategory(category);
  };

  const onSubCategoryHandler = (subCategory: Partial<SubCategory> | null) => {
    setSubCategory(subCategory);
  };
  const onTrem = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setTerm(e.target.value);
    } else {
      setTerm("null");
    }
  };

  const onSort = (fieldName: string) => {
    console.log(fieldName);
    if (sort === "null") {
      setSort(`-${fieldName}`);
    }
    if (sort.startsWith("-")) {
      console.log("desc");
      setSort(fieldName);
    }
    if (!sort.startsWith("-")) {
      console.log("asce");
      setSort(`-${fieldName}`);
    }
  };

  const onPublish = () => {
    setListStatus("publish");
  };
  const onSave = () => {
    setListStatus("save");
  };

  const onPage = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const onLimit = (limitNumber: number) => {
    setLimit(limitNumber);
  };

  let content;
  if (loading) {
    content = (
      <>
        <Header title="Experience" />
        <ExperienceFilterContainer
          project={project!}
          category={category}
          subCategory={subCategory}
          onCategoryHandler={onCatgoryHandler}
          onSubCategoryHandler={onSubCategoryHandler}
        />
        <ExperienceActionContainer
          onTerm={onTrem}
          listStatus={listStatus}
          onPublish={onPublish}
          onSave={onSave}
          term={term!}
        />
        <Loading />;
      </>
    );
  } else if (isSuccess) {
    content = (
      <>
        {projectApp && (
          <>
            <Header title="Experience" />
            <ExperienceFilterContainer
              project={project!}
              category={category}
              subCategory={subCategory}
              onCategoryHandler={onCatgoryHandler}
              onSubCategoryHandler={onSubCategoryHandler}
            />
            <ExperienceActionContainer
              onTerm={onTrem}
              listStatus={listStatus}
              onPublish={onPublish}
              onSave={onSave}
              term={term!}
            />
            {isSuccess && experienceCards && (
              <ExperiecneListContainer
                list={experienceCards!}
                onSort={onSort}
                sort={sort}
                page={page}
                limit={limit}
                onPage={onPage}
                onLimit={onLimit}
              />
            )}{" "}
          </>
        )}
      </>
    );
  }

  return <>{content}</>;
};

export default ExperienceMainPage;
