import styled from "@emotion/styled";


export const HomeOrganizationPresentationContainer = styled.div`
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 0px 10px;
    margin-top:20px;
`;

export const HomeOrganizationPresentationContent = styled.div`
    display: flex;
    text-align: left;
    padding: 0px 10px;
`;


export const HomeOrganizationPresentationTitle = styled.label`
    font-family: Poppins;
    font-size: 15px;
    color: rgb(112, 112, 112);
`;
export const HomeSearchStudentContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10;
    width: 65%;
    margin-top: 30px;
    margin-left: 7%;
`;
export const SearchStudentContainer = styled.div`
  width: 227px;
  height: 36px;
  position: relative;
  margin-left: 0px;
`;

export const SearchStudent = styled.input`
  width: 227px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid rgb(206, 212, 218);
  padding-left: 35px;
  text-indent: 10px;
  font-family: Poppins;
  font-size: 13px;

  &:focus {
    outline: none;
  }
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
`;

