import React from "react";
import FilterByProjectContainer from "../../../project/container/filter.by.project.container/FilterByProjectContainer";
import {
  AdminAccessSpecialContainerProps,
  AdminAccessSpecialPresentationProps,
  CategoryPermissionType,
  ProjectPermissionType,
  SubCategoryPermissionType,
} from "../../types/types";
import { EntityId } from "@reduxjs/toolkit";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import FilterSubCategoryContainer from "../../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import ShowCanView from "../../../../components/showCanView/ShowCanView";
import { Button, IconButton, Paper, Switch } from "@mui/material";
import { CircleIcon } from "./admin.access.special.presentation.styles";

import {
  ShowCanEditWrapper,
  ShowCanEditTitle,
  ShowCanEditItems,
  ShowCanEditItemsRow,
  ShowCanEditItemCol,
} from "../admin.access.schoolDisrict.presentation/admin.access.schooldistrict.styles";
import { IoMdAdd } from "react-icons/io";
import {
  Box,
  Row,
  Column,
  SubTitle,
  InnerCol,
  ConstantValue,
  SwitchLabel,
  Label,
} from "../../../../assets/style/General.style";
import { FaLock } from "react-icons/fa";
const AdminAccessSpecialPresentation: React.FC<
  AdminAccessSpecialPresentationProps
> = ({
  admin,
  onProjectHanlder,
  projectID,
  onCategoryHandler,
  categoryID,
  onSubCategoryHandler,
  subCategory,
  projectCanView,
  categoryCanview,
  subCategoryCanView,
  onAddAccessPermission,
  onProjectRemove,
  onCategoryRemove,
  onSubCategoryRemove,
  onClickProject,
  onClickCategory,
  onClickSubCategory,
  showCanEdit,
  onExpereinceCheck,
  onStoreCheck,
  onSurveyCheck,
  onUserCheck,
  onSaveHandler,
}) => {
  return (
    <Box elevation={3} className="st-info" style={{ flexDirection: "row" }}>
      <Row
        Direction="column"
        Width="40%"
        tabletWidth={"100%"}
        minWidth={""}
        alignment={""}
      >
        <Column
          style={{ marginBottom: -10, maxHeight: 60 }}
          width={""}
          minWidth={""}
          alignment={""}
          Direction={""}
        >
          <SubTitle>Special Projecs</SubTitle>
        </Column>
        <Column
          style={{ alignItems: "flex-start" }}
          width={""}
          minWidth={""}
          alignment={""}
          Direction={""}
        >
          <InnerCol Direction="column" align={""} alignment={""}>
            <Label>Organization</Label>
            <ConstantValue SpacingLR={"0"} title={admin?.organization?.title}>
              {admin?.organization?.title}
            </ConstantValue>
          </InnerCol>
          <InnerCol Direction="column" align={""} alignment={""}>
            <Label>Project</Label>
            <FilterByProjectContainer
              organizationID={admin?.organization?._id as EntityId}
              onSelectProject={onProjectHanlder!}
              filterdBy="Special Project"
            />
          </InnerCol>

          <InnerCol Direction="column" align={""} alignment={""}>
            <Label>Category</Label>
            <FilterCategoryContainer
              projectID={projectID!}
              onSelectCategory={onCategoryHandler}
            />
            <Label>Sub Category</Label>
            <FilterSubCategoryContainer
              categoryID={categoryID}
              onSelectSubCategory={onSubCategoryHandler}
            />
          </InnerCol>
          <InnerCol
            style={{ padding: "0px 10px 5px 0" }}
            align="center"
            alignment={""}
            Direction={""}
          >
            <CircleIcon onClick={onAddAccessPermission}>
              {false ? (
                <FaLock color="#fff" size={14} />
              ) : (
                <IoMdAdd color="#fff" size={14} />
              )}
            </CircleIcon>
          </InnerCol>
        </Column>
      </Row>

      <Row
        Width="30%"
        tabletWidth={"50%"}
        minWidth={""}
        alignment={""}
        Direction={""}
      >
        <Column width={""} minWidth={""} alignment={""} Direction={""}>
          <ShowCanView
            projectAccess={projectCanView}
            categoryAccess={categoryCanview}
            subCategoryAccess={subCategoryCanView}
            onCategoryRemove={onCategoryRemove}
            onProjectRemove={onProjectRemove}
            onSubCategoryRemove={onSubCategoryRemove}
            onClickProject={onClickProject}
            onClickCategory={onClickCategory}
            onClickSubCategory={onClickSubCategory}
          />
        </Column>
      </Row>

      <Row
        Width="20%"
        tabletWidth={"40%"}
        minWidth={"240px"}
        alignment={""}
        Direction={""}
      >
        {showCanEdit && (
          <>
            <Column
              width="fit-content"
              style={{ justifyContent: "end" }}
              minWidth={""}
              alignment={""}
              Direction={""}
            >
              <ShowCanEditTitle>
                {(showCanEdit as ProjectPermissionType).project
                  ? (showCanEdit as ProjectPermissionType).project.title
                  : (showCanEdit as CategoryPermissionType).category
                  ? (showCanEdit as CategoryPermissionType).category.title
                  : (showCanEdit as SubCategoryPermissionType).subcategory
                  ? (showCanEdit as SubCategoryPermissionType).subcategory.title
                  : null}
              </ShowCanEditTitle>
            </Column>
            <Column
              Direction="column"
              style={{ minWidth: "210px" }}
              width={""}
              minWidth={""}
              alignment={""}
            >
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>Users</SwitchLabel>
                <Switch checked={showCanEdit.users} onChange={onUserCheck} />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>Experience cards</SwitchLabel>
                <Switch
                  checked={showCanEdit.experience}
                  onChange={onExpereinceCheck}
                />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>surveys</SwitchLabel>
                <Switch checked={showCanEdit.survey} onChange={onSurveyCheck} />
              </InnerCol>
              <InnerCol alignment="space-between" align={""} Direction={""}>
                <SwitchLabel>stores</SwitchLabel>
                <Switch checked={showCanEdit.store} onChange={onStoreCheck} />
              </InnerCol>

              <InnerCol align={""} alignment={""} Direction={""}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#34c38f" }}
                  onClick={onSaveHandler}
                >
                  Save
                </Button>
              </InnerCol>
            </Column>
          </>
        )}
      </Row>
    </Box>
  );
};

export default AdminAccessSpecialPresentation;
