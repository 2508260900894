import { DialogContent, DialogActions, Button } from "@mui/material";
import React from "react";
import { DialogStyle } from "../../organization/presantation/organization.add.presentation/organization.add.styles";
import { Organization } from "../../organization/types/types";
import { Project } from "../../project/types/types";
import FilterCategoryContainer from "../../category/container/filter.category.container/FilterCategoryContainer";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import FilterSubCategoryContainer from "../../subcategory/container/FilterSubcategoryContainer/FilterSubCategoryContainer";
import { Student, StudentSystemType } from "../types/types";
import StudentSystemComponents from "./StudentSystemComponents";
import { FaPlus } from "react-icons/fa";
import {
  AddProjectColumn,
  AddProjectTitle,
  LabelStudent,
  AddProjectAddButton,
} from "../presantation/student.details.info.presentation/student.details.info.style";
import { SaveButton } from "../presantation/studentAddPresentation/student.add.presentation";
import FilterByProjectContainer from "../../project/container/filter.by.project.container/FilterByProjectContainer";
type StudentSystemAddProps = {
  open: boolean;
  onClose: () => void;
  selectedOrganization: Partial<Organization>;
  selectedProject: Partial<Project>;
  onSelectProject: (project: Partial<Project> | null) => void;
  onSelectedCategory: (category: Partial<Category> | null) => void;
  onSelectedSubCategory: (subCategory: Partial<SubCategory> | null) => void;
  onAddSystem: () => void;
  selectedCategory: Partial<Category> | null;
  system: StudentSystemType[];
  onRemove: (item: StudentSystemType) => void;
  student: Student;
};
const StudentSystemAdd: React.FC<StudentSystemAddProps> = ({
  open,
  onClose,
  selectedOrganization,
  selectedProject,
  onAddSystem,
  onSelectedCategory,
  onSelectedSubCategory,
  selectedCategory,
  system,
  onRemove,
  student,
  onSelectProject,
}) => {
  console.log(selectedOrganization);
  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ backdropFilter: "blur(15px)" }}
    >
      <DialogContent
        sx={{ width: "999px", backgroundColor: "rgba(255, 255, 255,0)" }}
      >
        <AddProjectColumn alignment={"center"}>
          <AddProjectTitle style={{ marginTop: 8, marginBottom: 20 }}>
            Projects
          </AddProjectTitle>
        </AddProjectColumn>
        <AddProjectColumn style={{ marginLeft: "5%" }} Direction="row">
          <LabelStudent htmlFor="project">Organization: </LabelStudent>
          <LabelStudent htmlFor="project">
            {selectedOrganization.title}
          </LabelStudent>
        </AddProjectColumn>
        <AddProjectColumn
          style={{ marginLeft: "5%", paddingTop: 18, width: 180 }}
        >
          <AddProjectColumn
            style={{ paddingLeft: 0 }}
            Direction="column"
            alignment={"center"}
          >
            <LabelStudent htmlFor="project">Project:</LabelStudent>
            <LabelStudent htmlFor="project">
              {/* {selectedProject.title} */}
              <FilterByProjectContainer
                organizationID={selectedOrganization._id!}
                onSelectProject={onSelectProject}
              />
            </LabelStudent>
          </AddProjectColumn>

          <AddProjectColumn Direction="column" alignment={"center"}>
            <FilterCategoryContainer
              onSelectCategory={onSelectedCategory}
              projectID={selectedProject?._id!}
            />
          </AddProjectColumn>
          <AddProjectColumn Direction="column" alignment={"center"}>
            <FilterSubCategoryContainer
              onSelectSubCategory={onSelectedSubCategory}
              categoryID={selectedCategory?._id!}
            />
          </AddProjectColumn>
          <AddProjectColumn>
            <AddProjectAddButton
              style={{ borderRadius: 50, marginLeft: 10 }}
              onClick={onAddSystem}
            >
              <FaPlus size={10} />
            </AddProjectAddButton>
          </AddProjectColumn>
        </AddProjectColumn>
        <AddProjectColumn
          Direction="column"
          style={{ marginLeft: "5%", marginRight: "3%" }}
        >
          <LabelStudent
            htmlFor="subCategory"
            style={{
              marginTop: 20,
              marginLeft: 5,
              fontSize: 18,
              color: "#003267",
            }}
          >
            Projects added
          </LabelStudent>
          <StudentSystemComponents
            system={system}
            onRemove={onRemove}
            student={student}
          />
        </AddProjectColumn>
        <AddProjectColumn
          alignment={"center"}
          style={{
            justifyContent: "space-around",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: "25%",
            marginRight: "25%",
          }}
        >
          <SaveButton onClick={onClose}>SAVE</SaveButton>
        </AddProjectColumn>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "35px",
        }}
      ></DialogActions>
    </DialogStyle>
  );
};

export default StudentSystemAdd;
