import { AiTwotoneSetting } from "react-icons/ai";
import { IoBriefcaseSharp } from "react-icons/io5";
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from "react-icons/tb";
import {
  SpanSidebar,
  SideBarContainer,
  SidebarLink,
  SidebarLogo,
  SidebarBoxHome,
  SidebarBox,
  SidebarBoxTitle,
  LayoutSidebarDownerText,
  LayoutSidebarDownerTextSpan,
  LayoutSidebarFooter,
  LayoutSidebarUpperText,
  LayoutSidebarCollaps,
  SidebarResponsiveLogo,
} from "./sidebar.styles";
import {
  FaBuilding,
  FaCompass,
  FaHome,
  FaUserAlt,
  FaTicketAlt,
  FaStore,
} from "react-icons/fa";
import { useAppSelector } from "../../app/hooks";
import { selectedProject } from "../../features/home/appSlice/appslice";
import { selectAdmin } from "../../features/auth/authSlice/authSlice";
import { useState } from "react";

type SidebarProps = {
  toggleSidebar: () => void;

};
const Sidebar: React.FC<
 SidebarProps
> = ({ toggleSidebar }) => {
  const project = useAppSelector(selectedProject);
  const admin = useAppSelector(selectAdmin);


  return (
    <SideBarContainer  >
      <SidebarLogo src={require("../../assets/image/gradegyLogo.png")} className="desktop-logo"/>
      <SidebarResponsiveLogo src={require("../../assets/image/g-icon.png")} className="responsive-logo"/>
      <SidebarBoxHome>
        <SidebarLink to="/">
          {" "}
          <FaHome color="#fff" size={20} /> <p> Home</p>
        </SidebarLink>
      </SidebarBoxHome>

      {project! ? (
        <SidebarBox>
          <SidebarBoxTitle>Student</SidebarBoxTitle>
          <SidebarLink to="/student">
            <FaUserAlt color="#fff" size={20} /> <p>Students</p>
          </SidebarLink>
          <SidebarLink to="/experience">
            <FaCompass color="#fff" size={20} /> <p>Experience</p>
          </SidebarLink>
          {project.reward_system && (
            <>
              <SidebarLink to="/shop">
                <FaStore color="#fff" size={20} /> <p>Shop</p>
              </SidebarLink>
              <SidebarLink to="raffelticket">
                <FaTicketAlt color="#fff" size={20} /> <p>Raffle Ticket</p>
              </SidebarLink>
            </>
          )}

          <SidebarLink to="/setting">
            <AiTwotoneSetting color="#fff" size={20} /> <p>Setting</p>
          </SidebarLink>
        </SidebarBox>
      ) : null}

      {admin?.adminLevel === "ULTRA" || admin?.adminLevel === "SUPER" ? (
        <SidebarBox>
          <SidebarBoxTitle style={{ marginTop: "2em" }}>Admin</SidebarBoxTitle>

          <SidebarLink to="/organization">
            <FaBuilding color="#fff" size={20} /> <p>Organization</p>
          </SidebarLink>

          <SidebarLink to="/admin">
            <IoBriefcaseSharp color="#fff" size={20} /> <p>Admin</p>
          </SidebarLink>
        </SidebarBox>
      ) : null}

      <LayoutSidebarFooter>
        <LayoutSidebarUpperText>
          Powered by <SpanSidebar>GRADegy AI</SpanSidebar>
        </LayoutSidebarUpperText>
        <LayoutSidebarDownerText>
          Organized by{" "}
          <LayoutSidebarDownerTextSpan to="https://ufcsc.org/">UFCS Collation</LayoutSidebarDownerTextSpan>
        </LayoutSidebarDownerText>
      </LayoutSidebarFooter>
        <LayoutSidebarCollaps>
           <TbLayoutSidebarLeftCollapse color="#fff" size={25} onClick={toggleSidebar} className="collapse-icon open"  />
           <TbLayoutSidebarRightCollapse color="#fff" size={25} onClick={toggleSidebar} className="collapse-icon close"  />
        </LayoutSidebarCollaps>

    
    </SideBarContainer>
  );
};

export default Sidebar;
