import React, { FC } from "react";
import { OrganizationActionProps } from "../../types/types";
import SearchInputBox from "../../../../components/design/searchInputBox/SearchInputBox";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  AddOrganizationButton,
  OrganizationActionAddButtonWrapper,
  OrganizationActionSearchWrapper,
  OrganizationActionWrapper,
} from "./organization.action.styles";
import { OrganizationButon } from "../organization.add.presentation/organization.add.styles";
import { MdAddCircle } from "react-icons/md";
const OrganizationActionPresentation: FC<OrganizationActionProps> = ({
  handleOpen,
  onTerm,
  term,
}) => {
  return (
    <OrganizationActionWrapper>
      <OrganizationActionSearchWrapper>
        <SearchInputBox
          name="organization"
          type="Search Organization"
          placeholder="Search Organization"
          value={term}
          onChange={(e) => onTerm!(e.target.value)}
        />
      </OrganizationActionSearchWrapper>
      <OrganizationActionAddButtonWrapper>
        <AddOrganizationButton
          style={{
            paddingTop: 6,
            paddingBottom: 6,
            fontSize: 16,
            width: "fit-content",
          }}
          onClick={handleOpen}
        >
          {" "}
          <span style={{ paddingRight: 18 }}>Add Organization</span>{" "}
          <MdAddCircle size={32} />
        </AddOrganizationButton>
      </OrganizationActionAddButtonWrapper>
    </OrganizationActionWrapper>
  );
};

export default OrganizationActionPresentation;
