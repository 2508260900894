import React, { useEffect, useState } from "react";
import RaffelTicketListPresentation from "../presentation/RaffelTicketListPresentation/RaffelTicketListPresentation";
import {
  useGetAllRaffelTicketQuery,
  useLazyGetAllRaffelTicketQuery,
} from "../api/raffelTicketApiSlice";
import { Project } from "../../project/types/types";
import { Category } from "../../category/types/types";
import { SubCategory } from "../../subcategory/types/types";
import { useNavigate } from "react-router-dom";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { RaffelTicket } from "../types/types";
type RaffelTicketListContainerProp = {
  project: Partial<Project> | null;
  category: Partial<Category> | null;
  subCategory: Partial<SubCategory> | null;
  page?: number;
  limit?: number;
  sort: string;
  term?: string;
  onSort: (fieldName: string) => void;
  onPage: (pageNumber: number) => void;
  onLimit: (limit: number) => void;
};
const RaffelTicketListContainer: React.FC<RaffelTicketListContainerProp> = ({
  project,
  category,
  subCategory,
  page,
  limit,
  sort,
  term,
  onSort,
  onLimit,
  onPage,
}) => {
  const navigation = useNavigate();

  const [raffleListData, setRaffleListData] =
    useState<EntityState<RaffelTicket> | null>(null);

  const [
    getAllRaffle,
    {
      isLoading: adminListisLoading,
      isSuccess: adminListSuccess,
      isError: adminListError,
    },
  ] = useLazyGetAllRaffelTicketQuery();

  const {
    data: getAllRaffelTicket,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllRaffelTicketQuery({
    project: project!._id!,
    category: String(category?._id!) ? String(category?._id!) : "null",
    subCategory: String(subCategory?._id!),
    page: String(page!),
    limit: String(limit!),
    sort: String(sort),
    term: String(term!),
  });

  useEffect(() => {
    (async () => {
      if (project) {
        try {
          const list = await getAllRaffle({
            project: project!._id!,
            category: String(category?._id!) ? String(category?._id!) : "null",
            subCategory: String(subCategory?._id!),
            page: String(page!),
            limit: String(limit!),
            sort: String(sort),
            term: String(term!),
          }).unwrap();

          setRaffleListData(list);

          if (term !== "null") {
            onPage(1);
          } else {
            onPage(page!);
          }
        } catch (error) {}
      }
    })();
  }, [
    project,
    category,
    subCategory,
    getAllRaffelTicket,
    term,
    sort,
    page,
    limit,
  ]);

  // const [raffleDataList, { isLoading:adminListisLoading, isSuccess:adminListSuccess, isError:adminListError }] =
  // useLazyGetAllRaffelTicketQuery();

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const rafList = await raffleDataList({
  //         project: project!._id!,
  //         category: String(category?._id!) ? String(category?._id!) : "null",
  //         subCategory: String(subCategory?._id!),
  //         page: String(page!),
  //         limit: String(limit!),
  //         sort: String(sort),
  //         term: String(term!),
  //       }).unwrap();

  //       setRaffleListData(rafList);
  //     } catch (error) {}
  //   })();
  // }, [raffleListData, term , sort]);

  console.log(raffleListData, "raffleListDataraffleListDataraffleListData");

  const editHanlder = (id: EntityId) => {
    navigation(`/raffelticket/${id}`);
  };

  useEffect(() => {
    if (term !== "null") {
      onPage(1);
    } else {
      onPage(Number(page));
    }
  }, [term]);
  return (
    <>
      {/* make sure that raffleDataList state not null after that render the raffleTicketListPresentation component */}
      {adminListSuccess && raffleListData && (
        <RaffelTicketListPresentation
          raffelList={raffleListData!}
          editHandler={editHanlder}
          onSort={onSort}
          sort={sort}
          page={page}
          onPage={onPage}
          onLimit={onLimit}
          limit={limit}
        />
      )}
    </>
  );
};

export default RaffelTicketListContainer;
