import { Paper } from "@mui/material";
import React from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import PolygonChart from "../../../../components/PolygonChart/PolygonChart";
import ShowListInTagFormat from "../../../../components/showListInTagFormat/ShowListInTagFormat";
import { OrganizationDetailsContainer, OrganizationInfo, OrganizationPic, OrganizationProjectEditButtton, OrganizationEditButttonText, OrganizationEditButttonIcon, ProjectCatListContainer, ToggleBtn } from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import { ProjectDetailsActionPersentationProps } from "../../types/types";

const ProjectDetailsActionPresentation: React.FC<
  ProjectDetailsActionPersentationProps
> = ({ project, handleOpen }) => {
  console.log(project);
  return (

    <OrganizationDetailsContainer className="">
      <Paper
        elevation={3}
        className="st-info"
        style={{ flexDirection: "column", padding: 20 }}
      >
        <ToggleBtn onClick={() => { }} >
          <AiOutlineCaretDown />
        </ToggleBtn>
        {!true ? (
          <span
            style={{
              position: "absolute",
              top: 8,
              left: 10,
              color: "black",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            Project detail
          </span>
        ) : null}
        {true ? (
          <OrganizationInfo>
            <OrganizationPic>
              <PolygonChart
                XP={3499}
                studentPic={project?.image}
                color="#000"
                style={{ transform: "scale(0.35)", top: -98, left: -17 }}
              />
            </OrganizationPic>
            <div style={{ paddingLeft: 40 }}>
              <h1>{project?.title}</h1>
              <OrganizationProjectEditButtton
                onClick={handleOpen}
              >
                <OrganizationEditButttonText>Edit</OrganizationEditButttonText>
                <OrganizationEditButttonIcon>
                  <MdEdit color="#34C38F" size={12} />
                </OrganizationEditButttonIcon>
              </OrganizationProjectEditButtton>
            </div>
          </OrganizationInfo>
        ) : null}
        {true ? (
          <div style={{ display: "flex", paddingTop: 25 }}>
            <div
              className="student-info first-col"
              style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <div style={{ width: "12%" }}>
                <h3>Project Type</h3>
                <p>{project?.project_type}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Project ID </h3>
                <p>{project?._id}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Condition</h3>
                <p>{project?.condition}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Status</h3>
                <p>{project?.status}</p>
              </div>
              <div style={{ width: "12%" }}>
                <h3>Students</h3>
                <p>{project?.studentsNumber}</p>
              </div>
              <div style={{ borderRight: 0, position: "relative", paddingTop: 17, width: "27%" }}>
                <h3 style={{ maxWidth: 235, position: "absolute", top: 0 }}>
                  Organizations - Collaboration
                </h3>
                <ProjectCatListContainer
                  style={{
                    width: "max-content",
                    padding: 12,
                    display: "flex",
                    overflow: "auto",
                    maxHeight: 125,
                    minWidth: 325,
                  }}
                >
                  {project && (
                    <ShowListInTagFormat
                      list={project.organizations}
                      unRemovabelFist={true}
                    />
                  )}



                </ProjectCatListContainer>
              </div>
            </div>
          </div>
        ) : null}
      </Paper>
    </OrganizationDetailsContainer>

  );
};

export default ProjectDetailsActionPresentation;
