import React from "react";
import { Bag } from "../../../bag/types/types";
import { TdBody } from "../../../shop/presentation/ShopEditReviewPresentation/ShopEditReviewRightcolPresentation/shop.right.col.style";
import { Switch } from "@mui/material";
import { Student } from "../../../students/types/types";
export type RaffelticketStudentItemPresenationProps = {
  itemBag: Bag;
};
const RaffelTicketStudentItemPresenation: React.FC<
  RaffelticketStudentItemPresenationProps
> = ({ itemBag }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "0 40px",
        }}
      >
        {" "}
        <TdBody style={{ justifyContent: "flex-start" }}>
          {(itemBag.student as Student).fullName}
        </TdBody>
        <TdBody>
          {new Date(
            new Date(itemBag.createdAt).toISOString()
          ).toLocaleDateString("en-US")}
        </TdBody>
        <TdBody>{itemBag.ticket}</TdBody>
        <TdBody>{itemBag.winner ? "Yes" : "No"}</TdBody>
        <TdBody>
          <Switch
            checked={itemBag.pickedUp}
            onChange={(e) => {
              // OnPickupBagClick(!itemBag.pickedUp, itemBag);
            }}
          />
        </TdBody>
        <TdBody>
          {itemBag.updatedAt
            ? new Date(
                new Date(itemBag.updatedAt).toISOString()
              ).toLocaleDateString("en-US")
            : "---"}
        </TdBody>
        {/* <TdBody style={{ fontSize: "10px" }}>{itemBag.sotck_id}</TdBody> */}
      </div>
    </>
  );
};

export default RaffelTicketStudentItemPresenation;
