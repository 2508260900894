import React from "react";
import { IoMdAdd } from "react-icons/io";
import { ProjectSection, ProjectInput, ProjectCircleIcon } from "../../../category/presentation/categorychagneLabelpresentation/category.changelabel.presentation.style";
import { SubCategoryAddPresentationProp } from "../../types/types";
const SubCategoryAddPresentation: React.FC<SubCategoryAddPresentationProp> = ({
  title,
  titleChangeHandler,
  onSubCategorySave,
}) => {
  return (





    <ProjectSection style={{ marginTop: 45 }} alignment={""} Direction={""}>
      <ProjectInput
        type="text"
        name="category"
        id="category"
        value={title}
        onChange={titleChangeHandler}
      />
      <ProjectCircleIcon
        style={{ marginLeft: 12 }}
        onClick={onSubCategorySave}
      >
        <IoMdAdd color="#fff" size={14} />
      </ProjectCircleIcon>
    </ProjectSection>



  );
};

export default SubCategoryAddPresentation;
