import React from "react";
import "./crystal.css";

import { images } from "../../constants";
type CrystalsPropProp = {
  crystalNumber: number;
};
const Crystals: React.FC<CrystalsPropProp> = ({ crystalNumber }) => {
  return (
    <div className="crystal-student">
      <img src={images.crystal} alt="" style={{width:25}}  />
      <p className="crystal-number">{crystalNumber}</p>
      <p className="crystal">Focus Crystal</p>
    </div>
  );
};

export default Crystals;
