import {
  createSelector,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import { apiSlice } from "../../../app/api/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SubCategory } from "../types/types";
import { IResponseList } from "../../../types/types";
import { RootState, store } from "../../../app/store";
import { setMetaData } from "../subCategorySlice/subCategorySlice";
import { url } from "inspector";

const subCategoryAdaptor = createEntityAdapter<SubCategory>({
  selectId: (subCategory) => subCategory._id!,
});

export interface SubCategoryInitialState extends EntityState<SubCategory> {}

const initialState: SubCategoryInitialState =
  subCategoryAdaptor.getInitialState();

export const extendSubCategoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubCategoryByCategoryId: builder.query<
      EntityState<SubCategory>,
      EntityId
    >({
      query: (id: EntityId) => `/subcategory/${id}`,
      transformResponse: (response: IResponseList<SubCategory[]>) => {
        const subCategoryData = response.data;
        const subCategoryMetaData = response.metadata;

        store.dispatch(setMetaData(subCategoryMetaData));
        return subCategoryAdaptor.setAll(initialState, subCategoryData);
      },
       //change the provideTags to the simple version
      providesTags:  [
        "SubCategory"
      ],
    }),
    getSubCategoryById: builder.query<EntityState<SubCategory>, EntityId>({
      query: (id: EntityId) => `/subcategory/subcategorydetails/${id}`,
      transformResponse: (response: SubCategory) => {
        return subCategoryAdaptor.setOne(initialState, response);
      },
      providesTags: (result, error, arg) => [
        ...result!.ids.map((id) => ({ type: "SubCategory" as const, id })),
      ],
    }),
    addNewSubCategory: builder.mutation<
      EntityState<SubCategory>,
      Partial<SubCategory>
    >({
      query: (subcategory) => ({
        url: "/subcategory",
        method: "POST",
        body: subcategory,
      }),
      transformResponse: (response: SubCategory) => {
        return subCategoryAdaptor.addOne(initialState, response);
      },
      invalidatesTags: ["SubCategory"],
    }),

    editSubCategory: builder.mutation<
      EntityState<SubCategory>,
      Partial<SubCategory>
    >({
      query: (subcategory) => ({
        url: `/subcategory/${subcategory._id}`,
        method: "PUT",
        body: subcategory,
      }),
      transformResponse: (response: SubCategory) => {
        console.log("response", response);
        return subCategoryAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: "SubCategory",
          id: "LIST",
        },
        ...result!.ids.map((id) => ({
          type: "SubCategory" as const,
          id: arg._id,
        })),
      ],
    }),
  }),
});

export const {
  useAddNewSubCategoryMutation,
  useEditSubCategoryMutation,
  useGetSubCategoryByIdQuery,
  useGetAllSubCategoryByCategoryIdQuery,
  useLazyGetAllSubCategoryByCategoryIdQuery,
  useLazyGetSubCategoryByIdQuery,
} = extendSubCategoryApi;

export const selectSubCategoryResult = (state: RootState, id: EntityId) =>
  extendSubCategoryApi.endpoints.getAllSubCategoryByCategoryId.select(id)(
    state
  );

export const selectSubCateogryData = createSelector(
  selectSubCategoryResult,
  (subCategoryResult) =>
    subCategoryAdaptor.getSelectors(
      () => subCategoryResult?.data ?? initialState
    )
);

export const selectAllSubCategories = createSelector(
  selectSubCateogryData,
  (result) => result.selectAll
);
export const selectAllSubCategoryByIds = createSelector(
  selectSubCateogryData,
  (result) => result.selectIds
);
export const selectAllSubCategoryById = createSelector(
  selectSubCateogryData,
  (result) => result.selectById
);
export const selectAllSubCategoriesEntities = createSelector(
  selectSubCateogryData,
  (result) => result.selectEntities
);
