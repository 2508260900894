import { DialogContent, DialogActions, Icon } from "@mui/material";
import { title } from "process";
import React from "react";
import UploadImageContainer from "../../../../components/UploadImage/UploadImageContainer";
import DisplayButton from "../../../../components/design/Button/DisplayButton";
import InputField from "../../../../components/design/inputField/InputField";
import SelectField from "../../../../components/design/selectFiled/selectField";
import {
  DialogStyle,
  OrganizationAddWrapper,
  OrganizationAddRow,
  OrganizationAddCol,
} from "../../../organization/presantation/organization.add.presentation/organization.add.styles";
import SearchSchoolDistrictContainer from "../../../schoolDistrict/searchSchoolDistrict/search.schoolDestict.container";
import SearchUniversityContainer from "../../../unviersity/container/university.container";
import SearchAdminContainer from "../../conatiner/admin.search.container/admin.search.container";
import { AdminAddPresentationProps } from "../../types/types";
import {
  AboutAddAdminWraper,
  AboutSectionWrapper,
  AddAdminButton,
  AddAdminHeaderWrapper,
  AddAdminWrapper,
  AdminFirstNameWrapper,
  AdminHighSchoolWrapper,
  AdminImageWrapper,
  AdminInput,
  AdminLastNameWrapper,
  AdminLevelLabel,
  AdminLevelSelectWrapper,
  AdminLevelWrapper,
  AdminLinkWrapper,
  AdminOrganizationInfoDetailsWrapper,
  AdminOrganizationInfoOrganizationSelectWrapper,
  AdminOrganizationInfoWrapper,
  AdminSchoolWrapper,
  AdminTitle,
  AdminTitleFirstNameAndLastNameWrapper,
  AdminTitleWrapper,
  CreateAdminForm,
  CreateAdminFormCol,
  CreateAdminFormRow,
  HighSchoolLabel,
  LabelAdmin,
  OrganizationInfoLabel,
  ProjectLabel,
} from "./admin.add.presentation.styles";
import SelectOrganizationListContainer from "../../../organization/container/select.organization.list.container/select.organization.list.container";
import FilterByOrganizationContainer from "../../../organization/container/filter.by.organization.container/FilterByOrganization.container";
import FilterByProjectContainer from "../../../project/container/filter.by.project.container/FilterByProjectContainer";
import { Project } from "../../../project/types/types";
import FilterCategoryContainer from "../../../category/container/filter.category.container/FilterCategoryContainer";
import { Category } from "../../../category/types/types";
import SearchCategoryContainer from "../../../category/container/search.category.container/SearchCategoryContainer";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Controller } from "react-hook-form";
import { error } from "console";
import { EntityId } from "@reduxjs/toolkit";
import { images } from "../../../../constants";
import { useAppSelector } from "../../../../app/hooks";
import { selectAdmin } from "../../../auth/authSlice/authSlice";
const AdminAddPresentation: React.FC<AdminAddPresentationProps> = ({
  openAddAdminState,
  openAddAdminHandler,
  closeHandler,
  authAdmin,
  image,
  firstName,
  lastName,
  fullName,
  adminLevel,
  email,
  jobTitle,
  onAdminLevelHandler,
  onEmailHandler,
  onFirstNameHandler,
  onImageHandler,
  onJobTitleHandler,
  onLastNameHandler,
  onPasswordHandler,
  onPhoneHandler,
  password,
  phone,
  setOrganizationHandler,
  organization,
  status,
  project,
  category,
  onCategoryHandler,
  onProjectHanlder,
  categoryLink,
  onCategoryLinkHandler,
  onSaveHandler,
  onCancel,
  control,
  formState,
  onSubmit,
  handleSubmit,
  reset,
  register,
  projectAccess,
  saveLoading,
}) => {
  const admin = useAppSelector(selectAdmin);
  console.log("proiejct accesssssss .........", projectAccess);
  return (
    <DialogStyle
      open={openAddAdminState}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <CreateAdminForm>
          <CreateAdminFormRow style={{ paddingLeft: 55 }}>
            <CreateAdminFormCol
              alignment="flex-start"
              style={{ paddingLeft: 0, position: "relative" }}
              Direction={""}
            >
              <UploadImageContainer
                defaultImage={image}
                onChangeImageUrlState={onImageHandler}
                hexShape
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol
              Direction="column"
              alignment="sttart"
              style={{ alignItems: "center" }}
            >
              <div
                style={{
                  paddingLeft: 12,
                  paddingTop: 22,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Controller
                  control={control}
                  defaultValue={firstName}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <div>
                      <AdminInput
                        Width="329px"
                        Height="53px"
                        Size="41px"
                        Weight="700"
                        placeholder="First Name"
                        SpacingTB={""}
                        SpacingLR={""}
                        onChange={onChange}
                        value={value}
                      />
                      <p
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                        }}
                      >
                        {formState.errors.firstName?.message}
                      </p>
                    </div>
                  )}
                  name="firstName"
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <AdminInput
                        Width="329px"
                        Height="53px"
                        Size="41px"
                        Weight="700"
                        placeholder="Last Name"
                        SpacingTB={""}
                        SpacingLR={""}
                        onChange={onChange}
                        value={value}
                      />
                      <p
                        style={{
                          color: "red",
                          paddingLeft: "10px",
                        }}
                      >
                        {formState.errors.lastName?.message}
                      </p>
                    </div>
                  )}
                />
              </div>
              <CreateAdminFormCol
                Direction="column"
                style={{ paddingTop: 20, paddingRight: "24%" }}
                alignment={""}
              >
                <LabelAdmin>Admin Level</LabelAdmin>
                <Controller
                  name="adminLevel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectField
                      onChange={(e) => {
                        field.onChange(e);
                        onAdminLevelHandler(e);
                      }}
                      defaultValue={field.value}
                    >
                      {admin?.adminLevel === "ULTRA" && (
                        <>
                          <option value="ULTRA">ULTRA</option>
                          <option value="SUPER">SUPER</option>
                        </>
                      )}

                      <option value="ADMIN">ADMIN</option>
                    </SelectField>
                  )}
                />
              </CreateAdminFormCol>
            </CreateAdminFormCol>
          </CreateAdminFormRow>

          {adminLevel === "SUPER" || adminLevel === "ADMIN" ? (
            <>
              <CreateAdminFormRow
                style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 35 }}
              >
                <AdminTitle>Organization Info</AdminTitle>
              </CreateAdminFormRow>
              <CreateAdminFormRow
                style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 4 }}
              >
                <CreateAdminFormCol
                  style={{
                    padding: 0,
                    paddingRight: 10,
                    borderRightWidth: 2,
                    borderRightColor: "#c8c8c8",
                    borderRightStyle: "solid",
                  }}
                  Direction="column"
                  alignment={""}
                >
                  <LabelAdmin>Organization</LabelAdmin>

                  <Controller
                    name="organization"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <FilterByOrganizationContainer
                          onSelectOrganization={(e) => {
                            onChange(e?._id);
                            setOrganizationHandler!(e);
                          }}
                          selectedDefault={value}
                        />
                        <p>{formState.errors.organization?.message}</p>
                      </>
                    )}
                  />
                </CreateAdminFormCol>

                {organization?.organization_type === "Secondary" &&
                adminLevel === "ADMIN" ? (
                  <>
                    <CreateAdminFormCol
                      style={{
                        width: "196px",
                        paddingRight: 10,
                        borderRightWidth: 2,
                        borderRightColor: "#c8c8c8",
                        borderRightStyle: "solid",
                      }}
                      Direction="column"
                      alignment={""}
                    >
                      <LabelAdmin>School District</LabelAdmin>
                      <Controller
                        name="projectAccess"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FilterByProjectContainer
                            organizationID={organization?._id!}
                            onSelectProject={(e) => {
                              onChange(e?._id);
                              onProjectHanlder!(e);
                            }}
                            selectedDefault={value}
                          />
                        )}
                      />
                    </CreateAdminFormCol>
                    <CreateAdminFormCol
                      Direction="column"
                      style={{ position: "relative" }}
                      alignment={""}
                    >
                      <LabelAdmin>School</LabelAdmin>

                      <Controller
                        name="categoryAccess"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <FilterCategoryContainer
                              onSelectCategory={(e) => {
                                onChange(e?._id);
                                onCategoryHandler!(e);
                              }}
                              selectedDefault={value}
                              projectID={project?._id as EntityId}
                            />
                            {/* <SearchCategoryContainer
                              onChangeState={(e) => {
                                // onChange(e._id);
                                // onCategoryHandler!(e);
                              }}
                              filterId={}
                            /> */}
                          </>
                        )}
                      />

                      {/* <Icon
                        component={categoryLink ? LinkIcon : LinkOffIcon}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={onCategoryLinkHandler}
                      /> */}
                    </CreateAdminFormCol>
                  </>
                ) : organization?.organization_type === "Post Secondary" &&
                  adminLevel === "ADMIN" ? (
                  "Post Secondary" && (
                    <>
                      <CreateAdminFormCol
                        style={{
                          width: "200px",
                          paddingRight: 10,
                          borderRightWidth: 2,
                          borderRightColor: "#c8c8c8",
                          borderRightStyle: "solid",
                        }}
                        Direction="column"
                        alignment={""}
                      >
                        <LabelAdmin>University</LabelAdmin>
                        <FilterByProjectContainer
                          organizationID={organization?._id!}
                          onSelectProject={onProjectHanlder!}
                        />
                      </CreateAdminFormCol>
                    </>
                  )
                ) : null}
              </CreateAdminFormRow>
            </>
          ) : null}

          <CreateAdminFormRow
            style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 16 }}
          >
            <AdminTitle>About</AdminTitle>
          </CreateAdminFormRow>
          <CreateAdminFormRow
            style={{ paddingRight: 20, paddingLeft: "11%", marginTop: 4 }}
          >
            <CreateAdminFormCol
              style={{
                padding: 0,
                borderRightWidth: 2,
                borderRightColor: "#c8c8c8",
                borderRightStyle: "solid",
              }}
              Direction="column"
              alignment={""}
            >
              <LabelAdmin>Phone</LabelAdmin>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <AdminInput
                      Width="158px"
                      Height="33px"
                      Size="20px"
                      SpacingTB="1px"
                      SpacingLR="10px"
                      style={{
                        marginRight: "10px",
                        marginLeft: "0px",
                      }}
                      Weight={""}
                      onChange={onChange}
                      value={value}
                    />
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {formState.errors.phone?.message}
                    </p>
                  </>
                )}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol
              style={{
                paddingRight: 0,
                borderRightWidth: 2,
                borderRightColor: "#c8c8c8",
                borderRightStyle: "solid",
              }}
              Direction="column"
              alignment={""}
            >
              <LabelAdmin>Email</LabelAdmin>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <AdminInput
                      Width="158px"
                      Height="33px"
                      Size="20px"
                      SpacingTB="1px"
                      SpacingLR="10px"
                      onChange={onChange}
                      value={value}
                      style={{
                        marginRight: "10px",
                        marginLeft: "0px",
                      }}
                      Weight={""}
                    />
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {formState.errors.email?.message}
                    </p>
                  </>
                )}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol
              style={{
                paddingRight: 0,
                borderRightWidth: 2,
                borderRightColor: "#c8c8c8",
                borderRightStyle: "solid",
              }}
              Direction="column"
              alignment={""}
            >
              <LabelAdmin>Password</LabelAdmin>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <>
                    <AdminInput
                      Width="158px"
                      Height="33px"
                      Size="20px"
                      SpacingTB="1px"
                      SpacingLR="10px"
                      value={value}
                      onChange={onChange}
                      style={{
                        marginRight: "10px",
                        marginLeft: "0px",
                      }}
                      Weight={""}
                    />
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {formState.errors.password?.message}
                    </p>
                  </>
                )}
              />
            </CreateAdminFormCol>
            <CreateAdminFormCol Direction="column" alignment={""}>
              <LabelAdmin>Job Title</LabelAdmin>
              <Controller
                name="jobTitle"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <AdminInput
                      Width="158px"
                      Height="33px"
                      Size="20px"
                      SpacingTB="1px"
                      SpacingLR="10px"
                      value={value}
                      onChange={onChange}
                      style={{
                        marginRight: "10px",
                        marginLeft: "0px",
                      }}
                      Weight={""}
                    />
                    <p
                      style={{
                        color: "red",
                      }}
                    >
                      {formState.errors.jobTitle?.message}
                    </p>
                  </>
                )}
              />
            </CreateAdminFormCol>
          </CreateAdminFormRow>
          <CreateAdminFormRow
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 30,
              marginBottom: 30,
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CreateAdminFormCol
              style={{ justifyContent: "flex-end", paddingRight: 10 }}
              alignment={""}
              Direction={""}
            >
              <AddAdminButton
                disabled={formState.isSubmitting}
                variant="contained"
                type="submit"
              >
                {formState.isSubmitting ? (
                  <img
                    src={images.saveLoadingSpinner}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    alt="this is test"
                  />
                ) : (
                  "Save"
                )}
              </AddAdminButton>
            </CreateAdminFormCol>
            <CreateAdminFormCol
              style={{ paddingLeft: 30 }}
              alignment={""}
              Direction={""}
            >
              <AddAdminButton onClick={onCancel}>Cancel</AddAdminButton>
            </CreateAdminFormCol>
          </CreateAdminFormRow>
        </CreateAdminForm>
      </form>
    </DialogStyle>
  );
};

export default AdminAddPresentation;
