import { createSlice } from "@reduxjs/toolkit";
import { Analytic, MetaData } from "../../../types/types";
import { RootState } from "../../../app/store";
import { StudentSystemType } from "../types/types";

interface IinitialState {
  accountSelected: StudentSystemType | null;
  metadata: MetaData;
  analytics?: Analytic;
}

const initialState: IinitialState = {
  accountSelected: null,
  metadata: {
    toatlCount: 0,
    totalPage: 0,
    nextPage: 0,
    prevPage: 0,
  },
  analytics: {
    totalCompleted: 0,
    totalNotcompleted: 0,
    totalView: 0,
  },
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    },
    setAccountSelected: (state, action) => {
      state.accountSelected = action.payload;
    },
  },
});

export const accountSelected = (state: RootState) =>
  state.student.accountSelected;

export const analyticsStudentExperience = (state: RootState) =>
  state.student.analytics;

export const { setMetaData, setAnalytics, setAccountSelected } =
  studentSlice.actions;
export default studentSlice.reducer;
