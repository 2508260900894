import { EntityId } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";
import { ExperiencePhoto } from "../../students/types/types";

export const experiencePhotoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    removePhoto: builder.mutation<ExperiencePhoto, EntityId>({
      query: (id) => ({
        url: `/experience-photo/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [
        "Student",
        "Admin",
        "ExperienceStudent",
        "ExperienceCard",
        "StudentCompletionPhoto",
        "LeaderBoard",
      ],
    }),
  }),
});

export const { useRemovePhotoMutation } = experiencePhotoApiSlice;
