import React from "react";

import { BsFillCaretRightFill } from "react-icons/bs";
import { BsFillCaretLeftFill } from "react-icons/bs";

import { IconButton } from "@material-ui/core";
import { FaTrashAlt } from "react-icons/fa";

import "./slider.css";

import PolygonChart from "../PolygonChart/PolygonChart";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import { images } from "../../constants";
import { Student } from "../../features/students/types/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { useRemovePhotoMutation } from "../../features/experiencePhoto/api/experiencePhotoApi";
type SliderType = {
  item: EntityState<Student>;
  id: EntityId;
  nextBtnHandler: () => void;
  leftBtnHandler: () => void;
  backdropHandler: () => void;
};

const Slider: React.FC<SliderType> = ({
  item,
  id,
  nextBtnHandler,
  leftBtnHandler,
  backdropHandler,
}) => {
  const changeToggelHandler = async () => {};
  const [removePhoto, { isLoading, isError, isSuccess }] =
    useRemovePhotoMutation();

  return (
    <>
      {/* {item.entities[id]?.photos && ( */}
      <>
        <div className="backdrop" onClick={backdropHandler} key={""}>
          {" "}
        </div>
        <div className="imageSlider">
          <div className="imageSlide">
            <img
              src={item.entities[id]?.photos!.photo}
              alt="gradify"
              className="imageStyle"
            />
            <div className="slideButtons">
              <CustomSwitch
                style={{
                  position: "relative",
                  top: 12,
                  left: 8,
                  transform: "scale(1.2)",
                }}
                isToggle={
                  item.entities[id]?.completion?.complete
                    ? item.entities[id]!.completion?.complete
                    : false
                }
                onChangeToggle={changeToggelHandler}
              />
              <IconButton
                onClick={async () => {
                  try {
                    console.log("run");
                    await removePhoto(item.entities[id]?.photos?._id!).unwrap();
                    backdropHandler();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <FaTrashAlt color="#fff" />
              </IconButton>
            </div>
            <div className="slideBoxProfile">
              <div className="slideBoxProfileContent">
                <PolygonChart studentPic={item.entities[id]?.image!} />
                <p style={{ fontSize: 70, overflow: "visible" , color:"#fff", paddingTop:5}}>
                  {item.entities[id]?.fullName!}
                </p>
              </div>

              <button className="style" onClick={() => {}}>
                Dwonload
                <img
                  src={images.DownloadsIcon}
                  width="20"
                  color="white"
                  style={{ color: "white" }}
                />
              </button>
            </div>
          </div>

          <button className="nextBtnStyle" onClick={nextBtnHandler}>
            <BsFillCaretRightFill
              style={{
                color: "white",
                transform: "scale(3)",
                marginBottom: -2,
              }}
            />
          </button>
          <button className="previousBtnStyle" onClick={leftBtnHandler}>
            <BsFillCaretLeftFill
              style={{
                color: "white",
                transform: "scale(3)",
                marginBottom: -2,
              }}
            />
          </button>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default Slider;
