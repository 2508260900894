import styled from "@emotion/styled";
import { Icon } from "@mui/material";

export const SerachInputWrapper = styled.div`

    width: 227px;
  height: 36px;
  position: relative;
`;

export const SearchInput = styled.input`
width: 227px;
height: 36px;
border-radius: 50px;
border: 1px solid #ced4da;
padding-left: 35px;
text-indent: 10px;

font-size: 13px;

&:focus {
  outline: none;
}
`;
