import {
  createSelector,
  createEntityAdapter,
  EntityState,
  EntityId,
} from "@reduxjs/toolkit";

import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../../app/api/apiSlice";
import { RootState } from "../../../app/store";
import { Admin } from "../types/types";
import { IResponseList, SearchResult } from "../../../types/types";
import { store } from "../../../app/store";
import { setMetaData } from "../adminSlice/adminSlice";

const adminAdaptor = createEntityAdapter<Admin>({
  selectId: (admin) => admin._id,
});

interface AdminInitialState extends EntityState<Admin> {}

const initialState: AdminInitialState = adminAdaptor.getInitialState();

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query<
      EntityState<Admin>,
      {
        page?: string;
        limit?: string;
        sort?: string;
        term?: string;
      }
    >({
      query: (data) =>
        `/admin?page=${data.page}&limit=${data.limit}&sort=${data.sort}&term=${data.term}`,
      transformResponse: (response: IResponseList<Admin[]>) => {
        const loadedDate = response.data;
        const metadata = response.metadata;
        console.log(response.data);
        store.dispatch(setMetaData(metadata));
        return adminAdaptor.setAll(initialState, loadedDate);
      },
      //use simple way for provided tags
      providesTags: ["Admin"]
    }),
    getAdminsByOrganization: builder.query<
      EntityState<Admin>,
      {
        organizationId: EntityId;
        page?: string;
        limit?: string;
      }
    >({
      query: (data) => `/admin/filterbyorganization/${data.organizationId}`,
      transformResponse: (response: IResponseList<Admin[]>) => {
        const loadedDate = response.data;
        const metadata = response.metadata;
        store.dispatch(setMetaData(metadata));
        return adminAdaptor.setAll(initialState, loadedDate);
      },
      providesTags: ["Admin"],

      // async onQueryStarted({ page, limit }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data: newData } = await queryFulfilled;
      //     adminAdaptor.setAll(
      //       initialState,
      //       Object.values(newData.entities) as Admin[]
      //     );

      //     extendedApiSlice.endpoints.getAdmins.useQuery({ page, limit });
      //     // const patchResult = dispatch(
      //     //   extendedApiSlice.util.updateQueryData(
      //     //     "getAdmins",
      //     //     { page, limit },
      //     //     (draft) => {
      //     //       // Object.assign(draft, newData);

      //     //       adminAdaptor.setAll(
      //     //         initialState,
      //     //         Object.values(draft.entities) as Admin[]
      //     //       );
      //     //     }
      //     //   )
      //     // );
      //   } catch (error) {}
      // },
    }),

    getAdminByProject: builder.query<
      EntityState<Admin>,
      {
        projectId: EntityId;
        page?: string;
        limit?: string;
      }
    >({
      query: (data) => `/admin/filterbyproject/${data.projectId}`,
      transformResponse: (response: IResponseList<Admin[]>) => {
        const loadedDate = response.data;
        console.log("loadedData", loadedDate);
        const metadata = response.metadata;
        store.dispatch(setMetaData(metadata));
        return adminAdaptor.setAll(initialState, loadedDate);
      },
      async onQueryStarted({ page, limit }, { dispatch, queryFulfilled }) {
        try {
          const { data: newData } = await queryFulfilled;
          const patchResult = dispatch(
            extendedApiSlice.util.updateQueryData(
              "getAdmins",
              { page, limit },
              (draft) => {
                Object.assign(draft, newData);
              }
            )
          );
        } catch (error) {}
      },
    }),

    getAdminByCategory: builder.query<
      EntityState<Admin>,
      {
        categoryId: EntityId;
        page?: string;
        limit?: string;
      }
    >({
      query: (data) => `/admin/filterbycategory/${data.categoryId}`,
      transformResponse: (response: IResponseList<Admin[]>) => {
        const loadedDate = response.data;
        const metadata = response.metadata;
        store.dispatch(setMetaData(metadata));
        return adminAdaptor.setAll(initialState, loadedDate);
      },
      async onQueryStarted({ page, limit }, { dispatch, queryFulfilled }) {
        try {
          const { data: newData } = await queryFulfilled;
          const patchResult = dispatch(
            extendedApiSlice.util.updateQueryData(
              "getAdmins",
              { page, limit },
              (draft) => {
                Object.assign(draft, newData);
              }
            )
          );
        } catch (error) {}
      },
    }),

    getAdminBySubCategory: builder.query<
      EntityState<Admin>,
      {
        subCategoryId: EntityId;
        page?: string;
        limit?: string;
      }
    >({
      query: (data) => `/admin/filterbysubcategory/${data.subCategoryId}`,
      transformResponse: (response: IResponseList<Admin[]>) => {
        const loadedDate = response.data;
        const metadata = response.metadata;
        store.dispatch(setMetaData(metadata));
        return adminAdaptor.setAll(initialState, loadedDate);
      },
      async onQueryStarted({ page, limit }, { dispatch, queryFulfilled }) {
        try {
          const { data: newData } = await queryFulfilled;
          const patchResult = dispatch(
            extendedApiSlice.util.updateQueryData(
              "getAdmins",
              { page, limit },
              (draft) => {
                Object.assign(draft, newData);
              }
            )
          );
        } catch (error) {}
      },
    }),
    searchAdmin: builder.query<SearchResult[], string>({
      query: (term: string) => `/admin/search?term=${term}`,
      providesTags: (result, error, arg) => [
        { type: "AdminSearch", id: "LIST" },
        ...result!.map((id) => ({ type: "AdminSearch" as const, id: id.id })),
      ],
    }),
    createAdmin: builder.mutation<EntityState<Admin>, Partial<Admin>>({
      query: (admin) => ({
        url: "/admin",
        method: "POST",
        body: admin,
      }),
      transformResponse: (response: Admin) => {
        return adminAdaptor.setOne(initialState, response);
      },
      invalidatesTags: [{ type: "Admin", id: "LIST" }],
    }),

    updateAdmin: builder.mutation<
      EntityState<Admin>,
      Partial<Admin> & { status?: boolean }
    >({
      query: (admin) => ({
        url: `/admin/${admin._id}`,
        method: "PUT",
        body: admin,
      }),
      transformResponse: (response: Admin) => {
        console.log("ressssssponse", response);
        return adminAdaptor.updateOne(initialState, {
          id: response._id,
          changes: response,
        });
      },
      invalidatesTags: (result, error, arg) => [{ type: "Admin", id: arg._id }],
    }),

    getAdminById: builder.query<EntityState<Admin>, EntityId>({
      query: (id) => `/admin/getAdmin/${id}`,
      transformResponse: (response: Admin) => {
        return adminAdaptor.setOne(initialState, response);
      },
      providesTags: ["Admin"],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useLazyGetAdminsQuery,
  useLazySearchAdminQuery,
  useCreateAdminMutation,
  useGetAdminsByOrganizationQuery,
  useLazyGetAdminsByOrganizationQuery,
  useLazyGetAdminByProjectQuery,
  useLazyGetAdminByCategoryQuery,
  useLazyGetAdminBySubCategoryQuery,
  useUpdateAdminMutation,
  useGetAdminByIdQuery,
} = extendedApiSlice;

export const selectAdminResult = extendedApiSlice.endpoints.getAdmins.select({
  page: "1",
  limit: "500",
});

export const selectedAdminData = createSelector(
  selectAdminResult,
  (adminResult) => {
    return adminResult.data;
  }
);
export const {
  selectAll: selectAllAdmins,
  selectById: selectAdminById,
  selectIds: selectAdminIds,
} = adminAdaptor.getSelectors(
  (state: RootState) => selectedAdminData(state) ?? initialState
);
