import React from "react";
import FilterSubCategoryPresentation from "../../presentation/FilterSubCategoryPresentation/FilterSubCategoryPresentation";
import { FilterSubCategoryContainerProps } from "../../types/types";
import { useGetAllSubCategoryByCategoryIdQuery } from "../../api/subCategoryApiSlice";

const FilterSubCategoryContainer: React.FC<FilterSubCategoryContainerProps> = ({
  categoryID,
  onSelectSubCategory,
  defaultValue,
}) => {
  const {
    data: subCategories,
    isLoading,
    isError,
    isSuccess,
  } = useGetAllSubCategoryByCategoryIdQuery(categoryID!);

  const onSelectSubCategoryValue = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (e.target.value === "All") {
      onSelectSubCategory(null);
    } else {
      onSelectSubCategory(subCategories?.entities[e.target.value]!);
    }
  };

  return (
    <>
      {isSuccess && (
        <>
          <FilterSubCategoryPresentation
            subCategories={subCategories!}
            onSelectSubCategory={onSelectSubCategoryValue}
            defaultValue={defaultValue}
          />
        </>
      )}
    </>
  );
};

export default FilterSubCategoryContainer;
